import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Avatar_21 } from "src/imagepath/imagepath";

export class ForumDetails extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ForumDetails");
    this.state = {
      data: [],
      forumComments: [],
      forumSaves: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      toggleReport: false,
    };
  }
  componentDidMount() {
    this.fetchlist();
  }

  fetchlist() {
    this.props.api
      .apiGetForumDetailsById({ post_id: this.props.urlparams.id })
      .then((data) => {
        console.log({ apiGetForumDetailsById: data });
        this.setState({
          data,
        });
      });
    this.fetchComments();
    this.fetchForumSaves();
  }

  handlePostLike(e, id) {
    let payload = {
      post_id: id,
      like: true,
    };
    this.props.api.apiCreateForumPostLike(payload).then((res) => {
      console.log({ apiCreateForumPostLike: res });
      this.fetchlist();
    });
  }

  handlePostSave(e, id) {
    let payload = {
      post_id: id,
      save: true,
    };
    this.props.api.apiSaveForumPost(payload).then((res) => {
      console.log({ apiSaveForumPost: res });
      this.fetchlist();
    });
  }

  fetchForumSaves() {
    let savePayload = {
      ...this.state.search,
    };
    this.props.api.apiGetForumPostSave(savePayload).then(({ data }) => {
      console.log({ apiGetForumPostSave: data });
      this.setState({
        forumSaves: data,
      });
    });
  }

  fetchComments() {
    let commentPayload = {
      post_id: this.props.urlparams.id,
    };
    this.props.api.apiGetForumComments(commentPayload).then(({ data }) => {
      console.log({ apiGetForumComments: data });
      this.setState({
        forumComments: data,
      });
    });
  }

  handleViewReply(e, id) {
    let payload = {
      parent_comment_id: id,
    };
    this.props.api.apiGetForumCommentReplies(payload).then(({ data }) => {
      console.log({ apiGetForumCommentReplies: data });

      this.setState((prevState) => {
        // console.log({ prevState });

        // Use map to update the specific item in the array
        const updatedItems = prevState.forumComments.map((item) => {
          if (item.comment_id === id) {
            // Update the property you want here
            // console.log({ item });
            return { ...item, replies: [...data] };
          }
          return item;
        });

        // Return the updated state
        return { forumComments: updatedItems };
      });
    });
  }

  handleDeletePost(e, id) {
    let payload = {
      post_id: id,
    };

    confirm(`Do you really want to delete this post?`).promise.then(() => {
      this.props.api.apiDeleteForumPost(payload).then(() => {
        let backlink = `/womenentrepreneur/forum`;
        this.props.navigate(backlink);
      });
    });
  }

  handleDeleteComment(e, id) {
    let payload = {
      comment_id: id,
    };

    confirm(`Do you really want to delete this comment?`).promise.then(() => {
      this.props.api.apiDeleteForumComment(payload).then(() => {
        this.fetchlist();
      });
    });
  }

  handleComment(e, data) {
    console.log({ handleComment: data });

    let payload = {
      post_id: this.props.urlparams.id,
      comment: data.comment,
    };

    if (data?.parent_comment_id) {
      payload.parent_comment_id = data.parent_comment_id;
    }

    this.props.api.apiCreateForumComment(payload).then((res) => {
      console.log({ apiCreateForumComment: res });
      this.fetchlist();
      this.refs.commentform.form.current.reset();
    });
  }

  handleReport(e, data) {
    console.log({ handleReport: data });

    let payload = {
      post_id: this.props.urlparams.id,
      reason: data.reason,
    };

    this.props.api.apiCreateForumPostReport(payload).then((res) => {
      console.log({ apiCreateForumPostReport: res });
      this.fetchlist();
      this.setState({ toggleReport: false });
      this.refs.reportform.form.current.reset();
    });
  }

  handleToggleReply(e, id) {
    let replyBox = document.getElementById(`replyBox-${id}`);
    // let replyCommentBox = document.getElementById(`replyCommentBox-${id}`);

    if (replyBox) replyBox.classList.toggle("hidden");
    // if (replyCommentBox) replyCommentBox.classList.toggle("hidden");
    console.log(replyBox);
  }

  handleCommentReply(e, data, form, id) {
    // console.log({ handleCommentReply: e, data, id });

    const resolveList = () => {
      let replyBox = document.getElementById(`replyBox-${id}`);
      let textarea = document.querySelector(`#replyBox-${id} textarea`);
      textarea.value = "";
      replyBox.classList.toggle("hidden");
    };

    let payload = {
      post_id: this.props.urlparams.id,
      parent_comment_id: id,
      comment: data.replyComment,
    };
    // console.log({ payload });
    this.props.api
      .apiCreateForumComment(payload)
      .then((res) => {
        console.log({ apiCreateForumComment: res });
        this.fetchlist();
        // this.refs.commentform.form.current.reset();
      })
      .then(resolveList);
  }

  getBackLink() {
    let backLink = "/womenentrepreneur/forum";
    return backLink;
  }
  render() {
    let { data, forumComments, forumSaves, toggleReport } = this.state;
    return (
      <LayoutWrapper
        title="Forum"
        back="Forum / Forum Details"
        backlink={this.getBackLink()}
      >
        {!!data.length &&
          data.map((forum) => {
            let postedAt = new Date(forum.createdAt).elapsedTime(new Date(), 1);
            return (
              <div className="forum_details_container card">
                <div className="card-header">
                  <h2 className="title">{forum.title}</h2>
                  <p className="m-0 timestamp">{postedAt}</p>
                </div>
                <div className="card-body">
                  <div className="detail_card">
                    <div className="img">
                      <img src={Avatar_21} alt="user image" />
                    </div>

                    <div className="content">
                      <p className="m-0">Posted by,</p>
                      <p className="m-0 name">{forum.created_by}</p>
                    </div>
                  </div>

                  <div className="content_box">
                    <p>{forum.content}</p>
                  </div>

                  <div className="action_box mb-2">
                    <button
                      type="button"
                      className="ps-0"
                      onClick={(e) => this.handlePostLike(e, forum.post_id)}
                    >
                      <i
                        className={`${
                          forum?.isLikedByUser
                            ? "fa fa-heart active"
                            : "fa fa-heart-o"
                        }`}
                      ></i>
                      <span>{forum?.likes} Likes</span>
                    </button>
                    <button type="button" className="">
                      <i className="fa fa-comment-o"></i>
                      <span>{forumComments.length} Comments</span>
                    </button>
                    <button
                      type="button"
                      className="d-none"
                      onClick={(e) => this.handlePostSave(e, forum.post_id)}
                    >
                      <i className="fa fa-bookmark-o"></i>
                      <span>{forumSaves.length} Save</span>
                    </button>
                    <button
                      type="button"
                      className={`${
                        this.props.store.user.employee_id === forum.employee_id
                          ? ""
                          : this.props.store.user.employee_role === "admin"
                          ? ""
                          : "d-none"
                      }`}
                      onClick={(e) => this.handleDeletePost(e, forum.post_id)}
                    >
                      <i className="fa fa-trash-o"></i>
                      <span>Delete</span>
                    </button>

                    <button
                      type="button"
                      className="d-none"
                      onClick={() =>
                        this.setState({ toggleReport: !toggleReport })
                      }
                    >
                      <i className="fa fa-exclamation-circle"></i>
                      <span>Report an Issue</span>
                    </button>
                  </div>

                  <div
                    className={Object.className({
                      "report_box mb-4": true,
                      hidden: !toggleReport,
                    })}
                  >
                    <AppForm
                      onSubmit={(...args) => this.handleReport(...args)}
                      className="row filter-row flex-auto"
                      ref="reportform"
                    >
                      <div className="col-sm-6 col-md-6">
                        <AppInput
                          type="textarea"
                          name="reason"
                          placeholder="Enter reason"
                          controlClass="!min-h-[80px]"
                        ></AppInput>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <button type="submit" className="btn btn-primary">
                          Report
                        </button>
                      </div>
                    </AppForm>
                  </div>

                  <div className="comment_box_container mt-4">
                    {forumComments?.map((comment) => {
                      return (
                        <>
                          {/* COMMENT BOX */}
                          <div
                            className="comment_box_card"
                            key={comment.comment_id}
                          >
                            {/* {this.props.store.user.employee_id ===
                              comment.employee_id && (
                              <button
                                type="button"
                                className={Object.className({
                                  delete_btn: true,
                                })}
                                onClick={(e) =>
                                  this.handleDeleteComment(
                                    e,
                                    replycomment.comment_id
                                  )
                                }
                              >
                                <i className="fa fa-trash-o"></i>
                              </button>
                            )} */}
                            <div className="d-flex">
                              <div>
                                <div className="name_circle">
                                  {comment.created_by
                                    .split(" ")
                                    .map((name) => name[0])
                                    .join("")
                                    .toUpperCase()}
                                </div>
                              </div>
                              <div className="content mt-0 ms-2">
                                <h3 className="title">{comment.created_by}</h3>
                                <p>{comment.comment}</p>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="reply_btn"
                              onClick={(e) =>
                                this.handleToggleReply(e, comment.comment_id)
                              }
                            >
                              Reply
                            </button>
                            <button
                              className={Object.className({
                                view_reply_btn: true,
                                hidden: comment.replyCount === 0,
                              })}
                              onClick={(e) =>
                                this.handleViewReply(e, comment.comment_id)
                              }
                            >
                              view replies
                            </button>
                          </div>
                          {/* REPLY COMMENT BOX */}
                          <div
                            className="reply_comments_container"
                            id={`replyCommentBox-${comment.comment_id}`}
                          >
                            {(comment?.replies || []).map((replycomment) => {
                              return (
                                <div
                                  className="comment_box_card reply_comment_box ms-5"
                                  key={replycomment.comment_id}
                                >
                                  {/* {this.props.store.user.employee_id ===
                                    replycomment.employee_id && (
                                    <button
                                      type="button"
                                      className={Object.className({
                                        delete_btn: true,
                                      })}
                                      onClick={(e) =>
                                        this.handleDeleteComment(
                                          e,
                                          replycomment.comment_id
                                        )
                                      }
                                    >
                                      <i className="fa fa-trash-o"></i>
                                    </button>
                                  )} */}

                                  <div className="d-flex">
                                    <div>
                                      <div className="name_circle">
                                        {replycomment.created_by
                                          .split(" ")
                                          .map((name) => name[0])
                                          .join("")
                                          .toUpperCase()}
                                      </div>
                                    </div>
                                    <div className="content mt-0 ms-2">
                                      <h3 className="title">
                                        {replycomment.created_by}
                                      </h3>
                                      <p>{replycomment.comment}</p>
                                    </div>
                                  </div>
                                  {/* <button
                                    type="button"
                                    className="reply_btn"
                                    onClick={(e) =>
                                      this.handleToggleReply(
                                        e,
                                        comment.comment_id
                                      )
                                    }
                                  >
                                    Reply
                                  </button> */}
                                </div>
                              );
                            })}
                          </div>
                          {/* REPLY BOX */}
                          <div
                            id={`replyBox-${comment.comment_id}`}
                            className="report_box ms-4 mb-4 hidden"
                          >
                            <AppForm
                              onSubmit={(...args) =>
                                this.handleCommentReply(
                                  ...args,
                                  comment.comment_id
                                )
                              }
                              className="row filter-row flex-auto"
                              ref="replyform"
                            >
                              <div className="col-sm-6 col-md-6">
                                <AppInput
                                  type="textarea"
                                  name="replyComment"
                                  placeholder="Enter Reply"
                                  controlClass="!min-h-[80px]"
                                ></AppInput>
                              </div>
                              <div className="col-sm-6 col-md-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Reply
                                </button>
                              </div>
                            </AppForm>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="card-footer">
                  <AppForm
                    onSubmit={(...args) => this.handleComment(...args)}
                    className="row filter-row flex-auto"
                    ref="commentform"
                  >
                    <div className="col-sm-6 col-md-9">
                      <AppInput
                        type="text"
                        name="comment"
                        placeholder="Type Message"
                      ></AppInput>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <button
                        type="submit"
                        className="btn btn-success submit_btn"
                      >
                        <i className="fa fa-paper-plane"></i>
                      </button>
                    </div>
                  </AppForm>
                </div>
              </div>
            );
          })}
        {data.length == 0 && <div className="empty_layout"></div>}
      </LayoutWrapper>
    );
  }
}

export default connect(ForumDetails);

import React, { Component } from "react";
import * as XLSX from "xlsx";
import LayoutWrapper from "../LayoutWrapper";
import TaskManagementSearch from "./TaskManagementSearch";
import { Link } from "react-router-dom";
import TaskManagementModal from "./TaskManagementModal";

class TaskManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("TaskManagement");
    this.state = {
      rowcount: 0,
      data: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      isMulti: false,
      isTaskCompleted: false,
      fprOptions: [],
    };
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Employee ID",
        dataIndex: "employee_id",
      },
      {
        title: "Employee Name",
        dataIndex: "employee_name",
      },
      {
        title: "Role",
        dataIndex: "employee_role",
        render: (text) => {
          return <>{text?.join(", ")}</>;
        },
      },
      {
        title: "Task Name",
        dataIndex: "task_name",
      },
      {
        title: "Task Type",
        dataIndex: "task_type",
        render: (text) => {
          return <>{text?.split("_")[0].toTitleCase()}</>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text) => {
          return <>{_enum.TASK_MANAGEMENT_STATUS_MAP[text]}</>;
        },
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
      },
      {
        title: "EDC",
        dataIndex: "expected_completion_date",
        render: (text) => {
          let dt = new Date(text);
          if (dt instanceof Date) {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          } else {
            return "N/A";
          }
        },
      },
      {
        title: "Created By",
        dataIndex: "created_by",
      },
      {
        title: "Completed On",
        dataIndex: "completion_date",
        render: (text) => {
          let dt = new Date(text);
          if (dt !== undefined && dt != "Invalid Date") {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          } else {
            return "N/A";
          }
          // return <>{text?.replace(/\//g, "-") || "N/A"}</>;
          // let dt = new Date(text);
          // if (dt instanceof Date) {
          //   return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          // } else {
          //   return "N/A";
          // }
        },
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "task_name",
        label: "Enter Task Name",
        required: true,
        customcolspan: "12",
      },
      {
        type: "date",
        name: "expected_completion_date",
        label: "Expected Date of Completion",
        required: true,
        customcolspan: "12",
      },
      {
        type: "date",
        name: "completion_date",
        label: "Completion Date",
        customcolspan: "12",
        className: "d-none",
        // required: false,
        disabledDates: (date) =>
          new Date().toDate().date().getTime() < date.getTime(),
      },
      {
        type: "select",
        name: "priority",
        label: "Select Priority",
        options: _enum.TASK_PRIORITY_LEVEL,
        required: true,
        customcolspan: "12",
      },
      {
        type: "select",
        name: "task_type",
        label: "Task Type",
        options: _enum.TASK_TYPE,
        onChange: (v) => this.handleTaskTypeChange(v),
        required: true,
        // defaultValue: "individual_task",
        customcolspan: "12",
      },
      {
        type: "select",
        name: "assigned_roles",
        label: "Role",
        // options: _enum.EMP_ROLES,
        options: [
          { key: "manager", label: "Project manager" },
          { key: "cluster-co-ordinator", label: "Cluster Co-ordinator" },
        ],
        multi: false,
        onChange: (v) => this.handleRoleChange(v),
        required: true,
        customcolspan: "12",
      },
      {
        type: "select",
        name: "fpr",
        label: "FPR",
        options: [],
        required: true,
        customcolspan: "12",
      },
      {
        type: "select",
        name: "status",
        label: "Status (Open)",
        options: _enum.MOM_STATUS,
        onChange: (v) => this.handleStatusChange(v),
        required: true,
        customcolspan: "12",
      },
      {
        type: "textarea",
        name: "remarks",
        label: "Remarks",
        required: true,
        customcolspan: "12",
      },
    ];
    this.taskModalRef = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  fetchList() {
    let payload = {
      ...this.state.search,
      ...(!this.isAdmin
        ? {
            employee_role: this.props.store.user.employee_role,
          }
        : {}),
    };
    this.props.api.apiGetHrmsTask(payload).then(({ data, total }) => {
      this.setState({
        data,
        rowcount: total,
      });
    });
  }

  componentDidMount() {
    this.fetchList();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  onSearch(reset, data) {
    // Remove properties with empty values
    const filteredObj = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value)
    );

    // console.log(filteredObj);
    let payload;

    if (reset) {
      payload = {
        ...filteredObj,
      };
    } else {
      payload = {
        ...this.state.search,
        ...filteredObj,
      };
    }
    this.setState({ search: payload }, () => {
      this.fetchList();
    });
  }

  onPageChange(pageData) {
    let payload = this.props.inactive === true ? { status: "disabled" } : {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList(payload)
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.props.api.apiGetHrmsTask({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      // let { data } = this.processData(totalrows);
      totalrows = totalrows.map((item) => {
        let edc = item?.expected_completion_date;
        let completionDate = item?.completion_date;
        return {
          ...item,
          // expected_completion_date: item?.expected_completion_date.replace(
          //   /\//g,
          //   "-"
          // ),
          expected_completion_date: new Date(edc).dateToDDMMYYYY(new Date(edc)),
          ...(completionDate
            ? {
                completion_date: new Date(completionDate).dateToDDMMYYYY(
                  new Date(completionDate)
                ),
              }
            : { completion_date: "-" }),
        };
      });
      console.log({ totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "employee_id",
        "employee_name",
        // "creator_role",
        "task_name",
        "status",
        "expected_completion_date",
        "completion_date",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `task_management_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  openTaskmodal() {
    this.taskModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#add_task_modal #bsmodalclose").click();
  }

  deleteAction(value) {
    // console.log({ deleteAction: value });
    let payload = {
      task_id: value.task_id,
    };
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteHrmsTask(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    if (v.task_id) {
      this.api.apiGetHrmsTaskById({ task_id: v.task_id }).then((res) => {
        console.log("apiGetHrmsTaskById", res.data);
        this.openTaskmodal();

        let filteredData = Object.only(
          res.data,
          this.modalFields.map((v) => v.name)
        );
        filteredData["completion_date"] = res?.data?.completion_date;
        // let fprAltered =
        //   res?.data?.fpr instanceof Array
        //     ? res?.data?.fpr.map((v) => v.employee_id)
        //     : [];
        // filteredData["fpr"] = fprAltered;

        console.log({ filteredData });

        this.setState({
          // popupdata: Object.only(filteredData, [
          //   "task_name",
          //   "priority",
          //   "expected_completion_date",
          //   "completion_date",
          //   "status",
          //   "remarks",
          // ]),
          popupdata: filteredData,
          editMode: true,
          viewMode: false,
          editData: res.data,
        });
      });
    }
  }

  handleTaskTypeChange(v) {
    console.log("handleTaskTypeChange: ", v);
    let targetValue = v[0].key;
    let bool;
    if (targetValue === "group_task") {
      bool = true;
    } else {
      bool = false;
    }
    this.setState({
      isMulti: bool,
    });

    //   let roleInput = document
    //     .querySelector(`#add_task_modal [name="role"]`)
    //     .parentNode.closest(".form-group");

    //   let fprInput = document
    //     .querySelector(`#add_task_modal [name="fpr"]`)
    //     .parentNode.closest(".form-group");
    //   if(roleInput || fprInput) {
    //   let { values: roleValues = [] } = roleInput?.vnode.refs.select.state;
    //   let { values: fprValues = [] } = fprInput?.vnode.refs.select.state;
    //   if (!this.state.editMode) {
    //     if (roleValues.length || fprValues.length) {
    //       roleInput.vnode.refs.select.clearAll();
    //       fprInput.vnode.refs.select.clearAll();
    //     }
    //   }
    // }
  }

  handleRoleChange(v) {
    // console.log("handleRoleChange: ", v);
    let targetValue = v[0]?.key;

    if (targetValue) {
      let multiRoles = v.map((v) => v.key);
      let multiRolePayload = {
        employee_roles: multiRoles,
      };
      // console.log({ multiRolePayload });
      this.api.apiEmployeeByMultiRoles(multiRolePayload).then((res) => {
        console.log({ apiEmployeeByMultiRoles: res });
        if (res?.data?.length) {
          // console.log("list: ", res?.data);
          let filteredArr = res?.data?.map((value) => {
            return {
              key: value.employee_id,
              label: value.employee_name + ` ( ${value.employee_id} ) `,
            };
          });
          console.log("filteredArr", filteredArr);
          this.setState({
            fprOptions: filteredArr,
          });
          let fprInput = document
            .querySelector(`#add_task_modal [name="fpr"]`)
            .parentNode.closest(".form-group");
          // if (fprInput) {
          //   let appselect = fprInput.vnode.refs.select;
          //   let { values, searchResults } = appselect.state;
          //   searchResults = filteredArr;
          //   // values[0] = {
          //   //   key: res.employee_id,
          //   //   label: res.employee_name,
          //   // };
          //   appselect.setState({ searchResults });
          // }
        }
      });
    }
  }

  handleStatusChange(v) {
    console.log("handleStatusChange: ", v);
    let targetValue = v[0]?.key;
    let completionInput = document
      .querySelector(`#add_task_modal [name="completion_date"]`)
      .parentNode.closest(".form-group");
    if (targetValue === "completed") {
      this.setState({ isTaskCompleted: true });
    } else {
      this.setState({ isTaskCompleted: false });
    }
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);
    data = {
      ...data,
      ...(data.expected_completion_date
        ? {
            expected_completion_date: new Date(
              data.expected_completion_date
            ).toISOString(),
          }
        : {}),
      ...(data?.assigned_roles
        ? {
            assigned_roles: data?.assigned_roles.split(","),
          }
        : {}),
      ...(data?.fpr
        ? {
            fpr: data?.fpr.splitBy(","),
          }
        : {}),
    };
    // check if the status is completed, completion date is required
    if (data.status === "completed" && data.completion_date === "") {
      alert("Please select a completion date");
      return;
    }
    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Task updated successfully"
            : "Task added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };

    if (this.state.editMode) {
      let updateHrmsTaskPayload = {
        ...data,
      };
      console.log({ updateHrmsTaskPayload });
      this.props.api
        .apiUpdateHrmsTask(updateHrmsTaskPayload, {
          task_id: this.state.editData.task_id,
        })
        .then(resolveList);
    } else {
      let addHrmsTaskPayload = {
        ...Object.except(data, "completion_date"),
      };

      console.log({ addHrmsTaskPayload });
      this.props.api.apiAddHrmsTask(addHrmsTaskPayload).then(resolveList);
    }
  }

  updateFields(fields) {
    // console.log({ updateFields: fields });
    if (Object.keys(fields).length > 0) {
      fields["task_type"].disabled = this.state.editMode;
      fields["assigned_roles"].disabled = this.state.editMode;

      if (!this.isAdmin) {
        fields["task_name"].disabled = this.state.editMode;
        fields["expected_completion_date"].disabled = this.state.editMode;
        fields["priority"].disabled = this.state.editMode;
      }

      // fields["status"].disabled = this.state.editMode;
      fields["assigned_roles"].multi = this.state.isMulti;
      fields["fpr"].disabled = this.state.editMode;
      fields["fpr"].multi = this.state.isMulti;
      fields["fpr"].options = this.state.fprOptions;
      fields["fpr"].selectAll = this.state.isMulti;
      fields["completion_date"].className = this.state.isTaskCompleted
        ? ""
        : "d-none";
      fields["completion_date"].required = this.state.isTaskCompleted;
      return fields;
    }
  }

  render() {
    let { data, editData, popupdata, popupfields, editMode, viewMode } =
      this.state;
    let { columns, isAdmin, modalFields } = this;

    popupfields = this.updateFields(popupfields);
    console.log({ popupfields });
    return (
      <LayoutWrapper title="Task Management" back="Task Management">
        <div
          className={Object.className({
            "page-header employee-header absolute top-8 right-8": true,
            invisible: !isAdmin,
          })}
        >
          <div className="row align-items-center">
            <div className="col-auto float-end ml-auto">
              <button
                type="button"
                className="btn add-btn border_btn"
                onClick={() => this.getDownloadData()}
              >
                <i className="fa fa-download me-2"></i>
                Download
              </button>
            </div>
            <div className="col-auto float-end ml-auto">
              <Link
                to="/admin/taskmanagement"
                className="btn add-btn"
                ref={this.taskModalRef}
                data-bs-toggle="modal"
                data-bs-target="#add_task_modal"
                onClick={(...args) => this.addModalFields(...args)}
              >
                <i className="fa fa-plus" />
                Create New
              </Link>
            </div>
          </div>
        </div>
        <TaskManagementSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={data}
              {...(!isAdmin
                ? {
                    columns: columns.filter(
                      (c) =>
                        c.dataIndex !== "employee_id" &&
                        c.dataIndex !== "employee_name" &&
                        c.dataIndex !== "employee_role"
                    ),
                    renderAction: (v) => v.task_type !== "group_task",
                  }
                : {
                    columns: columns,
                  })}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              editAction={(data) => this.editAction(data)}
              deleteAction={(data) => this.deleteAction(data)}
              viewable={false}
              pageSize={10}
            ></AppTable>
          </div>
        </div>

        <TaskManagementModal
          id="add_task_modal"
          modalSize="default"
          title={`${editMode ? "Update Task" : "Create New Task"}`}
          submitText={`${editMode ? "Update" : "Add"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          // disablefields={["email"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              popupfields: {},
              editMode: false,
              viewMode: false,
              editData: {},
            })
          }
        />
      </LayoutWrapper>
    );
  }
}

export default connect(TaskManagement);

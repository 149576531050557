import React, { Component } from "react";
export class AssessmentAttendeeSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "name",
        label: "Name",
        type: "text",
      },
      {
        name: "role",
        label: "Role",
        type: "select",
        options: [],
        placeholder: "Select Role",
        className: "select-focus",
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  onSearch(reset = false) {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data, reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  }

  componentDidMount() {
    console.log({ componentDidMount: "AssessmentAttendeeSearch" });
    window.AssessmentAttendeeSearch = this;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    let { isAdmin } = this;
    return (
      <>
        <AppForm className="row filter-row flex-auto" ref="form">
          <div className="col-sm-6 col-md-2 col-lg-1 col-xl-4 col-xxl-5 d-none d-md-block"></div>
          {this.props.hideStatus
            ? this.fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2" key={i}>
                    <AppInputFocus {...field}></AppInputFocus>
                  </div>
                ))
            : this.fields.map((field, i) => (
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-1 col-lg-1 col-xl-1">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2">
            <button
              type="button"
              className={Object.className({
                "btn add-btn sm mx-2": true,
                hidden: !isAdmin,
              })}
              //   onClick={() => this.getDownloadData()}
            >
              <i class="fa fa-download me-2"></i>
              Download Excel
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i className="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(AssessmentAttendeeSearch);

export default {
    apiGetTaskManagementDataMoke() {
        let data = [
            {
                employee_id: "0077766",
                employee_name: "Tony",
                role: "Cluster Co-Ordinator",
                task_name: "Tree Plantation",
                status: "Open",
                edc: "14/09/2023",
                completed_on: "19/09/2023",
            },
            {
                employee_id: "0077766",
                employee_name: "Peter",
                role: "Project Manager",
                task_name: "Tree Plantation",
                status: "Completed",
                edc: "14/09/2023",
                completed_on: "19/09/2023",
            },
            {
                employee_id: "0077766",
                employee_name: "Bruce",
                role: "Cluster Co-Ordinator",
                task_name: "Tree Plantation",
                status: "WIP",
                edc: "14/09/2023",
                completed_on: "19/09/2023",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiAddHrmsTask(data) {
        let payload = {
            ...data,
        }
        return this.request(this.api().addHrmsTask, payload)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiAddHrmsTask: response });
                return response.data
            })
            .get();
    },
    apiUpdateHrmsTask(data, urldata = {}) {
        console.log({ apiUpdateHrmsTask: data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            task_name: data.task_name,
            task_type: data.task_type,
            // completion_date: data.completion_date === "Invalid date" ? "" : data.completion_date,
            priority: data.priority,
            assigned_roles: data?.assigned_roles,
            fpr: data?.fpr,
            expected_completion_date: data.expected_completion_date,
            status: data.status,
            remarks: data.remarks,
        }

        if (data.completion_date && data.status === "completed") {
            payload.completion_date = data.completion_date
        } else {
            payload.completion_date = ""
        }

        return this.request(this.api().updateHrmsTask, payload).urltransform(urldata).exec().log().get();
    },
    apiGetHrmsTaskById(urldata = {}) {
        return this.request(this.api().getHrmsTaskById)
            .urltransform(urldata)
            .exec()
            .mapresults((res) => {
                let edc = new Date(res?.data?.expected_completion_date) instanceof Date ?
                    new Date(res?.data?.expected_completion_date)
                    : new Date().stringToDate(res?.data?.expected_completion_date);

                let completionDate = new Date(res?.data?.completion_date) instanceof Date ?
                    new Date(res?.data?.completion_date)
                    : new Date().stringToDate(res?.data?.completion_date);

                return {
                    data: {
                        _id: res?.data?._id,
                        task_id: res?.data?.task_id,
                        task_name: res?.data?.task_name,
                        // completion_date: new Date().stringToDate(res?.data?.completion_date),
                        expected_completion_date: res?.data?.expected_completion_date ? edc : "",
                        completion_date: res?.data?.completion_date ? completionDate : "",
                        priority: res?.data?.priority,
                        status: res?.data?.assignTask[0]?.status,
                        task_type: res?.data?.assignTask[0]?.task_type,
                        assigned_roles: res?.data?.assignTask[0]?.assigned_roles,
                        fpr: res?.data?.assignTask[0]?.assigned_list_item,
                        remarks: res?.data?.remarks,
                        assigned_by: res?.data?.assigned_by,
                        created_by: res?.data?.created_by,
                        last_updated_by: res?.data?.last_updated_by,
                        createdAt: res?.data?.createdAt,
                        updatedAt: res?.data?.updatedAt,
                        __v: res?.data?.__v,
                    }
                }
            })
            .log()
            .get();
    },
    apiGetHrmsTask(data) {
        // console.log({ apiGetHrmsTask: data });
        return this.request(this.api().getHrmsTask, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiDeleteHrmsTask(urldata = mixins.params()) {
        return this.request(this.api().deleteHrmsTask)
            .urltransform(urldata)
            .exec().log().get();
    },
    // 
    apiGetHrmsTaskNotifications(data) {
        // console.log({ apiGetHrmsTask: data });
        return this.request(this.api().getTaskNotifications, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data,
                }
            })
            .get();
    },
    apiUpdateHrmsTaskNotification(urldata = mixins.params()) {
        return this.request(this.api().updateTaskNotification)
            .urltransform(urldata)
            .exec().log().get();
    },
}
import React, { Component } from "react";
import SchemeModal from "./schemeModal";

export class DocumentsRequired extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("DocumentsRequired");
    this.state = {
      formFields: [
      {
        type: "textarea",
        name: "documents",
        label: "Documents Required",
        placeholder: "Enter if any",
        // maxLength: "1000",
        controlClass: "!min-h-[80px]",
        fieldclass: "col-12 col-lg-10",
      },
      ],
    }
    this.schemeModalRef = React.createRef();
  }

  addModalFields() {
		let fields = (this.formFields || []).reduce(
			(o, n) => ({ ...o, [n.name]: n }),
			{}
		);
	}
  
  openSchemeModal() {
		this.schemeModalRef.current.click();
	}

  closeModal() {
		document.querySelector("#open_scheme_modal #bsmodalclose").click();
	}

  submitForm(event, data) {
    let payload = {
      ...data,
      step: "4",
    }
    let scheme_id = !this.props.editMode ?  localStorage.getItem("scheme_id") : this.props.editData._id
    this.props.api
    .apiUpdateScheme(payload, {schemeId: scheme_id})
    .then((res) => {
      if (this.props.editMode && this.props.isActive) {
        this.openSchemeModal();
        this.props.getSchemeDetails(this.props.editData._id);
      } else if(this.props.editMode && !this.props.isActive) {
        this.props.onDocumentsSubmit(data);
      } else if(!this.props.editMode && !this.props.viewMode) {
        this.props.onDocumentsSubmit(data);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  componentDidMount() {
    if(this.props.showEditData) {
      this.props.showDataToEdit(localStorage.getItem("scheme_id"));
    }
  }

  render() {
    let { formFields } = this.state;
    let { editData, viewMode, editMode, isActive } = this.props;
    if (editData) {
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
          field.defaultValue = editData[field.name];
      }
      return field;
    });
    }

    return (
      <>
        <div className="row m-auto">
          <AppForm
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.submitForm(...args)}
          >
            <div className="row">
            <div className="step-title">Documents Required</div>
              {formFields?.map((field, i) => (
                <div
                  className={
                    field.fieldclass
                      ? field.fieldclass
                      : "col-12 col-md-6 col-lg-4"
                  }
                  key={"field" + i}
                >
                  <AppInput
                    {...field}
                    disabled={this.props.viewMode}
                  ></AppInput>
                </div>
              ))}
            </div>
            {!viewMode ?
              <div className="row no-gutters">
                  <button className="btn add-btn col col-md-1 ml-auto">
                    {`${editMode && isActive ? "Update" : "Save & Next"}`}
                  </button>
                  <button
                    onClick={() => location.reload()}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Cancel
                  </button>
              </div>
              : <div className="row no-gutters">
              <button
                onClick={() => location.reload()}
                className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
              >
              Cancel
              </button>
            </div>}
          </AppForm>
        </div>
        <SchemeModal
          id="open_scheme_modal"
          title=""
          message="Documents required details updated successfully"
          contentClass="smallModalBox"
          closeText="Ok"
        />
        {/* below button hidden on UI */}
        <button
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={(...args) => this.addModalFields(...args)}
          ref={this.schemeModalRef}
          data-bs-toggle="modal"
          data-bs-target="#open_scheme_modal"
        >
          <span>Hidden Button</span>
        </button>
      </>
    );
  }
}

export default connect(DocumentsRequired);

import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import pages from "../components/pages";
import App from "../App";

export const getComponent = (o)=>({
    path: o.path,
    element: (
      <App path={o.path}>
        <o.Element />
      </App>
    )
})

export const getRouter = (values,to="/open/login")=>(createBrowserRouter([
  ...values.map(getComponent),
  {
    path: "*",
    title: "*",
    element: <Navigate to={to} />,
  }
]));
export default getRouter(Object.values(pages));

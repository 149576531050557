import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../../LayoutWrapper";
import mixins from "../mixins";

export class AttendanceOverview extends Component {
    get daylist() {
        let { monthDate = new Date() } = this.state;
        return new Date(monthDate).dateList().map((o) => ({
            name: (
                <div>
                    <div className="font-bold text-center">{o.getDate()}</div>
                    <div
                        className={Object.className({
                            "text-sm text-center": true,
                            "text-red-500": o.getDayName() == "SUN",
                            "text-gray-500": o.getDayName() != "SUN",
                        })}
                    >
                        {o.getDayName().toLowerCase().toTitleCase()}
                    </div>
                </div>
            ),
            key: o.getDate(),
        }));
    }

    get monthDays() {
        let dayListArr = Object.entries(this.daylist);
        return dayListArr.map(([i, daynum]) => ({ key: daynum.key, index: i }));
    }

    constructor(props) {
        super(props);
        this.state = {
            monthDate: new Date(),
            attendanceData: [],
            search: {
                page: 1,
            },
        };
        this.fields = [
            {
                name: "name",
                label: "Employee Name",
                type: "text",
            },
            {
                name: "employee_id",
                label: "Employee ID",
                type: "text",
                className: "select-focus",
            },
            {
                name: "month",
                label: "Select Month",
                type: "month",
                value: new Date().getMonthDisplay(),
                className: "select-focus",
            },
            {
                name: "location",
                label: "Location",
                type: "select",
                options: "worklocationList",
                className: "select-focus",
            },
        ];
        this.links = mixins.menulinks;
        this.setColumns();
    }

    navigateAttendenceDeatisl(record) {
        let url = "/admin/profile/AttendanceDetails?emp_id=" + record.id;
        this.props.setstore("selectedemp", record);
        navigate(url);
    }

    setColumns() {
        let { daylist, monthDays } = this;
        let year = this.state.monthDate.getFullYear();
        let month = this.state.monthDate.getMonth();
        this.colors = {
            present: "text-green-500",
            absent: "text-red-500",
            leave: "text-orange-500",
            holiday: "text-primary",
            sunday: "text-gray-300",
            late_punch: "text-red-500",
        };
        this.status = Object.map(this.colors, (value, key) => {
            switch (key) {
                case "leave":
                    return <b className={`${this.colors[key]}`}>L</b>;
                case "holiday":
                    return <b className={`${this.colors[key]}`}>H</b>;
                case "late_punch":
                    return <b className={`${this.colors[key]}`}>LP</b>;
                case "sunday":
                    return <i className={`fa fa-circle ${this.colors[key]} text-xs`}></i>;
                case "absent":
                    return <i className={`fa fa-close ${this.colors[key]}`}></i>;
                default:
                    return <i className={`fa fa-check ${this.colors[key]}`}></i>;
            }
        });

        this.columns = [
            {
                dataIndex: "id",
                display: false,
            },
            {
                dataIndex: "employee_id",
                display: false,
            },
            {
                dataIndex: "name",
                title: "Employee Name",
                render: (text, record) => (
                    <h2 className="table-avatar">
                        <div
                            className="cursor-pointer"
                            onClick={() => this.navigateAttendenceDeatisl(record)}
                        >
                            {text} <span>{record.employee_id}</span>
                        </div>
                    </h2>
                ),
            },
            {
                dataIndex: "working_days",
                title: "Working Days"
            },
            {
                dataIndex: "present_count",
                title: "Present"
            },
            {
                dataIndex: "absent_count",
                title: "Absent"
            },
            {
                dataIndex: "leave_count",
                title: "Leaves"
            },
            {
                dataIndex: "late_punch_count",
                title: "Late Punch"
            }
        ];
    }

    getAttendaceData() {
        setTimeout(() => {
            this.props.api
                .apiEmployeeAttendaceList({
                    monthDate: this.state.monthDate.toDate(),
                })
                .then((data) => {
                    console.log(data);
                    this.setState({
                        attendanceData: data,
                    });
                });
        });
    }

    downloadCSVReport() {
        let { monthDate=new Date(),page=1,page_count=1 } = this.state;
        let generatedAt = new Date().toDateTime().split(":").join("_");
        let data = this.refs.form.form.current.getData();
        let pages = [].filllen(Math.ceil(this.state.rowcount/100)).map((v,i)=>({page:(i+1),limit:(i+1)*100}));
        let pagePromises = pages.map(({page})=>{
            let payload = {
                ...Object.except(data, ["month"]),
                year: new Date(data.month).getFullYear(),
                month: new Date(data.month).getMonth() + 1,
                ...this.state.search,
                page,
                limit: 100,
            };
            return this.props.api.apiEmployeeAttendaceList(payload);
        });
        Promise.all(pagePromises).then((dataArr)=>{
            let name = "Attendance-"+monthDate.getMonthDisplay()+``;
            let cumulativeData = dataArr.reduce((c,obj)=>c.concat(obj.data),[]);
            cumulativeData.map(o => Object.only(o, [
                    'employee_id',
                    'name', 
                    'working_days', 
                    'present_count', 
                    'absent_count', 
                    'late_punch'
            ])).csv().dowload(`${name}_generated_${generatedAt}.csv`);
        });
    }

    componentDidMount() {
        window.AttendanceOverview = this;
        this.fetchData();
    }

    onFilterChange(field, value, firstDate, lastDate, displayValue) {
        if (field.name == "month") {
            this.setState({
                monthDate: new Date(firstDate),
            });
            setTimeout(() => {
                this.fetchData();
            }, 0);
        }
    }

    onPageChange(pageData) {
        let payload = {};
        this.setState(
            {
                search: {
                    ...this.state.search,
                    page: pageData.current,
                    limit: pageData.pageSize || pageData.defaultPageSize || 25,
                },
            },
            () => this.fetchData(payload)
        );
    }
    fetchData(extra = {}) {
        let data = this.refs.form.form.current.getData();
        data = { ...data, ...extra };
        let payload = {
            ...Object.except(data, ["month"]),
            year: new Date(data.month).getFullYear(),
            month: new Date(data.month).getMonth() + 1,
            ...this.state.search,
        };
        this.props.api.apiEmployeeAttendaceList(payload).then((responce) => {
            console.log("responce:", responce);
            this.setState({
                page:responce.page,
                page_count:responce.page_count,
                rowcount: responce.total_count,
                attendanceData: responce.data,
            });
        });
    }

    fetchFilterData(extra = {}) {
        let data = this.refs.form.form.current.getData();

        if (data.name === "") {
            this.setState(
                {
                    search: {
                        page: this.state.search.page,
                        limit: 10,
                    },
                },
                () => this.fetchData()
            );
        } else {
            this.setState(
                {
                    search: {
                        page: "",
                        limit: "",
                    },
                },
                () => this.fetchData()
            );
        }
    }
    onPrev() {
        this.setState(
            (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
            () => {
                this.fetchData();
            }
        );
    }

    onNext() {
        this.setState(
            (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
            () => {
                this.fetchData();
            }
        );
    }

    render() {
        this.setColumns();
        let { columns, links, colors } = this;
        let { attendanceData } = this.state;
        return (
            <LayoutWrapper title="Attendance" back="Team Attendance">
                <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs>
                <AppForm className="row filter-row" ref="form">
                    {this.fields.map((field, i) => (
                        <div className="col-sm-6 col-md-2" key={i}>
                            <AppInputFocus
                                {...field}
                            // onChange={(...arg) => this.onFilterChange(field, ...arg)}
                            ></AppInputFocus>
                        </div>
                    ))}
                    <div className="col-sm-6 col-md-2 flex">
                        {
                            (attendanceData.length && <button className="btn btn-success btn-block px-4 mb-auto ml-auto xls_btn" onClick={()=>this.downloadCSVReport()} >
                                {/* <i className="fa fa-download mx-1"></i> */}
                                {/* Download EXCEL */}
                            </button>)||''
                        }
                    </div>
                    <div className="col-sm-6 col-md-2">
                        <button
                            className="btn btn-success btn-block w-100"
                            onClick={() => this.fetchFilterData()}
                        >
                            Search
                        </button>
                    </div>
                </AppForm>
                <div className="row mb-4">
                    <div className="col">
                        <AppTable
                            data={attendanceData}
                            columns={columns}
                            editable={false}
                            deletable={false}
                            reorder={true}
                            onNext={() => this.onNext()}
                            onPrev={() => this.onPrev()}
                            total={this.state.rowcount}
                            pageSize={10}
                            onChange={(...arg) => this.onPageChange(...arg)}
                        ></AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        );
    }
}

export default connect(AttendanceOverview);

export default {
    apiRunPayroll(data) {
        return this.request(this.api().runPayroll, data)
        .exec().log().get();
    },
    apiGetPayroll(data) {
        return this.request(this.api().getPayroll, data)
        .exec().log().get();
    },
    apiEmployeePayrollListMoke() {
        let data = [
            {id:1, name:"John Doe", employee_id:"0077766",office_location:"Location", working_days:30, lop:2, leaves:2,gross_salary:250000,pt:2500,payslip:"", status:"Approved"},
            {id:2, name:"John Doe", employee_id:"0077766",office_location:"Location", working_days:30, lop:2, leaves:2,gross_salary:250000,pt:2500,payslip:"", status:"Approved"},
            {id:3, name:"John Doe", employee_id:"0077766",office_location:"Location", working_days:30, lop:2, leaves:2,gross_salary:250000,pt:2500,payslip:"", status:"Approved"},
            {id:4, name:"John Doe", employee_id:"0077766",office_location:"Location", working_days:30, lop:2, leaves:2,gross_salary:250000,pt:2500,payslip:"", status:"Approved"},
            {id:5, name:"John Doe", employee_id:"0077766",office_location:"Location", working_days:30, lop:2, leaves:2,gross_salary:250000,pt:2500,payslip:"", status:"Approved"}
        ];
        return this.mokeapi(() => (data), "Getting attendance list please wait...", 2000);
    },
    apiEmployeePayrollList(data={}) {
        return this.request(this.api().getPayroll, data)
        .exec("Getting Payroll data please wait...").mapresults((responce)=>({
            ...responce,
            result:(data.load_all_approved?responce:responce.result),
            data:(data.load_all_approved?responce:responce.result).map((o)=>({
                id:o._id, 
                name:o.name, 
                employee_id:o.employee_id,
                office_location:o.work_location, 
                working_days:o.working_days,
                attendances:o.attendances,
                lop:o.lop, 
                leaves:o.leaves,
                net_paid_amount:o.net_paid_amount,
                gross_salary:o.gross_salary,
                last_salary:o.last_salary_revised,
                pt:o.pt_amount,
                pf_amount:o.pf_amount,
                tds_amount:o.tds_amount,
                paid_date:o.paid_date,
                payslip_date:new Date(`${o.payroll_year}-${o.payroll_month}-01`).toDate(),
                payslip:o.payment_status,
                status:o.status,
                allow_download:o.allow_download
            }))
        })).log().get();
    },
    apiEmployeeMasterListMoke() {
        let data = [
            {id:1, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", expInYears:10,lastDrawnSalary:25000,lastDrawnMonth:"january", status:"active"},
            {id:2, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", expInYears:10,lastDrawnSalary:25000,lastDrawnMonth:"january", status:"active"},
            {id:3, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", expInYears:10,lastDrawnSalary:25000,lastDrawnMonth:"january", status:"active"},
            {id:4, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", expInYears:10,lastDrawnSalary:25000,lastDrawnMonth:"january", status:"active"},
            {id:5, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", expInYears:10,lastDrawnSalary:25000,lastDrawnMonth:"january", status:"active"}
        ];
        return this.mokeapi(() => (data), "Getting attendance list please wait...", 2000);
    },
    apiEmployeeMasterList(data={}) {
        return this.request(this.api().masterData, data)
        .exec("Getting master list please wait...").mapresults((responce)=>({
            ...responce,
            data:responce.result.map((o)=>({
                id:o._id, 
                name:o.name, 
                employee_id:o.employee_id,
                office_location:o.work_location, 
                department:o.department, 
                doj:o.date_of_joining, 
                expInYears:10,
                lastDrawnSalary:o.last_drawn_salary,
                lastDrawnMonth:o.last_drawn_month, 
                status:o.status
            }))
        })).log().get();
    },
    apiPayrollStatutaryListMoke() {
        let data = [
            {id:1, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", panNo:"ASFFE3543D", uanNo:"2342345687", esicNo:"1234567895"},
            {id:2, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", panNo:"ASFFE3543D", uanNo:"2342345687", esicNo:"1234567895"},
            {id:3, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", panNo:"ASFFE3543D", uanNo:"2342345687", esicNo:"1234567895"},
            {id:4, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", panNo:"ASFFE3543D", uanNo:"2342345687", esicNo:"1234567895"},
            {id:5, name:"John Doe", employee_id:"0077766",office_location:"Location", department:"Department", doj:"2022-10-08", panNo:"ASFFE3543D", uanNo:"2342345687", esicNo:"1234567895"}
        ];
        return this.mokeapi(() => (data), "Getting attendance list please wait...", 2000);
    },
    apiPayrollStatutaryList(data={}) {
        return this.request(this.api().masterData, data)
        .exec("Getting Statutary list please wait...").mapresults((responce)=>({
            ...responce,
            data:responce.result.map((o)=>({
                id:o._id, 
                name:o.name, 
                employee_id:o.employee_id,
                office_location:o.work_location, 
                department:o.department, 
                doj:o.date_of_joining, 
                panNo:o.pan_number, 
                uanNo:o.pan_number, 
                esicNo:o.esi_number
            }))
        })).log().get();
    },
    apiUpdatePaySlip(data,urldata={}) {
        return this.request(this.api().updatePaySlip,data)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiAllowPayslip(data,urldata={}) {
        return this.request(this.api().allowPayslip,data)
            .urltransform(urldata)
            .exec("Allowing the payslip please wait...").log().get();
    }
}
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export class WellcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeCardData: [],
    };
  }
  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }
  get isDigitalSakhi() {
    return this.props.store.user?.employee_role === "digital-sakhi";
  }
  componentDidMount() {
    if (!document.body.classList.contains("mini-sidebar")) {
      document.body.classList.add("mini-sidebar");
    }
    this.setState({
      welcomeCardData: [
        {
          img: imagepaths.recruitmentImg,
          title: "Recruitment",
          icon: imagepaths.recruitmentIcon,
          route: "/recruitment/dashboard",
          id: "recruitment",
        },
        {
          img: imagepaths.hrmsImg,
          title: "HRMS",
          icon: imagepaths.galleryIcon,
          route: "/admin/dashboard",
          id: "hrms",
        },
        {
          img: imagepaths.dataFormsImg,
          title: "Data Collection Forms",
          icon: imagepaths.personListIcon,
          route: "/datacollection/dashboard",
          id: "datacollection",
        },
        {
          img: imagepaths.projectManagementImg,
          title: "Project Management",
          icon: imagepaths.personGearIcon,
          route: "/projectmanagement/dashboard",
          id: "projectmanagement",
        },
        {
          img: imagepaths.lmsImg,
          title: "LMS",
          icon: imagepaths.lmsIcon,
          route: "/lms/dashboard",
          id: "lms",
        },
        // {
        //   img: imagepaths.digitalSakhiImg,
        //   title: "Digital Sakhi",
        //   icon: imagepaths.digitalSakhiIcon,
        //   route: "/welcome",
        //   id: "digital_sakhi",
        // },
        {
          img: imagepaths.womenEnterpreneurImg,
          title: "Women Entrepreneur",
          icon: imagepaths.womenEntrepreneurIcon,
          route: "/womenentrepreneur/dashboard",
          id: "women_enterpreneur",
        },
        {
          img: imagepaths.schemesImg,
          title: "Schemes",
          icon: imagepaths.womenEntrepreneurIcon,
          route: "/schemes",
          id: "schemes",
        },
      ],
    });
  }
  // openSidebar() {
  //   document.body.classList.remove("mini-sidebar");
  // }
  render() {
    let { welcomeCardData } = this.state;
    let { isAdmin, isDigitalSakhi } = this;
    if (!isAdmin) {
      welcomeCardData = welcomeCardData.filter(
        (v) =>
          v.id !== "recruitment" &&
          v.id !== "digital_sakhi" &&
          v.id !== "women_enterpreneur"
      );
    }
    if (isDigitalSakhi) {
      welcomeCardData = welcomeCardData.filter((v) => v.id !== "hrms");
    }
    return (
      <>
        <Helmet>
          <title>Dashboard - Access Livelihoods</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        <div className="content container-fluid p-0">
          <div className="top_banner mb-md-5 px-5">
            <h2>Welcome</h2>
          </div>
          <div className="row g-5 mx-auto p-4">
            {welcomeCardData &&
              welcomeCardData?.map((card, i) => {
                return (
                  <div className="col col-md-4" key={i}>
                    <WelcomeCard
                      img={card.img}
                      title={card.title}
                      icon={card.icon}
                      route={card.route}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

export default connect(WellcomePage);

export const WelcomeCard = ({ img, title, icon, route }) => {
  return (
    <>
      <div className="card welcome_card">
        <Link
          to={route}
          className="route_link"
          onClick={() => document.body.classList.remove("mini-sidebar")}
        ></Link>
        <div className="card-header">
          <img src={img} className="img-fluid w-100" alt="hrmsImg" />
        </div>
        <div className="card-body">
          <img src={icon} alt="galleryIcon" />
          <h3>{title}</h3>
        </div>
      </div>
    </>
  );
};

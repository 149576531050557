/**
 * Crm Routes
 */
/* eslint-disable */
import React from "react";
import LayoutWrapper from "../../LayoutWrapper";
import ClusterList from "./ClusterList";

const ClusterRoute = ({ location }) => (
  <LayoutWrapper title="Master Data" back="Master Data / Cluster">
    <ClusterList />
  </LayoutWrapper>
);

export default connect(ClusterRoute);

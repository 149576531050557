import React, { Component } from "react";

export class DataCollectionSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("");
    this.state = {
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
      },
      selectedProject: "",
      dropdownOptions: [],
    };
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get isManager() {
    return this.props.store.user?.employee_role === "manager";
  }

  handleProjectChange(event) {
    this.setState({ selectedProject: event.target.value });
    this.props.onSearch && this.props.onSearch(event.target.value);
  }

  componentDidMount() {
    this.fetchProjectName();
  }

  fetchProjectName() {
    this.props.api.apiGetProjectsList().then(({ data, total }) => {
      let updatedOptions = data.map((v) => ({
        value: v.id,
        text: v.project_name,
      }));
      this.setState({
        dropdownOptions: updatedOptions,
      });
    });
  }

  resetSearchForm() {
    this.props.resetSearchForm();
    this.setState({ selectedProject: "" });
  }

  render() {
    return (
      <>
        <form
          className="row filter-row mb-4"
          onSubmit={(...args) => this.onSearch(...args)}
        >
          <div className="col-lg-3 col-sm-6 col-md-3">
            <div className="search-container">
              <button className="search-btn" type="button">
                <i className="search-icon fa fa-search"></i>
              </button>
              <select
                name="project_name"
                className="search-area"
                value={this.state.selectedProject}
                onChange={(...args) => this.handleProjectChange(...args)}
              >
                <option value="" disabled selected>
                  Search
                </option>
                {this.state.dropdownOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <button
                className="cross-btn"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                <i className="search-icon fa fa-close"></i>
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default connect(DataCollectionSearch);

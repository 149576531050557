import React, { Component, useEffect, useState } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { ConditionModel, Condition, ConditionType, OpeartionType } from "./conditionmodel";


const ConditionData = (props) => {
	let { path, type, arr, index } = props;
	const where = model.getWhere(path);
	const isOr = type == ConditionType.OR;
	const valobj = isOr ? where[0] : where;
	const keyOptions = [
		{ key: "option1", label: "Option 1" },
		{ key: "option2", label: "Option 2" },
	];
	const keyValueMaps = {
		option1: [
			{ key: "op1value1", label: "OP1 Value 1" },
			{ key: "op1value2", label: "OP1 Value 2" },
		],
		option2: [
			{ key: "op2value1", label: "OP2 Value 1" },
			{ key: "op2value2", label: "OP2 Value 2" },
		],
	};
	const makeChange = () => {
		props.onChange && props.onChange();
	};
	const change2Condition = (ctype) => {
		let newcondition = new Condition("", "", OpeartionType.EQ);
		let payload = newcondition;
		switch (ctype) {
			case "nested":
				payload = { value: [newcondition] };
				payload = type == ConditionType.OR ? [payload] : payload;

				break;
			case "simple":
				payload = type == ConditionType.OR ? [newcondition] : newcondition;
				break;
		}
		model.setWhere(path, payload);
		console.log(model.where, path);
		makeChange();
	};
	const isSimpleCondition = (valobj || {}).hasOwnProperty("key");
	const prepath = isOr ? path + ".0" : path + "";
	const nestedPath = isOr ? path + ".0.value" : path + ".value";
	const keyval = model.getWhere(prepath + ".key");
	const rightval = model.getWhere(prepath + ".value");
	const opval = model.getWhere(prepath + ".operator");
    const [valueOptions, setValueOptions] = useState(keyValueMaps[keyval]||[]);
	const onKeyChange = (selected) => {
		let [first] = selected;
		let keypath = nestedPath.replace(/.value$/, ".key");
		setValueOptions(keyValueMaps[first.key] || []);
		model.setWhere(keypath, first.key);
		makeChange();
		console.log({ keypath, nestedPath });
	};
	const onOperatorChange = (selected) => {
		let [first] = selected;
		let keypath = nestedPath.replace(/.value$/, ".operator");
		model.setWhere(keypath, first.key);
		makeChange();
	};
	const onValueChange = (selected) => {
		let [first] = selected;
		model.setWhere(nestedPath, first.key);
		makeChange();
		console.log({ nestedPath, first });
	};

	const deleteCondition = () => {
		let patharr = path.split(".");
		let parentpath = patharr.splice(0, patharr.length - 1).join(".");
		let where = model.getWhere(parentpath);
		where = where.filter((val, i) => i != index);
		model.setWhere(parentpath, where);
		makeChange();
		console.log({ where, path, index, parentpath });
		//props.onDelete && props.onDelete();
	};

	console.log({ path, where, valobj,keyval,rightval });
    const operatorValues = Object.entries(OpeartionType).map(([key, value]) => ({
        key: value,
        label: key,
    }));
	return (
		<>
			{isSimpleCondition ? (
				<>
					<AppInput
						name="key"
						type="select"
						options={keyOptions}
						onChange={(...args) => onKeyChange(...args)}
						value={keyval}
                        values={[keyOptions.find(o=>o.key==keyval)]}
                        >
                    </AppInput>
					<AppInput
						name="operator"
						type="select"
						options={operatorValues}
						onChange={(...args) => onOperatorChange(...args)}
                        value={opval}
                        values={[operatorValues.find(o=>o.key==opval)]}
                        >
                    </AppInput>
					<AppInput
						name="value"
						type="select"
						options={valueOptions}
						onChange={(...args) => onValueChange(...args)}
						value={rightval}
                        values={[valueOptions.find(o=>o.key==rightval)]}
                        >
                    </AppInput>
				</>
			) : (
				<div className="grow">
					<ConditionContainer
						where={model.getWhere(nestedPath)}
						model={model}
						path={nestedPath}
						onChange={() => makeChange()}></ConditionContainer>
				</div>
			)}

			<div>
				{isSimpleCondition ? (
					<button type="button" className="btn" onClick={() => change2Condition("nested")}>
						<i className="fa fa-sitemap"></i>
					</button>
				) : (
					<button type="button" className="btn" onClick={() => change2Condition("simple")}>
						<i className="fa fa-minus" aria-hidden="true"></i>
					</button>
				)}
				{arr.length > 1 && (
					<button type="button" className="btn mx-1" onClick={() => deleteCondition()}>
						<i className="fa fa-trash" aria-hidden="true"></i>
					</button>
				)}
			</div>
		</>
	);
};

const AndContainer = (props) => {
	let { model, path, index, arr } = props;
	const makeChange = () => {
		props.onChange && props.onChange();
	};
	function addCondition() {
		let key = `${index + 1}`;
		let value = new Condition("", "", OpeartionType.EQ);
		let patharr = path.split(".");
		let parentpath = patharr.splice(0, patharr.length - 1).join(".");
		parentpath = parentpath ? `${parentpath}.` : "";
		console.log({ key, value, index, path });
		model.setWhere(parentpath + key, value);
		makeChange();
	}

	return (
		<div className="condition-and">
			{index != 0 && (
				<div className="flex">
					<label className="m-auto">
						<b>AND</b>
					</label>
				</div>
			)}
			<div className="flex gap-2">
				<ConditionData
					onChange={() => makeChange()}
					type={ConditionType.AND}
					model={model}
					path={path}
					index={index}
					arr={arr}
				/>
			</div>
			{index == arr.length - 1 && (
				<div className="my-auto mr-auto">
					<button type="button" className="add-btn" onClick={() => addCondition()}>
						ADD
					</button>
					{props.children}
				</div>
			)}
		</div>
	);
};

const OrContainer = (props) => {
	let { model, path, index, arr } = props;
	const makeChange = () => {
		props.onChange && props.onChange();
	};
	function addCondition() {
		let key = `${index + 1}`;
		let value = [new Condition("", "", OpeartionType.EQ)];
		let patharr = path.split(".");
		let parentpath = patharr.splice(0, patharr.length - 1).join(".");
		parentpath = parentpath ? `${parentpath}.` : "";
		console.log({ key, value, index, path });
		model.setWhere(parentpath + key, value);
		makeChange();
	}

	return (
		<div className="condition-or">
			{index != 0 && (
				<div className="flex">
					<label className="m-auto">
						<b>OR</b>
					</label>
				</div>
			)}
			<div className="flex gap-2">
				<ConditionData
					onChange={() => makeChange()}
					type={ConditionType.OR}
					model={model}
					path={path}
					index={index}
					arr={arr}
				/>
			</div>
			{index == arr.length - 1 && (
				<div className="my-auto mr-auto">
					<button type="button" className="add-btn" onClick={() => addCondition()}>
						ADD
					</button>
					{props.children}
				</div>
			)}
		</div>
	);
};

const ConditionContainer = (props) => {
	let { model, path, where } = props;
	console.log("ConditionContainer", { path, where });
	if (!where) {
		return <></>;
	}
	let getPath = () => (path ? `${path}.` : "");
	const makeChange = () => {
		let tmpWhere = model.getWhere(path);
		console.log({ tmpWhere });
		props.onChange && props.onChange([...tmpWhere]);
	};
	useEffect(() => {
		//makeChange();
	}, [where]);

	const changeToAnd = () => {
		let tmpWhere = model.getWhere(path);
		let andwhere = tmpWhere.map((arr) => arr[0]);
		model.setWhere(path, [...andwhere]);
		props.onChange && props.onChange([...andwhere]);
	};

	const changeToOr = () => {
		let tmpWhere = model.getWhere(path);
		let objwhere = tmpWhere.map((obj) => [obj]);
		model.setWhere(path, [...objwhere]);
		console.log({ objwhere });
		props.onChange && props.onChange([...objwhere]);
	};

	return (
		<div className="condition-container flex gap-2">
			<div
				className={Object.className({
					"p-2": true,
					"border-dashed border-2 border-indigo-600":
						where && where instanceof Array && where[0] instanceof Array,
					"border-dashed border-2 border-yellow-600":
						where && where instanceof Array && !(where[0] instanceof Array),
				})}>
				{where.map((condition, i) => (
					<div key={"condition" + i}>
						{condition instanceof Array ? (
							<OrContainer
								model={model}
								path={getPath() + i}
								index={i}
								arr={where}
								onChange={() => makeChange()}>
								<>
									<button type="button" onClick={() => changeToAnd()} className="btn">
										<i className="fa fa-map-signs"></i> AND
									</button>
								</>
							</OrContainer>
						) : (
							<AndContainer
								model={model}
								path={getPath() + i}
								index={i}
								arr={where}
								onChange={() => makeChange()}>
								<>
									<button type="button" onClick={() => changeToOr()} className="btn">
										<i className="fa fa-map-signs"></i> OR
									</button>
								</>
							</AndContainer>
						)}
					</div>
				))}
			</div>
		</div>
	);
};
const model = new ConditionModel();

model.where = [
	[{ key: "option1", value: "op1value2", operator: "=" }],
    [
		{
			value: [
				{
					value: [
						[{ key: "option1", value: "op1value1", operator: "=" }],
						[{ key: "option2", value: "op2value1", operator: "=" }],
					],
				},
				{ key: "option2", value: "op2value2", operator: "=" },
			],
		},
	],
];

const TestComponent = () => {
	const [where, setWhere] = useState(model.where);
	useEffect(() => {
		if (model.where.length == 0) {
			let type = ConditionType.OR;
			let newcondition = new Condition("", "", OpeartionType.EQ);
			let value = type == ConditionType.AND ? newcondition : [newcondition];
			model.setWhere(model.where.length + "", value);
			setWhere(model.where);
		}
	}, [where]);
	return (
		<LayoutWrapper>
			<div className="px-2">
				<ConditionContainer where={where} model={model} path="" onChange={(val) => setWhere(val)} />
				<div>{JSON.stringify(where, null, "\n")}</div>

				<div>{JSON.stringify(model.getAST(), null, "\n")}</div>
				<div>{model.getSQL(model.where)}</div>
                {/* <div>{JSON.stringify(model.getASTbySQL(model.getSQL(model.where)), null, "\n")}</div> */}
			</div>
		</LayoutWrapper>
	);
};

window.model = model;
export default TestComponent;

import React, { useState } from "react";
import SchemeDetails from "./schemeDetails";
import EligibilityCriteria from "./eligibilityCriteria";
import Benefits from "./benefits";
import DocumentsRequired from "./documentsRequired";
import HowToApply from "./howToApply";

const AlterScheme = (props) => {
  console.log({ AlterScheme: props });
  const [goToStep, setGoToSteps] = useState('schemeDetails');
  // const [loader, setLoader] = useState(false);
  
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <button
            className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${goToStep == "schemeDetails" ? "tab-active" : ""
              }`}
            onClick={() => setGoToSteps("schemeDetails")}
          >
            Scheme Details
          </button>
          <button
            className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${goToStep == "eligibilityCriteria" ? "tab-active" : ""
              }`}
            onClick={() => setGoToSteps("eligibilityCriteria")}
          >
            Eligibility Criteria
          </button>
          <button
            className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${goToStep == "benefits" ? "tab-active" : ""
              }`}
            onClick={() => setGoToSteps("benefits")}
          >
            Benefits
          </button>
          <button
            className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${goToStep == "documentsRequired" ? "tab-active" : ""
              }`}
            onClick={() => setGoToSteps("documentsRequired")}
          >
            Documents Required
          </button>
          <button
            className={`btn rounded-full border my-2 mx-1 min-w-[150px] m-clip ${goToStep == "howToApply" ? "tab-active" : ""
              }`}
            onClick={() => setGoToSteps("howToApply")}
          >
            How to Apply
          </button>
        </div>
      </div>

      {goToStep === 'schemeDetails' && (
        <>
          <SchemeDetails
            editData={props.editData}
            viewMode={props.viewMode}
            editMode={props.editMode}
            isActive={props.isActive}
            getSchemeDetails={props.getSchemeDetails}

          />
        </>
      )}
      {goToStep === 'eligibilityCriteria' && (
        <>
          <EligibilityCriteria
            editData={props.editData}
            viewMode={props.viewMode}
            editMode={props.editMode}
            isActive={props.isActive}
          />
        </>
      )}
      {goToStep === 'benefits' && (
        <>
          <Benefits
            editData={props.editData}
            viewMode={props.viewMode}
            editMode={props.editMode}
            isActive={props.isActive}
            getSchemeDetails={props.getSchemeDetails}
          />
        </>
      )}
      {goToStep === 'documentsRequired' && (
        <>
          <DocumentsRequired
            editData={props.editData}
            viewMode={props.viewMode}
            editMode={props.editMode}
            isActive={props.isActive}
            getSchemeDetails={props.getSchemeDetails}
          />
        </>
      )}
      {goToStep === 'howToApply' && (
        <>
          <HowToApply
            editData={props.editData}
            viewMode={props.viewMode}
            editMode={props.editMode}
            isActive={props.isActive}
            getSchemeDetails={props.getSchemeDetails}
          />
        </>
      )}
    </>
  );
};

export default connect(AlterScheme);

/**
 * Crm Routes
 */
/* eslint-disable */
import React from "react";
import LayoutWrapper from "../../LayoutWrapper";
import VillageList from "./VillageList";

const VillageRoute = ({ location }) => (
  <LayoutWrapper title="Master Data" back="Master Data / Village">
    <VillageList />
  </LayoutWrapper>
);

export default connect(VillageRoute);

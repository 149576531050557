/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { Helmet } from "react-helmet";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectsData: [],
      rowcount: 0,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "job_id",
          title: "Job Id",
          display: false,
        },
        {
          dataIndex: "job_name",
          title: "Job Name",
          fixed: "left",
          render: (text, record) => <b>{record.job_name}</b>,
        },
        {
          dataIndex: "total",
          title: "Total Applicants",
          fixed: "left",
          render: (text, record) => <b>{record.total}</b>,
        },
        {
          dataIndex: "selected",
          title: "Selected Applicants",
          fixed: "left",
          render: (text, record) => <b>{record.selected}</b>,
        },
        {
          dataIndex: "rejected",
          title: "Rejected Applicants",
          fixed: "left",
          render: (text, record) => <b>{record.rejected}</b>,
        },
      ],
      dashboardFunnelData: [],
      dashboardTopData: {},
      dashboardJobwiseData: [],
    };
    this.funnelStatus = _enum.APPLICANT_DASHBOARD_FUNNEL_STATUS;
  }

  componentDidMount() {
    this.props.api.apiDashboardProjectsDataMoke().then((data) => {
      this.setState({
        projectsData: data,
      });
    });
    //
    this.props.api.apiDashboardFunnelData().then((data) => {
      this.setState({
        dashboardFunnelData: data.data,
      });
    });

    this.props.api.apiDashboardTotalData().then((data) => {
      console.log({
        apiDashboardTotalData: data,
      });
      let mergedData = {
        totalData: [
          // {
          //   name: "total_registration_form",
          //   label: "Total Registration Form",
          //   value: data.data.total_assessment[0].totalAssessment,
          //   icon: "la la-user",
          // },
          // {
          //   name: "total_aptitude_test",
          //   label: "Total Aptitude Test",
          //   value: data.data.total_assessment[0].totalAssessment,
          //   icon: "la la-user-check",
          // },
          // {
          //   name: "total_applicants_applied",
          //   label: "Total Applicants Applied",
          //   value: data.data.total_assessment[0].totalAssessment,
          //   icon: "la la-users",
          // },
          // {
          //   name: "total_applicants_rejected",
          //   label: "Total Applicants Rejected",
          //   value: data.data.total_assessment[0].totalAssessment,
          //   icon: "la la-user-slash",
          // },
          {
            name: "totalJobApplication",
            label: "Total Job Application",
            value: data.data.total_jobs[0].totalJobApplication,
            icon: "la la-user",
          },
          {
            name: "totalAssessment",
            label: "Total Assessment",
            value: data.data.total_assessment[0].totalAssessment,
            icon: "la la-user-check",
          },
        ],
        totalApplicantData: [
          {
            name: "digital_sakhi",
            label: "Digital Sakhi",
            value: data.data.total_applicant_role[0].appliedForDigitalSakhiRole,
            icon: "la la-user",
          },
          {
            name: "manager",
            label: "Manager",
            value: data.data.total_applicant_role[0].appliedForManagerRole,
            icon: "la la-user-check",
          },
          {
            name: "cluster_co_ordinator",
            label: "Cluster Co-Ordinator",
            value: data.data.total_applicant_role[0].appliedForCoOrdinatorRole,
            icon: "la la-users",
          },
          {
            name: "others",
            label: "Others",
            value: data.data.total_applicant_role[0].appliedForOtherRole,
            icon: "la la-user-slash",
          },
        ],
      };
      this.setState({
        dashboardTopData: mergedData,
      });
    });
    this.props.api.apiDashboardJobwiseData().then(({ data, count }) => {
      this.setState({
        dashboardJobwiseData: data.items,
        rowcount: data.count,
      });
    });
  }

  cardAction(o, i) {
    let now = new Date().toDate();
    switch (o.label.snakeCase()) {
      case "total_employees":
      case "active_employees":
        this.props.navigate("/admin/employee");
        break;
      case "today's_present":
        this.props.navigate(`/admin/attendance/daywiseattendace`);
        break;
      case "today's_absent":
        this.props.navigate(`/admin/attendance/daywiseattendaceabsent`);
        break;
      case "on_leave":
        this.props.navigate("/admin/leavemanagment/approvedleaves");
        break;
      case "leave_approvals":
        this.props.navigate("/admin/leavemanagment");
        break;
      case "exception_approvals":
        this.props.navigate(
          "/admin/attendance/exceptionapprovals?status=PENDING"
        );
        break;
      case "exceptions":
        this.props.navigate("/admin/attendance/approvedexceptions");
        break;
    }
  }

  render() {
    let {
      projectsData,
      columns,
      dashboardFunnelData,
      dashboardTopData,
      dashboardJobwiseData,
      rowcount,
    } = this.state;
    let { funnelStatus } = this;
    return (
      <>
        <Helmet>
          <title>Dashboard - Access Livelihoods</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Welcome Admin!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <p className="dash-widget-heading">Total</p>
            {dashboardTopData?.totalData?.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div
                  className="card dash-widget cursor-pointer"
                  // onClick={() => this.cardAction(o, i)}
                >
                  <div className="card-body">
                    <span className="dash-widget-icon">
                      <i className={o.icon} />
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.value}</h3>
                      <span>{o.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <p className="dash-widget-heading">Total Applicants</p>
            {dashboardTopData?.totalApplicantData?.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div className="card dash-widget cursor-pointer">
                  <div className="card-body">
                    <span className="dash-widget-icon">
                      <i className={o.icon} />
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.value}</h3>
                      <span>{o.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row pt-5">
            <div className="col-xl-6">
              <p className="dash-widget-heading">Recruitment Funnel</p>
              <div className="funnel_chart_container mb-5">
                {dashboardFunnelData.map((item) =>
                  Object.keys(item).map((itemKey, j) => (
                    <div className={`funnel_item funnel_item${j + 1}`} key={j}>
                      <label>
                        <span>{item[itemKey]}</span>
                        {funnelStatus[itemKey]}
                      </label>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="col-xl-6">
              <p className="dash-widget-heading">Applicants</p>
              <AppTable
                data={dashboardJobwiseData}
                columns={columns}
                total={rowcount}
                pageSize={10}
                editable={false}
                deletable={false}
                viewable={false}
                copyable={false}
                disableable={false}
                reorder={true}
              ></AppTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(AdminDashboard);

import React, { Component } from "react";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export class AppTimePicker extends Component {
  constructor(props) {
    super(props);
    this.appinput = React.createRef();
    this.state = {
        value: new Date()
    };
  }

  componentDidMount() {
    window.ComponentAppTimePicker = this;
  }
  
  onChange(value){
    console.log({onChange: value});
  }

  render() {
    let {value} = this.state;
    return (
      <>
        <TimePicker 
        onChange={(...args) => this.onChange(...args)} 
        value={value}
        // disableClock={true} 
        />
      </>
    );
  }
}

export default connect(AppTimePicker);
import { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";

export class AssessmentDetail extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AssessmentDetail");
    this.state = {
      data: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    let payload = {
      evaluation_id: this.props.urlparams.evaluation_id,
    };
    this.props.api.apiGetEvaluationAnswers(payload).then(({ data, total }) => {
      console.log({ apiGetEvaluationAnswers: data });
      this.setState({
        data,
        rowcount: total,
      });
    });
  }

  getBack() {
    let back = `Assessment Review / ${this.props?.urlparams?.assessment_name} / ${this.props?.urlparams?.attendee_name}`;
    return back;
  }
  getBackLink() {
    let backLink = `/lms/assessmentreview/assessmentattendees?${Object.QueryString(
      {
        assessment_name: this.props.urlparams.assessment_name,
        evaluation_id: this.props.urlparams.evaluation_id,
        assessment_id: this.props?.urlparams?.assessment_id,
      }
    )}`;
    return backLink;
  }

  render() {
    let { data = [] } = this.state;
    return (
      <LayoutWrapper
        title="Assessment Review"
        back={this.getBack()}
        backlink={this.getBackLink()}
      >
        <div className="row lms">
          <div className="col-12">
            {!!data.length && (
              <>
                <div className="card assessment_detail_card">
                  <div className="card-body">
                    {data?.map((list, i) => {
                      return (
                        <div className="list">
                          <p className="question">
                            Q{i + 1} : {list?.q_label}
                          </p>
                          {list?.q_type === "file" ? (
                            <p className="answer d-flex align-items-center">
                              <span>A:</span>
                              <AppInput
                                className={"px-2 readonly_upload"}
                                type="file"
                                name="file"
                                defaultValue={list?.q_answer}
                              ></AppInput>
                            </p>
                          ) : (
                            <p className="answer">A: {list?.q_answer}</p>
                          )}
                          <p className="correct_answer">
                            Correct Answer : {list?.a_value}
                          </p>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {data.length == 0 && <div className="empty_layout lms"></div>}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(AssessmentDetail);

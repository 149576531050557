import moment from "moment";

export function renderWorkingHours(obj) {
    let hrs, tDiff, diffObj;
    var now = moment(obj.in_time, "H:mm:ss A");
    var prev = moment(obj.out_time, "H:mm:ss A");
    if (!obj.exception_date) {
        hrs = moment(prev).diff(moment(now), 'hours');
    }
    else {
        hrs = moment(prev).diff(moment(now), 'hours');
    }

    if (Math.sign(hrs) === -1) {
        return '-';
    }
    else {
        diffObj = durationDiff(prev.valueOf() - now.valueOf());
    }
    if (moment(prev).isSame(moment(now))) {
        tDiff = '-'
    } else if (obj.status === 'ABSENT') {
        tDiff = '-'
    }
    else {
        tDiff = `${diffObj.hours} hrs ${diffObj.minutes} mins`;
    }
    return tDiff;
}
export function durationDiff(difference) {
    let secondsInMiliseconds = 1000,
        minutesInMiliseconds = 60 * secondsInMiliseconds,
        hoursInMiliseconds = 60 * minutesInMiliseconds;

    var differenceInHours = difference / hoursInMiliseconds,
        differenceInMinutes = differenceInHours % 1 * 60,
        differenceInSeconds = differenceInMinutes % 1 * 60;
    return {
        "hours": Math.floor(differenceInHours),
        "minutes": Math.round(differenceInMinutes),
        "seconds": Math.floor(differenceInSeconds)
    }
}
import { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import SchemeDetails from "./schemeDetails";
import EligibilityCriteria from "./eligibilityCriteria";
import Benefits from "./benefits";
import DocumentsRequired from "./documentsRequired";
import HowToApply from "./howToApply";

const SchemeSteps = (props) => {

    const [goSteps, setGoSteps] = useState(0);

    useEffect(() => {
        if(props.editMode){
            setGoSteps(props.editData.step)
        }
    }, []);

    const stepLabels = [
        { title: "Scheme Details" },
        { title: "Eligibility Criteria" },
        { title: "Benefits" },
        { title: "Documents Required" },
        { title: "How to Apply" }
    ];

    const onDetailsSubmit = () => {
        setGoSteps(1);
    };

    const onCriteriaSubmit = () => {
        setGoSteps(2);
    }

    const onBenefitsSubmit = () => {
        setGoSteps(3);
    }

    const onDocumentsSubmit = () => {
        setGoSteps(4);
    }

    return (
        <>
            <Stepper activeStep={goSteps}>
                {stepLabels.map((step, index) => (
                    <Step
                        key={step.title}
                        label={step.title}
                        completed={goSteps > index}
                        className="step-link"
                        onClick={() => setGoSteps(index)}
                    />
                ))}
            </Stepper>

            {goSteps === 0 && (
                <SchemeDetails
                    onDetailsSubmit={(...args) => onDetailsSubmit(...args)}
                    setGoSteps={setGoSteps}
                    editData={props.editData}
                    viewMode={props.viewMode}
                    editMode={props.editMode}
                    isActive={props.isActive}
                    showEditData={props.showEditData}
                    showDataToEdit={props.showDataToEdit}
                />
            )}
            {goSteps === 1 && (
                <EligibilityCriteria
                    onCriteriaSubmit={(...args) => onCriteriaSubmit(...args)}
                    setGoSteps={setGoSteps}
                    editData={props.editData}
                    viewMode={props.viewMode}
                    editMode={props.editMode}
                    isActive={props.isActive}
                    showEditData={props.showEditData}
                    showDataToEdit={props.showDataToEdit}
                />
            )}
            {goSteps === 2 && (
                <Benefits
                    onBenefitsSubmit={(...args) => onBenefitsSubmit(...args)}
                    setGoSteps={setGoSteps}
                    editData={props.editData}
                    viewMode={props.viewMode}
                    editMode={props.editMode}
                    isActive={props.isActive}
                    showEditData={props.showEditData}
                    showDataToEdit={props.showDataToEdit}
                />
            )}
            {goSteps === 3 && (
                <DocumentsRequired
                    onDocumentsSubmit={(...args) => onDocumentsSubmit(...args)}
                    setGoSteps={setGoSteps}
                    editData={props.editData}
                    viewMode={props.viewMode}
                    editMode={props.editMode}
                    isActive={props.isActive}
                    showEditData={props.showEditData}
                    showDataToEdit={props.showDataToEdit}
                />
            )}
            {goSteps === 4 && (
                <HowToApply
                setGoSteps={setGoSteps}
                editData={props.editData}
                viewMode={props.viewMode}
                editMode={props.editMode}
                isActive={props.isActive}
                showEditData={props.showEditData}
                showDataToEdit={props.showDataToEdit}
                />
            )}
        </>
    );
};

export default connect(SchemeSteps);

export default [
    {
        path: "/admin/dashboard",
        icon: "la la-dashboard",
        label: "Dashboard",
        id: "dashboard"
    },
    {
        path: "/admin/masterdata",
        icon: "la la-user",
        label: "Master Data",
        id: "masterdata",
        children: [
            {
                path: "/admin/masterdata",
                icon: "la la-user",
                label: "District",
            },
            {
                path: "/admin/masterdata/cluster",
                icon: "la la-user",
                label: "Cluster",
            },
            {
                path: "/admin/masterdata/village",
                icon: "la la-user",
                label: "Village",
            },
        ]
    },
    {
        path: "/admin/employee",
        icon: "la la-user",
        label: "Employees",
        id: "employees"
    },
    {
        path: "/admin/logbook",
        icon: "la la-user",
        label: "Log Book",
        id: "logbook"
    },
    {
        path: "/admin/attendance",
        icon: "la la-user",
        label: "Team Attendance",
        id: "teamattendance"
    },
    {
        path: "/admin/taskmanagement",
        icon: "la la-user",
        label: "Task Management",
        id: "taskmanagement"
    },
    {
        path: "/admin/mom",
        icon: "la la-user",
        label: "MOMs",
        id: "mom"
    },
];
import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";

export class ProjectResources extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ProjectResources");
    this.state = {
      columns: [],
      data: [],
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
      },
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    this.api.apiGetProjectsList(this.state.search).then(({ data, total }) => {
      this.setState({
        data: [...data],
        rowcount: total,
      });
    });
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  deleteProjectAction() {}

  editProjectAction() {}

  viewProjectAction() {}

  render() {
    let { columns, data = [], rowcount } = this.state;
    return (
      <LayoutWrapper title="Project Resources" back="Dashboard">
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <Link to="/projectmanagement/addresources" className="btn add-btn">
              Add
            </Link>
          </div>
        </div>
        <div className="row project_management">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                deleteAction={(v) => this.deleteProjectAction(v)}
                editAction={(v) => this.editProjectAction(v)}
                viewAction={(v) => this.viewProjectAction(v)}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout"></div>}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(ProjectResources);

import { Component } from "react";
import LayoutWrapper from "../../../LayoutWrapper";
import mixins from "../mixins";
import "../module.scss";

export class AssessmentsPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AssessmentsPage");
    this.state = {
      data: [],
      tabitems: [],
      form: null,
      rowcount: 0,
      errors: {},
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      closelink: "",
    };
    this.errorsMessages = {};
    this.tablinks = mixins.tablinks;
  }

  get pathFromModule() {
    return this.props.urlparams.pathFromModule;
  }

  get isReadonly() {
    return this.props.urlparams.readonly;
  }

  get errors() {
    return Object.entries(this.errorsMessages)
      .filter(([key, val]) => val)
      .map(([key, val]) => key);
  }

  componentDidMount() {
    this.fetchData();
    if (this.pathFromModule) {
      this.setState({ closelink: "/lms/module" });
    } else {
      this.setState({ closelink: "/lms/courses/module" });
    }
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      let payload = {
        ...this.state.search,
        module_id: this.props.urlparams.module_id,
      };
      this.props.api
        .apiGetAssessmentByModuleId(payload)
        .then(({ data, total }) => {
          console.log({ apiGetAssessments: data });
          if (data.length > 0) {
            let tabitems = data?.map((item, i) => {
              return {
                key: item.assessment_id,
                label: item.assessment_name,
                // children: `Content of Tab Pane ${i + 1}`,
                children: (
                  <Form
                    key={item.assessment_id}
                    form={data[i]}
                    onInputChange={(...args) => this.onInputChange(...args)}
                    submitForm={(...args) =>
                      this.submitForm(...args, item.assessment_id)
                    }
                    handleclose={(...args) => this.handleClose(...args)}
                    disabled={this.isReadonly}
                    formRef="form"
                  />
                ),
              };
            });
            this.setState({
              // form: data,
              // rowcount: total,
              tabitems,
            });
          }
          resolve();
        });
    });
  }

  handleClose(e, values) {
    e.preventDefault();
    e.stopPropagation();
    let closeLink =
      this.state.closelink +
      Object.encodeuri({
        course_id: this.props.urlparams.course_id,
        course_name: this.props.urlparams.course_name,
        ...(this.isReadonly ? { readonly: this.isReadonly } : {}),
      });
    this.props.navigate(closeLink);
  }

  getBreadCums() {
    if (this.props.urlparams.course_id) {
      return `Course / Assessments `;
    } else {
      return "Courses / Assessments";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.pathFromModule) {
      let backLink = `/lms/courses`;
      return backLink;
    } else if (this.pathFromModule) {
      let backLink =
        `/lms/module` +
        Object.encodeuri({
          course_id: this.props.urlparams.course_id,
          course_name: this.props.urlparams.course_name,
          ...(this.isReadonly ? { readonly: this.isReadonly } : {}),
        });
      return backLink;
    } else {
      return "/lms/courses";
    }
  }

  onInputChange(field, event, ...args) {
    let value = "";
    if (event?.nativeEvent instanceof InputEvent) {
      value = event.target.value;
    } else if (event instanceof String) {
      value = event;
    } else if (event instanceof Number) {
      value = "" + event;
    }
    console.log({ field });
    let constraints = field.fieldConstraint
      // .split(",")
      .map((v) => _enum.CONSTRAINTS_LMS_MAP[v]);
    let valid = constraints.reduce(
      (flag, v) => flag && value.match(new RegExp(v)),
      true
    );
    if (!valid) {
    }
    app.mixin.debounce(() => {
      this.setState({
        errors: {
          ...this.state.errors,
          [field.q_label]: !valid,
        },
      });
    }, 500);
  }

  // onFieldFocus(field) {
  //   console.log(field);
  // }

  submitForm(e, data, form, assessmentId) {
    let parsedPayload = parseInput(data);
    console.log({ data, form, assessmentId });
    // if (!this.isValid) {
    //   alert("Please fill valid values in " + this.errors.join(","));
    //   return;
    // }

    let answers = Object.entries(parsedPayload).map(([key, value]) => ({
      q_label: key,
      q_answer: value,
    }));

    let payload = {
      module_id: this.props.urlparams.module_id,
      course_id: this.props.urlparams.course_id,
      assessment_id: assessmentId,
      answers,
    };
    console.log("payload: ", payload);

    this.props.api.apiCreateEvaluation(payload).then((data) => {
      console.log({ apiCreateEvaluation: data });
      success("Assessment Submitted Successfully");
      let closeLink =
        "/lms/courses/module" +
        Object.encodeuri({
          course_id: this.props.urlparams.course_id,
          course_name: this.props.urlparams.course_name,
        });
      this.props.navigate(closeLink);
    });
  }
  render() {
    let { data = [], tabitems, form } = this.state;
    let { tablinks } = this;
    return (
      <LayoutWrapper
        title="Course"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppTabs tabitems={tabitems} />
        {tabitems.length == 0 && (
          <div className="empty_layout">
            <p className="m-0 text-center fs-5">No Assessments Found!</p>
            <div className="mt-4 d-flex flex-column align-items-end justify-content-end h-100">
              <button
                type="button"
                onClick={(...args) => this.handleClose(...args)}
                className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </LayoutWrapper>
    );
  }
}
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  get formData() {
    console.log(this.refs);
    return this.refs.form.form.current.getData();
  }
  get isValid() {
    let data = this.formData;
    let keys = Object.keys(data);
    // let { fields = [] } = this.state.form || {};
    let fields = this.state.form || [];
    console.log({ fields });
    return keys.reduce((flag, key) => {
      let field = fields.find((o) => o.q_label == key || o.name == key) || {};
      let constraints = field.fieldConstraint
        .filter((v) => v)
        .map((v) => _enum.CONSTRAINTS_LMS_MAP[v]);
      let value = data[key];
      let valid = constraints.reduce(
        (flag, v) =>
          flag &&
          ((typeof v == "string" && value.match(new RegExp(v))) ||
            (typeof v == "function" &&
              v(field.q_type == "date" ? value.date() : value))),
        true
      );
      if (!constraints.includes("mandatory") && !value) {
        valid = true;
      }
      if (!valid) {
        this.errorsMessages[key] = true;
      } else {
        this.errorsMessages[key] = false;
      }
      return flag && !!valid;
    }, true);
  }

  isCheckboxOrRadio = (field) => {
    return ["checkbox", "radio"].includes(field.q_type);
  };

  render() {
    let { form, formRef, onInputChange, submitForm, handleclose } = this.props;
    console.log({ form });
    return (
      <AppForm
        className="m-auto p-2 w-full custom_form"
        onSubmit={(...args) => submitForm(...args)}
        ref={formRef}
      >
        {form?.assessment_questions?.map((field, i) => (
          <div key={field.q_label + "-" + i} className="grow">
            <>
              {this.isCheckboxOrRadio(field) && (
                <div className="list-group option_box">
                  <AppInput
                    type="label"
                    required={field?.fieldConstraint?.includes("mandatory")}
                    label={`${i + 1}. ${field.q_label}`}
                  >
                    {field.q_options.map((option, j) => (
                      <AppInput
                        key={"option_" + i + "-" + j}
                        type={field.q_type}
                        className="list-group-item"
                        name={field._id}
                        value={option}
                        disabled={this.props.disabled}
                      >
                        {option}
                      </AppInput>
                    ))}
                  </AppInput>
                </div>
              )}
              {!this.isCheckboxOrRadio(field) && (
                <AppInput
                  title={field?.fieldConstraint}
                  type={field.q_type}
                  name={field.name || field._id}
                  label={`${i + 1}. ${field.q_label}`}
                  className="list-group option_box"
                  // {...(field.q_type == "select"
                  //   ? {
                  //       onDropdownOpen: () => this.onFieldFocus(field),
                  //       onDropdownClose: () => this.onFieldFocus(field),
                  //     }
                  //   : {})}
                  {...(field.q_type == "date"
                    ? {
                        disabledDates: (date) =>
                          this.handelDisabledDates(date, field),
                      }
                    : {})}
                  {...(field.q_type == "file"
                    ? {
                        fileType: "online_assessment",
                      }
                    : {})}
                  options={
                    field.q_options
                      ? field.q_options
                          // .split(",")
                          .options("", "", (v) => v)
                      : []
                  }
                  required={field?.fieldConstraint?.includes("mandatory")}
                  onChange={(...args) => onInputChange(field, ...args)}
                  disabled={this.props.disabled}
                ></AppInput>
              )}
            </>
          </div>
        ))}
        <div className="mt-4 flex">
          <button
            className={Object.className({
              "btn add-btn col col-md-1 ml-auto": true,
              invisible: this.props.disabled,
            })}
          >
            Submit
          </button>
          <button
            type="button"
            onClick={(...args) => handleclose(...args)}
            className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
          >
            Cancel
          </button>
        </div>
      </AppForm>
    );
  }
}
const Testcomponent = () => {
  return <>tab content</>;
};
export default connect(AssessmentsPage);

import React, { Component } from "react";
import _enum from "src/mixins/enum";

export class CriteriaModal extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("CriteriaModal");
    this.state = {
      values: [],
      showInBetween: false,
      OpsValues: [{ key: "INBETWEEN", label: "In-Between" }]
    }
    this.form = React.createRef();
  }

  componentDidMount() {
    window.CriteriaModal = this;
  }

  closemodal() {
    this.props.onClose();
  }

  handleParameterValues(index, values) {
    // console.log("Parameters: ", values);
    let selectValues = [];
    if (values[0]?.name == "Eligible Schemes") {
      selectValues = this.props.eligibleSchemes?.map((v) => {
        return v.name;
      });
      this.setState({ showInBetween: false});
    } else if(values[0]?.values?.length == 0 && values[0]?.name != "Eligible Schemes") {
      this.setState({ showInBetween: true});
    } else {
      this.setState({ showInBetween: false});
      selectValues = values[0]?.values?.map((v) => {
        if (typeof v === "boolean") {
          return JSON.stringify(v);
        } else {
          return v;
        }
      });
    }

    // CLEARING DEFAULT SELECT VALUES
    let valueSelectBox = document.querySelector(
      `[name='appinput-criterias.${index}.values']`
    );
    if (valueSelectBox) {
      let { values: criteriaValues = [] } =
        valueSelectBox.vnode.refs.select.state;
      if (criteriaValues.length) {
        valueSelectBox.vnode.refs.select.clearAll();
      }
    }

    // Extracting options and creating parameterValues
    let parameterValues = [];
    parameterValues = this.props?.editData?.criterias?.map((parameter) => {
      const { options } = parameter;
      return options?.map((option) => ({
        key: option,
        label: option,
      }));
    });
    if (selectValues) {
      const formattedOptions = selectValues?.map((ops) => {
        const words = ops.toLowerCase().split("_");
        const transformedWords = words.map((word, index) => {
          if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          } else {
            return word;
          }
        });
        return { key: ops, label: transformedWords.join(" ") };
      });
      if (this.props.editMode) {
        parameterValues[index] = formattedOptions;
        this.setState({
          values: parameterValues,
        });
      } else {
        this.props.handleCriteriaValue(index, selectValues.options());
      }
      this.props.handleParameters();
    }
  }

  handleOperators(index, values) {
    // console.log("Operators: ", values, index);
    let valueBox = document.querySelector(
      `[name="appinput-criterias.${index}.values"]`
    );
    let minInput = document.querySelector(
      `[name="appinput-criterias.${index}.min"]`
    );
    let maxInput = document.querySelector(
      `[name="appinput-criterias.${index}.max"]`
    );

    if (values && values.length > 0) {
      if (values[0].key === "EQUAL" || values[0].key === "NOT_EQUAL") {
        valueBox && valueBox.classList.remove("d-none");
        minInput && minInput.classList.add("d-none");
        maxInput && maxInput.classList.add("d-none");
      } else {
        valueBox && valueBox.classList.add("d-none");
        minInput && minInput.classList.remove("d-none");
        maxInput && maxInput.classList.remove("d-none");
      }
    }
  }

  render() {
    let { props, state } = this;
    let {
      id,
      title,
      submitText,
      criterias,
      parameterName,
      editData,
      editMode,
      viewMode,
      editParameterValues,
    } = props;
    let { values, showInBetween, OpsValues } = state;
    let formAttrs = Object.except(props, ["id", "title", "type", "onClose"]);

    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={(...args) => this.closemodal(...args)}
      >
        <AppForm {...formAttrs} ref={this.form}>
          <Row className="mx-0">
            <AppInput
              type="text"
              name="name"
              label="Criteria Name"
              required="true"
              defaultValue={editData.name}
              disabled={viewMode}
              className="col-12"
            ></AppInput>
            {editMode || viewMode
              ? editData?.criterias?.map((item, i) => {
                  // always hide range inputs by default
                  let showvalueinput = false;
                  let showrangeinputs = true;
                  if (item.values.length !== 0) {
                    showrangeinputs = true;
                    showvalueinput = false;
                  } else if (
                    item.values.length === 0 &&
                    item.parameterName === ""
                  ) {
                    showrangeinputs = true;
                    showvalueinput = false;
                  } else {
                    showrangeinputs = false;
                    showvalueinput = true;
                  }
                  return (
                    <>
                      <div className="row" key={i}>
                        <AppInput
                          type="select"
                          name={`criterias.${i}.parameterName`}
                          label="Parameters"
                          defaultValue={item?.parameterId}
                          options={parameterName}
                          required="true"
                          disabled={viewMode}
                          onChange={(...args) =>
                            this.handleParameterValues(i, ...args)
                          }
                          className="col-4"
                          placeholder="Select"
                        ></AppInput>
                        <AppInput
                          type="select"
                          name={`criterias.${i}.operator`}
                          defaultValue={item?.operator}
                          options={showInBetween ? OpsValues : _enum.OPERATOR_TYPES}
                          onChange={(...args) =>
                            this.handleOperators(i, ...args)
                          }
                          label="Ops"
                          required="true"
                          disabled={viewMode}
                          className="col-3"
                          placeholder="Select"
                        ></AppInput>
                        <AppInput
                          type="select"
                          name={`criterias.${i}.values`}
                          iscustomjoin={true}
                          customjoinval="|END|"
                          defaultValue={
                            item?.values[0]?.includes(",")
                              ? item?.values[0].split(",")
                              : item?.values
                          }
                          options={
                            editParameterValues && this.props.editMode
                              ? values[i]
                              : item?.options?.map((value) => {
                                  return { key: value, label: value };
                                })
                          }
                          label="Value"
                          disabled={viewMode}
                          multi={true}
                          className={Object.className({
                            "col-4": true,
                            "d-none": showvalueinput,
                          })}
                          placeholder="Select"
                        ></AppInput>
                        <AppInput
                          type="text"
                          name={`criterias.${i}.min`}
                          label="Min"
                          className={Object.className({
                            "col-2": true,
                            "d-none": showrangeinputs,
                          })}
                          defaultValue={item?.min}
                          disabled={viewMode}
                        ></AppInput>
                        <AppInput
                          type="text"
                          name={`criterias.${i}.max`}
                          label="Max"
                          className={Object.className({
                            "col-2": true,
                            "d-none": showrangeinputs,
                          })}
                          defaultValue={item?.max}
                          disabled={viewMode}
                        ></AppInput>
                        {i != 0 && (
                          <button
                            disabled={viewMode}
                            onClick={(e) =>
                              this.props?.deleteField(e, i, this.form)
                            }
                            className="btn text-white col-1 mt-4"
                          >
                            <img
                              src={imagepaths.deleteIcon}
                              className="deleteIcon"
                            />
                          </button>
                        )}
                      </div>
                    </>
                  );
                })
              : criterias?.map((item, i) => (
                  <div className="row" key={i}>
                    <AppInput
                      type="select"
                      name={`criterias.${i}.parameterName`}
                      label="Parameters"
                      options={parameterName}
                      required="true"
                      disabled={viewMode}
                      onChange={(...args) =>
                        this.handleParameterValues(i, ...args)
                      }
                      className="col-4"
                      placeholder="Select"
                    ></AppInput>
                    <AppInput
                      type="select"
                      name={`criterias.${i}.operator`}
                      options={showInBetween ? OpsValues : _enum.OPERATOR_TYPES}
                      onChange={(...args) => this.handleOperators(i, ...args)}
                      label="Ops"
                      required="true"
                      disabled={viewMode}
                      className="col-3"
                      placeholder="Select"
                    ></AppInput>
                    <AppInput
                      type="select"
                      name={`criterias.${i}.values`}
                      options={item.values}
                      label="Value"
                      disabled={viewMode}
                      iscustomjoin={true}
                      customjoinval="|END|"
                      multi={true}
                      className="col-4"
                      placeholder="Select"
                    ></AppInput>
                    <AppInput
                      type="text"
                      name={`criterias.${i}.min`}
                      label="Min"
                      className="col-2 d-none"
                      disabled={viewMode}
                    ></AppInput>
                    <AppInput
                      type="text"
                      name={`criterias.${i}.max`}
                      label="Max"
                      className="col-2 d-none"
                      disabled={viewMode}
                    ></AppInput>
                    {i != 0 && (
                      <button
                        disabled={viewMode}
                        onClick={(e) =>
                          this.props?.deleteField(e, i, this.form)
                        }
                        className="btn text-white col-1 mt-4"
                      >
                        <img
                          src={imagepaths.deleteIcon}
                          className="deleteIcon"
                        />
                      </button>
                    )}
                  </div>
                ))}
            <div className="col-6">
              <button
                disabled={viewMode}
                className="text-primary addMoreBtn border-none bg-white"
                onClick={(...args) => this.props?.addField(...args)}
                type="button"
              >
                <b>+ Add More</b>
              </button>
            </div>
            <div className="mt-4 ms-2">* All parameters will be AND</div>
          </Row>
          {!viewMode && (
            <Row className="mt-4">
              <Col
                className={Object.className({
                  "text-right": true,
                  // hidden: disableallfields,
                })}
              >
                <button type="submit" className="btn add-btn">
                  {submitText}
                </button>
              </Col>
              <Col>
                <button
                  type="reset"
                  className={Object.className({
                    "btn cancel-btn": true,
                    // "m-auto": disableallfields,
                  })}
                  data-bs-dismiss="modal"
                  onClick={(...args) => this.closemodal(...args)}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          )}
          {viewMode && (
            <Col className="d-flex float-right">
              <button
                type="reset"
                className={Object.className({
                  "btn cancel-btn": true,
                })}
                data-bs-dismiss="modal"
                onClick={(...args) => this.closemodal(...args)}
              >
                Cancel
              </button>
            </Col>
          )}
        </AppForm>
      </AppModalBs>
    );
  }
}

export default connect(CriteriaModal);

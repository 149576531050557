import { Component } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import LayoutWrapper from "./LayoutWrapper";

export class ProjectManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ProjectManagement");
    this.state = {
      data: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "project_name",
          title: "Project Name",
          render: (text, record) => {
            return (
              <span
                className="text_truncate cursor-pointer"
                title={record?.project_name}
                onClick={() => alert(record?.project_name)}
              >
                {record?.project_name}
              </span>
            );
          },
        },
        {
          dataIndex: "project_start_date",
          title: "Project Start Date",
          render: (text) => {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          },
        },
        {
          dataIndex: "project_end_date",
          title: "Project End Date",
          render: (text) => {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          },
        },
        {
          dataIndex: "attachments",
          title: "Attachments",
          render: (text) => {
            if (text[0]) {
              let { attachmentId, attachmentName } = text[0];
              return (
                <>
                  <a
                    className="link_btn"
                    href={this.downloadFile(attachmentId)}
                    target="_blank"
                  >
                    Download
                  </a>
                </>
              );
            } else {
              return <>-</>;
            }
          },
        },
        {
          dataIndex: "project_status",
          title: "Project Status",
          render: (text) => this.getStatus(text),
        },
      ],
    };
  }
  getStatus(status) {
    let enums = _enum.PM_STATUS;
    let targetItem = enums.filter((v) => v.key === status);

    return (
      <span style={{ color: `${targetItem[0].colorCode}` }}>
        {targetItem[0].label}
      </span>
    );
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    this.api.apiGetProjectsList(this.state.search).then(({ data, total }) => {
      // console.log({ apiGetProjectsList: data });
      this.setState({
        data: [...data],
        rowcount: total,
      });
    });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  deleteProjectAction(value) {
    let payload = {
      project_id: value.project_id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteProject(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editProjectAction(v) {
    this.props.navigate(
      "/projectmanagement/addproject?" +
        Object.QueryString({
          project_id: v.project_id,
        })
    );
  }

  viewProjectAction(v) {
    this.props.navigate(
      "/projectmanagement/addproject?" +
        Object.QueryString({
          project_id: v.project_id,
        }) +
        "&readonly=1"
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.props.api.apiGetProjectsList({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      let enums = _enum.PM_STATUS;
      console.log({ totalrows });
      totalrows = totalrows.map((item) => {
        return {
          ...item,
          project_status: enums.filter((v) => v.key === item.project_status)[0]
            .label,
          project_start_date: new Date(item.project_start_date).dateToDDMMYYYY(
            new Date(item.project_start_date)
          ),
          project_end_date: new Date(`${item.project_end_date}`).dateToDDMMYYYY(
            new Date(`${item.project_end_date}`)
          ),
        };
      });
      console.log({ totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "project_name",
        "project_start_date",
        "project_end_date",
        "project_status",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `project_management_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };
  
  render() {
    let { columns, data = [], rowcount } = this.state;
    return (
      <LayoutWrapper title="Project Management" back="Project">
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <Link
              to={
                "/projectmanagement/addproject?" +
                Object.QueryString({
                  new: 1,
                })
              }
              className="btn add-btn"
            >
              Add
            </Link>
            <button
              type="button"
              className="btn add-btn mx-2"
              onClick={() => this.getDownloadData()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
        </div>
        {/* <ProjectManagementSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        /> */}
        <div className="row project_management">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                deleteAction={(v) => this.deleteProjectAction(v)}
                editAction={(v) => this.editProjectAction(v)}
                viewAction={(v) => this.viewProjectAction(v)}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout"></div>}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(ProjectManagement);

import React, { Component } from 'react'
import LayoutWrapper from '../../LayoutWrapper';
import { tabs } from '../leavemanagment';

export class Holidays extends Component {
    constructor(props) {
        super(props);
        this.links = tabs;
        this.columns = [
            {
                dataIndex:"_id",
                title:"Sl",
                render:(text,record,i)=>(i+1)
            },
            {
                dataIndex:"holiday_name",
            },
            {
                dataIndex:"holiday_date",
                render:(text)=>(text.date().moment().format("DD MMM YYYY"))
            },
            {
                dataIndex:"holiday_day",
                title:"Day"
            },
            {
                dataIndex:"employer_obj_id",
                display:false
            },
            {
                dataIndex:"status",
                display:false
            },
            {
                dataIndex:"details",
                display:false
            },
            {
                dataIndex:"created_at",
                display:false
            }
        ];

        this.state = {
            holidayList:this.props.store.holidayList
        };

        this.addfields = [
            {
                name: "holiday_date",
                label:"Holiday Date",
                type:"date",
                required:true,
            },
            {
                name: "holiday_name",
                label: "Holiday Name",
                required:true,
            },
            {
                name: "details",
                label:"Holiday Details",
                type:"textarea",
                controlClass:"h-52",
                required:true,
            }
        ]
    }

    componentDidMount() {
        
    }

    setHolidayList() {
        setTimeout(()=>{
            console.log({list:this.props.store.holidayList});
            this.setState({
                holidayList:this.props.store.holidayList
            });
        },0);
    }

    addHoliday(payload=null) {
        let edit = !!payload;
        let modalTitle = edit?"Edit Holiday":"Add Holiday";
        let apiEndPoint = edit?"apiEditHoliday":"apiAddHoliday";
        payload = payload||{}
        prompt(modalTitle,{},<div id="prompt" className='row text-left'>
            {
                this.addfields.map((field, i) => (
                    <div className="col-sm-12" key={i} >
                        <AppInput
                            {...field}
                            defaultValue={payload[field.name]}
                        >
                        </AppInput>
                    </div>
                ))
            }
        </div>).promise.then(()=>{
            let data = document.querySelector("#appmodal").getData();
            this.props.api[apiEndPoint]({
                ...data,
                holiday_day:new Date(data.holiday_date).getDayFullName()
            },{
                hol_id:payload._id
            }).then(()=>this.setHolidayList());
        });
    }

    editHoliday(payload) {
        //console.log({payload});
        this.addHoliday(payload);
    }

    deleteHoliday(payload) {
        confirm("Please confirm your delete action").promise.then(()=>{
            this.props.api.apiDeleteHoliday({},{
                hol_id:payload._id
            }).then(()=>this.setHolidayList());
        });
    }

    render() {
        let { links, columns=[] } = this;
        let { holidayList=[] } = this.state;
        return (
            <LayoutWrapper title="Holidays" back="Leave Management">
                <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs>
                <h3 className='mt-12 mb-2'>Holidays {(new Date().getFullYear())}</h3>
                <div className='flex mb-6'>
                    <button className='ml-auto btn add-btn' onClick={()=>this.addHoliday()}>+ Add Holiday</button>
                </div>
                <div className='row mb-4'>
                    <div className='col'>
                        <AppTable
                            data={holidayList}
                            columns={columns}
                            reorder={true}
                            deleteAction={(...args)=>this.deleteHoliday(...args)}
                            editAction={(...args)=>this.editHoliday(...args)}
                        >
                        </AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default connect(Holidays);
import React, { Component } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";

export class DocList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedData: props.data,
    };

    this.columns = [
      {
        title: "Doc Type",
        dataIndex: "doctype",
      },
      {
        title: "Doc Name",
        dataIndex: "docname",
      },
      {
        title: "File",
        dataIndex: "file",
        render: (text, record) => record?.file?.name,
      },
    ];
  }

  componentDidMount() {
    this.props.api.apiGetProfileDocsDetails().then((data) => {
      this.setState((state) => ({
        ...state,
        uploadedData: data,
      }));
    });
  }

  render() {
    let { uploadedData } = this.state;
    let { columns, props } = this;
    let { data } = props;
    return (
      <div className="col-12">
        <Table
          className="table-striped"
          pagination={{
            total: uploadedData.length,
            showTotal: (total, range) =>
              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          }}
          columns={columns}
          dataSource={uploadedData}
          rowKey={(record) => record.id}
          onChange={console.log("change")}
        />
      </div>
    );
  }
}

export default connect(DocList);

import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";

export class AddMom extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddMom");
    this.state = {
      form: {},
      key_points: [{}, {}, {}, {}],
      actionable: [{}],
      mounted: false,
      rendered: false,
      fprOptions: [],
    };
  }

  get has_edit() {
    return !!this.props.urlparams.id;
  }

  get formEle() {
    return this.refs?.form?.form?.current;
  }

  get formData() {
    let formData = this.formEle?.getData() || {};
    let parsedData = parseInput(formData);
    // parsedData.key_points = (parsedData.key_points || []).map((field) => {
    //   let { isSelect, FIELD_CONSTRAINTS } = this.checkFieldTypes({
    //     ...field,
    //     key: field.field_type,
    //   });
    //   let { isNumeric, hasSubCategory } = this.checkConstraints(field);
    //   console.log({ FIELD_CONSTRAINTS });
    //   return {
    //     ...field,
    //     FIELD_CONSTRAINTS,
    //     hasSelect: isSelect,
    //     isNumeric,
    //     hasSubCategory,
    //   };
    // });
    // console.log({ formData: parsedData });
    parsedData.key_points = (parsedData.key_points || []).map(
      (field) => field.pointsDiscussed
    );

    return parsedData;
  }

  addKeyPointField(e) {
    let { key_points } = this.state;
    // console.log({ key_points });
    key_points = [...key_points, {}];
    this.setState({
      key_points,
    });
  }

  addActionField(e) {
    let { actionable } = this.state;
    actionable = [...actionable, {}];
    // console.log({ actionable });
    this.setState({
      actionable,
    });
  }

  deleteKeyPointField(index) {
    const removeLocal = () => {
      let { key_points } = this.state;
      key_points = key_points.filter((v, i) => i != index);
      this.setState({
        key_points,
      });
    };
    removeLocal();
  }

  deleteActionField(index) {
    const removeLocal = () => {
      let { actionable } = this.state;
      actionable = actionable.filter((v, i) => i != index);
      this.setState({
        actionable,
      });
    };
    removeLocal();
  }

  componentDidMount() {
    if (this.mixin.params().id) {
      this.api
        .apiGetMeetingMinutesById({ mom_id: this.props.urlparams.id })
        .then((res) => {
          console.log("apiGetMeetingMinutesById", res.data);
          this.setState({
            form: res.data,
            key_points: res?.data?.key_points.map((str) => ({
              pointsDiscussed: str,
            })),
            actionable: res?.data?.actionable.map((val, i) => ({
              // ...(val?.task_id
              //   ? {
              //       task_id: res?.data?.actionable[i].task_id,
              //     }
              //   : {}),
              task_id: val?.task_id,
              task_name: val.task_name,
              task_type: val.task_type,
              expected_completion_date: val.expected_completion_date,
              assigned_roles: val.assigned_roles,
              fpr: val.fpr instanceof Array ? val.fpr : [],
              status: val.status,
              priority: val.priority,
              isMulti: val.task_type === "group_task" ? true : false,
              hasTaskId: val.task_id ? true : false,
              ...this.getRoles(val.assigned_roles, i),
            })),
            mounted: true,
          });
        });
    } else {
      this.setState({
        mounted: true,
      });
      // SET ONLY FIRST KEY-POINT AS REQUIRED
      document
        .querySelector(`[name="key_points.${0}.pointsDiscussed"]`)
        .setAttribute("required", true);
    }
  }

  checkConstraints(field) {
    let isNumeric = (field.fieldConstraint || "")
      .split(",")
      .includes("numeric");
    let hasSubCategory = ["true"].includes(field.subCategory + "");
    return {
      isNumeric,
      hasSubCategory,
    };
  }

  handleTaskTypeChange(v, i) {
    console.log("handleTaskTypeChange: ", v);
    let bool;

    if (v[0].key === "group_task") {
      bool = true;
    } else {
      bool = false;
    }
    if (v) {
      let roleInput = document.querySelector(
        `[name='appinput-actionable.${i}.assigned_roles'].form-group`
      );
      // console.log(roleInput);
      let { values: roleValues = [] } = roleInput.vnode.refs.select.state;
      if (roleValues.length && !this.has_edit) {
        // if (roleValues.length) {
        roleInput.vnode.refs.select.clearAll();
        this.setState({
          fprOptions: [],
        });
      }
    }
    const setFields = (state) => {
      let fields = Object.setNested(state.actionable, `${i}.isMulti`, null);
      fields = Object.setNested(state.actionable, `${i}.isMulti`, bool);
      return fields;
    };
    this.setState((state) => ({
      ...state,
      actionable: setFields(state),
    }));
  }

  handleRoleChange(val, i) {
    console.log("handleRoleChange: ", val);
    if (val) {
      let fprName = document.querySelector(
        `[name='appinput-actionable.${i}.fpr'].form-group`
      );
      // console.log(fprName);
      let { values: fprValues = [] } = fprName.vnode.refs.select.state;
      if (fprValues.length) {
        fprName.vnode.refs.select.clearAll();
        this.setState({
          fprOptions: [],
        });
      }
      this.getRoles(val, i);
    }
  }

  getRoles(v, i) {
    console.log("getRoles assigned_roles: ", v);
    let targetValue = v;
    console.log({ targetValue });

    if (targetValue) {
      let multiRolePayload = {};

      if (typeof targetValue[0] === "object") {
        multiRolePayload = {
          employee_roles: v.map((v) => v.key),
        };
      } else {
        multiRolePayload = {
          employee_roles: targetValue,
        };
      }
      console.log({ multiRolePayload });
      this.api
        .apiEmployeeByMultiRoles(multiRolePayload)
        .then((res) => {
          //console.log("list of supervisors: ", res);
          if (res?.data?.length) {
            let filteredArr = res?.data?.map((value) => {
              return {
                key: value.employee_id,
                label: value.employee_name + ` ( ${value.employee_id} ) `,
              };
            });
            console.log("filteredArr", filteredArr, targetValue);

            let selectAllOption = {
              key: "*",
              label: "Select All",
            };

            this.setState({
              fprOptions: filteredArr,
            });
            // let addedAllOpts = [selectAllOption, ...filteredArr];

            const setFields = (state) => {
              let fields = Object.setNested(state.actionable, `${i}.fpr`, []);
              fields = Object.setNested(
                state.actionable,
                `${i}.fpr`,
                filteredArr
              );
              return fields;
            };
            this.setState((state) => ({
              ...state,
              actionable: setFields(state),
            }));
          }
        })
        .catch((err) => {
          // console.log({ err });
          this.props.onError && this.props.onError(err);
          //alert(err.message);
        });
    } else {
      return;
    }
  }

  sumitForm(e, data, form) {
    // let parsedPayload = parseInput(data);
    // console.log(parsedPayload, data, this.formData);
    console.log("formdata: ", this.formData);
    let dt = this.formData?.date.date();
    dt = dt.dateToDDMMYYYY(dt, "/");
    let payload = {
      ...this.formData,
      date: dt,
      actionable: this.formData?.actionable.map((action) => {
        return {
          ...action,
          ...(action?.task_id
            ? {
                task_id: action.task_id,
              }
            : {}),
          ...(action?.assigned_roles
            ? {
                assigned_roles: action?.assigned_roles.split(","),
              }
            : {}),
          ...(action?.fpr
            ? {
                // fpr: action?.fpr.split(",").map((item) => ({ employee_id: item })),
                fpr: action?.fpr.splitBy(","),
              }
            : {}),
        };
      }),
      key_points: this.formData?.key_points?.filter((item) => item !== ""),
    };
    console.log("altered formdata: ", payload);

    if (this.mixin.params().id) {
      this.api
        .apiUpdateMeetingMinutes(payload, {
          mom_id: this.props.urlparams.id,
        })
        .then(() => {
          success("Form updated successfully");
          this.navigate("/admin/mom");
        });
    } else {
      console.log({ apiCreateMeetingMinutes: payload });
      this.api.apiCreateMeetingMinutes(payload).then(() => {
        this.navigate("/admin/mom");
      });
    }
  }

  getMergeData(obj1 = {}, obj2 = {}, empty = [undefined, null, ""]) {
    const isempty = (v1, v2) => (empty.includes(v1) ? v2 : v1);
    return Object.keys({
      ...obj1,
      ...obj2,
    }).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          this.props.urlparams.id || new Date()
            ? isempty(obj1[key], obj2[key])
            : isempty(obj2[key], obj1[key]),
      }),
      {}
    );
  }

  getBreadCums() {
    if (this.props.urlparams.id) {
      return `MOMs / Update MOM`;
    } else {
      return "MOMs / Create New MOM";
    }
  }
  getBackLink() {
    return "/admin/mom";
  }

  render() {
    let { props, has_edit } = this;
    let { key_points, actionable, form, rendered, mounted } = this.state;
    let { key_points: keyfields = [], actionable: actionfields = [] } =
      this.formData;
    let hasForm = key_points.length == 0;
    let hasActionForm = actionable.length == 0;

    let maxFields = hasForm ? keyfields : key_points;
    let maxActionFields = hasActionForm ? actionfields : actionable;

    // console.log({ rendered, maxActionFields });
    if (mounted && !rendered) {
      this.setState({ rendered: true });
    }
    if (mounted && rendered) {
      key_points = maxFields.map((v, i) =>
        this.getMergeData(key_points[i], keyfields[i])
      );
    }
    actionable = maxActionFields.map((v, i) =>
      this.getMergeData(actionable[i], actionfields[i])
    );
    // console.log({
    //   rendered,
    //   mounted,
    //   key_points,
    //   keyfields,
    //   actionable,
    //   actionfields,
    // });
    return (
      <LayoutWrapper
        title="MOMs"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppForm
          ref="form"
          className="row gap-3 p-4 bg-white"
          onSubmit={(...args) => this.sumitForm(...args)}
        >
          <div className="col-12 col-md-3">
            <AppInput
              type="date"
              label="Date"
              name="date"
              required={true}
              defaultValue={form?.date}
              disabled={props.urlparams.readonly}
              disabledDates={(date) =>
                new Date().toDate().date().getTime() < date.getTime()
              }
            ></AppInput>
          </div>
          <div className="col-12 col-md-3">
            <AppInput
              type="time"
              label="Time"
              name="time"
              required={true}
              defaultValue={form?.time}
              disabled={props.urlparams.readonly}
            ></AppInput>
          </div>
          <div className="col-12 col-md-3">
            <AppInput
              type="text"
              label="Meeting Agenda"
              name="meeting_agenda"
              required={true}
              defaultValue={form?.meeting_agenda}
              disabled={props.urlparams.readonly}
            ></AppInput>
          </div>

          <div className="row">
            <label className="mb-2">Key Points Discussed</label>
            {key_points.map((field, i) => (
              <>
                <div className="col-10 col-md-10" key={"field" + i}>
                  <AppInput
                    type="text"
                    name={`key_points.${i}.pointsDiscussed`}
                    // required={true}
                    placeholder={`Enter Key Point Discussed ${i + 1}`}
                    defaultValue={field?.pointsDiscussed}
                    disabled={props.urlparams.readonly}
                  ></AppInput>
                </div>
                <div className="col-2 col-md-2">
                  {i >= 4 && (
                    <button
                      className={Object.className({
                        "btn m-auto mb-2 text-white delete_btn": true,
                        "pe-none": props.urlparams.readonly,
                      })}
                      type="button"
                      onClick={() => this.deleteKeyPointField(i)}
                      // disabled={props.urlparams.readonly}
                    >
                      <i className="fa fa-trash-o text-error" />
                    </button>
                  )}
                </div>
              </>
            ))}
            <div>
              {!props.urlparams.readonly && (
                <button
                  className="btn bg-transparent flex gap-2 text-primary mb-2"
                  type="button"
                  onClick={(e) => this.addKeyPointField(e)}
                >
                  <i className="fa fa-plus m-auto" />
                  <span className="font-bold">Add More</span>
                </button>
              )}
            </div>
            <hr />
          </div>

          <>
            {actionable.length > 0 &&
              actionable?.map((field, i) => (
                <div
                  className="row position-relative"
                  key={"field" + i}
                  id={field?.task_id}
                  style={{
                    maxWidth: `calc(100% - ${180}px)`,
                  }}
                >
                  {field?.task_id?.length > 0 ? (
                    <div className="col-12 col-md-6 d-none">
                      <AppInput
                        label="Task Id"
                        type="text"
                        name={`actionable.${i}.task_id`}
                        placeholder={`Actionable ${i + 1}.`}
                        defaultValue={field?.task_id}
                        disabled={props.urlparams.readonly}
                        readonly={true}
                      ></AppInput>
                    </div>
                  ) : null}
                  <div className="col-12 col-md-6">
                    <AppInput
                      label="Actionable"
                      type="text"
                      name={`actionable.${i}.task_name`}
                      placeholder={`Actionable ${i + 1}.`}
                      defaultValue={field?.task_name}
                      disabled={props.urlparams.readonly}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-4">
                    <AppInput
                      label="Task Type"
                      type="select"
                      name={`actionable.${i}.task_type`}
                      onChange={(v) => this.handleTaskTypeChange(v, i)}
                      options={_enum.TASK_TYPE}
                      defaultValue={field?.task_type}
                      disabled={field?.hasTaskId}
                      // disabled={props.urlparams.readonly || props.urlparams.id}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-4">
                    <AppInput
                      label="Role"
                      type="select"
                      name={`actionable.${i}.assigned_roles`}
                      options={[
                        { key: "manager", label: "Project manager" },
                        {
                          key: "cluster-co-ordinator",
                          label: "Cluster Co-ordinator",
                        },
                      ]}
                      onChange={(v) => this.handleRoleChange(v, i)}
                      multi={actionable[i].isMulti}
                      required={true}
                      defaultValue={field?.assigned_roles}
                      disabled={field?.hasTaskId}
                      // disabled={props.urlparams.readonly || props.urlparams.id}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-4">
                    <AppInput
                      label="FPR"
                      type="select"
                      name={`actionable.${i}.fpr`}
                      selectAll={actionable[i].isMulti}
                      // selectAllLabel="Select all"
                      // clearable={true}
                      // separator={true}
                      options={actionable[i].fpr || []}
                      multi={actionable[i].isMulti}
                      required={true}
                      defaultValue={field?.fpr || []}
                      disabled={field?.hasTaskId}
                      // disabled={props.urlparams.readonly || props.urlparams.id}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-4">
                    <AppInput
                      label="EDC"
                      type="date"
                      name={`actionable.${i}.expected_completion_date`}
                      required={true}
                      defaultValue={field?.expected_completion_date}
                      disabledDates={(date) =>
                        new Date().toDate().date().getTime() > date.getTime()
                      }
                      disabled={props.urlparams.readonly}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-4">
                    <AppInput
                      label="Status"
                      type="select"
                      name={`actionable.${i}.status`}
                      options={_enum.MOM_STATUS}
                      required={true}
                      defaultValue={field?.status}
                      disabled={props.urlparams.readonly}
                    ></AppInput>
                  </div>
                  <div className="col-12 col-md-4">
                    <AppInput
                      label="Priority"
                      type="select"
                      name={`actionable.${i}.priority`}
                      options={_enum.TASK_PRIORITY_LEVEL}
                      required={true}
                      defaultValue={field?.priority}
                      disabled={props.urlparams.readonly}
                    ></AppInput>
                  </div>
                  <hr className="mt-4 mb-0" />
                  {i >= 1 && (
                    <button
                      className={Object.className({
                        "btn m-auto mb-2 text-white delete_btn float": true,
                        "pe-none": props.urlparams.readonly,
                      })}
                      type="button"
                      onClick={() => this.deleteActionField(i)}
                    >
                      <i className="fa fa-trash-o text-error" />
                    </button>
                  )}
                </div>
              ))}
            <div>
              {!props.urlparams.readonly && (
                <button
                  className="btn bg-transparent flex gap-2 text-primary"
                  type="button"
                  onClick={(e) => this.addActionField(e)}
                >
                  <i className="fa fa-plus m-auto" />
                  <span className="font-bold">Add More</span>
                </button>
              )}
            </div>
          </>
          <div className="flex">
            <div className="grow"></div>
            {!props.urlparams.readonly && (
              <>
                <button className="btn btn-primary add-btn">
                  {has_edit ? "Update" : "Save & Next"}
                </button>
              </>
            )}
            <Link to={"/admin/mom"} className="btn btn_primary grey ms-2 me-2">
              Cancel
            </Link>
            {props.urlparams.readonly && (
              <>
                <a
                  className="btn btn-primary add-btn"
                  href={`${window.location.pathname}?id=${props.urlparams.id}`}
                >
                  Edit
                </a>
              </>
            )}
          </div>
        </AppForm>
      </LayoutWrapper>
    );
  }
}

export default connect(AddMom);

import React, { Component } from "react";
import LayoutWrapper from "./LayoutWrapper";

export class PublicPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("PublicPage");
    this.state = {
      form: null,
      rowcount: 0,
    };
    this.errorsMessages = {};
  }

  get errors() {
    return Object.entries(this.errorsMessages)
      .filter(([key, val]) => val)
      .map(([key, val]) => key);
  }

  get isValid() {
    let data = this.formData;
    let keys = Object.keys(data);
    let { fields = [] } = this.state.form || {};
    return keys.reduce((flag, key) => {
      let field =
        fields.find((o) => o.field_name == key || o.name == key) || {};
      let constraints = (field.field_constraint || "")
        .split(",")
        .filter((v) => v)
        .map((v) => _enum.CONSTRAINTS_MAP[v]);
      let value = data[key];
      console.log({ constraints, key, field, value });
      let valid = constraints.reduce(
        (flag, v) =>
          flag &&
          ((typeof v == "string" && value.match(new RegExp(v))) ||
            (typeof v == "function" &&
              v(field.field_type == "date" ? value.date() : value))),
        true
      );
      if (!valid) {
        this.errorsMessages[key] = true;
      } else {
        this.errorsMessages[key] = false;
      }
      return flag && !!valid;
    }, true);
  }

  get formData() {
    return this.refs.form.form.current.getData();
  }
  componentDidMount() {
    document.body.addEventListener("googleLangugeLoaded", (e) => {
      this.checkLangRender();
    });
    this.api.apiGetCandidateRegistrationForm().then((data) => {
      this.store("translateLang", data.language.split(","));
      this.setState({
        form: data,
      });
    });
  }

  checkLangRender() {
    console.log("checkLangRender", window.langRender);
    if (window.langRender) {
      mixins.debounce(() => window.langRender());
    } else {
      setTimeout(() => {
        this.checkLangRender();
      }, 500);
    }
  }

  onFieldFocus(field) {
    console.log("onFocus");
    langRender();
  }

  isCheckboxOrRadio(field) {
    return ["checkbox", "radio"].includes(field.field_type);
  }

  submitForm(e, data, form) {
    if (!this.isValid) {
      alert("Please fill valid values in " + this.errors.join(","));
      langRender();
      return;
    }
    console.log("form data: ", this.state.form);
    let parsedPayload = parseInput(data);
    let MANDATORIES = Object.values(_enum.MANDATORY_OBJS);
    let inMandatory = (key) =>
      MANDATORIES.find((o) => o.field_name == key || o.name == key);
    this.store(`publiccollect.${this.mixin.params().id}`, parsedPayload);
    let mandatories = Object.filter(parsedPayload, (value, key) =>
      inMandatory(key)
    );
    let answers = Object.entries(parsedPayload)
      .filter(([key, value]) => !inMandatory(key))
      .map(([key, value]) => ({
        field_label: key,
        field_response: value,
      }));

    this.api
      .request(this.api.api().saveFormResponce, {
        job_id: this.mixin.params().id,
        ...mandatories,
        answers,
      })
      .exec()
      .get()
      .then((res) => {
        if (res.status === 200) {
          success("Successfully saved").promise.then(() => {
            navigate("/public/thankyouFormConfig");
          });
        } else {
          alert("Error: " + res.message);
        }
      });
  }

  handelDisabledDates(date, field) {
    let constraints = field.field_constraint.split(",");
    let hasFutureDates = constraints.includes("future_dates_only");
    let hasPastDates = constraints.includes("past_dates_only");
    if (hasFutureDates) {
      return !_enum.CONSTRAINTS_MAP.future_dates_only(date);
    } else if (hasPastDates) {
      return !_enum.CONSTRAINTS_MAP.past_dates_only(date);
    }
    return false;
  }

  handleOptions(event, field, i, checkIndex) {
    let { form } = this.state;
    let isChecked = event.target.checked;
    if (["checkbox"].includes(field.field_type)) {
      if (isChecked && !isNaN(checkIndex)) {
        form.fields[i].checkArr = form.fields[i].checkArr || [];
        form.fields[i].checkArr.push(checkIndex);
      } else {
        form.fields[i].checkArr = form.fields[i].checkArr.filter(
          (v) => v !== checkIndex
        );
      }
      form.fields[i].checkValue = form.fields[i].checkArr.length;
    } else if (["radio"].includes(field.field_type)) {
      form.fields[i].checkValue = isChecked;
    }
    form.fields = [...form.fields];
    console.log(form.fields[i].checkValue, event.target.checked, { index: i });
    this.setState({
      form: { ...form },
    });
  }

  render() {
    let { state } = this;
    let { form, errors } = state;
    if (form) {
      console.log({ render: form.fields });
    }

    return (
      <LayoutWrapper>
        <div className="d-flex align-items-center justify-content-center">
          {form && (
            <AppForm
              className="card m-auto my-5 p-4 w-full public_form"
              onSubmit={(...args) => this.submitForm(...args)}
              ref="form"
            >
              <div className="">
                {form.fields.map((field, i) => (
                  <div key={field.field_name + "-" + i} className="grow">
                    {this.isCheckboxOrRadio(field) && (
                      <div className="list-group option_box">
                        <AppInput
                          type="label"
                          required={field.field_constraint.includes(
                            "mandatory"
                          )}
                          label={field.field_name}
                        >
                          {field.possible_values.split(",").map((option, j) => (
                            <AppInput
                              key={j}
                              type={field.field_type}
                              className="list-group-item"
                              name={field.field_name}
                              value={option}
                              onChange={(event) =>
                                this.handleOptions(event, field, i, j)
                              }
                              required={
                                field.field_constraint.includes("mandatory") &&
                                !field.checkValue &&
                                j == 0
                              }
                            >
                              {option}
                            </AppInput>
                          ))}
                        </AppInput>
                      </div>
                    )}
                    {!this.isCheckboxOrRadio(field) && (
                      <AppInput
                        title={field.field_constraint}
                        required={field.field_constraint.includes("mandatory")}
                        type={field.field_type}
                        name={field.name || field.field_name}
                        label={field.field_name}
                        className="list-group option_box"
                        {...(field.field_type == "select"
                          ? {
                              onDropdownOpen: () => this.onFieldFocus(field),
                              onDropdownClose: () => this.onFieldFocus(field),
                            }
                          : {})}
                        {...(field.field_type == "date"
                          ? {
                              disabledDates: (date) =>
                                this.handelDisabledDates(date, field),
                            }
                          : {})}
                        options={
                          field.possible_values
                            ? field.possible_values
                                .split(",")
                                .options("", "", (v) => v)
                            : []
                        }
                      ></AppInput>
                    )}
                  </div>
                ))}
              </div>
              <div className="mt-2 flex">
                <button className="btn add-btn m-auto">Submit</button>
              </div>
            </AppForm>
          )}
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(PublicPage);

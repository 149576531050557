import React, { Component } from "react";
import _enum from "src/mixins/enum";
import SchemeModal from "./schemeModal";

export class schemeDetails extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("schemeDetails");
    this.state = {
      loader: false,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      data: [],
      editData: {},
      loader: false,
    };
    this.formFields = [
      {
        type: "text",
        name: "name",
        label: "Scheme Name",
        required: true,
      },
      {
        type: "select",
        name: "category",
        label: "Category",
        options: _enum.SCHEMES_CATEGORY,
        placeholder: "Select",
        className: "select-focus",
        required: true,
      },
      {
        type: "select",
        name: "department",
        label: "Concerned Department / Agency",
        placeholder: "Select",
        className: "select-focus dropdown-styling",
        required: true,
      },
      {
        type: "select",
        name: "type",
        label: "Scheme Type",
        options: _enum.SCHEMES_TYPES,
        placeholder: "Select",
        className: "select-focus",
      },
      {
        type: "date",
        name: "implementationDate",
        label: "Implementation Date",
        required: true,
      },
      {
        type: "date",
        name: "endDate",
        label: "Scheme End Date",
      },
      {
        type: "textarea",
        name: "objectives",
        label: "Objectives",
        controlClass: "!min-h-[80px]",
        fieldclass: "col-12 col-lg-12",
      },
      {
        type: "text",
        name: "grm",
        label: "Grievance Redressal Mechanism",
        fieldclass: "col-12 col-lg-12",
      },
      {
        type: "text",
        name: "specialProvisions",
        label: "Special Provisions (If any)",
        fieldclass: "col-12 col-lg-12",
      },
      {
        type: "text",
        name: "website",
        label: "Official Website",
        fieldclass: "col-12 col-lg-12",
      },
      {
        type: "textarea",
        name: "description",
        label: "Description",
        fieldclass: "col-12 col-lg-12",
        controlClass: "!min-h-[150px]",
      }, 
      {
        type: "date",
        name: "createdAt",
        label: "Created At",
        fieldclass: "col-12 col-lg-6",
      },
      {
        type: "date",
        name: "updatedAt",
        label: "Updated At",
        fieldclass: "col-12 col-lg-6",
      },
    ];
    this.schemeModalRef = React.createRef();
  }

  addModalFields() {
		let fields = (this.formFields || []).reduce(
			(o, n) => ({ ...o, [n.name]: n }),
			{}
		);
	}
  
  openSchemeModal() {
		this.schemeModalRef.current.click();
	}

  closeModal() {
		document.querySelector("#open_scheme_modal #bsmodalclose").click();
	}

  submitForm(event, data) {
    if(this.props.editMode || this.props.showEditData) {
      this.handleUpdate(event, data);
      this.props.onDetailsSubmit(data);
    } else {
    this.setState({
      loader: true,
    })
    this.props.api
    .apiCreateScheme(data).then((response) => {
      localStorage.setItem("scheme_id", response._id)
      this.props.onDetailsSubmit(data);
      this.props.showDataToEdit(response._id);
    }).catch((err) => {
      console.log(err)
    });
   }
  }

  handleUpdate(event, data) {
    this.props.api
    .apiUpdateScheme(data, {schemeId: this.props.editData._id})
    .then((res) => {
      this.openSchemeModal();
      this.props.getSchemeDetails(this.props.editData._id);
    }).catch((err) => {
      console.log(err)
    })
  }

  componentDidMount() {
    this.fetchDepartmentList();
    if(this.props.showEditData) {
      this.props.showDataToEdit(localStorage.getItem("scheme_id"));
    }
  }

  fetchDepartmentList() {
    this.props.api
      .apiGetDepartmentList(this.state.search)
      .then(({ data, total }) => {
        console.log({apiGetDepartmentList: data});
        this.setState({
          data,
          // rowcount: total,
        });
        let department = this.formFields.find(
          (o) => o.name == "department"
        );
        department.options = data?.items?.options("_id", "name");
      }).catch((err) => {
      });
  }

  render() {
    let { formFields } = this;
    let { editData, viewMode, editMode, isActive } = this.props;
    if(!viewMode) {
      formFields = formFields?.filter(v => v.name !== "updatedAt" && v.name !== "createdAt")
    }
    if (editData) {
      formFields = formFields.map((field) => {
        field.defaultValue = field.defaultValue;
        if (![undefined].includes(editData[field.name])) {
          if (field.type == "date") {
            if(editData[field.name]?.date()) {
              field.defaultValue = editData[field.name].date();
            } else {
              field.defaultValue = editData[field.name];
            }
          } else {
            field.defaultValue = editData[field.name];
          }
        }
        return field;
      });
    }

    return (
      <>
        <div className="row m-auto">
          <AppForm
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => editMode && isActive
               ? this.handleUpdate(...args) : this.submitForm(...args)}
          >
            <div className="row">
              {formFields?.map((field, i) => (
                <div
                className={
                  field.fieldclass
                    ? field.fieldclass
                    : "col-12 col-md-6 col-lg-4"
                }
                  key={"field" + i}
                >
                  <AppInput
                    {...field}
                    disabled={this.props.viewMode}
                  ></AppInput>
                </div>
              ))}
            </div>
            {!viewMode ?
              <div className="row no-gutters">
                  <button className="btn add-btn col col-md-1 ml-auto">
                    {`${editMode && isActive ? "Update" : "Save & Next"}`}
                  </button>
                  <button
                    onClick={() => location.reload()}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Cancel
                  </button>
              </div>
              : <div className="row no-gutters">
              <button
                onClick={() => location.reload()}
                className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
              >
              Cancel
              </button>
            </div>}
          </AppForm>
        </div>
        <SchemeModal
          id="open_scheme_modal"
          title=""
          message="Scheme details updated successfully"
          contentClass="smallModalBox"
          closeText="Ok"
        />
        {/* below button hidden on UI */}
        <button
          className="btn btn-sm btn-outline-secondary d-none"
          onClick={(...args) => this.addModalFields(...args)}
          ref={this.schemeModalRef}
          data-bs-toggle="modal"
          data-bs-target="#open_scheme_modal"
        >
          <span>Hidden Button</span>
        </button>
      </>
    );
  }
}

export default connect(schemeDetails);

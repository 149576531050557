import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../LayoutWrapper";
export class Thankyouquiz extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Thankyouquiz");
  }
  proceed() {
    this.navigate("/");
  }
  componentDidMount() {
    if (mixins.params().id) {
      this.api.apiGetResponce().then((candidate) => {
        this.api
          .apiGetCandidateAssementTest({}, { id: candidate.data.assessment_id })
          .then((form) => {
            this.store("translateLang", form.language.split(","));
          });
      });
    }
  }
  render() {
    let { props } = this;
    return (
      <LayoutWrapper>
        <div className="thanks_card">
          <div className="container py-5">
            <p>
              Thank you for choosing us and applying through our portal. We are
              very happy to share with you the online assessment, and hope you
              do well with your assessments.
            </p>
            <img
              src={imagepaths.thanksImg}
              alt="thanksImg"
              className="img-fluid"
            />
            {props.urlparams.id && (
              <div className="flex align-items-center justify-content-end">
                <Link
                  className={"btn btn_primary"}
                  to={`/public/candidatetest?id=${props.urlparams.id}`}
                >
                  Proceed
                </Link>
              </div>
            )}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Thankyouquiz);

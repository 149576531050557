import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";

export class AddPPT extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddPPT");
    this.state = {
      data: [],
      editMode: false,
      viewMode: false,
      addMode: false,
      editData: {},
      rowcount: 0,
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        filter: {
          course_id: props?.urlparams?.course_id,
        },
      },
    };
    this.tablinks = mixins.tablinks;
    this.columns = [
      {
        dataIndex: "ppt_name",
        title: "PPT Name",
      },
      {
        dataIndex: "attachment_id",
        title: "Attachments",
        render: (text) => {
          console.log(text);
          if (text) {
            return (
              <>
                <a
                  className="link_btn"
                  href={this.downloadFile(text)}
                  target="_blank"
                >
                  Download
                </a>
              </>
            );
          } else {
            return <>-</>;
          }
        },
      },
      {
        dataIndex: "ppt_description",
        title: "Description",
        render: (text, record) => {
          return (
            <span
              className="text_truncate cursor-pointer"
              title={record?.ppt_description}
              onClick={() => alert(record?.ppt_description)}
            >
              {record?.ppt_description}
            </span>
          );
        },
      },
      {
        dataIndex: "created_on",
        title: "Created on",
        render: (text) => {
          return <>{text ? new Date(`${text}`).toLocaleDateString() : "-"}</>;
        },
      },
      {
        dataIndex: "created_by",
        title: "Created by",
      },
    ];
    this.formFields = [
      {
        type: "text",
        name: "ppt_name",
        label: "PPT Name",
        maxLength: "30",
        required: true,
      },
      {
        type: "file",
        name: "attachment_id",
        label: "Upload",
        fileType: "ppt_file",
        accept: ".ppt, .pptx",
        required: true,
      },
      {
        type: "textarea",
        name: "ppt_description",
        label: "PPT Description (Max 250 words)",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-8",
        required: true,
      },
      {
        type: "select",
        name: "is_active",
        label: "Acitve",
        options: _enum.LMS_ACTIVE_STATES,
        fieldclass: "col-2",
        required: true,
        defaultValue: ["true"],
      },
    ];
  }

  get listMode() {
    return !(this.state.editMode || this.state.addMode);
  }

  addRecord() {
    this.setState({
      addMode: true,
    });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      let payload = {
        ...Object.except(this.state.search, "filter"),
      };
      console.log({ fetchData: payload });
      this.props.api
        .apiGetAllPPT(payload, { module_id: this.props.urlparams.module_id })
        .then(({ data, total }) => {
          this.setState({
            data,
            rowcount: total,
          });
          resolve();
        });
    });
  }

  editAction(v) {
    this.setState({
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }

  viewAction(v) {
    this.setState({
      editMode: true,
      viewMode: true,
      editData: v,
    });
  }

  deleteAction(v) {
    console.log("delete ", v);
    let payload = {
      ppt_id: v.ppt_id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeletePPT(payload).then(() => {
        this.fetchData();
      });
    });
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  sumitForm(event, data, form) {
    console.log({ addPPT: data });
    let { editData } = this.state;

    const resolveList = () => {
      this.fetchData().then(() => {
        this.setState({
          editMode: false,
          addMode: false,
          editData: {},
        });
      });
      form.reset();
    };
    if (this.state.editMode) {
      let payload = {
        ...data,
      };
      this.api
        .apiUpdatePPT(payload, { ppt_id: this.state.editData.ppt_id })
        .then((res) => {
          console.log(res);
          resolveList();
        });
    } else {
      this.setState({
        editData: data,
      });
      let payload = {
        ...data,
        course_id: this.props.urlparams.course_id,
        module_id: this.props.urlparams.module_id,
      };
      this.api.apiCreatePPT(payload).then((res) => {
        // console.log({ apiCreatePPT: res });
        resolveList();
      });
    }
  }

  getBreadCums() {
    if (this.props.urlparams.course_name) {
      return `${this.props.urlparams.course_name
        .spaceCase()
        .toTitleCase()} / Module Details / Videos / PPT`;
    } else {
      return "Course Name / Module Details / Videos / PPT";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.mixin.params().readonly) {
      let backLink = `/lms/module/addvideos?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&module_id=${this.props.urlparams.module_id}`;
      return backLink;
    } else if (this.mixin.params().readonly) {
      let backLink = `/lms/module/addvideos?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&module_id=${this.props.urlparams.module_id}&readonly=${this.props.urlparams.readonly}`;
      return backLink;
    } else {
      return "/lms/module";
    }
  }

  render() {
    let { tablinks, columns, props, formFields } = this;
    let { data = [], rowcount, editData } = this.state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      } else if (field.name == "is_active") {
        field.defaultValue = editData[field.name] || [true];
      } else {
        field.defaultValue = "";
      }
      return field;
    });

    return (
      <LayoutWrapper
        title="Course Configure"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        {(props?.urlparams?.course_id && (
          <>
            {!this.listMode && (
              <div className="row m-auto">
                <AppForm
                  className="flex flex-col gap-4 bg-white p-4"
                  onSubmit={(...args) => this.sumitForm(...args)}
                >
                  <div className="row">
                    {formFields?.map((field, i) => (
                      <div
                        className={
                          field.fieldclass
                            ? field.fieldclass
                            : "col-12 col-md-6 col-lg-4"
                        }
                        key={"field" + i}
                      >
                        <AppInput
                          className="grow"
                          {...field}
                          disabled={this.state.viewMode}
                        ></AppInput>
                      </div>
                    ))}
                  </div>
                  <div className="row no-gutters">
                    <button
                      className={Object.className({
                        "btn add-btn col col-md-1 ml-auto": true,
                        hidden: this.state.viewMode,
                      })}
                    >
                      {this.state.editMode ? "Update" : "Add"}
                    </button>
                    <button
                      onClick={() =>
                        this.setState({
                          editMode: false,
                          addMode: false,
                          viewMode: false,
                          editData: {},
                        })
                      }
                      className={Object.className({
                        "btn btn_primary col col-md-1 grey me-2 ms-2":
                          !this.state.viewMode,
                        "btn btn_primary col col-md-1 grey me-2 ml-auto":
                          this.state.viewMode,
                      })}
                    >
                      Cancel
                    </button>
                  </div>
                </AppForm>
              </div>
            )}
            {this.listMode && (
              <div className="row">
                <div className="col-12 mb-4">
                  <button
                    className={Object.className({
                      "btn add-btn ml-auto": true,
                      hidden: this.props.urlparams.readonly == 1 ? true : false,
                    })}
                    onClick={() => this.addRecord()}
                  >
                    <i className="fa fa-plus"></i>
                    Add PPT
                  </button>
                </div>
              </div>
            )}

            <div className="row addppt">
              <div className="col-12">
                {!!data.length && this.listMode && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    deleteAction={(v) => this.deleteAction(v)}
                    editAction={(v) => this.editAction(v)}
                    viewAction={(v) => this.viewAction(v)}
                    editable={this.props.urlparams.readonly == 1 ? false : true}
                    deletable={
                      this.props.urlparams.readonly == 1 ? false : true
                    }
                    pageSize={10}
                    targetType="tap"
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>

            {this.listMode && (
              <div className="col-12 mt-4">
                <Link
                  to={
                    "/lms/module/addassessment?" +
                    Object.QueryString({
                      ...this.props.urlparams,
                    })
                  }
                  className="btn btn_primary grey col col-md-1 float-right ms-3"
                >
                  Skip
                </Link>
                <Link
                  to={
                    "/lms/module/addassessment" +
                    Object.encodeuri({
                      course_id: this.props.urlparams.course_id,
                      course_name: this.props.urlparams.course_name,
                      module_id: this.props.urlparams.module_id,
                    })
                  }
                  className={Object.className({
                    "btn add-btn": true,
                    "d-none": this.props.urlparams.readonly,
                  })}
                >
                  Save & Next
                </Link>
              </div>
            )}
          </>
        )) ||
          ""}
      </LayoutWrapper>
    );
  }
}

export default connect(AddPPT);

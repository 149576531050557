/**
 * Crm Routes
 */
/* eslint-disable */
import React from "react";
import { LayoutWrapper } from "../LayoutWrapper";
import MasterDataList from "./MasterDataList";

const MasterDataRoute = ({ location }) => (
  <LayoutWrapper title="Master Data" back="Master Data / District">
    <MasterDataList />
  </LayoutWrapper>
);

export default connect(MasterDataRoute);

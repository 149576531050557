export default [
    {
        path: "/recruitment/dashboard",
        icon: "la la-dashboard",
        label: "Dashboard"
    },
    {
        path: "/recruitment",
        icon: "la la-file-text",
        label: "Registration Forms",
    },
    {
        path: "/recruitment/test_applications",
        icon: "la la-file-text",
        label: "Aptitude Tests",
    }
];

export default {

    MANDATORY_DC_OBJS: {
        "name": {
            name: "name",
            label: "Primary Name",
            fieldType: "text",
            fieldConstraint: "mandatory,character",
            possibleValues: []
        },
        "mobile_no": {
            name: "mobile_no",
            label: "Primary Number",
            fieldType: "text",
            fieldConstraint: "mandatory,phonenumber",
            possible_values: []
        }
    },
    MANDATORY_DC_FIELDS: ["name", "mobile_no"],
    APPLICANT_ROLES_DC: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-Ordinator" },
        { key: "woman-entrepreneur", label: "Woman Entrepreneur" },
        { key: "others", label: "Others" },
    ],
    APPLICANT_PN_DC: [
        { key: "645b7eac8ab3f1923c8b5084", label: "Digital Literacy" },
    ],
    APPLICANT_DC_LANGUAGE_LIST: [
        { key: "ta", label: "Tamil" },
        { key: "te", label: "Telugu" },
        { key: "kn", label: "Kannada" },
        { key: "hi", label: "Hindi" },
        { key: "en", label: "English" },
        { key: "ml", label: "Malayalam" },
    ],
    FIELD_DC_TYPES: [
        { key: "text", label: "Text" },
        { key: "textarea", label: "Free Text area" },
        // { key: "textarea", label: "Free Text Area" },
        { key: "select", label: "Dropdown" },
        { key: "checkbox", label: "Multi Select : Check Box" },
        { key: "radio", label: "Single Select : Radio Button" },
        { key: "file", label: "Upload" },
        { key: "date", label: "Date" },
        { key: "geolocation", label: "Geolocation" },
    ],
    FIELD_DC_CONSTRAINTS: [
        { key: "numeric", label: "Numeric" },
        { key: "character", label: "Character" },
        { key: "phonenumber", label: "Phone Number" },
        { key: "email", label: "Email" },
        { key: "future_dates_only", label: "Future Dates Only" },
        { key: "past_dates_only", label: "Past Dates Only" },
        { key: "mandatory", label: "Mandatory" },
        { key: "length", label: "Length" },
        { key: "upload", label: "Upload" },
        //{ key: "hassubfields", label: "Has Category Info" },
    ],
    CONSTRAINTS_DC_MAP: {
        numeric: "^(0|[1-9][0-9]+)$",
        // character: "^[A-Za-z ]+$",
        character: "^.*$",
        email: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        phonenumber: "^[0-9]{10}$",
        mandatory: "^(.+)$",
        future_dates_only: (date) => (date.getTime() >= new Date().toDate().date().getTime()),
        past_dates_only: (date) => (date.getTime() <= new Date().toDate().date().getTime())
    },
    DC_SUBCATEGORY: [
        { key: true, label: "Yes" },
        { key: false, label: "No" },
    ]
}
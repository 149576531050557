import React, { Component } from "react";

export class SchemeModal extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.SchemeModal = this;
  }
  render() {
    let { props } = this;
    let { id, title, onClose, message, contentClass, closeText } = props;

    return (
      <AppModalBs id={id} title={title} onClose={onClose} contentClass={contentClass}>
        <div className="d-flex justify-center">
            <img src={imagepaths.tickMarkIcon} alt="Image" width="60px" height="60px" />
        </div>
        <div className="d-flex justify-center modal-message mt-3">{message}</div>
        <div className="row no-gutters mt-2">
          <Row className="mt-4 d-flex justify-center scheme-modal">
            <button
              type="reset"
              data-bs-dismiss="modal"
              onClick={() => {onClose, closeText == "Close" ? location.reload() : ""}}
              className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1">
              {closeText ? closeText : `Close`}
            </button>
          </Row>
        </div>
      </AppModalBs>
    );
  }
}

export default SchemeModal;

import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import "./reportsummary.scss";
import * as XLSX from "xlsx";

export class ReportSummary extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ReportSummary");
    this.state = {
      data: [],
      columns: [],
      rowcount: 0,
      projectSearch: {
        //limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        //page: 1
      },
      financialSearch: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
        filter: {
          project_id: "",
        },
      },
      financialYear: new Date().getFullYear(),
    };
    this.formFields = [
      {
        type: "select",
        name: "project_id",
        label: "Project Name",
        options: [],
        required: true,
        onChange: (...args) => this.handleOnProjectChange(...args),
      },
      {
        type: "select",
        name: "project_fy_id",
        label: "Financial Year",
        options: [],
        required: true,
        onChange: (...args) => this.handleFYChange(...args),
      },
    ];
  }

  submitSearch(event, data) {
    console.log("Submit Search", event, data);
    this.generateColumns(
      this.state.financialYear - 1,
      this.state.financialYear
    );

    this.api.apiGetBudgetPlannerReport(data).then(({ data, total }) => {
      let tableData = data.map((row) => {
        return {
          task_id: row.task_id,
          task_name: row.task_name || "-",
          ...Object.keys(row.months).reduce(
            (c, n) => ({
              ...c,
              [n + "_budget"]: row.months[n]["total_budget"].cur(),
              [n + "_expense"]: row.months[n]["total_expense"].cur(),
            }),
            {}
          ),
          ...Object.keys(row.quarter).reduce(
            (c, n) => ({
              ...c,
              [n + "_budget"]: row.quarter[n]["total_budget"].cur(),
              [n + "_expense"]: row.quarter[n]["total_expense"].cur(),
            }),
            {}
          ),
          annual_budget: row.annualy.total_budget,
          annual_expense: row.annualy.total_expense,
          budget: row.budget_name,
        };
      });
      this.setState({
        data: tableData,
        rowcount: total,
      });
    });
  }

  handleOnProjectChange(values, value) {
    let { financialSearch } = this.state;
    let data = (
      this.formFields.find((o) => o.name == "project_id")?.options || []
    ).find((o) => o.key == value.key);
    console.log({ data });
    this.formFields.find((o) => o.name == "project_fy_id").options =
      data.financial_years.options(
        "project_fy_id",
        (v) => "FY" + v.project_fy_year
      );
    this.setState({
      financialSearch: { ...financialSearch },
    });
  }

  handleFYChange(values) {
    this.setState({
      financialYear: new Date(values[0].project_fy_year).getFullYear(),
    });
  }

  componentDidMount() {
    this.fetchData();
    // by default, generating financial years columns
    this.generateColumns(
      this.state.financialYear - 1,
      this.state.financialYear
    );
  }

  generateColumns(startYear, endYear) {
    const startDate = new Date(`${startYear}-04-01`);
    const endDate = new Date(`${endYear}-03-31`);

    let currentDate = new Date(startDate);
    let dynamicColumnsArr = [];
    let i = 1;
    let qIndex = 1;

    while (currentDate <= endDate) {
      const year = currentDate.getFullYear() % 100;
      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "short",
      }).format(currentDate);

      currentDate.setMonth(currentDate.getMonth() + 1);

      dynamicColumnsArr.push({
        title: `${monthName} ${year.toString().padStart(2, "0")}`,
        width: 150,
        children: [
          {
            title: "Plan",
            dataIndex: `m${i}_budget`,
          },
          {
            title: "Actual",
            dataIndex: `m${i}_expense`,
          },
        ],
      });
      if (i % 3 === 0) {
        dynamicColumnsArr.push({
          title: `Q${qIndex}`,
          children: [
            {
              title: "Plan",
              dataIndex: `q${qIndex}_budget`,
              width: 150,
              className: "q-col",
            },
            {
              title: "Actual",
              dataIndex: `q${qIndex}_expense`,
              width: 100,
              className: "q-col",
            },
          ],
          className: "q-col",
        });
        qIndex++;
      }
      i++;
    }

    let projectManagementObj = {
      title: (v) => {
        return (
          <>
            <div>Project Management</div>
            <div>(Tasks)</div>
          </>
        );
      },
      width: 150,
      dataIndex: "task_name",
      fixed: "left",
      sorter: (a, b) => a.task_name.localeCompare(b.task_name),
    };

    let budgetObj = {
      title: "Budgets",
      width: 150,
      dataIndex: "budget_name",
      fixed: "left",
      render: (text, record) => {
        return (
          <span
            className="text_truncate short cursor-pointer"
            title={record?.budget}
            onClick={() => alert(record?.budget)}
          >
            {record?.budget}
          </span>
        );
      },
    };
    let annualBudgetObj = {
      title: "Annual Budget",
      children: [
        {
          title: "Plan",
          dataIndex: "annual_budget",
          width: 100,
          fixed: "right",
        },
        {
          title: "Actual",
          dataIndex: "annual_expense",
          width: 100,
          fixed: "right",
        },
      ],
    };

    dynamicColumnsArr.unshift(budgetObj);
    dynamicColumnsArr.unshift(projectManagementObj);
    dynamicColumnsArr.push(annualBudgetObj);

    // console.log(dynamicColumnsArr);

    this.setState({
      columns: dynamicColumnsArr,
    });
  }

  apiProjectSearch() {
    let { projectSearch } = this.state;
    this.api
      .apiGetProjectsList(projectSearch)
      .then(({ data = [], total = 0 }) => {
        this.formFields.find((o) => o.name == "project_id").options =
          data.options("project_id", "project_name");
        this.setState({
          projectSearch: { ...projectSearch },
        });
      });
  }

  fetchData() {
    this.apiProjectSearch();
  }

  getDownloadData() {
    let { rowcount, search , data} = this.state;
    console.log(rowcount, search);
    // this.props.api
    //   .apiGetProjectsList({
    //     ...this.state.projectSearch,
    //     page: 1,
    //   })
    //   .then((results) => {
    //     let totalrows = results;
    //     console.log({ results, totalrows });
    //   });
      this.handleDownload(data);
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.except(row, [
        "task_id", 
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `report_summary_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  render() {
    let { formFields = [] } = this;
    let { data, columns, rowcount } = this.state;
    return (
      <LayoutWrapper title="Report Summary" back="Dashboard">
        <AppForm
          ref={"appform"}
          className="flex flex-col gap-4 bg-white p-4"
          onSubmit={(...args) => this.submitSearch(...args)}
        >
          <div className="row">
            {formFields?.map((field, i) => (
              <div
                className={Object.className({
                  "col-12 col-md-6 col-lg-3": !field.fieldclass,
                  [field.fieldclass]: field.fieldclass,
                  [field.name]: true,
                })}
                key={"field" + i}
              >
                <AppInput className="grow" {...field}></AppInput>
              </div>
            ))}
            <div className="col flex">
              <button className="btn add-btn mr-0 mt-auto mb-2">
                Show Reports
              </button>
              {data?.length > 0 && (
                <button
                  type="button"
                  className={Object.className({
                    "btn add-btn mr-auto mt-auto ms-2 mb-2": true,
                  })}
                  onClick={() => this.getDownloadData()}
                  >
                  <i class="fa fa-download me-2"></i>
                  Download
                </button>
              )}
            </div>
          </div>
        </AppForm>
        <div className="reportsummary">
          <AppTable
            data={data}
            columns={columns}
            reorder={true}
            viewable={false}
            editable={false}
            deletable={false}
            total={rowcount}
            pageSize={10}
            showPagination={false}
            attrs={{
              scroll: { x: 2 * 1800, y: 500 },
            }}
          ></AppTable>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(ReportSummary);

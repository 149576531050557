export default {
    schemesList: {
        url: "/govSchemes/scheme",
        method: "get"
    },
    schemeDetails: {
        url: "/govSchemes/scheme",
        method: "post"
    },
    updateSchemeDetails: {
        url: "/govSchemes/scheme/{schemeId}",
        method: "put"
    },
    departmentList: {
        url: "/govSchemes/department",
        method: "get"
    },
    parameterList: {
        url: "/govSchemes/parameter",
        method: "get"
    },
    schemeDetailsById: {
        url: "/govSchemes/scheme/{schemeId}",
        method: "get",
    },
    createCriteria: {
        url: "/govSchemes/criteria/{schemeId}",
        method: "post",
    },
    getCriterias: {
        url: "/govSchemes/criteria/{schemeId}",
        method: "get",
    },
    deleteScheme: {
        url: "/govSchemes/scheme/{schemeId}",
        method: "delete",
    },
    deleteCriteria: {
        url: "/govSchemes/criteria/{criteriaId}",
        method: "delete",
    },
    updateCriteria: {
        url: "/govSchemes/criteria/{criteriaId}",
        method: "put"
    },
}
import React, { Component } from "react";
import AddForumModal from "./AddForumModal";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import "./forum.scss";
export class Forum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      data: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      activeBtn: "latest",
    };
    this.columns = [
      {
        title: "Topics",
        dataIndex: "title",
        render: (text, record) => {
          return (
            <div>
              <p className="fw-bold title">{record.title}</p>
              <p className="m-0 text_truncate">{record.content}</p>
            </div>
          );
        },
      },
      {
        title: "Replies",
        dataIndex: "commentsCount",
      },
      // {
      //   title: "Views",
      //   dataIndex: "views",
      // },
      {
        title: "Active",
        dataIndex: "createdAt",
        render: (text, record) => {
          return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
        },
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "title",
        label: "Topic",
        customcolspan: "12",
        required: true,
      },
      {
        type: "textarea",
        name: "content",
        label: "Description",
        customcolspan: "12",
        controlClass: "!min-h-[150px]",
        required: true,
      },
    ];
    this.buttonGroups = [
      { key: "latest", label: "Latest" },
      // { key: "topfive", label: "Top (5)" },
      { key: "mytopic", label: "My Topic" },
    ];
    this.forumModalRef = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get currentLoginUser() {
    return this.props?.store.user;
  }

  fetchList(params) {
    let payload = {
      ...this.state.search,
      ...params,
    };
    this.props.api.apiGetForums(payload).then(({ data, total }) => {
      console.log({ apiGetForums: data });
      this.setState({
        data,
        rowcount: total,
      });
    });
  }

  componentDidMount() {
    this.fetchList();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  openVendorModal() {
    this.forumModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#add_forum_modal #bsmodalclose").click();
  }

  handleBtnChange(e) {
    console.log({ handleBtnChange: e.target.id });
    let targetBtn = e.target.id;
    this.setState({
      activeBtn: targetBtn,
    });
    if (targetBtn === "mytopic") {
      this.fetchList({ employee: true });
    } else {
      this.fetchList();
    }
  }

  handleRowClick(record) {
    console.log({ handleRowClick: record });
    let nextlink = `/womenentrepreneur/forum/forumdetails?id=${record.post_id}`;
    this.props.navigate(nextlink);
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Forum updated successfully"
            : "Forum Post added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    // if (this.state.editMode) {
    // 	this.props.api
    // 		.apiEditSchool(data, { school_id: this.state.editData.school_id })
    // 		.then(resolveList);
    // } else {
    // 	let payload = {
    // 		...data,
    // 	};
    // 	this.props.api.apiAddSchool(payload).then(resolveList);
    // }
    // resolveList();
    let payload = {
      ...data,
    };
    console.log({ payload });
    this.props.api.apiAddForumPost(payload).then(resolveList);
  }

  onPageChange(pageData) {
    console.log({ onPageChange: pageData });
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  updateFields(fields) {
    // fields.find((o) => o.name === "admin_name").options =
    // 	this.state.schoolAdmins;

    return fields;
  }

  render() {
    let {
      data,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      activeBtn,
    } = this.state;
    let { columns, isAdmin, modalFields, buttonGroups } = this;
    modalFields = this.updateFields(modalFields);

    return (
      <LayoutWrapper title="Forum" back="Dashboard / Forum">
        <div className="page-header employee-header absolute top-8 right-8">
          <div className="row">
            <div className="col"></div>
            <div className="mb-4">
              <Link
                className="btn add-btn"
                ref={this.props.forumModalRef}
                data-bs-toggle="modal"
                data-bs-target="#add_forum_modal"
                onClick={(...args) => this.addModalFields(...args)}
              >
                <i className="fa fa-plus" />
                Create New
              </Link>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-start mb-4">
          <div className="me-5">
            <AppInput
              type="select"
              name="category"
              placeholder="All Categories"
              options={_enum.FORUM_CATEGORIES || []}
              onChange={(...args) => this.handleForumChange(...args)}
              className="forum_top_select small"
            ></AppInput>
          </div>
          <div className="forum_tabs_group me-5">
            {buttonGroups?.map((btn, i) => {
              return (
                <button
                  key={i}
                  id={btn.key}
                  type="button"
                  className={Object.className({
                    active: activeBtn === btn.key,
                  })}
                  onClick={(...args) => this.handleBtnChange(...args)}
                >
                  {btn.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="row mb-4">
          <div className="forum_table_outer col">
            <AppTable
              data={data}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              editable={false}
              deletable={false}
              viewable={false}
              pageSize={10}
              onRowClick={(record, rowIndex) => {
                return {
                  onClick: (event) => this.handleRowClick(record),
                };
              }}
              // showPagination={false}
            ></AppTable>
          </div>
        </div>

        <AddForumModal
          id="add_forum_modal"
          title={`${editMode ? "Update Forum" : "Create Forum"}`}
          submitText={`${editMode ? "Update" : "Create"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          // disablefields={["email"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
      </LayoutWrapper>
    );
  }
}

export default connect(Forum);

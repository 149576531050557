import React, { Component } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import DocsUploadModal from "./DocsUploadModal";

export class DocListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedData: props.data,
      popupdata: {},
      popuptitle: "Add Document",
      popuptype: "OnlineAssessment",
      popupfields: {},
    };
    this.refs = {
      docsModalRef: React.createRef(),
    };
    this.columns = [
      {
        title: "SL No.",
        dataIndex: "serial_no",
      },
      {
        title: "Doc Type",
        dataIndex: "doc_type",
      },
      {
        title: "Doc Name",
        dataIndex: "doc_name",
      },
      {
        title: "Uploaded On",
        dataIndex: "uploaded_on",
      },
      // {
      //   title: "File",
      //   dataIndex: "file_source",
      //   render: (text, record) => (
      //     <div className="flex flex-wrap gap-4">
      //       <span>{record?.file_key.file()}</span>
      //     </div>
      //   ),
      // },
      {
        title: "Action",
        dataIndex: "_id",
        render: (text, record) => (
          <div className="divide-x flex gap-2 flex-wrap">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() =>
                confirm("Are you sure you want to delete").promise.then(() => {
                  this.deleteFile(record);
                })
              }
            >
              <em className="la la-trash-o"></em>
            </button>
            <a
              href={this.props.api.apiDownloadFile(record)}
              target="_blank"
              className="btn btn-success"
            >
              <em className="la la-download"></em>
            </a>
          </div>
        ),
      },
    ];
    this.docsModalFields = [
      {
        type: "text",
        name: "doc_type",
        label: "Doc Type (only pdf, doc & jpg.)",
      },
      {
        type: "text",
        name: "doc_name",
        label: "Doc Name",
      },
      {
        type: "file",
        name: "doc_file",
        label: "Upload Policy",
        required: true,
      },
    ];
  }

  downloadFile(record) {
    this.props.api.apiDownloadFile(record);
    this.props.onDelete && this.props.onDelete(record);
  }

  deleteFile(record) {
    this.props.api
      .apiDeleteFile(record, { docs_id: record._id })
      .then(() => this.props.getDocList())
      .then(() => this.props.fetchDocsDetails());
  }
  uploadEmployeeDocs(e) {
    e.preventDefault();
    e.stopPropagation();

    console.log("uploadEmployeeDocs", uploadEmployeeDocs);
  }
  render() {
    let { uploadedData, popuptype, popupfields, popuptitle, popupdata } =
      this.state;
    let { columns, props, docsModalFields } = this;
    let { data } = props;
    return (
      <>
        <div className="col-12 mb-4">
          <a
            href="#"
            className="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target="#docs_upload_modal"
            // onClick={() => this.refs.docsModalRef.current.click()}
          >
            <i className="fa fa-plus" />
            Add New Doc
          </a>
        </div>

        <div className="col-12">
          <Table
            className="table-striped"
            pagination={{
              total: data.length,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record._id}
          />
        </div>
        {console.log("popupfields", docsModalFields)}
        <DocsUploadModal
          list={docsModalFields}
          id="docs_upload_modal"
          title={popuptitle}
          onSubmit={(...arg) => this.uploadEmployeeDocs(...arg)}
          onClose={() => this.setState({ docsModalFields: [] })}
        />
      </>
    );
  }
}

export default connect(DocListView);

export default [
    {
        path: "/datacollection/dashboard",
        icon: "la la-dashboard",
        label: "Dashboard"
    },
    {
        path: "/datacollection",
        icon: "la la-file-text",
        label: "Data Collection",
    },
];
export default {
    tablinks: [
        {
            path: "/projectmanagement/addproject",
            label: "Project"
        },
        {
            path: "/projectmanagement/addfinancialyear",
            label: "Financial Year"
        },
        {
            path: "/projectmanagement/addtask",
            label: "Tasks"
        },
        {
            path: "/projectmanagement/addbudget",
            label: "Budget"
        },
        {
            path: "/projectmanagement/addresources",
            label: "Resources"
        },
    ]
}
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

export class AppFormData extends Component {
  render() {
    let { props } = this;
    let {
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      editdata,
      customcolspan = "6",
    } = props;
    let list = Object.entries(data).chunk(size);
    let getField = (label) => fields[label] || {};
    let getColClass = (c) => fields[c]?.customcolspan || "6";
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    // console.log({ fields, data });
    return (
      <AppForm {...formAttrs}>
        {list.map((row, i) => (
          <Row className="mx-0" key={i}>
            {row.map(([label, value, options], j) => (
              <Col lg={getColClass(label)} key={j}>
                <AppInput
                  name={label}
                  label={label.toTitleCase()}
                  defaultValue={value}
                  disabled={disableallfields}
                  {...(disablefields.includes(label) &&
                  Object.keys(editdata).length > 0
                    ? {
                        readOnly: true,
                      }
                    : { readOnly: false })}
                  {...getField(label)}
                ></AppInput>
              </Col>
            ))}
          </Row>
        ))}
        <Row className="mt-4">
          <Col
            className={Object.className({
              "text-right": true,
              hidden: disableallfields,
            })}
          >
            <button type="submit" className="btn add-btn">
              {submitText}
            </button>
          </Col>
          <Col>
            <button
              type="reset"
              className={Object.className({
                "btn cancel-btn": true,
                "m-auto": disableallfields,
              })}
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </AppForm>
    );
  }
}

export default AppFormData;

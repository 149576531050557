export default {
    courseList: {
        url: "/lms/course",
        method: "get"
    },
    createCourse: {
        url: "/lms/course",
        method: "post"
    },
    getCourseByRole: {
        url: "/lms/course/role/{job_role}",
        method: "get"
    },
    getCourseById: {
        url: "/lms/course/{course_id}",
        method: "get"
    },
    updateCourse: {
        url: "/lms/course/{course_id}",
        method: "put"
    },
    deleteCourse: {
        url: "/lms/course/{course_id}",
        method: "delete"
    },
    fileuploader: {
        url: "/lms/upload",
        method: "post"
    },
    fileDownloader: {
        url: "/lms/upload/{attachmentId}/",
        method: "get"
    },
    // COURSE MODULE
    getLmsDashboard: {
        url: "/lms/course/dashboard",
        method: "get"
    },
    createModule: {
        url: "/lms/module",
        method: "post"
    },
    getAllModules: {
        url: "/lms/module/course/{course_id}",
        method: "get"
    },
    getModuleById: {
        url: "/lms/module/{module_id}",
        method: "get"
    },
    updateModule: {
        url: "/lms/module/{module_id}",
        method: "put"
    },
    deleteModule: {
        url: "/lms/module/{module_id}",
        method: "delete"
    },
    getAllModuleStatus: {
        url: "/lms/module/all/status/{course_id}/{module_id}",
        method: "get"
    },
    getModuleStatusById: {
        url: "/lms/module/status/{module_id}",
        method: "get"
    },
    // MEDIA
    createMedia: {
        url: "/lms/media",
        method: "post"
    },
    getAllMedia: {
        url: "/lms/media/module/{module_id}",
        method: "get"
    },
    getMedia: {
        url: "/lms/media/{media_id}",
        method: "get"
    },
    updateMedia: {
        url: "/lms/media/{media_id}",
        method: "put"
    },
    deleteMedia: {
        url: "/lms/media/{media_id}",
        method: "delete"
    },
    // PPT
    createPPT: {
        url: "/lms/ppt",
        method: "post"
    },
    getAllModulePPT: {
        url: "/lms/ppt/module/{module_id}",
        method: "get"
    },
    getPPT: {
        url: "/lms/ppt/{ppt_id}",
        method: "get"
    },
    updatePPT: {
        url: "/lms/ppt/{ppt_id}",
        method: "put"
    },
    deletePPT: {
        url: "/lms/ppt/{ppt_id}",
        method: "delete"
    },
    // ASSESSMENTS
    createAssessment: {
        url: "/lms/assessment",
        method: "post"
    },
    getAssessments: {
        url: "/lms/assessment",
        method: "get"
    },
    getAssessmentById: {
        url: "/lms/assessment/{assessment_id}",
        method: "get"
    },
    updateAssessment: {
        url: "/lms/assessment/{assessment_id}",
        method: "put"
    },
    deleteAssessment: {
        url: "/lms/assessment/{assessment_id}",
        method: "delete"
    },
    deleteAssessmentQuestion: {
        url: "/lms/assessment/question/{assessment_id}/{question_id}",
        method: "delete"
    },
    getAssessmentByModuleId: {
        url: "/lms/assessment/module/{module_id}",
        method: "get"
    },
    updateActivity: {
        url: "/lms/activity",
        method: "put"
    },
    // EVALUATION
    createEvaluation: {
        url: "/lms/evaluation",
        method: "post"
    },
    getEvaluations: {
        url: "/lms/evaluation",
        method: "get"
    },
    getEvaluationById: {
        url: "/lms/evaluation/assessment/{assessment_id}",
        method: "get"
    },
    getEvaluationAnswers: {
        url: "/lms/evaluation/answers/{evaluation_id}",
        method: "get"
    },
}
import React, { Component, useEffect } from "react";
import { useState } from "react";
import { GapFields } from "./BasicDetails";

export const AccountDetailsFields = [
  {
    type: "text",
    name: "holderName",
    label: "Account Name",
    required: true,
  },
  {
    type: "password",
    name: "accountNo",
    label: "Account Number",
    onInput: (e) => {
      e.target.parentElement.nextElementSibling.children.accountNo.pattern =
        e.target.value;
    },
    autoComplete: "new-password",
    required: true,
  },
  {
    type: "text",
    name: "accountNo",
    label: "Re-Enter Account Number",
    onInvalid: (e) => e.target.setError("ex. Please match the Account Number."),
    onInput: (e) => e.target.setMessage(),
    required: true,
  },
  {
    type: "text",
    name: "ifsc_code",
    label: "IFSC Code",
    pattern: "[A-Z]{4}0[A-Z0-9]{6}",
    onInvalid: (e) => e.target.setError("ex. Please set valid IFSC formate."),
    onInput: (e) => e.target.setMessage(),
    required: true,
  },
  {
    type: "select",
    name: "bankName",
    label: "Bank Name",
    required: true,
    options: [].options(),
    className: "select-focus",
  },
  {
    type: "text",
    name: "branchName",
    label: "Branch",
    required: true,
  },
];
const AccountDetails = (props) => {
  // let [fields] = useState(AccountDetailsFields);

  useEffect(() => {
    // console.log("AccountDetailsFields: ", AccountDetailsFields);
    // console.log("banklist from store", props.store.bankList);

    // populating bank name options from store
    var bankListArr = [];
    for (var prop in props.store.bankList) {
      if (props.store.bankList.hasOwnProperty(prop)) {
        var options = {};
        options.key = props.store.bankList[prop].bank_name;
        options.label = props.store.bankList[prop].bank_name;
        bankListArr.push(options);
      }
    }
    console.log("banklist after push: ", bankList);
    if (props.store.bankList) {
      AccountDetailsFields.find((v) => v.name === "bankName").options =
        bankListArr;
    }
    // console.log("AccountDetailsFields after change: ", AccountDetailsFields);
  }, []);

  return (
    <form
      onSubmit={(...e) => (props?.onSubmit || (() => ""))(...e)}
      className={"form"}
      autoComplete="new-password"
    >
      <div className="row">
        {AccountDetailsFields.map((field, i) => (
          <GapFields {...{ field, i }} key={i} />
        ))}
      </div>
      <div className="row">
        <button className="btn add-btn col col-md-1 ml-auto">
          Save & Next
        </button>
        <div className="col-12 col-md-3"></div>
      </div>
    </form>
  );
};

export default connect(AccountDetails);

import React, { Component } from "react";
import { Link } from "react-router-dom";

export const GapFields = ({
  field,
  i,
  className = "col-12 col-md-3 px-2 ",
  readOnly,
}) => (
  <>
    <AppInput
      {...field}
      className={className + (field?.className || "")}
      key={i}
      disabled={readOnly || field.disabled}
    ></AppInput>
    {i != 0 && i % 3 == 2 && (
      <div className="col-12 col-md-3 px-2" key={"div" + i}></div>
    )}
  </>
);

class BasicDetails extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("BasicDetails");
    this.state = {
      editData: {},
      districts: [],
      clusters: [],
      villageData: [],
      villageNamesData: [],
      villageNames: [],
      supervisorOptions: [],
      supervisorID: "",
      supervisorsList: [],
      digitalSakhiList: [],
      selectedSupervisorName: [],
      search: {
        // limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        district: "",
        cluster: "",
      },
      addrole: {
        role_type: "digital-sakhi",
      },
      fieldValid: {
        isPanValid: false,
        isGstValid: true,
        isAadhaarValid: false,
        isEmpIdValid: false,
      },
    };
    this.fields = [
      {
        type: "text",
        name: "employee_name",
        label: "Name",
        required: true,
      },
      {
        type: "text",
        name: "business_name",
        label: "Business Name",
        required: true,
      },
      {
        type: "text",
        name: "employee_id",
        label: "Women Entreprenuer Code",
        onChange: (...args) => this.handleValidate(...args),
        disabled: this.props.urlparams.emp_id && true,
        required: true,
      },
      {
        type: "text",
        name: "mobile_num",
        label: "Mobile Number",
        pattern: "[0-9]{10}",
        onInvalid: (e) =>
          e.target.setError(
            "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
          ),
        onInput: (e) => e.target.setMessage(),
        required: true,
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "email",
        name: "email_address",
        label: "Email ID",
        required: true,
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "temp_password",
        label: "Password",
        required: true,
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "select",
        name: "district",
        label: "District Name",
        options: [],
        required: true,
        onChange: (...args) => this.handleDistrictChange(...args),
      },
      {
        type: "select",
        name: "cluster_code",
        label: "Cluster Code",
        options: [],
        required: true,
        onChange: (...args) => this.handleClusterCodeChange(...args),
      },
      {
        type: "select",
        name: "village_id",
        label: "Village ID",
        options: [],
        multi: true,
        defaultValue: [],
        required: true,
        onChange: (...args) => this.handleVillageIdChange(...args),
      },
      {
        type: "text",
        name: "village_name",
        label: "Village Name",
        required: true,
      },
      {
        type: "select",
        name: "digital_sakhi_id",
        label: "Digital Sakhi ID",
        options: [],
        required: true,
      },
      {
        type: "text",
        name: "gst",
        label: "GST",
        onChange: (...args) => this.handleValidate(...args),
      },
      {
        type: "text",
        name: "pan_card_num",
        label: "PAN",
        onChange: (...args) => this.handleValidate(...args),
        required: true,
      },
      {
        type: "text",
        name: "aadhar_card_num",
        label: "Aadhar Number",
        onChange: (...args) => this.handleValidate(...args),
        required: true,
      },
      {
        type: "text",
        name: "entrepreneur_business_type",
        label: "Business Type",
        required: true,
      },
      {
        type: "number",
        name: "financial_yr_turnover",
        label: "Existing Financial Year Turnover",
        required: true,
      },
      {
        type: "date",
        name: "edp_training_date",
        label: "EDP Training Date",
        required: true,
      },
      {
        type: "date",
        name: "business_established_date",
        label: "Date of Establishment of Business",
        required: true,
      },
      {
        type: "select",
        name: "gap_funding",
        label: "Applied for Gap Funding",
        options: _enum.YES_NO_OPTIONS,
        required: true,
      },
      {
        type: "textarea",
        name: "address",
        label: "Address",
        className: "col-md-9",
        controlClass: "!min-h-[150px]",
        required: true,
      },
    ];
  }
  componentDidMount() {
    this.fetchDistrict();
    this.getDigitalsakhis();
    this.getEditData();

    if (this.props.urlparams.emp_id) {
      this.setState({
        fieldValid: {
          isGstValid: true,
          isPanValid: true,
          isAadhaarValid: true,
        },
      });
    }
  }

  getDigitalsakhis() {
    let payload = {
      employee_roles: ["digital-sakhi"],
    };
    this.api.apiEmployeeByMultiRoles(payload).then((res) => {
      if (res?.data?.length) {
        console.log("list: ", res?.data);
        let filteredArr = res?.data?.map((value) => {
          return {
            key: value.employee_id,
            label: value.employee_name + ` ( ${value.employee_id} ) `,
          };
        });
        console.log("filteredArr", filteredArr);
        this.setState({
          digitalSakhiList: filteredArr,
        });
      }
    });
  }

  getEditData() {
    if (this.props.urlparams.emp_id) {
      // console.log("edit flow");

      let payload = {
        employee_id: this.props.urlparams.emp_id,
      };

      this.api.apiGetEmployeeById(payload).then(({ data }) => {
        // console.log({ editData: data });
        this.setState({ editData: data });
        this.getSupervisors(data["employee_role"]);
      });
    }
  }

  fetchDistrict() {
    let payload = {
      ...this.state.search,
    };
    this.api.getDistrict(payload).then((res) => {
      // console.log({ getDistrict: data });
      this.setState({ districts: res.data });
    });
  }

  handleDistrictChange([value]) {
    // console.log({ handleDistrictChange: value });
    this.setState({
      search: {
        ...this.state.search,
        district: value.key,
      },
    });
    this.fetchCluster(value.key);
  }

  fetchCluster(district) {
    let payload = {
      ...Object.except(this.state.search, ["search"]),
    };
    this.api.getCluster(payload, { district: district }).then(({ data }) => {
      this.setState({ clusters: data });
    });
  }

  handleClusterCodeChange([value]) {
    let { search } = this.state;
    if (!value) {
      return;
    }
    this.setState({
      search: {
        ...this.state.search,
        cluster: value.key,
      },
    });

    let payload = {
      ...Object.except(this.state.search, ["district", "search", "cluster"]),
    };

    this.api
      .getVillageList(payload, {
        district: this.state.search.district,
        cluster: value.key,
      })
      .then((res) => {
        let options = res?.data?.map((item) => {
          return {
            key: item.village_id,
            label: item.village_name,
          };
        });
        let optionsWithKey = res?.data?.map((item) => {
          return {
            key: item.village_id?.trim(),
            label: item.village_id?.trim(),
          };
        });

        this.setState({ villageData: optionsWithKey });
        this.setState({ villageNamesData: options });
      });
  }

  handleVillageIdChange(values) {
    // console.log({ handleVillageIdChange: values });

    let villageNamesArr = [];

    // console.log("villageNamesData", this.state.villageNamesData);

    const filteredVillageNames = this.state.villageNamesData.filter((el) => {
      return values.some((f) => {
        return f.key === el.key;
      });
    });

    filteredVillageNames.map((item) => {
      villageNamesArr.push(item.label);
    });
    // console.log("villageNamesArr", villageNamesArr);
    this.setState({
      editData: {
        ...this.state.editData,
        village_name: villageNamesArr,
      },
      villageNames: villageNamesArr,
    });
  }

  handleEmployeeRoleChange([val]) {
    // console.log({ val });
    if (val) {
      this.setState((state) => ({
        ...state,
        clusters: [],
        villageData: [],
        villageNamesData: [],
        villageNames: [],
        supervisorOptions: [],
        addrole: {
          role_type: val.key,
        },
      }));
      let supervisorName = document.querySelector(
        "[name='appinput-supervisor_name'].form-group"
      );
      console.log(supervisorName);
      let { values: supervisorNames = [] } =
        supervisorName.vnode.refs.select.state;
      if (supervisorNames.length) {
        supervisorName.vnode.refs.select.clearAll();
      }
      this.getSupervisors(val.key);
      // console.log({
      //   supervisorNames,
      //   val,
      // });
    }
  }

  getSupervisors(role) {
    // console.log("getSupervisors role: ", role);

    let roleType = _enum.EMP_ROLE_MAP[role];

    if (roleType) {
      this.api
        .apiGetEmployeeByRole(roleType)
        .then((res) => {
          //console.log("list of supervisors: ", res);

          if (res.length) {
            console.log("list of supervisors: ", res);
            let filteredArr = res.map((value) => {
              return {
                key: value.employee_id,
                label: value.employee_name + ` ( ${value.employee_id} ) `,
              };
            });
            switch (role) {
              case "cluster-co-ordinator":
                this.setState({
                  supervisorOptions: filteredArr,
                });
                break;
              case "manager":
                this.setState({
                  supervisorOptions: filteredArr,
                });
                break;
              default:
                this.setState({
                  supervisorOptions: filteredArr,
                });
                break;
            }

            console.log("filteredArr", filteredArr, role, roleType);
            if (role === "manager") {
              let selectedSuperOpt = filteredArr.filter(
                (v) => v.key === this.state.editData.supervisor_id
              );
              this.setState({
                selectedSupervisorName: selectedSuperOpt,
              });
            } else {
              let selectedSuperOpt = filteredArr.filter(
                (v) => v.key === this.state.editData.supervisor_id
              );
              console.log("selectedOpt: ", selectedSuperOpt);
              this.setState({
                selectedSupervisorName: selectedSuperOpt,
              });
            }
          }
        })
        .catch((err) => {
          // console.log({ err });
          this.props.onError && this.props.onError(err);
          //alert(err.message);
        });
    }
  }

  handleSupervisorChange(val) {
    let { editData } = this.state;
    console.log("handleSupervisorChange", val);
    if (val.length) {
      this.setState({
        editData: {
          ...editData,
          supervisor_id: val[0].key,
        },
        supervisorID: val[0].key,
      });
    } else {
      this.setState({
        supervisorID: "",
      });
    }
  }

  validateID(name, value) {
    // Remove spaces from the value for consistent validation
    value = value.replace(/\s/g, "");

    // Validate based on the input name
    switch (name.toLowerCase()) {
      case "gst":
        let isGstValid;
        if (value.length > 0) {
          isGstValid = _enum.ID_REGEXP["gst"].test(value);
        } else {
          isGstValid = true;
        }
        this.setState({
          fieldValid: {
            ...this.state.fieldValid,
            isGstValid,
          },
        });
        return isGstValid;
      case "pan_card_num":
        let isPanValid = _enum.ID_REGEXP["pan"].test(value);
        this.setState({
          fieldValid: {
            ...this.state.fieldValid,
            isPanValid,
          },
        });
        return isPanValid;
      case "employee_id":
        let isEmpIdValid =
          _enum.ID_REGEXP["no_slash_and_backslash"].test(value);
        this.setState({
          fieldValid: {
            ...this.state.fieldValid,
            isEmpIdValid,
          },
        });
        return isEmpIdValid;
      case "aadhar_card_num":
        let isAadhaarValid = _enum.ID_REGEXP["aadhaar"].test(value);
        this.setState({
          fieldValid: {
            ...this.state.fieldValid,
            isAadhaarValid,
          },
        });
        return isAadhaarValid;
      default:
        // Invalid name, return false
        return false;
    }
  }

  handleValidate(e) {
    let value = e.target.value;
    let name = e.target.name;

    mixins.debounce(() => {
      let bool = this.validateID(name, value);
      let input = document.getElementById(`errMsgFor-${name}`);
      console.log({ name, value, bool });

      if (name !== "gst" && value.length === 0 && !bool) {
        input.classList.remove("d-none");
        input.innerHTML = `${name?.toSpacedString()} cannot be empty`;
      } else if (name === "employee_id" && !bool) {
        input.classList.remove("d-none");
        input.innerHTML = `/ and \\ are not allowed`;
      } else if (value.length > 0 && !bool) {
        input.classList.remove("d-none");
        input.innerHTML = `Please enter a valid ${name
          ?.toSpacedString()
          .toUpperCase()}`;
      } else {
        input.classList.add("d-none");
      }
    }, 500);
  }

  updateFields(fields) {
    fields.find((o) => o.name === "district").options =
      // this.state.districts.options("_id", "district");
      this.state.districts.map((v) => v.district).options();

    fields.find((o) => o.name === "cluster_code").options = this.state.clusters
      .map((v) => v.cluster)
      .options();

    fields.find((o) => o.name === "village_id").options =
      this.state.villageData;

    fields.find((o) => o.name === "village_name").defaultValue =
      this.state.villageNames;

    fields.find((o) => o.name === "digital_sakhi_id").options =
      this.state.digitalSakhiList;

    return fields;
  }

  render() {
    let { fields } = this;
    let { editData } = this.state;
    fields = this.updateFields(fields);

    // console.log("fields: ", fields);
    // console.log("editData: ", editData);

    console.log(this.state.fieldValid);
    let isFieldValid;
    if (
      !this.props.urlparams.emp_id &&
      this.state.fieldValid.isGstValid === true &&
      this.state.fieldValid.isPanValid === true &&
      this.state.fieldValid.isAadhaarValid === true &&
      this.state.fieldValid.isEmpIdValid === true
    ) {
      isFieldValid = false;
    } else if (
      this.props.urlparams.emp_id &&
      this.state.fieldValid.isGstValid === true &&
      this.state.fieldValid.isPanValid === true &&
      this.state.fieldValid.isAadhaarValid === true &&
      this.state.fieldValid.isEmpIdValid === true
    ) {
      isFieldValid = false;
    } else {
      isFieldValid = true;
    }

    if (editData && editData?.supervisor_id) {
      editData["supervisor_name"] = editData["supervisor_id"];
    }

    fields = fields?.map((field) => {
      field.defaultValue = field.defaultValue;

      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      return field;
    });

    return (
      <form
        onSubmit={(...e) => (this.props?.onSubmit || (() => ""))(...e)}
        className={"form"}
        autoComplete="off"
      >
        <div className="row">
          {fields
            ?.filter((field) => (field?.rule || (() => true)).bind(this)())
            .map((field, i) => (
              <GapFields
                {...{ field, i, readOnly: true }}
                key={i}
                readOnly={this.props.urlparams.readonly ? true : false}
              />
            ))}
        </div>

        <div className="flex mt-4">
          <div className="grow"></div>
          <button
            type="submit"
            className={Object.className({
              "btn btn-primary add-btn": true,
              "d-none": this.props.urlparams.readonly,
              disabled: isFieldValid,
            })}
          >
            {this.props.urlparams.emp_id ? "Update" : "Submit"}
          </button>
          <Link
            to={"/womenentrepreneur"}
            className="btn btn_primary grey ms-2 me-2"
          >
            Cancel
          </Link>
        </div>
      </form>
    );
  }
}

export default connect(BasicDetails);

import React, { Component } from "react";
import DocListView from "./DocListView";
export class EditDocList extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      form: React.createRef(),
    };
    this.state = {
      uploadList: [],
      DocTypes: [
        "address_proff",
        "identity_proof",
        "certificates",
        "resume",
        "others",
      ].options(),
    };
    this.getDocList = this.getDocList.bind(this);
  }

  componentDidMount() {
    window.EditDocList = this;
    this.getDocList();
  }

  getDocList() {
    this.props.api.apiGetProfileDocsDetails().then((data) => {
      this.setState((state) => ({
        ...state,
        uploadList: data,
      }));
    });
  }

  uploadFile(e) {
    e.preventDefault();
    let data = this.refs.form.getData();
    this.props.api
      .apiUploadDocument(data)
      .then(() => this.getDocList())
      .then(() => this.props.fetchDocsDetails());
    this.refs.form.reset();
  }

  onDeleteFile(record) {
    this.props.api.apiDeleteFile(record).then(() => this.getDocList());
  }

  onModalLoad({ closebtn }) {
    this.closebtn = closebtn;
  }

  closeModal() {
    this.closebtn.current.click();
    this.getDocList();
  }

  render() {
    let { props, form } = this;
    let { uploadList, DocTypes } = this.state;
    let { id, title } = props;
    return (
      <AppModalBs
        id={id}
        title={title}
        contentClass={"w-3/6 m-auto"}
        onLoad={(...args) => this.onModalLoad(...args)}
        data-bs-backdrop="static"
        // onClose={() => console.log("closed")}
      >
        <form
          ref="form"
          className="form mb-6"
          onSubmit={(e) => this.uploadFile(e)}
        >
          <div className="row -mx-9">
            <div className="col-12 col-md-3">
              <AppInput
                className={"px-2 "}
                label="Doc Type"
                type="select"
                options={DocTypes}
                name="doc_type"
                required
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                className={"px-2 "}
                label="Doc Name"
                type="text"
                name="doc_name"
                required
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                className={"px-2 "}
                label="Upload File"
                type="file"
                name="file"
                required
              ></AppInput>
            </div>
            <div className="col-12 col-md-3 flex">
              <button className="btn btn-success btn-block w-100 m-auto mb-4">
                Upload
              </button>
            </div>
          </div>
        </form>
        <form onSubmit={(...e) => (props?.onSubmit || (() => ""))(...e)}>
          <div className="row mb-4">
            <DocListView
              data={uploadList}
              getDocList={this.getDocList}
              fetchDocsDetails={this.props.fetchDocsDetails}
              onDelete={(...arg) => this.onDeleteFile(...arg)}
            />
          </div>
          <div className="row no-gutters">
            <div className="col col-md-2  ml-auto">
              <button
                className="btn add-btn"
                type="button"
                onClick={() => this.closeModal()}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </AppModalBs>
    );
  }
}

export default connect(EditDocList);

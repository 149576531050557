export default {
    menulinks:[
        {
            path:"/admin/leavemanagment",
            label:"Leave Approvals"
        },
        {
            path:"/admin/leavemanagment/approvedleaves",
            label:"Approved Leaves"
        },
        {
            path:"/admin/leavemanagment/holidays",
            label:"Holidays"
        },
        {
            path:"/admin/leavemanagment/leaveconfig",
            label:"Leave Config"
        },
        {
            path:"/admin/leavemanagment/leavepolicies",
            label:"Leave Policies"
        },
    ]
}
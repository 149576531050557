import React, { Component } from 'react';
import LayoutWrapper from '../LayoutWrapper';
import "./registredbanks.scss";

export class Designations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowcount:0,
            banksData:[],
            search:{
                page:1
            }
        }
        
        let renderField = (name,edit=false)=>((text,record)=><div>
            <span>{record[name]}</span>
        </div>);

        this.columns = [
            ...["_id","created_at"].map(dataIndex=>({dataIndex,display:false})),
            {
                dataIndex: "bank_name",
                title: "Bank Name",
                render:renderField('bank_name')
            }
        ]

        this.addfields = [
            {
                name: "bank_name",
                label: "Register Bank"
            }
        ];
    }

    onPrev() {
        this.setState(state => ({ search: { ...state.search, page: state.search.page - 1 } }), () => {
            this.fetchData();
        });
    }

    onNext() {
        this.setState(state => ({ search: { ...state.search, page: state.search.page + 1 } }), () => {
            this.fetchData();
        });
    }

    addBank() {
        let payload = this.refs.addform.form.current.getData();
        let data = {
            bank_name:payload.bank_name
        };
        return this.props.api.apiCreateBank(data)
        .then(()=>this.fetchData());
    }

    componentDidMount() {
        window.BankList = this;
        this.fetchData();
    }

    fetchData() {
        let loaderObj = loader("Setting Banks please wait...");
        setTimeout(()=>{
            let responce = this.props.store.bankList;
            this.setState({
                banksData:responce,
                rowcount: responce.length,
            });
            loaderObj.close();
        },0);
    }

    deleteBank(payload) {
        confirm(`Confirm the delete of ${payload.designation} from list...?`).promise.then(()=>{
            this.props.api.apiDeleteBank({ },{
                designa_id:payload._id
            }).then((responce)=>{
                this.fetchData();
            });
        });
    }

    editBank(payload) {
        prompt(`Edit`,{},<div id="prompt" className='row text-left'>
            {
                this.addfields.map((field, i) => (
                    <div className="col-sm-12" key={i} >
                        <AppInput
                            {...field}
                            defaultValue={payload[field.name]}
                        >
                        </AppInput>
                    </div>
                ))
            }
        </div>).promise.then(()=>{
            let data = document.querySelector("#appmodal").getData();
            this.props.api.apiEditBank(data,{
                designa_id:payload._id
            }).then((responce)=>{
                this.fetchData();
            });
        });
    }

    render() {
        let { banksData } = this.state;
        let { columns,props } = this;
        return (
            <LayoutWrapper title="Registred Banks" back="Dashboard" >
                <AppForm className="row mb-2" ref="addform" onSubmit={() => this.addBank()}>
                    {
                        this.addfields.map((field, i) => (
                            <div className="col-sm-6 col-md-3" key={i} >
                                <AppInput
                                    {...field}
                                >
                                </AppInput>
                            </div>
                        ))
                    }
                    {/* <div className='col-sm-6 col-md-6'></div> */}
                    <div className="col-sm-6 col-md-3">
                        <div className='form-group'>
                            <label className="col-form-label block">&nbsp;</label>
                            <div className='flex'><button className='btn add-btn h-10 mr-auto'> Add </button></div>
                        </div>
                    </div>
                </AppForm>
                <hr/>
                <div className='row'>
                    <div className='col-12'>
                        <AppTable
                            data={banksData}
                            columns={columns}
                            onNext={() => this.onNext()}
                            onPrev={() => this.onPrev()}
                            total={this.state.rowcount}
                            reorder={true}
                            deleteAction={(v)=>this.deleteBank(v)}
                            editAction={(v)=>this.editBank(v)}
                            targetType="tap"
                        >
                        </AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default connect(Designations);
export default {
    apiGetMOMDataMoke() {
        let data = [
            {
                id: "01",
                date: "10/10/2023",
                meeting_agenda: "To discuss about new rules",
                points_discussed: "05",
                actionable: "05",
            },
            {
                id: "02",
                date: "09/10/2023",
                meeting_agenda: "To discuss about new rules",
                points_discussed: "04",
                actionable: "04",
            },
            {
                id: "03",
                date: "08/10/2023",
                meeting_agenda: "To discuss about new rules",
                points_discussed: "03",
                actionable: "03",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiCreateMeetingMinutes(data) {
        let payload = {
            date: data?.date,
            time: data?.time,
            meeting_agenda: data?.meeting_agenda,
            key_points: data?.key_points.map(v => v),
            actionable: Object.values(data?.actionable || []).filter(v => v).map((field) => ({
                task_name: field?.task_name,
                task_type: field?.task_type,
                assigned_roles: field?.assigned_roles,
                fpr: field?.fpr,
                expected_completion_date: new Date(field?.expected_completion_date,).toISOString(),
                status: field?.status,
                priority: field?.priority,
            })),
        };
        // console.log({ apiCreateMeetingMinutes: payload });
        return this.request(this.api().addMeetingMinutes, payload)
            .exec()
            .log()
            .get();
    },
    apiGetMeetingMinutes(data) {
        return this.request(this.api().getMeetingMinutes, data)
            .exec()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .log()
            .get();
    },
    apiGetMeetingMinutesById(urldata = {}) {
        return this.request(this.api().getMeetingMinutesById)
            .urltransform(urldata)
            .exec()
            .mapresults((res) => {
                console.log({ apiGetMeetingMinutesById: res?.data });
                let dateString = res?.data?.date;
                let [day, month, year] = dateString.split('/').map(Number);
                let outerDate = new Date(year, month - 1, day);
                return {
                    data: {
                        _id: res.data?._id,
                        mom_id: res.data?.mom_id,
                        date: outerDate,
                        time: res.data?.time,
                        meeting_agenda: res.data?.meeting_agenda,
                        key_points: res?.data?.key_points.map(v => v),
                        actionable: res?.data?.actionable instanceof Array ? res?.data?.actionable.map((action, i) => {
                            return {
                                task_name: action.task_name,
                                ...(res.data.actionable[i]?.assignTask[0]?.assigned_roles ? {
                                    assigned_roles: action?.assignTask[0]?.assigned_roles.map(role => role),
                                    assigned_by: action?.assignTask[0]?.assigned_by,
                                    status: action?.assignTask[0]?.status,
                                    task_type: action?.assignTask[0]?.task_type,
                                    task_id: action?.assignTask[0]?.task_id,
                                } : {}),
                                ...(res.data.actionable[i]?.assignTask[0]?.assigned_list_item ? {
                                    fpr: action?.assignTask[0]?.assigned_list_item?.splitBy(','),
                                } : {}),
                                expected_completion_date: new Date(action.expected_completion_date,).toISOString(),
                                priority: action.priority,
                                created_by: action.created_by,
                                last_updated_by: action.last_updated_by,
                                _id: action._id,
                            }
                        }) : [],
                        created_by: res?.data?.created_by,
                        last_updated_by: res?.data?.last_updated_by,
                        createdAt: res?.data?.createdAt,
                        updatedAt: res?.data?.updatedAt,
                        __v: res?.data?.__v,
                    }
                }
            })
            .log()
            .get();
    },
    apiUpdateMeetingMinutes(data, urldata = {}) {
        console.log({ apiUpdateMeetingMinutes: data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            date: data?.date,
            time: data?.time,
            meeting_agenda: data?.meeting_agenda,
            key_points: data?.key_points.map(v => v),
            actionable: Object.values(data?.actionable || []).filter(v => v).map((field, i) => ({
                ...(data.actionable[i].task_id ? {
                    task_id: field?.task_id,
                } : {}),
                task_name: field?.task_name,
                task_type: field?.task_type,
                // role: field?.role,
                // fpr: field?.fpr,
                ...(data.actionable[i].assigned_roles ? {
                    assigned_roles: field?.assigned_roles.map(role => role),
                } : {}),
                ...(data.actionable[i].fpr ? {
                    fpr: field?.fpr,
                } : {}),
                expected_completion_date: new Date(field?.expected_completion_date,).toISOString(),
                status: field?.status,
                priority: field?.priority,
            })),
        };
        return this.request(this.api().updateMeetingMinutes, payload).urltransform(urldata).exec().log().get();
    },
    apiDeleteMeetingMinutes(urldata = mixins.params()) {
        return this.request(this.api().deleteMeetingMinutes)
            .urltransform(urldata)
            .exec().log().get();
    },
}
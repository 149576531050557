const editfield = (name, key, value) => {
    document.querySelector(`#modal_call_status [name="${name}"]`).setAttribute(key, value)
};
const getfield = (name) => {
    return document.querySelector(`#modal_call_status [name="${name}"]`);
};
export const modeledit = (node) => ({
    "send_online_assessment": [
        {
            type: "select",
            name: "assessment_id",
            label: "Select Online Assessment",
            // options: ["option 1", "option 2", "option 3"].options(),
        },
        {
            type: "date",
            name: "calendar",
            label: "Select Expairy Date",
            disabledDates: (date) => (new Date().toDate().date().getTime() > date.getTime())
        },
    ],
    "call_for_face2face_interview": [
        {
            type: "select",
            name: "interviewType",
            label: "Select Interview Type",
            options: _enum.INTERVIEW_TYPE,
            onChange([value]) {
                let meetingLinkFG = document.querySelector(`#modal_call_status [name="meetingLink"]`).parentNode.closest('.form-group');
                let venueLocationFG = document.querySelector(`#modal_call_status [name="address"]`).parentNode.closest('.form-group');
                // console.log(meetingLinkFG);
                if (value.key === "virtual") {
                    venueLocationFG.classList.add('d-none')
                    meetingLinkFG.classList.remove('d-none')
                    document.querySelector(`#modal_call_status [name="address"]`).removeAttribute('required')
                    document.querySelector(`#modal_call_status [name="meetingLink"]`).setAttribute('required', 'true')
                } else {
                    meetingLinkFG.classList.add('d-none')
                    venueLocationFG.classList.remove('d-none')
                    document.querySelector(`#modal_call_status [name="meetingLink"]`).removeAttribute('required')
                    document.querySelector(`#modal_call_status [name="address"]`).setAttribute('required', 'true')
                }
            },
            required: true,
        },
        {
            type: "date",
            name: "calendar",
            label: "Enter Date",
            disabledDates: (date) => (new Date().toDate().date().getTime() > date.getTime()),
            required: true,
        },
        {
            type: "time",
            name: "time",
            label: "Enter Time",
            required: true,
        },
        {
            type: "text",
            name: "meetingLink",
            label: "Enter Meeting Link",
            required: true,
        },
        {
            type: "text",
            name: "address",
            label: "Venue Location",
            className: "d-none",
            required: true,
        },
    ],
    "release_offer": [
        {
            type: "date",
            name: "calendar",
            label: "Date Of Joining",
            required: true,
            disabledDates: (date) => (new Date().toDate().date().getTime() > date.getTime())
        },
        {
            type: "text",
            name: "role",
            label: "Selected Role",
            readOnly: true,
            required: true,
        },
        {
            type: "number",
            name: "anual_ctc",
            label: "Anual CTC",
            required: true,
            onChange(e) {
                editfield('fixed_salary_pa', 'max', e.target.value);
            }
        },
        {
            type: "number",
            name: "fixed_salary_pa",
            label: "Fixed Salary Per Annum",
            required: true,
            onChange(e) {
                let fixed = +e.target.value;
                let calendar = getfield('calendar');
                let role = getfield('role');
                let anual_ctc = getfield('anual_ctc');
                let fields = {
                    calendar,
                    role,
                    anual_ctc,
                };
                let required = Object.entries(fields).find(([key, val]) => (!val.value));
                if (required) {
                    console.log({ required });
                    //alert("Missing Field "+required[1].getAttribute('label'));
                    return;
                }
                console.log({ fixed });
                app.mixin.debounce(() => {
                    app.api.apiSalaryBreakdown(fixed).then((response) => {
                        const getAlternate = (obj1, obj2) => {
                            let arr1 = Object.entries(obj1);
                            let arr2 = Object.entries(obj2);
                            let maxlen = arr1.length > arr2.length ? arr1.length : arr2.length;
                            return [].filllen(maxlen).reduce((c, v, i) => {
                                console.log({ c, v, i, arr1 });
                                let [key1, value1] = arr1[i] || [];
                                let [key2, value2] = arr2[i] || [];
                                if (key1) { c[key1] = value1; }
                                if (key2) { c[key2] = value2; }
                                return c;
                            }, {})
                        };
                        console.log({ fields: getAlternate(response.earnings, response.deductions) })
                        let fields = {
                            ...getAlternate(response.deductions, response.earnings),
                            ...Object.except(response, ['deductions', 'earnings'])
                        };
                        fields.variable_component = getfield('anual_ctc').value - fixed;
                        let fieldarr = Object.entries(fields).map(([key, value]) => ({
                            type: "number",
                            name: key,
                            label: _enum.SALARYBREAKUP_MAP[key],
                            readOnly: true,
                            value: value
                        }));
                        let modeledits = [
                            ...node.state.modeledit.release_offer,
                            ...fieldarr
                        ]
                        let popupfields = (modeledits || []).reduce((o, n) => ({ ...o, [n.name]: n }), {});
                        let popupdata = Object.map(fields, (v, k) => v.defaultValue);

                        node.setState((state) => ({
                            popupfields: { ...popupfields },
                            popupdata: {
                                ...state.popupdata,
                                ...popupdata,
                            }
                        }))
                        console.log({ response, fieldarr });
                    });
                });
            }
        },
    ]
})
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { xlsIcon } from "../../../../imagepath/imagepath";
import LayoutWrapper from "../LayoutWrapper";
import * as XLSX from "xlsx";
import mixins from "./mixins";

export class Attendance extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Attendance");
    this.state = {
      rowcount: 0,
      monthDate: new Date(),
      attendanceData: [],
      locations: [],
      search: {
        page: 1,
        month: new Date().getMonth(),
        limit: 10,
      },
    };
    this.fields = [
      {
        name: "employee_id",
        label: "Employee ID",
        type: "text",
        className: "select-focus",
      },
      {
        name: "employee_name",
        label: "Employee Name",
        type: "text",
        className: "select-focus",
      },
      {
        name: "month",
        label: "Select Month",
        type: "month",
        value: new Date().getMonthDisplay(),
        className: "select-focus",
      },
      {
        name: "district",
        label: "Location",
        type: "select",
        options: [],
        className: "select-focus",
      },
    ];
    this.links = mixins.menulinks;
    this.setColumns();
  }
  get daylist() {
    let { monthDate = new Date() } = this.state;
    return new Date(monthDate).dateList().map((o) => ({
      name: (
        <div>
          <div className="font-bold text-center">{o.getDate()}</div>
          <div
            className={Object.className({
              "text-sm text-center": true,
              "text-red-500": o.getDayName() == "SUN",
              "text-gray-500": o.getDayName() != "SUN",
            })}
          >
            {o.getDayName().toLowerCase().toTitleCase()}
          </div>
        </div>
      ),
      key: o.getDate(),
    }));
  }

  get monthDays() {
    let dayListArr = Object.entries(this.daylist);
    return dayListArr.map(([i, daynum]) => ({ key: daynum.key, index: i }));
  }

  navigateAttendenceDeatisl(record) {
    let url = "/admin/profile/AttendanceDetails?emp_id=" + record.id;
    this.props.setstore("selectedemp", record);
    navigate(url);
  }

  setColumns() {
    let { daylist, monthDays } = this;
    let year = this.state.monthDate.getFullYear();
    let month = this.state.monthDate.getMonth();
    this.colors = {
      // holiday: "text-primary",
      // leave: "text-orange-500",
      present: "text-green-500",
      absent: "text-red-500",
      sunday: "text-gray-300",
      nostatus: "text-yellow-300",
      // late_punch: "text-red-500",
    };
    this.status = Object.map(this.colors, (value, key) => {
      switch (key) {
        // case "leave":
        //   return <b className={`${this.colors[key]}`}>L</b>;
        // case "holiday":
        //   return <b className={`${this.colors[key]}`}>H</b>;
        // case "late_punch":
        //   return <b className={`${this.colors[key]}`}>LP</b>;
        case "sunday":
          return <i className={`fa fa-circle ${this.colors[key]} text-xs`}></i>;
        case "nostatus":
          return <i className={`fa fa-circle ${this.colors[key]} text-xs`}></i>;
        case "absent":
          return <i className={`fa fa-close ${this.colors[key]}`}></i>;
        default:
          return <i className={`fa fa-check ${this.colors[key]}`}></i>;
      }
    });

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <div
              className="cursor-pointer"
              // onClick={() => this.navigateAttendenceDeatisl(record)}
            >
              {record.employee_name}
              <span>({record.employee_id})</span>
            </div>
          </h2>
        ),
      },
      ...monthDays.map(({ key, index }) => ({
        dataIndex: key,
        title: daylist[index]?.name,
        sorter: false,
        render: (text, record) => {
          let dateobj = new Date(year, month, key);
          let holidaydate = this.props.store?.holidayList?.find(
            (o) => new Date(o.holiday_date).toDate() == dateobj.toDate()
          );
          return (
            <div className="text-center" title={holidaydate?.holiday_name}>
              {this.status[text] || this.status["absent"]}
            </div>
          );
        },
      })),
    ];
  }

  // getAttendaceData() {
  //   setTimeout(() => {
  //     this.props.api
  //       .apiEmployeeAttendaceList({
  //         monthDate: this.state.monthDate.toDate(),
  //       })
  //       .then((data) => {
  //         this.setState({
  //           attendanceData: data,
  //         });
  //       });
  //   });
  // }
  componentDidMount() {
    window.Attendance = this;
    this.fetchData();
    this.getDistrictLocations();
  }

  fetchData(extra = {}) {
    let data = this.refs.form.form.current.getData();
    data = { ...data, ...extra };
    console.log(data, this.state.search);

    let payload = {};

    if (this.state.search?.month?.length > 0) {
      payload = {
        ...this.state.search,
        year: new Date(this.state.search.month).getFullYear(),
        month: new Date(this.state.search.month).getMonth(),
      };
    } else {
      console.log("fallback search");
      payload = {
        ...this.state.search,
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
    }

    console.log({ payload });
    // payload = {
    //   ...payload,
    //   employee_role: "cluster-co-ordinator"
    // }
    // this.props.api.apiEmployeeAttendaceListMoke({monthDate: "2023"}).then((responce) => {
    //   console.log({ apiEmployeeAttendaceListMoke: responce });
    //   this.setState({
    //     rowcount: responce.total_count,
    //     attendanceData: responce,
    //   });
    // });

    this.props.api
      .apiEmployeeAttendaceListMonthly(payload)
      .then(({ data, total }) => {
        console.log("responce:", data);
        this.setState({
          attendanceData: data,
          rowcount: total,
        });
      });
  }

  getDistrictLocations() {
    this.props.api.apiGetDistrictLocation().then((res) => {
      let filteredLocations = res?.map((v) => v.district).options("district");
      console.log({ apiGetDistrictLocation: filteredLocations });
      this.setState({ locations: filteredLocations });
    });
  }

  emptyCheck(obj) {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value !== "string" || value.trim() !== "") {
          result[key] = value;
        }
      }
    }

    return result;
  }

  fetchFilterData(extra = {}) {
    let data = this.refs.form.form.current.getData();

    const filteredObj = this.emptyCheck(data);
    console.log({ filteredObj });

    this.setState(
      {
        search: {
          page: this.state.search.page,
          ...filteredObj,
        },
      },
      () => this.fetchData()
    );
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {
    console.log({ field, value, firstDate, lastDate, displayValue });
    let data = this.refs.form.form.current.getData();

    let filteredObj = this.emptyCheck(data);
    filteredObj.month = displayValue;
    console.log({ filteredObj });
    if (filteredObj !== undefined) {
      if (field.name == "month") {
        this.setState(
          {
            monthDate: new Date(firstDate),
            search: {
              page: this.state.search.page,
              ...filteredObj,
              limit: 10,
            },
          },
          () => this.fetchData()
        );
        // setTimeout(() => {
        //   this.fetchData();
        // }, 0);
      }
    } else {
      this.setState(
        {
          monthDate: new Date(),
          search: {
            page: this.state.search.page,
            month: new Date().getMonthDisplay(),
            limit: 10,
          },
        },
        () => this.fetchData()
      );
    }
  }

  onPageChange(pageData) {
    console.log({ onPageChange: pageData });
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchData()
    );
  }

  downloadCSVReport() {
    // this.getDataList({
    //   load_all_approved: true,
    // }).then((responce) => {
    //   let payrollMonth = this.refs.runpayrollform.getData().payrollMonth;
    //   let generatedAt = new Date().toDateTime().split(":").join("_");
    //   responce.result
    //     .csv()
    //     .dowload(`${payrollMonth}_generated_${generatedAt}.csv`);
    // });
    this.props.api
      .apiEmployeeAttendaceList({
        monthDate: this.state.monthDate.toDate(),
      })
      .then((data) => {
        // this.setState({
        //   attendanceData: data,
        // });
        console.log(data);
      });
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.setState(
      {
        monthDate: new Date(),
        search: {
          page: 1,
          month: new Date().getMonth(),
        },
      },
      () => this.fetchData()
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    // console.log(rowcount, search.limit);
    let pageCount = Math.ceil(rowcount / search.limit);
    // console.log(pageCount);
    let promises = [];
    promises.length = 1;
    promises.fill(0);
    // console.log(promises);

    let payload = {};

    if (this.state.search?.month?.length > 0) {
      payload = {
        ...this.state.search,
        year: new Date(this.state.search.month).getFullYear(),
        month: new Date(this.state.search.month).getMonth(),
      };
    } else {
      // console.log("fallback search");
      payload = {
        ...this.state.search,
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
    }
    console.log({ payload });
    promises = promises.map((v, i) =>
      this.props.api.apiEmployeeAttendaceListDownload({
        ...payload,
        page: i + 1,
      })
    );
    // console.log(promises);
    Promise.all(promises).then((res) => {
      let responseData = res[0].data || [];
      // console.log({ responseData });
      let totalrows = res.reduce((col, item) => col.concat(item.data), []);
      // let { data } = this.processData(totalrows);
      console.log({ responseData, totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = row;
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let orderedHeaders = ["employee_id", "employee_name"];
    let mainHeaders = Object.keys(addeddata[0] || {});
    mainHeaders = [
      ...orderedHeaders,
      ...mainHeaders.filter((k) => !orderedHeaders.includes(k)),
    ];

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);

    let payload = {};
    if (this.state.search?.month?.length > 0) {
      payload = {
        ...this.state.search,
        year: new Date(this.state.search.month).getFullYear(),
        month: new Date(this.state.search.month).getMonth(),
      };
    } else {
      // console.log("fallback search");
      payload = {
        ...this.state.search,
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
    }
    // console.log({ payload });
    concatedHeader = concatedHeader.map((item) => {
      if (/\d/.test(item)) {
        // console.log(item);
        let dt = new Date(payload.year, payload.month, item);
        dt = new Date(dt).dateToDDMMYYYY(dt);
        // console.log({ dt });
        return dt;
      } else {
        return item.toSpacedString();
      }
    });

    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
      concatedData,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `attendance_list_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  downloadActivityData() {
    let payload = {};

    if (this.state.search?.month?.length > 0) {
      payload = {
        ...this.state.search,
        year: new Date(this.state.search.month).getFullYear(),
        month: new Date(this.state.search.month).getMonth(),
      };
    } else {
      payload = {
        ...this.state.search,
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
    }
    console.log({ payload });

    let authToken = window.store.getState().root.auth;
    let urldata = `?year=${payload?.year}&month=${payload?.month}&auth=${authToken}`;

    return (
      app.api
        .request(app.apis().attendanceActivityDownload, payload)
        .urltransform().url + urldata
    );
  }

  updateFields(fields) {
    fields.find((o) => o.name === "district").options = this.state.locations;
    return fields;
  }

  render() {
    this.setColumns();
    let { columns, links, colors, fields } = this;
    let { attendanceData } = this.state;
    fields = this.updateFields(fields);
    return (
      <LayoutWrapper title="Team Attendance" back="Team Attendance">
        {/* <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs> */}
        <div className="page-header employee-header absolute top-8 right-8">
          <div className="row align-items-center">
            <div className="col-auto">
              <button
                type="button"
                className="btn add-btn"
                onClick={() => this.getDownloadData()}
              >
                <i class="fa fa-download me-2"></i>
                Download
              </button>
            </div>
            <div className="col-auto">
              <a href={this.downloadActivityData()} className="btn add-btn">
                <i class="fa fa-download me-2"></i>
                Activity
              </a>
            </div>
          </div>
        </div>
        <div>
          <AppForm className="row filter-row" ref="form">
            {this.fields.map((field, i) => (
              <div className="col-sm-6 col-md-2" key={i}>
                <AppInputFocus
                  {...field}
                  onChange={(...arg) => this.onFilterChange(field, ...arg)}
                ></AppInputFocus>
              </div>
            ))}
            {/* <div className="col-sm-6 col-md-1"></div> */}
            <div className="col-sm-6 col-md-2">
              <div className="d-flex">
                {/* <button
                className="btn btn-success btn-block xls_btn me-3"
                onClick={() => this.downloadCSVReport()}
              ></button> */}
                <button
                  className="btn btn-success btn-block w-100"
                  onClick={() => this.fetchFilterData()}
                >
                  Search
                </button>
              </div>
            </div>
          </AppForm>
          <button
            type="button"
            className="btn clear_filter_btn ml-auto d-block mb-3"
            onClick={(...args) => this.resetSearchForm(...args)}
          >
            <i className="fa fa-times-circle-o me-2"></i>
            Clear Filters
          </button>
        </div>
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={attendanceData}
              columns={columns}
              editable={false}
              deletable={false}
              viewable={false}
              reorder={true}
              total={this.state.rowcount}
              pageSize={10}
              onChange={(...arg) => this.onPageChange(...arg)}
            ></AppTable>
          </div>
        </div>
        <div className="flex flex-wrap test">
          {Object.entries(colors)
            // .filter(([status]) => status != "sunday")
            .map(([status, color], i) => (
              <div key={i} className="px-2 mx-2">
                {/* <i className={`fa fa-circle ${color} mr-2`} /> */}
                {status === "present" ? (
                  <i className={`fa fa-check ${color} mr-2`}></i>
                ) : status === "absent" ? (
                  <i className={`fa fa-close ${color} mr-2`}></i>
                ) : // : status === "leave" ? (
                //   <b className={`${color} mr-2`}>L</b>
                // )
                // : status === "holiday" ? (
                //   <b className={`text-primary mr-2`}>H</b>
                // )
                status === "nostatus" ? (
                  <i className={`fa fa-circle ${color} mr-2`}></i>
                ) : status === "sunday" ? (
                  <i className={`fa fa-circle ${color} mr-2`}></i>
                ) : null}
                <b>{status.toTitleCase()}</b>
              </div>
            ))}
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Attendance);

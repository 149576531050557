export default {
    postDistrict(data) {
        return this.request(this.api().addMasterDistrict, {
            "districts": [data?.key?.split(" ").join("_") || data.district]
        }).exec()
            .log()
            .get();
    },
    getDistrict(data) {
        return this.request(this.api().getMasterDistrict, data)
            .exec()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .log()
            .get();
    },
    apiGetDistrictLocation() {
        return this.request(this.api().getDistrictLocation)
            .exec()
            .mapresults((response) => {
                return response.data
            })
            .log()
            .get();
    },
    updateDistrict(data, urldata) {
        console.log("updateDistrict", data, urldata);

        // data = {
        //     ...Object.except(data, ["supervisor_name"]),
        // }
        return this.request(this.api().updateMasterDistrict, data)
            .urltransform(urldata)
            .exec().log().mapresults(async (data) => {
                return data
            }).get();
    },
    updateVillage(data, urldata) {
        console.log("updateVillage", data, urldata);

        return this.request(this.api().updateMasterVillage, data)
            .urltransform(urldata)
            .exec().log().mapresults(async (data) => {
                return data
            }).get();
    },
    moveVillage(data, urldata) {
        console.log("moveVillage", data, urldata);

        return this.request(this.api().moveVillage, data)
            .urltransform(urldata)
            .exec().log().mapresults(async (data) => {
                return data
            }).get();
    },
    deleteDistrict(urldata = mixins.params()) {
        return this.request(this.api().deleteMasterDistrict)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    deleteMasterVillage(urldata = mixins.params()) {

        return this.request(this.api().deleteMasterVillage)
            .urltransform(urldata)
            .exec().log().get();
    },
    getCluster(data, urldata = {}) {
        console.log('getCluster: ', { data, urldata });
        return this.request(this.api().getMasterCluster, data)
            .urltransform(urldata)
            .exec()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .log()
            .get();
    },
    getAllClusters() {
        return this.request(this.api().getAllClusters)
            .exec()
            .mapresults((response) => {
                return response.data
            })
            .log()
            .get();
    },
    postCluster(data) {
        console.log({ postCluster: data });
        return this.request(this.api().addMasterCluster, {
            "district": data.district,
            clusters: [data?.key?.toLowerCase().split(" ").join("-") || data.cluster_code],
        }).exec()
            .log()
            .get();
    },
    updateCluster(data, urldata) {
        console.log("updateCluster", data, urldata);

        return this.request(this.api().updateMasterCluster, data)
            .urltransform(urldata)
            .exec().log().mapresults(async (data) => {
                return data
            }).get();
    },
    deleteCluster(urldata = mixins.params()) {
        return this.request(this.api().deleteMasterCluster)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiCreateMasterData(data) {
        return this.request(this.api().addMasterVillage, {
            "district": data.district,
            "cluster": data.cluster_code,
            "villages": [
                {
                    "village_name": data.village_name,
                    "village_id": data.village_id
                }
            ]
        }).exec()
            .log()
            .get();
    },
    getVillageList(data, urldata = {}) {
        console.log({ getVillageList: data, urldata });
        return this.request(this.api().getMasterVillage, data)
            .urltransform(urldata)
            .exec()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .log()
            .get();
    },
    getVillageListMokeData() {
        let data = [
            {
                id: 0,
                village_id: "012345",
                village_name: "village 1",
            },
            {
                id: 1,
                village_id: "012346",
                village_name: "village 2",
            },
            {
                id: 2,
                village_id: "012347",
                village_name: "village 3",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    getClusterListMokeData() {
        let data = [
            {
                cluster_code: "012345",
                number_of_village: "10",
            },
            {
                cluster_code: "012346",
                number_of_village: "20",
            },
            {
                cluster_code: "012347",
                number_of_village: "30",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
}
export default {
    menulinks:[
        {
            path:"/recruitment/configurations",
            label:"Registration Form"
        },
        {
            path:"/recruitment/apttest",
            label:"Aptitude Test & Case Study’s"
        },
    ]
}
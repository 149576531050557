import React, { Component } from "react";
import { Divider, Radio, Table } from "antd";
import { Link } from "react-router-dom";

export class AppTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };
  }

  componentDidMount() {
    window.AppTableComponent = this;
  }

  render() {
    let { props } = this;
    // console.log("props: ", props);

    const itemRender = function (current, type, originalElement) {
      if (type === "prev") {
        return (
          <div
            onClick={() => props.onPrev && props.onPrev()}
            className={`${props.page <= 0 ? "pe-none" : ""}`}
          >
            Prev
          </div>
        );
      } else if (type === "next") {
        return (
          <div
            onClick={() => props.onNext && props.onNext()}
            className={`${props.page === props.pageCount ? "pe-none" : ""}`}
          >
            Next
          </div>
        );
      } else if (type === "page") {
        return originalElement;
      }
    };
    const getColumns = function () {
      if (props.columns instanceof Array && props.columns.length) {
        return props.columns;
      } else if (data.length) {
        return Object.keys(data[0]).map((key) => ({
          type: "text",
          title: key.toTitleCase(),
          dataIndex: key,
          key: key,
        }));
      } else {
        return [];
      }
    };
    let {
      data = [],
      columns = [],
      attrs = {},
      editTraget = "#edit_employee",
      deleteTarget = "#delete_employee",
      viewTarget = "#view_employee",
      copyTarget = "#copy_employee",
      disableTarget = "#disable_datacollection",
      targetType = "tap",
      copyLinkAction = () => "",
      exportAction = () => "",
      viewAction = () => "",
      editAction = () => "",
      deleteAction = () => "",
      deactiveAction = () => "",
      isDeactivated = () => "",
      editable = true,
      deletable = true,
      viewable = true,
      deactivatable = false,
      copyable = false,
      exportable = false,
      reorder = false,
      checkbox = false,
      renderAction = () => true,
      actionList = [],
      preview = false,
    } = props;
    columns = getColumns();
    let editTragetFun =
      typeof editTraget == "function" ? editTraget : () => editTraget;
    let deleteTargetFun =
      typeof deleteTarget == "function" ? deleteTarget : () => deleteTarget;
    let viewTragetFun =
      typeof viewTarget == "function" ? viewTarget : () => viewTarget;
    let copyTargetFun =
      typeof copyTarget == "function" ? copyTarget : () => copyTarget;
    let disableTargetFun =
      typeof disableTarget == "function" ? disableTarget : () => disableTarget;
    let visiblecolumns = columns.filter((o) => o.display !== false);
    let incolumns = data
      .maxObjKeys()
      .map((key) => ({
        title: key.toTitleCase(),
        dataIndex: key,
        key: key,
        sorter: (a, b) => {
          let isString = typeof a[key] == "string";
          let isNumber = typeof a[key] == "number";
          let flag = isNumber && a[key] - b[key];
          flag =
            flag === false ? isString && a[key]?.length - b[key]?.length : flag;
          //console.log({ a, b, key, flag, isNumber, isString });
          return flag;
        },
        display: !reorder,
        ...(columns.find((o) => o.dataIndex == key) || {}),
      }))
      .filter((o) => o.display !== false);
    let extra = columns
      .filter((o) => !incolumns.map((o) => o.dataIndex).includes(o.dataIndex))
      .filter((o) => o.display !== false);
    extra.map((o) => incolumns.push(o));

    if (reorder) {
      let sortedcolumns = visiblecolumns.reduce((o, n) => {
        let obj = incolumns.find((o) => o.dataIndex == n.dataIndex);
        obj &&
          o.push({
            ...obj,
            ...n,
          });
        return o;
      }, []);
      let unsorted = incolumns.filter(
        (o) => !sortedcolumns.map((o) => o.dataIndex).includes(o.dataIndex)
      );
      sortedcolumns = sortedcolumns.concat(unsorted);
      incolumns = sortedcolumns;
    }

    if (editable || deletable || viewable || copyable || exportable || deactivatable) {
      incolumns.push({
        title: "Action",
        render: (text, record) => (
          <div
            className={Object.className({
              "dropdown dropdown-action text-end": true,
              [props.actionClass || ""]: true,
            })}
          >
            {renderAction(record) && (
              <a
                href="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </a>
            )}
            <div className="dropdown-menu dropdown-menu-right">
              {copyable && ( // Add this conditional check
                <>
                  {targetType === "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={copyTargetFun(record)}
                      onClick={() => copyLinkAction()}
                    >
                      <i className="fa fa-copy m-r-5" /> {preview ? `Preview` : `Copy Link`}
                    </a>
                  )}
                  {targetType === "tap" && (
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => copyLinkAction(record)}
                    >
                      <i className="fa fa-copy m-r-5" /> {preview ? `Preview` : `Copy Link`}
                    </div>
                  )}
                </>
              )}
              {exportable && ( // Add this conditional check
                <>
                  {targetType === "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={copyTargetFun(record)}
                      onClick={() => exportAction()}
                    >
                      <i className="fa fa-external-link m-r-5" /> Export Data
                    </a>
                  )}
                  {targetType === "tap" && (
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => exportAction(record)}
                    >
                      <i className="fa fa-external-link m-r-5" /> Export Data
                    </div>
                  )}
                </>
              )}
              {viewable && (
                <>
                  {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={viewTragetFun(record)}
                      onClick={() => viewAction()}
                    >
                      <i className="fa fa-eye m-r-5" /> View
                    </a>
                  )}
                  {targetType == "tap" && (
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => viewAction(record)}
                    >
                      <i className="fa fa-eye m-r-5" /> View
                    </div>
                  )}
                </>
              )}
              {deletable && (
                <>
                  {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={deleteTargetFun(record)}
                      onClick={() => deleteAction()}
                    >
                      <i className="fa fa-trash-o m-r-5" /> Delete
                    </a>
                  )}
                  {targetType == "tap" && (
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => deleteAction(record)}
                    >
                      <i className="fa fa-trash-o m-r-5" /> Delete
                    </div>
                  )}
                </>
              )}
              {editable && (
                <>
                  {targetType == "link" && (
                    <Link to={editTragetFun(record)} className="dropdown-item">
                      <i className="fa fa-pencil m-r-5" /> Edit
                    </Link>
                  )}
                  {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={editTragetFun(record)}
                      onClick={() => editAction()}
                    >
                      <i className="fa fa-pencil m-r-5" /> Edit
                    </a>
                  )}
                  {targetType == "tap" && (
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => editAction(record)}
                    >
                      <i className="fa fa-pencil m-r-5" /> Edit
                    </div>
                  )}
                </>
              )}
              {deactivatable && (
                <>
                  {targetType == "modal" && (
                    <a
                      className="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target={viewTragetFun(record)}
                      onClick={() => deactiveAction()}
                    >
                      <img src={imagepaths.toggleIcon} alt="toggle-icon"/>
                      {`${isDeactivated(record) == "Deactivate" ? " Activate" : " Deactivate"}`}
                    </a>
                  )}
                  {targetType == "tap" && (
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => deactiveAction(record)}
                    >
                      <img src={imagepaths.toggleIcon} alt="toggle-icon"/>
                      {`${isDeactivated(record) == "Deactivate" ? " Activate" : " Deactivate"}`}
                    </div>
                  )}
                </>
              )}
              {actionList
                .filter((action) =>
                  action.isVisible && typeof action.isVisible === "function"
                    ? action.isVisible(record)
                    : [undefined, true].includes(action.isVisible)
                )
                .map(
                  ({
                    targetFun = () => "",
                    callback = () => "",
                    type: actionType = "tap",
                    title: actionTitle,
                    icon: actionIcon,
                  }) => (
                    <>
                      {actionType == "link" && (
                        <Link to={targetFun(record)} className="dropdown-item">
                          <i className={actionIcon + " m-r-5"} /> {actionTitle}
                        </Link>
                      )}
                      {actionType == "modal" && (
                        <a
                          className="dropdown-item"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target={targetFun(record)}
                          onClick={() => callback(record)}
                        >
                          <i className={actionIcon + " m-r-5"} /> {actionTitle}
                        </a>
                      )}
                      {actionType == "tap" && (
                        <div
                          className="dropdown-item cursor-pointer"
                          onClick={() => callback(record)}
                        >
                          <i className={actionIcon + " m-r-5"} /> {actionTitle}
                        </div>
                      )}
                    </>
                  )
                )}
            </div>
          </div>
        ),
      });
    }

    return (
      <div className="table-responsive">
        <Table
          className="table-striped"
          // rowSelection={
          //   checkbox
          //     ? {
          //         type: "checkbox",
          //         // ...this.rowSelection,
          //         onChange: (selectedRowKeys, selectedRows) => {
          //           console.log(
          //             `selectedRowKeys: ${selectedRowKeys}`,
          //             "selectedRows: ",
          //             selectedRows
          //           );
          //         },
          //         onSelectAll: (selected, selectedRows, changeRows) => {
          //           console.log({ selected, selectedRows, changeRows });
          //         },
          //       }
          //     : false
          // }
          rowSelection={props.rowSelection}
          pagination={
            this.props.showPagination === false
              ? false
              : {
                  total: props.total,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  itemRender: itemRender,
                  // defaultPageSize: data?.length || props.pageSize,
                  showSizeChanger: true,
                }
          }
          style={{ overflowX: "auto" }}
          columns={incolumns}
          // bordered
          dataSource={data}
          rowKey={(record) => record.id || record.village_id}
          onChange={props.onChange}
          onRow={props.onRowClick}
          {...attrs}
        />
      </div>
    );
  }
}

export default AppTable;

import { Component } from "react";
import LayoutWrapper from "../../../LayoutWrapper";
import "../module.scss";

export class VideoPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("VideoPage");
    this.state = {
      data: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      moduledata: [],
      selectedMedia: [],
      activelink: "",
      closelink: "",
    };
  }

  get pathFromModule() {
    return this.props.urlparams.pathFromModule;
  }

  get isReadonly() {
    return this.props.urlparams.readonly;
  }

  componentDidMount() {
    this.fetchData();
    if (this.pathFromModule) {
      this.setState({ closelink: "/lms/module" });
    } else {
      this.setState({ closelink: "/lms/courses/module" });
    }
  }

  fetchData() {
    let payload = {
      ...this.state.search,
    };
    this.props.api
      .apiGetAllMedia(payload, { module_id: this.props.urlparams.module_id })
      .then(({ data, total }) => {
        console.log({ apiGetAllMedia: data });
        let tempData = data;
        let newFinalArr = [];
        tempData.map((item) => {
          let linkArr = item.link.split(".be/");
          let newStr = "https://www.youtube.com/embed/" + linkArr[1];
          item.link = newStr;
          newFinalArr.push(item);
        });

        this.setState({
          data: newFinalArr,
          rowcount: total,
          activelink: newFinalArr[0]?.link,
        });
      });
    this.props.api
      .apiGetModuleById({ module_id: this.props.urlparams.module_id })
      .then((res) => {
        console.log({ moduledata: res });
        this.setState({
          moduledata: res,
        });
      });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  handleBoxClick(e, data) {
    e.preventDefault();
    e.stopPropagation();
    console.log(data.link);

    let embedurl = data?.link?.replace(/watch/g, "embed");
    // console.log(embedurl);

    this.setState({
      activelink: embedurl,
    });
  }
  handleToggle(e, item) {
    e.preventDefault();
    e.stopPropagation();
    let checked = e.target.checked;
    console.log("toggle modules:", checked, item);
  }

  onCandidateSelect(event, obj) {
    console.log(event.target.checked);
    let payload = {
      module_id: obj.module_id,
      course_id: obj.course_id,
      type: "video",
      item_id: obj.media_id,
      status: event.target.checked,
    };
    this.props.api.apiUpdateActivity(payload).then((data) => {
      console.log({ apiUpdateActivity: data });
      this.fetchData();
    });
  }

  handleClose(e, values) {
    e.preventDefault();
    e.stopPropagation();
    let closeLink =
      this.state.closelink +
      Object.encodeuri({
        course_id: this.props.urlparams.course_id,
        course_name: this.props.urlparams.course_name,
        ...(this.isReadonly ? { readonly: this.isReadonly } : {}),
      });
    this.props.navigate(closeLink);
  }

  getBreadCums() {
    if (this.props.urlparams.course_id) {
      return `Videos`;
    } else {
      return "Courses / Module";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.pathFromModule) {
      let backLink = `/lms/courses`;
      return backLink;
    } else if (this.pathFromModule) {
      let backLink =
        `/lms/module` +
        Object.encodeuri({
          course_id: this.props.urlparams.course_id,
          course_name: this.props.urlparams.course_name,
          ...(this.isReadonly ? { readonly: this.isReadonly } : {}),
        });
      return backLink;
    } else {
      return "/lms/courses";
    }
  }

  render() {
    let { data = [], moduledata = [], activelink } = this.state;
    return (
      <LayoutWrapper
        title="Course"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <div className="card bg-white media_box">
          <div className="card-header">
            <h3 className="title">{moduledata.module_name}</h3>
          </div>
          <div className="card-body px-0">
            <div className="row g-4 mx-auto px-0">
              <div className="col-12 col-md-7 position-relative">
                <iframe
                  width="560"
                  height="315"
                  className="iframe_container"
                  src={activelink}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-12 col-md-5">
                <p className="content_description">
                  {moduledata.module_description}
                </p>
                <div className="mini_box_container">
                  {data?.map((item, j) => {
                    return (
                      <div className="mini_box" key={j}>
                        <div className="row g-3 m-auto px-0">
                          <div className="col-4">
                            <p className="title">{item.media_name}</p>
                            <img
                              src={this.downloadFile(item.thumbnail)}
                              className="thumbnail_box"
                              onClick={(...args) =>
                                this.handleBoxClick(...args, item)
                              }
                            />
                          </div>
                          <div className="col-8 d-flex align-items-center justify-content-start">
                            <AppInput
                              onChange={(v) => this.onCandidateSelect(v, item)}
                              type="checkbox"
                              checked={item.activity_exist}
                              label="Completed"
                              name={`check-complete-${j}`}
                              key={`check-complete-${j}`}
                            ></AppInput>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {!data.length && <h3>No Videos were found</h3>}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="card-footer social_box">
            <button className="btn add-btn float-none me-3">
              0% Completed
            </button>
            {/* <button type="button" className="social_btn">
              <i class="fa fa-thumbs-o-up"></i>
              <span>{item.total_likes}</span>
            </button>
            <button type="button" className="social_btn">
              <i class="fa fa-thumbs-o-down"></i>
              <span>{item.total_dislikes}</span>
            </button> */}
            <button
              type="button"
              onClick={(...args) => this.handleClose(...args)}
              className="btn add-btn min-wd-100"
            >
              close
            </button>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(VideoPage);

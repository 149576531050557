
export default {

    LMS_ROLES: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-Ordinator" },
        { key: "woman-entrepreneur", label: "Woman Entrepreneur" },
        { key: "others", label: "Central Team" }
    ],
    COURSE_TYPES: [
        { key: "online", label: "Online" },
        // { key: "offline", label: "Offline" },
    ],
    DIFFICULTY_LEVEL: [
        { key: "simple", label: "Simple" },
        { key: "medium", label: "Medium" },
        { key: "complex", label: "Complex" },
    ],
    QUESTION_TYPES: [
        { key: "text", label: "Free Text" },
        { key: "checkbox", label: "Multi Select (Check Box)" },
        { key: "radio", label: "Single Select (Radio Button)" },
        { key: "file", label: "File Upload" },
    ],
    CONSTRAINTS_LMS_MAP: {
        numeric: "^(0|[1-9][0-9]+)$",
        // character: "^[A-Za-z ]+$",
        character: "^.*$",
        email: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        phonenumber: "^[0-9]{10}$",
        mandatory: "^(.+)$",
        future_dates_only: (date) => (date.getTime() >= new Date().toDate().date().getTime()),
        past_dates_only: (date) => (date.getTime() <= new Date().toDate().date().getTime())
    },
    FIELD_LMS_CONSTRAINTS: [
        { key: "numeric", label: "Numeric" },
        { key: "character", label: "Character" },
        { key: "phonenumber", label: "Phone Number" },
        { key: "email", label: "Email" },
        { key: "future_dates_only", label: "Future Dates Only" },
        { key: "past_dates_only", label: "Past Dates Only" },
        { key: "mandatory", label: "Mandatory" },
        { key: "length", label: "Length" },
        { key: "upload", label: "Upload" },
        //{ key: "hassubfields", label: "Has Category Info" },
    ],
    LMS_ACTIVE_STATES: [
        { key: true, label: "Yes" },
        { key: false, label: "No" },
    ]
}
import { error } from "jquery";

export const loginService = {
  apiGetOrganisationConfig() {
    let data = {
      name: "",
      shortname: "",
      tagline: "",
      logo: "self",
      colors: {
        "defaultbg": "white",
        "defaultText": "black",
        // "primary": "#1c67f6",
        "primary": "#FF7C21",
        "primaryText": "white",
        "secoundary": "#692E20",
        "secoundaryText": "white",
      }
    };
    return this.mokeapi(() => (data), "", 2000);
  },
  apiLoginMoke() {
    let data = {
      user: {
        name: "Admin",
        role: "employee"
      },
      auth: (10).uniqueId().toString().encode()
    }
    return this.mokeapi(() => (data), <div>
      Logging in...
    </div>, 2000);
  },
  apiLogin(data) {
    return this.request(this.api().login, data)
      .exec().log().mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(this.api().getUser, {}, {
            authorization: "Bearer " + response.data.access_token,
          }).exec().get().then((userresponse) => {
            console.log("userresponse", userresponse);
            let retdata = {
              auth: response.data.access_token,
              logged: {
                ...Object.except(data, ["password"]),
                ...response.data
              },
              user: {
                ...userresponse.data,
                refresh: response.data.refresh_token
              }
            };
            setTimeout(() => {

              this.apiRefreshToken()
              .then((res) => console.log(res))
              .catch((error) => console.log(error));
            }, retdata.logged.accessExpiresIn);
            resolve(retdata);
            return retdata;
          })
        });
      }).get();
  },
  apiOTPLogin(data) {
    console.log("apiOTPLogin", data);
    return this.request(this.api().otpLogin)
      .urltransform({
        mobile_num: data.mobile
      })
      .exec().log().mapresults((res) => {
        return res.data
      }).get();
  },
  apiOTPLoginVerify(data) {
    console.log({ apiOTPLoginVerify: data });
    return this.request(this.api().otpLoginVerify, data)
      .exec().log().mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(this.api().getUser, {}, {
            authorization: "Bearer " + response.data.access_token,
          }).exec().get().then((userresponse) => {
            console.log("userresponse", userresponse);
            let retdata = {
              auth: response.data.access_token,
              logged: {
                ...Object.except(data, ["password"]),
                ...response.data
              },
              user: {
                ...userresponse.data,
                refresh: response.data.refresh_token
              }
            };
            resolve(retdata);
            return retdata;
          })
        });
      }).get();
  },
  apiRefreshToken() {
    let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
    refreshTokenAttemp = refreshTokenAttemp || 1;
    localStorage.setItem("refreshTokenAttemp", (+refreshTokenAttemp) + 1);
    return this.request(this.api().refreshToken).headers({
      refresh: app.props.store.user.refresh
    }).exec().log().mapresults((response) => {
      let { logged = {} } = app.props.store;
      app.props.setstore('auth', response.data.access_token);
      app.props.setstore('logged', { ...logged, ...response.data });
      app.props.setstore('user.refresh', response.data.refresh_token);
      localStorage.setItem("refreshTokenAttemp", 0);
    }).get();
  }
};

export default loginService;

export default {
    menulinks:[
        {
            path:"/datacollection/datacollectioninfo",
            label:"Data Collection Form"
        },
        // {
        //     path:"/datacollection/datacollectioninfo",
        //     label:"Data Collection Form"
        // },
    ]
}
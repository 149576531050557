import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";

export class Payroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      search: {
        page: 1,
      },
      payrollData: [],
    };
    this.fields = [
      {
        name: "employee_id",
        label: "Employee ID",
        type: "text",
      },
      {
        name: "name",
        label: "Employee Name",
        type: "text",
      },
      {
        name: "location",
        label: "Location",
        type: "select",
        options: "worklocationList",
        className: "select-focus",
      },
    ];
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        dataIndex: "employee_id",
        display: false,
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <a href="#">
              <div>{text}</div>
              <div className="grid grid-cols-2">
                <span>{record.employee_id}</span>
                <span>| {record.office_location}</span>
              </div>
            </a>
          </h2>
        ),
      },
      ...[
        "pt",
        "gross_salary",
        "office_location",
        "lop",
        "working_days",
        "pf_amount",
        "tds_amount",
      ].map((dataIndex) => ({ dataIndex, display: false })),
      {
        title: "Attendances",
        dataIndex: "attendances",
        className: "text-center",
        render: (text, record) => (
          <div>
            <b>{text}</b>/{record.working_days} Days
          </div>
        ),
      },
      {
        title: "Leaves",
        dataIndex: "leaves",
        render: (text, record) => (
          <div className="">
            <div>
              <span className="w-10 inline-block text-gray-700 font-bold text-xs">
                LOP
              </span>
              <span className="">{record?.lop}</span>
            </div>
            <div>
              <span className="w-10 inline-block text-gray-700 font-bold text-xs">
                PL
              </span>
              <span className="">{record?.leaves}</span>
            </div>
          </div>
        ),
      },
      {
        title: "Paid Salary",
        dataIndex: "net_paid_amount",
        className: "w-full",
        render: (text, record) => (
          <div className="w-full">
            <div className="grid grid-auto-100px">
              <div>
                <span className="w-9 inline-block text-gray-700 font-bold text-xs">
                  Gross
                </span>
                <span className="">{(record?.gross_salary || "").cur(0)}</span>
              </div>
              <div>
                <span className="w-9 inline-block text-gray-700 font-bold text-xs">
                  P.Tax
                </span>
                <span className="">{(record?.pt || "").cur(0)}</span>
              </div>
              <div>
                <span className="w-9 inline-block text-gray-700 font-bold text-xs">
                  PF
                </span>
                <span className="">{(record?.pf_amount || "").cur(0)}</span>
              </div>
              <div>
                <span className="w-9 inline-block text-gray-700 font-bold text-xs">
                  TDS
                </span>
                <span className="">{(record?.tds_amount || "").cur(0)}</span>
              </div>
              <div>
                <span className="w-9 inline-block text-gray-700 font-bold text-xs">
                  Net
                </span>
                <span className="">{text.cur(0)}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Last Salary",
        dataIndex: "last_salary",
        render: (text) => text?.moment().format("DD MMM YYYY"),
      },
      {
        title: "Paid Date",
        dataIndex: "paid_date",
        render: (text) => text?.moment().format("DD MMM YYYY"),
      },
      {
        title: "Payslip Date",
        dataIndex: "payslip_date",
        render: (text) => text.date().moment().format("DD MMM YYYY"),
      },
      {
        title: "Payslip",
        dataIndex: "payslip",
        render: (text, record) => (
          <div className="flex">
            {record.status == "APPROVED" && (
              <button
                className="btn add-btn mx-auto px-4"
                disabled={record.allow_download}
                onClick={() => this.allowPayslip(record)}
              >
                {record.allow_download ? "Allowed" : "Allow"} Payslip
              </button>
            )}
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let status = ["APPROVED", "PENDING", "REJECTED"];
          let icons = {
            APPROVED: "text-success",
            PENDING: "text-danger",
            REJECTED: "text-info",
          };
          return (
            <div className="dropdown action-label text-center">
              <a
                className="btn btn-white btn-sm btn-rounded dropdown-toggle px-4"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className={Object.className({
                    "fa fa-dot-circle-o": true,
                    [icons[text]]: true,
                  })}
                />
                {text.toLowerCase().toTitleCase()}
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {status.map((value) => (
                  <div
                    className="dropdown-item"
                    onClick={() => this.updateValue(value, record, "status")}
                  >
                    <i className={"fa fa-dot-circle-o " + icons[value]} />
                    {value.toLowerCase().toTitleCase()}
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    ];
  }

  get approvedList() {
    return this.state.payrollData.filter((o) => o.status == "APPROVED");
  }

  allowPayslip(record) {
    confirm(`Allowing the payslip...?`).promise.then(() => {
      this.props.api
        .apiAllowPayslip(
          {},
          {
            payslip_id: record.id,
          }
        )
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  getUpdateValue(record, name) {
    prompt(`Please enter the ${name} value that need to update`, {
      defaultValue: record[name],
    }).promise.then((value) => {
      this.updateValue(value, record, name);
    });
  }

  updateValue(value, record, name) {
    confirm(`Updating the ${name} of payslip...?`).promise.then(() => {
      this.props.api
        .apiUpdatePaySlip(
          { [name]: value },
          {
            payslip_id: record.id,
          }
        )
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  componentDidMount() {
    window.Payroll = this;
    this.fetchData();
  }

  getDataList(extrapayload = {}) {
    let payload = this.refs.form.form.current.getData();
    let payrollMonth = this.refs.runpayrollform.getData().payrollMonth;
    let datearr = new Date(payrollMonth).toMonthDate().split("-");
    let data = {
      ...payload,
      payroll_year: Number(datearr[0]) || "",
      payroll_month: Number(datearr[1]) || "",
      ...this.state.search,
      ...extrapayload,
    };
    return this.props.api.apiEmployeePayrollList(data);
  }

  fetchData() {
    this.getDataList().then((responce) => {
      this.setState({
        payrollData: responce.data,
        rowcount: responce.data_count,
      });
    });
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  runPayroll() {
    let message =
      "Please compleate the review of the exception requests list of the requested month and proceed further. do you still want to progress here.?";
    confirm(message).promise.then(() => {
      let payrollMonth = this.refs.runpayrollform.getData().payrollMonth;
      let datearr = new Date(payrollMonth || new Date())
        .toMonthDate()
        .split("-");
      let data = {
        payroll_year: Number(datearr[0]),
        payroll_month: Number(datearr[1]),
      };
      this.props.api.apiRunPayroll(data).then(() => this.fetchData());
    });
  }

  downloadCSVReport() {
    this.getDataList({
      load_all_approved: true,
    }).then((responce) => {
      let payrollMonth = this.refs.runpayrollform.getData().payrollMonth;
      let generatedAt = new Date().toDateTime().split(":").join("_");
      responce.result
        .csv()
        .dowload(`${payrollMonth}_generated_${generatedAt}.csv`);
    });
  }

  render() {
    let { columns, links, approvedList } = this;
    let { payrollData } = this.state;
    return (
      <LayoutWrapper title="Payroll" back="Dashboard">
        <AppForm className="row filter-row" ref="form">
          {this.fields.map((field, i) => (
            <div className="col-sm-6 col-md-3" key={i}>
              <AppInputFocus {...field}></AppInputFocus>
            </div>
          ))}
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.fetchData()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </AppForm>
        <form className="row" ref={"runpayrollform"}>
          <div className="col"></div>
          <div className="w-80">
            <AppInput
              className="mx-2"
              type="month"
              name="payrollMonth"
              defaultValue={new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).getMonthDisplay()}
              controlClass={"rounded-full text-center"}
            ></AppInput>
          </div>
          <div className="w-40">
            <button
              className="btn add-btn mx-2 ml-auto"
              type="button"
              onClick={() => this.runPayroll()}
            >
              Run Payslip
            </button>
          </div>
        </form>
        <div className="row">
          <div className="col-12">
            <AppTable
              data={payrollData}
              columns={columns}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              editable={false}
              deletable={false}
            ></AppTable>
          </div>
          <div className="col-12 flex">
            {approvedList.length && (
              <button
                className="btn add-btn px-4 ml-auto"
                onClick={() => this.downloadCSVReport()}
              >
                Download
              </button>
            )}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Payroll);

import React, { Component } from "react";
import { subscribeinputs } from "src/mixins";

export class AppForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    window.AppFormComponent = this;
    let names = this.form.current.getNames();
    // console.log("root",this.form.current);
    // subscribeinputs(names,{
    //     root:this.form.current,
    //     setter:(value)=>this.setState({data:value}),
    //     getter:{
    //         get () {
    //             return this.state.data;
    //         }
    //     }
    // });
  }

  onSubmit(e) {
    let { props } = this;
    e.preventDefault();
    let submit = props?.onSubmit || (() => "");
    submit(e, this.form.current.getData(), this.form.current);
  }

  handleOnReset(e) {
    [...e.target.querySelectorAll("[name^='appinput']")].map((e) =>
      e.vnode.clearValues()
    );
  }

  render() {
    let { props } = this;
    return (
      <form
        ref={this.form}
        {...props}
        onSubmit={(e) => this.onSubmit(e)}
        onReset={(e) => this.handleOnReset(e)}
      >
        <button
          type="submit"
          disabled
          className="hidden"
          aria-hidden="true"
        ></button>
        {props.children}
      </form>
    );
  }
}

export default AppForm;

import lmsMapcolumns from "./lms.mapcolumns";

export default {
    apiCourseConfigureDataMoke() {
        let data = [
            {
                course_name: "Developer",
                assigned_to: "Digital-Sakni, Manager",
                course_type: "online",
                created_date: "01/02/2023",
                modules: 3,
                instructor_name: "N/A",
                max_limit: "N/A",
                enrolled: "99 / 105",
                active: "10/99",
                completed: "5/99",
            },
            {
                course_name: "Designer",
                assigned_to: "Cluster Co-ordinator",
                course_type: "online",
                created_date: "01/02/2023",
                modules: 10,
                instructor_name: "Ashish",
                max_limit: "N/A",
                enrolled: "9 / 10",
                active: "10/20",
                completed: "5/20",
            },
            {
                course_name: "Content Writer",
                assigned_to: "Manager",
                course_type: "online",
                created_date: "01/02/2023",
                modules: 7,
                instructor_name: "N/A",
                max_limit: "N/A",
                enrolled: "99 / 105",
                active: "10/99",
                completed: "5/99",
            },

        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetModulesDataMoke() {
        let data = [
            {
                module_id: 1,
                title: "Module 1",
                status: "active",
                percent: 75,
                description:
                    "Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla",
                links: [
                    {
                        attachment_id: "267f0f00-4339-4f5b-b38b-f856111ff03j",
                        file_type: "video",
                        file_name: "Video",
                    },
                    {
                        attachment_id: "267f0f00-4339-4f5b-b38b-f856111ff04h",
                        file_type: "ppt",
                        file_name: "PPT",
                    },
                    {
                        attachment_id: "267f0f00-4339-4f5b-b38b-f856091ff04h",
                        file_type: "notes",
                        file_name: "Assessments",
                    },
                ],
            },
            {
                module_id: 2,
                title: "Module 2",
                status: "disabled",
                percent: 75,
                description:
                    "Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla Sorem ipsum dolor sit amet consectetur adipiscing elit massa aenean orci erat pellentesque areaultrices idposere interdum est proin lacus acilisis faucibus egestas fringilla",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiGetVideosMoke() {
        let data = [
            {
                video_name: "video name 1",
                attachments: "attachments",
                description: "description here",
                created_on: "1 Jan 2022",
                created_by: "Admin",
            },
            {
                video_name: "video name 2",
                attachments: "attachments",
                description: "description here",
                created_on: "14 Mar 2022",
                created_by: "Admin",
            },
            {
                video_name: "video name 3",
                attachments: "attachments",
                description: "description here",
                created_on: "13 Sept 2022",
                created_by: "Admin",
            },
            {
                video_name: "video name 4",
                attachments: "attachments",
                description: "description here",
                created_on: "1 Jul 2023",
                created_by: "Admin",
            },
        ]
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);

    },
    apiGetPPTMoke() {
        let data = [
            {
                ppt_name: "ppt name 1",
                attachments: "attachments",
                description: "description here",
                created_on: "1 Jan 2022",
                created_by: "Admin",
            },
            {
                ppt_name: "ppt name 2",
                attachments: "attachments",
                description: "description here",
                created_on: "14 Mar 2022",
                created_by: "Admin",
            },
            {
                ppt_name: "ppt name 3",
                attachments: "attachments",
                description: "description here",
                created_on: "13 Sept 2022",
                created_by: "Admin",
            },
            {
                ppt_name: "ppt name 4",
                attachments: "attachments",
                description: "description here",
                created_on: "1 Jul 2023",
                created_by: "Admin",
            },
        ]
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);

    },
    apiGetAssessmentDataMoke() {
        let data = [
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                possible_values: "values",
                difficulty_level: "medium",
            },
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                possible_values: "values",
                difficulty_level: "complex",
            },
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                possible_values: "values",
                difficulty_level: "simple",
            },
        ]
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);

    },
    apiGetVideosPreviewDataMoke() {
        let data = [
            {
                file_id: "1",
                file_name: "Video Name 1",
                file_type: "video",
                attachment_id: "attachment id",
            },
            {
                file_id: "2",
                file_name: "Video Name 2",
                file_type: "video",
                attachment_id: "attachment id",
            },
            {
                file_id: "3",
                file_name: "Video Name 3",
                file_type: "video",
                attachment_id: "attachment id",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetPPTPreviewDataMoke() {
        let data = [
            {
                file_id: "1",
                file_name: "PPT Name - 1",
                file_type: "ppt",
                attachment_id: "attachment id",
            },
            {
                file_id: "2",
                file_name: "PPT Name - 2",
                file_type: "ppt",
                attachment_id: "attachment id",
            },
            {
                file_id: "3",
                file_name: "PPT Name - 3",
                file_type: "ppt",
                attachment_id: "attachment id",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetAssessmentsPreviewDataMoke() {
        let data = [
            {
                assessment_id: "d6sparsllet48rv",
                assessment_name: "Test AB",
                assessment_questions: [
                    {
                        q_label: "node questions",
                        q_type: "text",
                        q_options: [
                            "q_options"
                        ],
                        q_level: "simple",
                        a_value: "answer",
                        _id: "64ddc44c9ea5365f67f90f83"
                    }
                ],
                created_by: "N/A",
                last_updated_by: "N/A",
                createdAt: "2023-08-17T06:55:08.399Z",
                updatedAt: "2023-08-17T06:55:08.399Z"
            },
            {
                assessment_id: "d6sp9mgllet81xg",
                assessment_name: "Test AB1",
                assessment_questions: [
                    {
                        q_label: "node questions",
                        q_type: "text",
                        q_options: [
                            "q_options"
                        ],
                        q_level: "simple",
                        a_value: "answer",
                        _id: "64ddc4fe01bbc4adeae00f0a"
                    }
                ],
                created_by: "N/A",
                last_updated_by: "N/A",
                createdAt: "2023-08-17T06:58:06.152Z",
                updatedAt: "2023-08-17T06:58:06.152Z"
            }
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiCoursesDataMoke() {
        let data = [
            {
                id: 1,
                title: "Course 1",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            {
                id: 2,
                title: "Course 2",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            {
                id: 3,
                title: "Course 3",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            {
                id: 4,
                title: "Course 4",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetCourseAssessmentDataMoke() {
        let data = [
            {
                label: "Select the three most important values you consider a person should have",
                fieldType: "checkbox",
                possibleValues: ["a", "s", "d"],
                fieldConstraint: ["mandatory"],
                id: 1
            },
            {
                label: "question two",
                fieldType: "radio",
                possibleValues: ["a", "b", "c", "d"],
                fieldConstraint: ["mandatory"],
                id: 2
            },
            {
                label: "question two",
                fieldType: "select",
                possibleValues: ["option 1", "option 2", "option 3"],
                fieldConstraint: ["mandatory"],
                id: 3
            },
            {
                label: "question three",
                fieldType: "textarea",
                possibleValues: [],
                fieldConstraint: ["mandatory"],
                id: 4
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetVideoDataMoke() {
        let data = [
            {
                session_title: "Session 1",
                description: "Lorem ipsum dolor sit amet consectetur adipiscing",
                link: "",
                total_likes: "12,456",
                total_dislikes: "456",
                total_percent: 75,
                suggestions: [
                    {
                        title: "Video 1",
                        link: "",
                    },
                    {
                        title: "Video 2",
                        link: "",
                    },
                    {
                        title: "Video 3",
                        link: "",
                    },
                ]
            }
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetAssessmentReviewMoke() {
        let data = [
            {
                id: "234566",
                course_name: "Developer",
                assessment_name: "Assessment - 1",
                submitted_by: 5,
                num_of_questions: 20,
                submitted_on: "24 Jan 2022",
            },
            {
                id: "475476",
                course_name: "Graphics",
                assessment_name: "Assessment - 2",
                submitted_by: 10,
                num_of_questions: 20,
                submitted_on: "12 Feb 2023",
            },
            {
                id: "235364656",
                course_name: "Content Writer",
                assessment_name: "Assessment - 3",
                submitted_by: 7,
                num_of_questions: 20,
                submitted_on: "10 Jan 2022",
            },
            {
                id: "3532524",
                course_name: "Tester",
                assessment_name: "Assessment - 4",
                submitted_by: 4,
                num_of_questions: 20,
                submitted_on: "10 Jan 2022",
            },
            {
                id: "335354",
                course_name: "Video Ananlytics",
                assessment_name: "Assessment - 5",
                submitted_by: 9,
                num_of_questions: 20,
                submitted_on: "1 Jan 2023",
            },
        ];

        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetAssessmentViewMoke() {
        let data = [
            {
                name: "Yash Bharat",
                role: "Digital Sakhi",
                num_of_questions: 10,
                num_of_answers: 10,
                submitted_on: "24 Jan 2022",
            },
            {
                name: "Keerthi Sri",
                role: "Cluster Co-Ordinator",
                num_of_questions: 10,
                num_of_answers: 7,
                submitted_on: "12 Feb 2023",
            },
            {
                name: "Rohit Das",
                role: "Project Manager",
                num_of_questions: 10,
                num_of_answers: 8,
                submitted_on: "10 Jan 2022",
            },
            {
                name: "Dheeraj Reddy",
                role: "Women Entrepreneur",
                num_of_questions: 10,
                num_of_answers: 5,
                submitted_on: "10 Jan 2022",
            },
            {
                name: "Amrita Gupta",
                role: "Digital Sakhi",
                num_of_questions: 10,
                num_of_answers: 6,
                submitted_on: "1 Jan 2023",
            },
        ];

        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetAssessmentDetailsMoke() {
        let data = [
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                answer: "Explore the origins, history and meaning of the famous passage",
                correct_answer: "Lorem Ipsum placeholder text for use in your graphic",
            },
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                answer: "Explore the origins, history and meaning of the famous passage",
                correct_answer: "Lorem Ipsum placeholder text for use in your graphic",
            },
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                answer: "Explore the origins, history and meaning of the famous passage",
                correct_answer: "Lorem Ipsum placeholder text for use in your graphic",
            },
            {
                question: "Lorem Ipsum placeholder text for use in your graphic",
                answer: "Explore the origins, history and meaning of the famous passage",
                correct_answer: "Lorem Ipsum placeholder text for use in your graphic",
            },
        ];

        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    // REAL API SERVICES

    apiGetLmsDashboard() {
        return this.request(this.api().getLmsDashboard)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data[0];
            })
            .get();
    },
    apiGetAllCourses(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().courseList, payload)
            .exec().log()
            .mapresults((response) => {
                console.log(response);
                return {
                    data: response.data[0].records.map((record) => lmsMapcolumns.mapFields('courses', record)),
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetCourseById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getCourseById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiCreateCourse(data) {
        // console.log({ apiCreateCourse: data });
        let payload =
        {
            ...lmsMapcolumns.mapPayload('courses', data),
            roles: data.roles.splitBy(',')
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createCourse, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiUpdateCourse(data, urldata = {}) {
        console.log({ apiUpdateCourse: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            ...Object.except(lmsMapcolumns.mapPayload('courses', data), ["course_id"]),
            roles: data.roles.splitBy(',')
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().updateCourse, payload).urltransform(urldata).exec().log().get();
    },
    apiDeleteCourse(urldata = mixins.params()) {

        return this.request(this.api().deleteCourse)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiGetModules(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getAllModules, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiGetAllModuleStatus(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getAllModuleStatus)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiGetModuleById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getModuleById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiCreateModule(data) {
        // console.log({ apiCreateModule: data });
        let payload =
        {
            ...lmsMapcolumns.mapPayload('modules', data),
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createModule, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiUpdateModule(data, urldata = {}) {
        console.log({ apiUpdateModule: data });
        urldata = { ...mixins.params(), ...urldata };

        let payload =
        {
            ...lmsMapcolumns.mapPayload('modules', data),
        }
        return this.request(this.api().updateModule, payload).urltransform(urldata).exec().log()
            .mapresults((response) => {
                return {
                    data: response.data
                }
            }).get();
    },
    apiDeleteModule(urldata = mixins.params()) {

        return this.request(this.api().deleteModule)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiGetModuleStatusById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getModuleStatusById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiGetAllMedia(data, urldata = {}) {
        console.log("apiGetAllMedia: ", data, urldata)
        console.log({ apiGetAllMedia: data });
        return this.request(this.api().getAllMedia, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiCreateMedia(data) {
        console.log({ apiCreateMedia: data });
        let payload =
        {
            ...lmsMapcolumns.mapPayload('media', data),
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createMedia, [payload])
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiUpdateMedia(data, urldata = {}) {
        // console.log({ apiUpdateMedia: data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload =
        {
            ...data
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().updateMedia, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteMedia(urldata = mixins.params()) {
        return this.request(this.api().deleteMedia)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiGetAllPPT(data, urldata = {}) {
        // urldata = { ...mixins.params(), ...urldata };

        // return this.request(this.api().getAllModulePPT)
        //     .urltransform(urldata)
        //     .exec()
        //     .log()
        //     .mapresults((response) => {
        //         return {
        //             data: response.data[0].records,
        //             total: response.data[0].pagination[0]?.totalRecords || 0
        //         }
        //     })
        //     .get();
        console.log({ apiGetAllPPT: data });
        return this.request(this.api().getAllModulePPT, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiCreatePPT(data) {
        // console.log({ apiCreatePPT: data });
        let payload =
        {
            ...lmsMapcolumns.mapPayload('ppt', data),
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createPPT, [payload])
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiUpdatePPT(data, urldata = {}) {
        // console.log({ apiUpdatePPT: data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload =
        {
            ...data
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().updatePPT, payload).urltransform(urldata).exec().log().get();
    },

    apiDeletePPT(urldata = mixins.params()) {
        // console.log({ apiDeletePPT: urldata });
        return this.request(this.api().deletePPT)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiGetAssessments(data) {

        return this.request(this.api().getAssessments, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetAssessmentById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getAssessmentById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiGetAssessmentByModuleId(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getAssessmentByModuleId)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiCreateAssessment(data) {
        console.log({ apiCreateAssessment: data.assessment_questions });

        let payload = {
            ...data,
            assessment_questions: data?.assessment_questions?.map(v => {
                return {
                    ...v, q_options: v?.q_options?.splitBy(",")
                }
            })
        }

        // console.log({ payload: payload })
        return this.request(this.api().createAssessment, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiUpdateAssessment(data, urldata = {}) {
        console.log({ data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            // assessment_name: data.assessment_name,
            assessment_questions: data?.assessment_questions?.map(v => {
                return {
                    ...v, q_options: v?.q_options?.splitBy(",")
                }
            })
        };
        return this.request(this.api().updateAssessment, payload).urltransform(urldata).exec().log().get();
    },
    apiDeleteAssessment(urldata = mixins.params()) {
        return this.request(this.api().deleteAssessment)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiUpdateAssessmentQuestion(data, urldata = {}) {
        // console.log({ apiUpdateHandout: data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload =
        {
            ...data
        }
        if (data.q_options) {
            payload = {
                ...data,
                q_options: data.q_options.split(",") || [],
            }
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().updateAssessmentQuestion, payload).urltransform(urldata).exec().log().get();
    },
    apiDeleteAssessmentQuestion(urldata = mixins.params()) {
        // console.log({ apiDeleteAssessmentQuestion: urldata });
        return this.request(this.api().deleteAssessmentQuestion)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiUpdateActivity(data) {
        console.log({ apiUpdateActivity: data });

        let payload = {
            ...data,
        }

        // console.log({ payload: payload })
        return this.request(this.api().updateActivity, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    // EVALUATIONS
    apiCreateEvaluation(data) {
        console.log({ apiCreateEvaluation: data.assessment_questions });

        let payload = {
            ...data,
        }

        return this.request(this.api().createEvaluation, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiGetAllEvaluations(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getEvaluations, payload)
            .exec().log()
            .mapresults((response) => {
                console.log(response);
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetEvaluationById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getEvaluationById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetEvaluationAnswers(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().getEvaluationAnswers)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response?.data?.items,
                }
            })
            .get();
    },
}
import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";

export class AddAssessment extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddAssessment");
    this.state = {
      data: [],
      editMode: false,
      viewMode: false,
      addMode: false,
      editData: {},
      form: {},
      fields: [{}],
      mounted: false,
      rendered: false,
      rowcount: 0,
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      disabled: _enum.MANDATORY_FIELDS,
      assessmentData: {
        assessment_id: "",
      },
    };
    this.tablinks = mixins.tablinks;
    this.columns = [
      {
        dataIndex: "question_id",
        title: "Q. No.",
        render: (value, item, index) => {
          return <>{index + 1}.</>;
        },
      },
      {
        dataIndex: "q_label",
        title: "Question",
        render: (text, record) => {
          return (
            <span
              className="text_truncate cursor-pointer"
              title={record?.q_label}
              onClick={() => alert(record?.q_label)}
            >
              {record?.q_label}
            </span>
          );
        },
      },
      {
        dataIndex: "q_options",
        title: "Possible Values",
        render: (text) => {
          return <>{text.join(", ")}</>;
        },
      },
      {
        dataIndex: "q_level",
        title: "Difficulty Levels",
      },
    ];
  }

  get has_edit() {
    return !!this.props.urlparams.course_id;
  }

  get listMode() {
    return !(this.state.editMode || this.state.addMode || this.state.viewMode);
  }
  addField(e) {
    let { fields } = this.state;
    fields = [...fields, {}];
    // console.log({ fields });
    this.setState({
      fields,
    });
  }

  deleteField(e, index) {
    e.preventDefault();
    const removeLocal = () => {
      let { fields } = this.state;
      fields = fields.filter((v, i) => i != index);
      this.setState({
        fields,
      });
    };
    removeLocal();
  }

  handleFieldTypeChange(v, i) {
    console.log(v, i);
    if (v.length > 0) {
      const setFields = (state) => {
        let isSelect = ["select", "checkbox", "radio"].includes(v[0].key);
        let hasQuestionUpload = ["questionfile"].includes(v[0].key);
        let multiselect = v[0].key === "checkbox" ? true : false;

        let fields = Object.setNested(state.fields, `${i}.hasSelect`, isSelect);

        fields = Object.setNested(
          fields,
          `${i}.hasQuestionUpload`,
          hasQuestionUpload
        );

        fields = Object.setNested(fields, `${i}.multiselect`, multiselect);

        return fields;
      };
      this.setState((state) => ({
        fields: setFields(state),
      }));
    }
  }

  getElement(name) {
    return document.querySelector(`[name='${name}']`);
  }

  getElementValue(name) {
    return this.getElement(name)?.value || "";
  }

  getElementOptions(name) {
    // console.log({ name: name });
    return this.getElementValue(name)
      .split(",")
      .filter((v) => v)
      .map((v) => ({
        key: v,
        label: v,
      }));
  }

  removeConsecutiveCommas(str) {
    var parts = str.split(",");

    var filteredParts = parts.filter(function (part) {
      return part !== "";
    });

    var result = filteredParts.join(",");
    return result;
  }

  handlePossibleValuesChange(field, e) {
    // console.log({ handlePossibleValuesChange: e.target.value });
    this.mixin.debounce(() => {
      e.target.value = this.removeConsecutiveCommas(e.target.value);
      this.setState({
        fields: [...this.state.fields],
      });
      // console.log(this.state.fields);
    }, 2000);
  }
  get $ele() {
    return this.refs.form.form.current;
  }

  get formEle() {
    return this.refs?.form?.form?.current;
  }

  get formData() {
    let formData = this.formEle?.getData() || {};
    let parsedData = parseInput(formData);
    console.log({ parsedData });
    parsedData.fields = (parsedData.fields || []).map((field) => {
      let { isSelect, FIELD_LMS_CONSTRAINTS } = this.checkFieldTypes({
        ...field,
        key: field.q_type,
      });
      let { isNumeric } = this.checkConstraints(field);
      // console.log({ FIELD_LMS_CONSTRAINTS });
      return {
        ...field,
        FIELD_LMS_CONSTRAINTS,
        hasSelect: isSelect,
        isNumeric,
      };
    });

    return parsedData;
  }

  addRecord() {
    this.setState({
      addMode: true,
    });
  }

  checkConstraints(field) {
    // console.log({ checkConstraints: field });
    let isNumeric = (field.fieldConstraint || "")
      .split(",")
      .includes("numeric");
    return {
      isNumeric,
    };
  }
  checkFieldTypes(field) {
    console.log({ checkFieldTypes: field.key });
    let isFreeText = ["text", "textarea"].includes(field.key);
    let isSelect = ["select", "checkbox", "radio"].includes(field.key);
    let isFile = ["file"].includes(field.key);
    let isDate = ["date"].includes(field.key);
    let FIELD_LMS_CONSTRAINTS = _enum.FIELD_LMS_CONSTRAINTS;
    if (isFreeText) {
      FIELD_LMS_CONSTRAINTS = _enum.FIELD_LMS_CONSTRAINTS.exclude(
        ["future_dates_only", "past_dates_only"],
        "key"
      );
    } else if (isDate) {
      FIELD_LMS_CONSTRAINTS = _enum.FIELD_LMS_CONSTRAINTS.only(
        ["mandatory", "future_dates_only", "past_dates_only"],
        "key"
      );
    } else {
      FIELD_LMS_CONSTRAINTS = _enum.FIELD_LMS_CONSTRAINTS.only(
        ["mandatory"],
        "key"
      );
    }
    return {
      FIELD_LMS_CONSTRAINTS,
      hasSelect: isSelect,
      isFile,
      isSelect,
      isDate,
      isFreeText,
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      // apiGetAssessments
      // this.props.api
      //   .apiGetAssessments(this.state.search)
      //   .then(({ data, total }) => {
      //     // console.log(data);
      //     this.setState({
      //       data,
      //       rowcount: total,
      //     });
      //     resolve();
      //   });
      this.props.api
        .apiGetAssessmentByModuleId({
          module_id: this.props.urlparams.module_id,
        })
        .then(({ data, total }) => {
          this.setState({
            data,
            rowcount: total,
            mounted: true,
          });
          resolve();
        });
    });
  }

  getAssessment(id) {
    this.props.api.apiGetAssessmentById({ assessment_id: id }).then((res) => {
      console.log({ getAssessment: res });
      this.setState({
        editData: res,
        form: res,
        fields: res?.assessment_questions?.map((o) => {
          let isSelect = ["select", "checkbox", "radio"].includes(o.q_type);
          let hasQuestionUpload = ["questionfile"].includes(o.q_type);
          let multiselect = o.q_type === "checkbox" ? true : false;
          let { isNumeric } = this.checkConstraints({
            ...o,
            key: o.q_type,
          });
          return {
            ...o,
            isNumeric,
            hasSelect: isSelect,
            hasQuestionUpload,
            multiselect,
          };
        }),
      });
    });
  }

  viewAction(v, id) {
    this.setState({
      editMode: false,
      viewMode: true,
      // editData: v,
    });
    this.setState({
      assessmentData: {
        assessment_id: id,
      },
    });
    this.getAssessment(id);
  }

  editAction(v, id) {
    this.setState({
      editMode: true,
      viewMode: false,
      // editData: v,
    });
    this.setState({
      assessmentData: {
        assessment_id: id,
      },
    });
    this.getAssessment(id);
  }

  deleteAction(v, assessmentId) {
    // console.log("delete ", v, assessmentId);
    let payload = {
      assessment_id: assessmentId,
      question_id: v._id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteAssessmentQuestion(payload).then(() => {
        this.fetchData();
      });
    });
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  handleAssessmentDelete(e, assessmentId) {
    let payload = {
      assessment_id: assessmentId,
    };
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteAssessment(payload).then(() => {
        this.fetchData();
      });
    });
  }

  sumitForm(event, data, form) {
    // console.log({ addAssessment: data });
    let parsedPayload = parseInput(data);
    // console.log({ parsedPayload });

    let { editData } = this.state;

    const resolveList = () => {
      this.fetchData().then(() => {
        this.setState({
          editMode: false,
          addMode: false,
          editData: {},
          fields: [{}],
        });
      });
      form.reset();
    };
    if (this.state.editMode) {
      this.api
        .apiUpdateAssessment(parsedPayload, {
          assessment_id: this.state.assessmentData.assessment_id,
        })
        .then(resolveList);
    } else {
      this.setState({
        editData: data,
      });
      let payload = {
        ...parsedPayload,
        module_id: this.props.urlparams.module_id,
        course_id: this.props.urlparams.course_id,
      };
      resolveList();
      this.api.apiCreateAssessment(payload).then(resolveList);
    }
  }

  getBreadCums() {
    if (this.props.urlparams.course_name) {
      return `${this.props.urlparams.course_name
        .spaceCase()
        .toTitleCase()} / Module Details / Videos / PPT / Assessment`;
    } else {
      return "Course Name / Module Details / Videos / PPT / Assessment";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.mixin.params().readonly) {
      let backLink = `/lms/module/addppt?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&module_id=${this.props.urlparams.module_id}`;
      return backLink;
    } else if (this.mixin.params().readonly) {
      let backLink = `/lms/module/addppt?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&module_id=${this.props.urlparams.module_id}&readonly=${this.props.urlparams.readonly}`;
      return backLink;
    } else {
      return "/lms/module";
    }
  }

  getMergeData(obj1 = {}, obj2 = {}, empty = [undefined, null, ""]) {
    const isempty = (v1, v2) => (empty.includes(v1) ? v2 : v1);
    return Object.keys({
      ...obj1,
      ...obj2,
    }).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.props.urlparams.id
          ? isempty(obj1[key], obj2[key])
          : isempty(obj2[key], obj1[key]),
      }),
      {}
    );
  }

  render() {
    let { tablinks, columns, props, has_edit } = this;
    let {
      data = [],
      rowcount,
      editData,
      form,
      fields,
      disabled,
      viewMode,
      rendered,
      mounted,
    } = this.state;

    let { assessment_questions: formFields = [] } = this.formData;
    let hasForm = fields?.length == 0;
    let maxFields = hasForm ? formFields : fields;
    if (mounted && !rendered) {
      this.setState({ rendered: true });
    }
    if (mounted && rendered) {
      fields = maxFields.map((v, i) =>
        this.getMergeData(fields[i], formFields[i])
      );
    }
    // console.log({ rendered, mounted, fields, formFields });
    console.log({ newFields: fields });

    return (
      <LayoutWrapper
        title="Course Configure"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        {(props?.urlparams?.course_id && (
          <>
            {!this.listMode && (
              <div className="row m-auto">
                <AppForm
                  ref={"form"}
                  className="flex flex-col gap-4 bg-white p-4"
                  onSubmit={(...args) => this.sumitForm(...args)}
                >
                  <div className="flex flex-wrap gap-4">
                    <div style={{ flex: "0.3" }}>
                      <AppInput
                        type="text"
                        label="Assessment Name"
                        name="assessment_name"
                        maxLength="50"
                        pattern="^[A-Za-z][a-zA-Z0-9\/ \\ ]*$"
                        invalidmessage="Value Should be Valid Aphanumeric Content"
                        defaultValue={form?.assessment_name}
                        disabled={props.urlparams.readonly || viewMode}
                        required={true}
                      ></AppInput>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {fields.map((qfield, i) => (
                      <div className="flex flex-wrap gap-4" key={"field" + i}>
                        <AppInput
                          label="Question"
                          type="text"
                          name={`assessment_questions.${i}.q_label`}
                          className="grow"
                          // invalidmessage="Value Should be Valid Aphanumeric Content"
                          defaultValue={qfield?.q_label}
                          required={true}
                          disabled={props.urlparams.readonly || viewMode}
                        ></AppInput>
                        <AppInput
                          label="Question Type"
                          type="select"
                          name={`assessment_questions.${i}.q_type`}
                          options={_enum.QUESTION_TYPES}
                          className="grow"
                          onChange={(v) => this.handleFieldTypeChange(v, i)}
                          defaultValue={qfield?.q_type}
                          disabled={props.urlparams.readonly || viewMode}
                          required={true}
                        ></AppInput>
                        {this.state.fields[i].hasQuestionUpload && (
                          <>
                            <AppInput
                              type="file"
                              label="Upload Question Sheet"
                              name={`assessment_questions.${i}.q_options`}
                              className="grow"
                              disabled={
                                props.urlparams.readonly ||
                                disabled.includes(
                                  qfield.name || qfield.field_name
                                ) ||
                                viewMode
                              }
                              defaultValue={qfield?.q_options}
                            ></AppInput>
                          </>
                        )}
                        {this.state.fields[i].hasSelect && (
                          <>
                            <AppInput
                              label="Possible Values"
                              type="text"
                              name={`assessment_questions.${i}.q_options`}
                              className="grow"
                              defaultValue={qfield?.q_options || []}
                              disabled={
                                props.urlparams.readonly ||
                                disabled.includes(
                                  qfield.name || qfield.field_name
                                ) ||
                                viewMode
                              }
                              onChange={(...args) =>
                                this.handlePossibleValuesChange(qfield, ...args)
                              }
                              note="possible value should be comma separated"
                            ></AppInput>
                            <AppInput
                              label="Correct Answer"
                              type="select"
                              name={`assessment_questions.${i}.a_value`}
                              className="grow"
                              options={
                                qfield?.q_options
                                  ?.splitBy(",")
                                  .options("", "", (v) => v) ||
                                this.getElementOptions(
                                  `assessment_questions.${i}.q_options`
                                )
                              }
                              defaultValue={
                                this.state.fields[i].multiselect &&
                                qfield?.a_value
                                  ? [qfield?.a_value].splitBy(",")
                                  : [qfield?.a_value].filter((v) => v)
                              }
                              disabled={props.urlparams.readonly || viewMode}
                              multi={this.state.fields[i].multiselect}
                              required={true}
                            ></AppInput>
                          </>
                        )}

                        <AppInput
                          label="Difficulty Level"
                          type="select"
                          name={`assessment_questions.${i}.q_level`}
                          options={_enum.DIFFICULTY_LEVEL}
                          className="grow"
                          defaultValue={qfield?.q_level}
                          disabled={props.urlparams.readonly || viewMode}
                          required={true}
                        ></AppInput>
                        {!props.urlparams.readonly && (
                          <button
                            onClick={(e) => this.deleteField(e, i)}
                            className={Object.className({
                              "btn bg-red-500 hover:bg-red-500 !m-auto !mb-3 text-white": true,
                              invisible: viewMode,
                            })}
                          >
                            <i className="fa fa-trash-o text-error" />
                          </button>
                        )}
                      </div>
                    ))}
                    <div>
                      {!props.urlparams.readonly && (
                        <button
                          className={Object.className({
                            "btn bg-transparent flex gap-2 text-primary": true,
                            invisible: viewMode,
                          })}
                          type="button"
                          onClick={(e) => this.addField(e)}
                        >
                          <i className="fa fa-plus m-auto" />
                          <span className="font-bold">Add Questions</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <button
                      className={Object.className({
                        "btn add-btn col col-md-1 ml-auto": true,
                        invisible:
                          this.state.viewMode || props.urlparams.readonly,
                      })}
                    >
                      {this.state.editMode ? "Update" : "Save & Next"}
                    </button>
                    <button
                      onClick={() =>
                        this.setState({
                          editMode: false,
                          addMode: false,
                          viewMode: false,
                          editData: {},
                        })
                      }
                      className={Object.className({
                        "btn btn_primary col col-md-1 grey me-2 ms-2":
                          !this.state.viewMode,
                        "btn btn_primary col col-md-1 grey me-2 ml-auto":
                          this.state.viewMode,
                      })}
                    >
                      Cancel
                    </button>
                  </div>
                </AppForm>
              </div>
            )}
            {this.listMode && (
              <div className="row">
                <div className="col-12 mb-4">
                  <button
                    className={Object.className({
                      "btn add-btn ml-auto": true,
                      hidden: this.props.urlparams.readonly == 1 ? true : false,
                    })}
                    onClick={() => this.addRecord()}
                  >
                    <i className="fa fa-plus"></i>
                    Add Assessment
                  </button>
                </div>
              </div>
            )}

            <div className="row addppt">
              <div className="col-12">
                {!!data.length &&
                  this.listMode &&
                  data.map((item, i) => {
                    return (
                      <div className="assessment_container" key={i}>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="flex flex-column">
                            <h4>Assessment No. {i + 1}</h4>
                            <p className="sub_title">{item?.assessment_name}</p>
                          </div>
                          <div className="flex">
                            <button
                              type="button"
                              className={Object.className({
                                "btn bg-grey-500 text-dark mx-1 action_top_btn": true,
                                "d-none": props.urlparams.readonly,
                              })}
                              // disabled={
                              //   this.props.urlparams.readonly == 1
                              //     ? true
                              //     : false
                              // }
                              onClick={(v) =>
                                this.viewAction(v, item?.assessment_id)
                              }
                            >
                              <i className="fa fa-eye fs-6" />
                            </button>
                            <button
                              type="button"
                              className={Object.className({
                                "btn bg-grey-500 text-dark mx-1 action_top_btn": true,
                                "d-none": props.urlparams.readonly,
                              })}
                              onClick={(v) =>
                                this.editAction(v, item?.assessment_id)
                              }
                            >
                              <i className="fa fa-pencil fs-6" />
                            </button>
                            <button
                              type="button"
                              className={Object.className({
                                "btn bg-red-500 text-white mx-1 action_top_btn red": true,
                                "d-none": props.urlparams.readonly,
                              })}
                              onClick={(...args) =>
                                this.handleAssessmentDelete(
                                  ...args,
                                  item?.assessment_id
                                )
                              }
                            >
                              <i className="fa fa-trash-o fs-6" />
                            </button>
                          </div>
                        </div>
                        <AppTable
                          data={item?.assessment_questions}
                          columns={columns}
                          onNext={() => this.onNext()}
                          onPrev={() => this.onPrev()}
                          onChange={(...arg) => this.onPageChange(...arg)}
                          total={rowcount}
                          reorder={true}
                          // deleteAction={(v) =>
                          //   this.deleteAction(v, item?.assessment_id)
                          // }
                          // editAction={(v) =>
                          //   this.editAction(v, item?.assessment_id)
                          // }
                          // viewAction={(v) =>
                          //   this.viewAction(v, item?.assessment_id)
                          // }
                          // editable={
                          //   this.props.urlparams.readonly == 1 ? false : true
                          // }
                          // deletable={
                          //   this.props.urlparams.readonly == 1 ? false : true
                          // }
                          deletable={false}
                          editable={false}
                          viewable={false}
                          pageSize={10}
                          showPagination={false}
                          targetType="tap"
                        ></AppTable>
                        <div className="w-100 my-4">
                          <button
                            type="button"
                            className={Object.className({
                              "text-primary fw-bold border-0 bg-transparent fs-6 ml-auto d-flex align-items-center justify-content-between": true,
                              "d-none": props.urlparams.readonly,
                            })}
                            onClick={(...args) =>
                              this.editAction(...args, item?.assessment_id)
                            }
                          >
                            {/* <i className="fa fa-plus"></i> */}
                            <span>Add more Questions</span>
                          </button>
                          <hr />
                        </div>
                      </div>
                    );
                  })}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
              {this.listMode && (
                <div className="col-12 my-4">
                  <Link
                    to={
                      "/lms/module/preview?" +
                      Object.QueryString({
                        ...this.props.urlparams,
                      })
                    }
                    className="btn btn_primary grey col col-md-1 float-right ms-3"
                  >
                    Skip
                  </Link>
                  <Link
                    to={
                      "/lms/module/preview?" +
                      Object.QueryString({
                        ...this.props.urlparams,
                      })
                    }
                    className="btn add-btn"
                  >
                    Preview
                  </Link>
                </div>
              )}
            </div>
          </>
        )) ||
          ""}
      </LayoutWrapper>
    );
  }
}

export default connect(AddAssessment);

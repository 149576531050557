import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import BasicDetails from "../BasicDetails";

export const tabs = [
  {
    path: "/admin/employee/onboarding",
    label: "Personal Info",
    name: "personal_info",
  },
  {
    path: "/admin/employee/onboarding/documents",
    label: "Docs Upload",
    name: "docs_upload",
  },
];

export const getEmpId = function (props, defEmpId = 0) {
  let empId = props.location.href.url().params.emp_id || "";
  return empId != 0 ? empId : defEmpId;
};

export const checkOnboardTab = function (props) {
  let onboarding = props.store?.onborading?.flow || "personal_info";
  let empId = props.store?.onborading?.id || 0;
  let route = tabs.find((o) => o.name == onboarding);
  let isLive = props.location.pathname == route?.path;
  let hasEmpId = getEmpId(props, 0);
  if ((!isLive && route) || !hasEmpId) {
    props.navigate(route.path + `?emp_id=${empId}`);
  }
};

export const currentRouteIndex = function (props) {
  let onboarding = props.store?.onborading?.flow || "personal_info";
  return tabs.findIndex((o) => o.name == onboarding);
};

export const nextRoute = function (props) {
  let index = currentRouteIndex(props);
  return tabs[index + 1];
};

export const navigateNextTab = function (props, defEmpId) {
  let empId = getEmpId(props, defEmpId);
  let route = nextRoute(props);
  if (route) {
    props.setstore("onborading", { flow: route.name, id: empId });
    props.navigate(route.path + `?emp_id=${empId}`);
  }
};
export class OnBoarding extends Component {
  componentDidMount() {
    // checkOnboardTab(this.props);
    window.OnBoarding = this;
  }

  handleSubmit(e) {
    e.preventDefault();

    let formData = e.target.getData();

    formData = {
      ...formData,
      village_id: formData.village_id?.splitBy(","),
      village_name: formData.village_name?.splitBy(","),
    };

    console.log("formData", formData);

    if (formData?.gst?.length === 0) {
      delete formData.gst;
    } else {
      formData.gst = formData.gst;
    }

    if (this.props.urlparams.emp_id) {
      this.props.api
        .apiUpdateEmployee(formData, {
          employee_id: this.props.urlparams.emp_id,
        })
        .then(({ data }) => {
          let url =
            "/womenentrepreneur?" +
            (this?.props?.urlparams?.is_deleted ? "is_deleted=true" : "");
          console.log({ editData: data, url });
          navigate(url);
        });
    } else {
      let payload = {
        ...formData,
        employee_role: "woman-entrepreneur",
      };
      this.props.api.apiCreateEmployee(payload).then((res) => {
        console.log({ apiCreateEmployee: res });
        alert("Women Entrepreneur Created Successfully!!");
        navigate("/womenentrepreneur");
      });
    }
  }

  getBack() {
    let back;
    if (
      Object.keys(this.props.urlparams).includes("emp_id") &&
      !this.props.urlparams.readonly
    ) {
      back = `Women Entrepreneur / ${this.props?.urlparams?.emp_name} / Edit Personal Info.`;
    } else if (this.props.urlparams.readonly === "true") {
      back = `Women Entrepreneur / ${this.props?.urlparams?.emp_name}`;
    } else {
      back = "Dashboard / Women Entrepreneur";
    }
    return back;
  }
  getBackLink() {
    let backLink;
    if (Object.keys(this.props.urlparams).includes("emp_id")) {
      backLink = `/womenentrepreneur?${Object.QueryString({
        emp_id: this.props.urlparams.emp_id,
        emp_name: this.props.urlparams.emp_name,
      })}`;
    } else {
      backLink = "/womenentrepreneur";
    }

    return backLink;
  }

  render() {
    return (
      <LayoutWrapper
        title="Women Entrepreneur"
        back={this.getBack()}
        backlink={this.getBackLink()}
      >
        <BasicDetails
          onSubmit={(...args) => this.handleSubmit(...args)}
        ></BasicDetails>
      </LayoutWrapper>
    );
  }
}

export default connect(OnBoarding);

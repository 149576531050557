export default {
    ROLE_LIST: [
        {
            id: 1,
            role: 'Role 1',
        },
        {
            id: 2,
            role: 'Role 2',
        }
    ],
    PROJECT_LIST: [
        {
            id: 1,
            project_name: "Project Name 1",
            project_start_date: new Date().toDate(),
            project_end_date: new Date().toDate(),
            attachments: "1",
            project_status: "completed",
            project_description: "Project Description"
        },
        {
            id: 2,
            project_name: "Project Name 2",
            project_start_date: new Date().toDate(),
            project_end_date: new Date().toDate(),
            attachments: "1",
            project_status: "onprogress",
            project_description: "Project Description"
        },
        {
            id: 3,
            project_name: "Project Name 3",
            project_start_date: new Date().toDate(),
            project_end_date: new Date().toDate(),
            attachments: "1",
            project_status: "initialized",
            project_description: "Project Description"
        }
    ],
    FINANCIAL_YEAR_LIST: [
        {
            id: 1,
            financial_year_name: "FY 2022-23",
            financial_year: new Date().getFullYear().toString(),
            comments: "Comments Here",
        },
        {
            id: 2,
            financial_year_name: "FY 2021-22",
            financial_year: '2022',
            comments: "Some comments here",
        }
    ],
    TASK_LIST: [
        {
            id: 1,
            task_name: "Task Name 1",
            task_start_date: new Date().toDate(),
            task_end_date: new Date().toDate(),
            attachments: "1",
            task_priority: "high",
            task_status: "inprogress",
        }
    ],
    BUDGET_LIST: [
        {
            budget_name: "Budget 1",
            task_id: 1,
            unit_of_mesure: "monthly",
            budget_amount: 100,
            anual_budget_amount: 100 * 12,
            comments: "Some comments"
        },
        {
            budget_name: "Budget 1",
            task_id: 2,
            unit_of_mesure: "quarterly",
            budget_amount: 250,
            anual_budget_amount: 250 * 4,
            comments: "Some comments"
        }
    ],
    EXPENCES_LIST: [
        {
            expense_name: "Name",
            expense_category: "Category",
            expense_amount: "100",
            expense_date: new Date().toDate(),
            project_name: "Project Name",
            task_name: "Task Name",
            attachments: "1",
            approval_status: "approved"
        }
    ],
    PROJECT_RESOURCES: [
        {
            resource_type: "material",
            role: "test role",
            employee_id: "EMP001",
            material_id: "material ABC",
            project_onboarding_date: new Date().toDate(),
            project_offboarding_date: new Date().toDate(),
            task_id: "TSK001",
        }

    ],
    REPORT_DATA: [
        {
            task_id: "TASK101",
            m1_expense: "50,000",
            m1_budget: "100,000",
            m2_expense: "50,000",
            m2_budget: "100,000",
            m3_expense: "50,000",
            m3_budget: "100,000",
            m4_expense: "50,000",
            m4_budget: "100,000",
            m5_expense: "50,000",
            m5_budget: "100,000",
            m6_expense: "50,000",
            m6_budget: "100,000",
            m7_expense: "50,000",
            m7_budget: "100,000",
            m8_expense: "50,000",
            m8_budget: "100,000",
            m9_expense: "50,000",
            m9_budget: "100,000",
            m10_expense: "50,000",
            m10_budget: "100,000",
            m11_expense: "50,000",
            m11_budget: "100,000",
            m12_expense: "50,000",
            m12_budget: "100,000",
            q1_expense: "1,50,000",
            q1_budget: "3,00,000",
            q2_expense: "1,50,000",
            q2_budget: "3,00,000",
            q3_expense: "1,50,000",
            q3_budget: "3,00,000",
            q4_expense: "1,50,000",
            q4_budget: "3,00,000",
            annual_budget: 0,
            annual_expense: 1000,
        },
        {
            task_id: "TASK102",
            m1_expense: "50,000",
            m1_budget: "100,000",
            m2_expense: "50,000",
            m2_budget: "100,000",
            m3_expense: "50,000",
            m3_budget: "100,000",
            m4_expense: "50,000",
            m4_budget: "100,000",
            m5_expense: "50,000",
            m5_budget: "100,000",
            m6_expense: "50,000",
            m6_budget: "100,000",
            m7_expense: "50,000",
            m7_budget: "100,000",
            m8_expense: "50,000",
            m8_budget: "100,000",
            m9_expense: "50,000",
            m9_budget: "100,000",
            m10_expense: "50,000",
            m10_budget: "100,000",
            m11_expense: "50,000",
            m11_budget: "100,000",
            m12_expense: "50,000",
            m12_budget: "100,000",
            q1_expense: "1,50,000",
            q1_budget: "3,00,000",
            q2_expense: "1,50,000",
            q2_budget: "3,00,000",
            q3_expense: "1,50,000",
            q3_budget: "3,00,000",
            q4_expense: "1,50,000",
            q4_budget: "3,00,000",
            annual_budget: 0,
            annual_expense: 1000,
        },]
}
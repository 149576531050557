import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import mixins from "../mixins";

export class ExceptionApprovals extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      form: React.createRef(),
    };
    this.state = {
      rowcount: 0,
      attendanceData: [],
      search: {
        page: 1,
        limit: 10,
      },
      pageCount: 0,
    };
    this.fields = [
      {
        name: "employeeName",
        label: "Employee Name",
        type: "text",
      },
      {
        name: "employeeId",
        label: "Employee ID",
        type: "text",
      },
      {
        name: "location",
        label: "Location",
        type: "select",
        options: "worklocationList",
        className: "select-focus",
      },
      {
        name: "status",
        label: "Status",
        type: "select",
        options: [
          { label: "ALL", key: "" },
          ...["PENDING", "APPROVED", "REJECTED"].options("", "", (v) => v),
        ],
        defaultValue: props.urlparams.status || "",
        className: "select-focus",
      },
    ];
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        dataIndex: "name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <a href="#">
              {text}
              <span>{record.role}</span>
            </a>
          </h2>
        ),
      },
      {
        dataIndex: "exception_date",
        render: (text, record) =>
          (text || "").date().moment().format("DD MMM YYYY"),
      },
      {
        dataIndex: "remark",
        render: (text, record) => (
          <div>
            <span>{text}</span>
            <img
              src={imagepaths.pencilplus}
              className={"h-5 w-5 float-right cursor-pointer"}
              onClick={() => this.getUpdateValue(record, "remark")}
            />
          </div>
        ),
      },
      {
        dataIndex: "role",
        display: false,
      },
      {
        dataIndex: "status",
        render: (text, record) => {
          let status = ["APPROVED", "PENDING", "REJECTED"];
          let icons = {
            APPROVED: "text-success",
            PENDING: "text-danger",
            REJECTED: "text-info",
          };
          return (
            <div className="dropdown action-label text-center">
              <a
                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className={Object.className({
                    "fa fa-dot-circle-o": true,
                    [icons[text]]: true,
                  })}
                />
                {text.toLowerCase().toTitleCase()}
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {status.map((value) => (
                  <div
                    className="dropdown-item"
                    onClick={() => this.updateValue(value, record, "status")}
                  >
                    <i className={"fa fa-dot-circle-o " + icons[value]} />
                    {value.toLowerCase().toTitleCase()}
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    ];
    this.links = mixins.menulinks;
  }

  getUpdateValue(record, name) {
    prompt(`Please enter the ${name} value that need to update`, {
      defaultValue: record[name],
    }).promise.then((value) => {
      this.updateValue(value, record, name);
    });
  }

  updateValue(value, record, name) {
    confirm(`Updating the ${name} of request...?`).promise.then(() => {
      this.props.api
        .apiUpdateExceptionRequest(
          { [name]: value },
          {
            req_id: record.id,
          }
        )
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  componentDidMount() {
    window.ExceptionApprovals = this;
    this.fetchData();
  }

  fetchData() {
    let payload = this.refs.form.form.current.getData();
    console.log({ payload, search: this.state.search });
    this.props.api
      .apiExceptionApprovalList({
        ...payload,
        ...this.state.search,
      })
      .then((res) => {
        console.log(res);
        this.setState({
          rowcount: res.total_count,
          attendanceData: res.data,
          pageCount: res.page_count,
        });
      });
  }
  fetchFilterData() {
    this.setState(
      {
        search: {
          page: "",
          limit: "",
        },
      },
      () => this.fetchData()
    );
  }

  onPageChange(pageData) {
    let payload = {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchData()
    );
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    let { columns, links } = this;
    let { attendanceData } = this.state;
    return (
      <LayoutWrapper title="Exception Approvals" back="Team Attendance">
        <AppLinkTabs data={links}></AppLinkTabs>
        <AppForm className="row filter-row" ref="form">
          {this.fields.map((field, i) => (
            <div className="col-sm-6 col-md-2" key={i}>
              <AppInputFocus {...field}></AppInputFocus>
            </div>
          ))}
          <div className="col-sm-6 col-md-1"></div>
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.fetchFilterData()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <div className="row">
          <div className="col">
            <AppTable
              data={attendanceData}
              columns={columns}
              deletable={false}
              editable={false}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              onChange={(...arg) => this.onPageChange(...arg)}
              pageSize={10}
              page={this.state.search.page}
              pageCount={this.state.pageCount}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(ExceptionApprovals);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../../LayoutWrapper";

export class Module extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Module");
    this.state = {
      data: [],
      modulesData: [],
      baseroute: "",
      sessiondetails: {},
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      accordionlinks: [],
    };
  }

  componentDidMount() {
    this.fetchList();
    this.setState({
      sessiondetails: {
        course_id: this.props.urlparams.course_id,
        course_name: this.props.urlparams.course_name,
        pathFromModule: false,
      },
    });
  }

  fetchList() {
    if (this.props.urlparams.course_id) {
      let payload = {
        course_id: this.props.urlparams.course_id,
      };
      this.props.api
        .apiGetModules(this.state.search, payload)
        .then(({ data, total }) => {
          let modifiedData = [];
          if (data.length > 0) {
            data.forEach((obj, i) => {
              this.props.api
                .apiGetModuleStatusById({
                  module_id: obj.module_id,
                })
                .then((res) => {
                  modifiedData.push({
                    ...obj,
                    completion_status: Math.round(res[0].completion_status),
                  });
                  this.setState({
                    modulesData: modifiedData,
                  });
                });
            });
          }
        });
    }
  }

  handleEdit(e, index) {
    e.preventDefault();
    e.stopPropagation();
    console.log("edit modules", index);
  }

  handleDelete(e, index) {
    e.preventDefault();
    e.stopPropagation();
    console.log("delete modules", index);
  }

  handleToggle(checked) {
    console.log("toggle modules:", checked);
  }

  handleRoute(e, type) {
    e.preventDefault();
    e.stopPropagation();
    console.log("link type:", type);

    let link;
    switch (type) {
      case "notes":
        link = `/lms/courses/module/assessmentspage?course_id=${this.props.urlparams.course_id}`;
        break;

      default:
        link = `/lms/courses/module/videopage?course_id=${this.props.urlparams.course_id}`;
        break;
    }
    this.setState({ baseroute: link });
    this.props.navigate(link);
  }
  getBreadCums() {
    if (this.props.urlparams.course_id) {
      return `Courses / ${this.props.urlparams.course_name
        ?.spaceCase()
        .toTitleCase()}`;
    } else {
      return "Courses";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id) {
      let backLink = `/lms/courses`;
      return backLink;
    } else {
      return "/lms/courses";
    }
  }

  render() {
    let { modulesData, accordionlinks } = this.state;
    return (
      <LayoutWrapper
        title="Course Modules"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppAccordian
          data={modulesData?.map((item, i) => ({
            Id: item.module_id && (() => item.module_id),
            Title: item.module_name && (() => item.module_name),
            Status: item.status && (() => item.status),
            Percent: item.completion_status && (() => item.completion_status),
            Content: item.module_description && (() => item.module_description),
            // Links: (() => accordionlinks),
            accordionlinks,
            record: item,
            sessiondetails:
              this.state.sessiondetails && this.state.sessiondetails,
          }))}
          crudaction={false}
          toggleaction={false}
          statusaction={false}
          targetType="route"
          baseroute={this.state.baseroute}
          getlinktype={(...args) => this.handleRoute(...args)}
          showlinks={true}
        ></AppAccordian>
        {modulesData.length == 0 && <div className="empty_layout"></div>}
      </LayoutWrapper>
    );
  }
}

export default connect(Module);

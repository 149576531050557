import React, { Component } from "react";
import { ProfileCard, ColFields, ActionTag } from "./employee-profile";
import { SalaryDetailsFields } from "../../employee/Addemployee/SalaryDetails";
export class SalaryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  labelMaps = {
    ctc: "CTC",
    hra: "HRA",
    pf: "PF",
    tds: "TDS",
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.api.apiGetProfileSalaryDetails().then((data) => {
      this.setState((state) => ({
        ...state,
        data,
      }));
    });
  }

  valReducer(value, label) {
    return Number(value).cur();
  }

  render() {
    let { labelMaps, props, valReducer } = this;
    let { onEdit } = props;
    let { data } = this.state;
    let fields = SalaryDetailsFields.map((o) => ({
      ...o,
      className: "",
    }));
    let config = {
      updateapi: "apiUpdateEmployeeProfilePatchSalary",
      component: this,
    };
    return (
      <ProfileCard
        ActionTag={ActionTag}
        onEdit={() => onEdit(data, fields, "Salary Details", config)}
      >
        <div className="mb-4 flex">
          <div>
            <img className="h-10 w-10 m-auto" src={imagepaths.money} />
          </div>
          <div className="text-xl mx-2 font-semibold m-auto">
            Salary Details
          </div>
        </div>
        <ColFields
          data={data}
          labelMaps={labelMaps}
          size={2}
          valueReduce={valReducer}
          colclass="col-12 col-md-6"
        />
      </ProfileCard>
    );
  }
}

export default connect(SalaryDetails);

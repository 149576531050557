import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";

export class Project extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Project");
    this.state = {
      editData: {},
      projectEndDate: new Date(),
    };
    this.formFields = [
      {
        type: "text",
        name: "project_name",
        label: "Project Name",
        maxLength: "50",
        required: true,
      },
      {
        type: "date",
        name: "project_start_date",
        label: "Project Start Date",
        onChange: (date) => {
          console.log("start date value", date.getTime());
          let { editData } = this.state;
          editData["project_start_date"] = date.toDate();
          editData["project_end_date"] = date.addDays().toDate();
          this.setState({
            editData: { ...editData },
          });
        },
        required: true,
      },
      {
        type: "date",
        name: "project_end_date",
        label: "Project End Date",
        disabledDates: (date) => {
          let { editData } = this.state;
          let startDate = editData["project_start_date"];
          return date.getTime() < new Date(startDate).toDate().date().getTime();
        },
        required: true,
        defaultValue: new Date(this.state.startDate).toDate(),
      },
      {
        type: "file",
        name: "attachments",
        label: "Attachments (optional)",
        fileType: "project_file",
      },
      {
        type: "select",
        name: "project_status",
        label: "Project Status",
        options: _enum.PM_PROJECT_STATUS,
        required: true,
      },
      {
        type: "textarea",
        label: "Project Description (Max 250 words)",
        name: "project_description",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12",
      },
    ];
    this.tablinks = mixins.tablinks;
  }

  get has_edit() {
    return !!this.props.urlparams.project_id;
  }
  sumitForm(event, data) {
    if (this.mixin.params().project_id) {
      console.log({ "submit form data: ": data });
      let payload = {
        ...data,
        project_id: this.props.urlparams.project_id,
      };
      console.log("readonly: ", this.props.urlparams.readonly);
      this.api.apiEditProject(payload).then((data) => {
        let nextLink =
          "/projectmanagement/addfinancialyear" +
          Object.encodeuri({
            project_id: data?.data.project_id,
            ...(this.props.urlparams.readonly
              ? { readonly: this.props.urlparams.readonly }
              : {}),
          });
        this.props.navigate(nextLink);
      });
    } else {
      data = {
        ...data,
        project_status: "open",
      };

      this.api.apiAddProject(data).then((res) => {
        // console.log({ apiAddProject: res });
        let nextLink =
          "/projectmanagement/addfinancialyear?" +
          Object.encodeuri({
            new: this.props.urlparams.new,
            project_id: res?.data.project_id,
            ...(this.props.urlparams.readonly
              ? { readonly: this.props.urlparams.readonly }
              : {}),
          });

        this.props.navigate(nextLink);
      });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    if (this.props.urlparams.project_id) {
      this.api.apiGetProject().then((data) => {
        console.log({ editData: data });
        this.setState({ editData: data });
      });
    }
  }

  render() {
    let { tablinks, formFields, state, has_edit } = this;
    let { editData } = state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else if (field.type == "file") {
          if (
            editData[field.name] &&
            editData[field.name] instanceof Array &&
            editData[field.name].length
          ) {
            field.defaultValue = editData[field.name][0].attachmentId;
            field.filename = editData[field.name][0].attachmentName;
          }
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      if (field.name == "project_status") {
        if (this.mixin.params().new) {
          field = {
            ...field,
            defaultValue: "open",
          };
        }
      }
      return field;
    });
    console.log({ formFields });
    return (
      <LayoutWrapper title="Project" back="Create Project">
        {/* <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs> */}
        <AppCircleStepper data={tablinks}></AppCircleStepper>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <AppForm
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.sumitForm(...args)}
          >
            <div className="row">
              {formFields?.map((field, i) => (
                <div
                  className={
                    field.fieldclass
                      ? field.fieldclass
                      : "col-12 col-md-6 col-lg-4"
                  }
                  key={"field" + i}
                >
                  <AppInput
                    {...field}
                    {...(field.name === "project_status" &&
                    this.mixin.params().new
                      ? {
                          disabled: true,
                        }
                      : { disabled: this.props.urlparams.readonly })}
                  ></AppInput>
                </div>
              ))}
            </div>
            <div className="row no-gutters">
              {this.props.urlparams.readonly ? (
                <>
                  <Link
                    to={"/projectmanagement"}
                    className="btn btn_primary col col-md-1 ml-auto grey ml-auto me-2"
                  >
                    Cancel
                  </Link>
                  <a
                    href={`${window.location.pathname}?project_id=${this.props.urlparams.project_id}`}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Edit
                  </a>
                  <button className="btn add-btn col col-md-1">Next</button>
                </>
              ) : (
                <>
                  <button className="btn add-btn col col-md-1 ml-auto">
                    Save & Next
                  </button>
                  <Link
                    to={"/projectmanagement"}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Cancel
                  </Link>
                </>
              )}
            </div>
          </AppForm>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Project);

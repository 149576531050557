import React, { Component } from "react";
import { Link } from "react-router-dom";
import EmployeeSearch from "./EmployeeSearch";
import EmployeeHeader from "./EmployeeHeader";

export const EmployeeCard = (props) => (
  <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
    <div className="profile-widget">
      <div className="profile-img">
        <Link
          to={"/profile/employee-profile/" + props.employee_id}
          className="avatar"
        >
          <img src={props.image} alt={"Image " + props.name} />
        </Link>
      </div>
      <div className="dropdown profile-action">
        <a
          href="#"
          className="action-icon dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a
            className="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#edit_employee"
          >
            <i className="fa fa-pencil m-r-5" /> Edit
          </a>
          <a
            className="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#delete_employee"
          >
            <i className="fa fa-trash-o m-r-5" /> Delete
          </a>
        </div>
      </div>
      <h4 className="user-name m-t-10 mb-0 text-ellipsis">
        <Link to={"/profile/employee-profile/" + props.employee_id}>
          {props.name}
        </Link>
      </h4>
      <div className="small text-muted">{props.role}</div>
    </div>
  </div>
);

export class EmployeeGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeData: [],
    };
  }

  componentDidMount() {
    const {
      Avatar_05,
      Avatar_02,
      Avatar_11,
      Avatar_12,
      Avatar_09,
      Avatar_10,
      Avatar_13,
    } = imagepaths;
    this.props.api.apiEmployeeData().then((data) => {
      this.setState({
        employeeData: data,
      });
    });
  }

  render() {
    return (
      <>
        {/* Page Header */}
        <EmployeeHeader />
        {/* /Page Header */}
        {/* Search Filter */}
        <EmployeeSearch />
        {/* Search Filter */}
        <div className="row staff-grid-row">
          {this.state.employeeData.map((o) => (
            <EmployeeCard key={o.id} {...o} />
          ))}
        </div>
      </>
    );
  }
}

export default connect(EmployeeGrid);

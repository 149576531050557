import React, { Component } from 'react';
import LayoutWrapper from '../../LayoutWrapper';

export class PayrollStatutary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowcount:0,
            search: {
                page:1
            },
            payrollStatuaryData: []
        }
        this.fields = [
            {
                name: "employee_id",
                label: "Employee ID",
                type: "text"
            },
            {
                name: "name",
                label: "Employee Name",
                type: "text"
            },
            {
                name: "location",
                label: "Location",
                type: "select",
                options: "worklocationList",
                className: "select-focus"
            }
        ];
        this.columns = [
            {
                dataIndex:"id",
                display:false
            },
            {
                title:"Name",
                dataIndex: "name",
                render: (text, record) => (
                    <h2 className="table-avatar">
                        <a href='#'>
                            {text}
                            <span>{record.role}</span>
                        </a>
                    </h2>
                ),
            },
            {
                title:"Date Of Joining",
                dataIndex:"doj",
                render:text=>text.moment().format("DD MMM YYYY")
            },
        ];
    }

    componentDidMount() {
        window.StatutaryList =  this;
        this.fetchData();
    }

    fetchData() {
        let data = {
            ...this.refs.search.form.current.getData(),
            ...this.state.search
        };
        this.props.api.apiPayrollStatutaryList(data).then((responce) => {
            this.setState({
                rowcount:responce.total_count,
                payrollStatuaryData: responce.data
            });
        });
    }

    onPrev() {
        this.setState(state=>({search:{...state.search,page:state.search.page-1}}),()=>{
            this.fetchData();
        });
    }

    onNext() {
        this.setState(state=>({search:{...state.search,page:state.search.page+1}}),()=>{
            this.fetchData();
        });
    }

    render() {
        let { columns } = this;
        let { payrollStatuaryData, rowcount } = this.state;
        return (
            <LayoutWrapper title="Statutory Data" back="Payroll">
                <AppForm className="row filter-row" ref={"search"}>
                    {
                        this.fields.map((field, i) => (
                            <div className="col-sm-6 col-md-3" key={i} >
                                <AppInputFocus
                                    {...field}
                                >
                                </AppInputFocus>
                            </div>
                        ))
                    }
                    <div className="col-sm-6 col-md-3">
                        <button className="btn btn-success btn-block w-100" onClick={()=>this.fetchData()}> Search </button>
                    </div>
                </AppForm>
                <div className='row'>
                    <div className='col'>
                        <AppTable
                            data={payrollStatuaryData}
                            columns={columns}
                            total={rowcount}
                            onNext={()=>this.onNext()}
                            onPrev={()=>this.onPrev()}
                            editable={false}
                            deletable={false}
                        >
                        </AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default connect(PayrollStatutary);
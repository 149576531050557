import { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import "./preview.scss";
import { Link } from "react-router-dom";

export class Preview extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Preview");
    this.state = {
      videosData: [],
      pptData: [],
      assessmentData: [],
      moduleData: {},
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      // this.props.api.apiGetVideosPreviewDataMoke().then((res) => {
      //   this.setState({
      //     videosData: res,
      //   });
      //   resolve();
      // });
      // this.props.api.apiGetPPTPreviewDataMoke().then((res) => {
      //   this.setState({
      //     pptData: res,
      //   });
      //   resolve();
      // });
      // this.props.api.apiGetAssessmentsPreviewDataMoke().then((res) => {
      //   this.setState({
      //     assessmentData: res,
      //   });
      //   resolve();
      // });
      let payload = {
        ...this.state.search,
      };
      this.props.api
        .apiGetAllMedia(payload, { module_id: this.props.urlparams.module_id })
        .then(({ data, total }) => {
          console.log({ videosData: data });
          this.setState({
            videosData: data,
          });
          resolve();
        });
      this.props.api
        .apiGetAllPPT(payload, { module_id: this.props.urlparams.module_id })
        .then(({ data, total }) => {
          this.setState({
            pptData: data,
          });
          resolve();
        });
      this.props.api
        .apiGetAssessmentByModuleId({
          module_id: this.props.urlparams.module_id,
        })
        .then(({ data, total }) => {
          console.log({ apiGetAssessments: data });
          this.setState({
            assessmentData: data,
          });
          resolve();
        });
      this.props.api
        .apiGetModuleById({ module_id: this.props.urlparams.module_id })
        .then((res) => {
          this.setState({ moduleData: res });
        });
    });
  }

  sumitForm(event, data, form) {
    console.log({ previewData: data });
  }

  getBreadCums() {
    if (this.props.urlparams.course_name) {
      return `${this.props.urlparams.course_name
        .spaceCase()
        .toTitleCase()} / Module Details / Videos / PPT / Assessment / Preview`;
    } else {
      return "Course Name / Module Details / Videos / PPT / Assessment / Preview";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.mixin.params().readonly) {
      let backLink = `/lms/module/addassessment?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&module_id=${this.props.urlparams.module_id}`;
      return backLink;
    } else if (this.mixin.params().readonly) {
      let backLink = `/lms/module/addassessment?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&module_id=${this.props.urlparams.module_id}&readonly=${this.props.urlparams.readonly}`;
      return backLink;
    } else {
      return "/lms/module";
    }
  }

  render() {
    let { props } = this;
    let { videosData, pptData, assessmentData, moduleData } = this.state;

    return (
      <LayoutWrapper
        title="Course Configure"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>

        <div className="row bg-white">
          <div className="module_detail_container">
            <h2 className="title">{moduleData.module_name}</h2>
            <p className="sub_title">{moduleData.module_description}</p>

            <div className="media_card">
              <p className="heading">No of Videos - {videosData.length}</p>
              <div className="mini_box_container row g-3">
                {videosData?.map((item, i) => {
                  return (
                    <div className="mini_box col-6 col-md-3" key={i}>
                      <p className="card_title">{item.media_name}</p>
                      <p className="link">Preview Video</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="media_card">
              <p className="heading">No of PPT - {pptData.length}</p>
              <div className="mini_box_container row g-3">
                {pptData?.map((item, i) => {
                  return (
                    <div className="mini_box col-6 col-md-3" key={i}>
                      <p className="card_title">{item.ppt_name}</p>
                      <p className="link">Preview PPT</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="assessment_card">
              <p className="heading">
                No of Assessments - {assessmentData.length}
              </p>
              <div className="assessment_box_container">
                {assessmentData?.map((item, i) => {
                  return (
                    <div className="assessment_box" key={i}>
                      <p className="card_title">{item.assessment_name}</p>
                      {item?.assessment_questions?.map((field, j) => {
                        return (
                          <div className="mb-2" key={j}>
                            <p className="question">
                              <span>Q:</span>
                              {field.q_label}
                            </p>
                            <p className="answer">
                              <span>A:</span>
                              {field.a_value}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 my-5 px-4">
            {this.props.urlparams.readonly ? (
              <>
                <Link
                  to={"/lms"}
                  className="btn btn_primary grey col col-md-1 float-right ms-3"
                >
                  Close
                </Link>
              </>
            ) : (
              <>
                <Link
                  to={
                    "/lms/module/addassessment?" +
                    Object.QueryString({
                      ...this.props.urlparams,
                    })
                  }
                  className="btn btn_primary grey col col-md-1 float-right ms-3"
                >
                  Cancel
                </Link>
                <Link
                  to={
                    "/lms/module?" +
                    Object.QueryString({
                      ...this.props.urlparams,
                    })
                  }
                  className="btn add-btn"
                >
                  Submit
                </Link>
              </>
            )}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Preview);

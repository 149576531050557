import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";

export class DataCollectionFormResPage extends Component {
  constructor(props) {
    // debugger
    super(props);
    props.initialconstruct.bind(this)("DataCollectionFormResPage");
    this.state = {
      form: null,
      rowcount: 0,
      errors: {},
      // rowcount: 0,
      // errors: {},
      // form: {},
      // fields: [{}],
      // fields: [],
      // disabled: _enum.MANDATORY_FIELDS,
    };
    this.errorsMessages = {};
  }

  get errors() {
    return Object.entries(this.errorsMessages)
      .filter(([key, val]) => val)
      .map(([key, val]) => key);
  }

  get isValid() {
    let data = this.formData;
    let keys = Object.keys(data);
    let { fields = [] } = this.state.form || {};
    return keys.reduce((flag, key) => {
      let field = fields.find((o) => o.label == key || o.name == key) || {};
      let constraints = (field.fieldConstraint || "")
        .split(",")
        .filter((v) => v)
        .map((v) => _enum.CONSTRAINTS_DC_MAP[v]);
      let value = data[key];
      let valid = constraints.reduce(
        (flag, v) =>
          flag &&
          ((typeof v == "string" && value.match(new RegExp(v))) ||
            (typeof v == "function" &&
              v(field.fieldType == "date" ? value.date() : value))),
        true
      );
      if (!constraints.includes("mandatory") && !value) {
        valid = true;
      }
      if (!valid) {
        this.errorsMessages[key] = true;
      } else {
        this.errorsMessages[key] = false;
      }
      return flag && !!valid;
    }, true);
  }

  get formData() {
    return this.refs.form.form.current.getData();
  }

  checkLangRender() {
    if (window.langRender) {
      mixins.debounce(() => window.langRender());
    } else {
      setTimeout(() => {
        this.checkLangRender();
      }, 500);
    }
  }

  componentDidMount() {
    let loaded = loader("Loading data please wait....");
    document.body.addEventListener("googleLangugeLoaded", (e) => {
      this.checkLangRender();
    });
    if (this.mixin.params().id) {
      this.api.apiGetDataCollectionForm().then((data) => {
        console.log(data, "dcfResponse data");
        this.store("translateLang", data.language);
        loaded.close();

        console.log(data);

        if (data.disable === true) {
          alert("This form is disabled").promise.then(() => {
            window.close();
          });
        } else {
          this.setState({
            form: data,
          });
        }
      });
    }
  }

  onInputChange(field, event, ...args) {
    let value = "";
    if (event?.nativeEvent instanceof InputEvent) {
      value = event.target.value;
    } else if (event instanceof String) {
      value = event;
    } else if (event instanceof Number) {
      value = "" + event;
    }
    let constraints = field.fieldConstraint
      .split(",")
      .map((v) => _enum.CONSTRAINTS_DC_MAP[v]);
    let valid = constraints.reduce(
      (flag, v) => flag && value.match(new RegExp(v)),
      true
    );
    if (!valid) {
    }
    app.mixin.debounce(() => {
      this.setState({
        errors: {
          ...this.state.errors,
          [field.label]: !valid,
        },
      });
    }, 500);
  }

  onFieldFocus(field) {
    langRender();
  }

  isCheckboxOrRadio(field) {
    return ["checkbox", "radio"].includes(field.fieldType);
  }

  submitForm(e, data, form) {
    if (!this.isValid) {
      alert("Please fill valid values in " + this.errors.join(","));
      langRender();
      return;
    }
    let parsedPayload = parseInput(data);
    let MANDATORIES = Object.values(_enum.MANDATORY_DC_OBJS);
    let inMandatory = (key) =>
      MANDATORIES.find((o) => o.label == key || o.name == key);
    this.store(`publicdcfcollect.${this.mixin.params().id}`, parsedPayload);
    let mandatories = Object.filter(parsedPayload, (value, key) =>
      inMandatory(key)
    );
    let answers = Object.entries(parsedPayload)
      .filter(([key, value]) => !inMandatory(key))
      .map(([key, value]) => ({
        fieldLabel: key,
        fieldResponse: value,
      }));

    this.api
      .request(this.api.api().saveDCFormResponse, {
        formId: this.mixin.params().id,
        projectId: "645b7eac8ab3f1923c8b5084",
        ...mandatories,
        answers,
      })
      .exec()
      .get()
      .then((res) => {
        if (res.status === 200) {
          success("Successfully saved").promise.then(() => {
            navigate("/public/thankyou");
          });
        } else {
          alert("Error: " + res.message);
        }
      });
  }

  handelDisabledDates(date, field) {
    let constraints = field.fieldConstraint.split(",");
    let hasFutureDates = constraints.includes("future_dates_only");
    let hasPastDates = constraints.includes("past_dates_only");
    if (hasFutureDates) {
      return _enum.CONSTRAINTS_DC_MAP.future_dates_only(date);
    } else if (hasPastDates) {
      return _enum.CONSTRAINTS_DC_MAP.past_dates_only(date);
    }
    return false;
  }

  render() {
    let { state } = this;
    let { form, errors } = state;
    return (
      <LayoutWrapper>
        <div className="d-flex align-items-center justify-content-center">
          {form && (
            <AppForm
              className="card m-auto my-5 p-4 w-full public_form"
              onSubmit={(...args) => this.submitForm(...args)}
              ref="form"
            >
              {form.fields.map((field, i) => (
                <div key={field.label + "-" + i} className="grow">
                  <>
                    {this.isCheckboxOrRadio(field) && (
                      <div className="list-group option_box">
                        <AppInput
                          type="label"
                          required={field.fieldConstraint.includes("mandatory")}
                          label={field.label}
                        >
                          {field.possibleValues.split(",").map((option, j) => (
                            <AppInput
                              key={"option_" + i + "-" + j}
                              type={field.fieldType}
                              className="list-group-item"
                              name={field.label}
                              value={option}
                            >
                              {option}
                            </AppInput>
                          ))}
                        </AppInput>
                      </div>
                    )}
                    {!this.isCheckboxOrRadio(field) && (
                      <AppInput
                        title={field.fieldConstraint}
                        type={field.fieldType}
                        name={field.name || field.label}
                        label={field.label}
                        className="list-group option_box"
                        {...(field.fieldType == "select"
                          ? {
                              onDropdownOpen: () => this.onFieldFocus(field),
                              onDropdownClose: () => this.onFieldFocus(field),
                            }
                          : {})}
                        {...(field.fieldType == "date"
                          ? {
                              disabledDates: (date) =>
                                this.handelDisabledDates(date, field),
                            }
                          : {})}
                        {...(field.fieldType == "file"
                          ? {
                              fileType: "data_collection_form",
                            }
                          : {})}
                        options={
                          field.possibleValues
                            ? field.possibleValues
                                .split(",")
                                .options("", "", (v) => v)
                            : []
                        }
                        required={field.fieldConstraint.includes("mandatory")}
                        onChange={(...args) =>
                          this.onInputChange(field, ...args)
                        }
                      ></AppInput>
                    )}
                  </>
                </div>
              ))}
              <div className="mt-2 flex">
                <button className="btn add-btn m-auto">Submit</button>
              </div>
            </AppForm>
          )}
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(DataCollectionFormResPage);

import React, { Component } from "react";
import { Link } from "react-router-dom";

export const GapFields = ({
  field,
  i,
  className = "col-12 col-md-3 px-2 ",
  readOnly,
}) => (
  <>
    {/* {console.log(readOnly)} */}
    <AppInput
      {...field}
      className={className + (field?.className || "")}
      key={i}
      disabled={readOnly || field.disabled}
    ></AppInput>
    {i != 0 && i % 3 == 2 && (
      <div className="col-12 col-md-3 px-2" key={"div" + i}></div>
    )}
  </>
);

class BasicDetails extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("BasicDetails");
    this.state = {
      editData: {},
      districts: [],
      clusters: [],
      villageData: [],
      villageNamesData: [],
      villageNames: [],
      supervisorOptions: [],
      employeeAge: "",
      supervisorID: "",
      supervisorsList: [],
      selectedSupervisorName: [],
      search: {
        // limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        district: "",
        cluster: "",
      },
      addrole: {
        role_type: "digital-sakhi",
      },
      isFormValid: true,
    };
    this.fields = [
      {
        type: "select",
        name: "employee_role",
        label: "Role",
        options: _enum.EMP_ROLES_FOR_HRMS,
        // defaultValue: "digital-sakhi",
        onChange: (v) => this.handleEmployeeRoleChange(v),
        required: true,
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "employee_id",
        label: "DS ID",
        onChange: (v) => this.handleEmployeeIdChange(v),
        required: true,
        rule: () => this.state.addrole.role_type == "digital-sakhi",
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "employee_id",
        label: "Project Manager Code",
        onChange: (v) => this.handleEmployeeIdChange(v),
        required: true,
        rule: () => this.state.addrole.role_type == "manager",
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "employee_id",
        label: "Cluster Co-ordinator Code",
        onChange: (v) => this.handleEmployeeIdChange(v),
        required: true,
        rule: () => this.state.addrole.role_type == "cluster-co-ordinator",
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "employee_id",
        label: "Women Entreprenuer Code",
        onChange: (v) => this.handleEmployeeIdChange(v),
        required: true,
        rule: () => this.state.addrole.role_type == "woman-entrepreneur",
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "employee_name",
        label: "Name",
        required: true,
      },
      {
        type: "select",
        label: "Gender",
        name: "gender",
        options: ["Male", "Female", "Others"].options(),
        required: true,
      },
      {
        type: "date",
        name: "date_of_birth",
        label: "Date Of Birth",
        onChange: function (value) {
          let age = document.querySelector(`input[name='age']`);
          let ageValue = new Date(value).getAge();
          if (age) {
            age.value = ageValue;
          }
        },
        disabledDates: (date) => {
          return date.getAge() < 18;
        },
        defaultValue: new Date().setAge(18),
        required: true,
      },
      {
        type: "text",
        name: "age",
        label: "Age",
        min: 18,
        max: 80,
        defaultValue: 18,
        required: true,
        disabled: true,
      },
      {
        type: "text",
        name: "mobile_num",
        label: "Mobile Number",
        pattern: "[0-9]{10}",
        onInvalid: (e) =>
          e.target.setError(
            "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
          ),
        onInput: (e) => e.target.setMessage(),
        required: true,
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "date",
        name: "date_of_joining",
        label: "Date Of Joining",
        disabledDates: (date) =>
          date.getTime() > new Date().toDate().date().getTime(),
        required: true,
      },
      {
        type: "email",
        name: "email_address",
        label: "Email ID",
        required: true,
        disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "temp_password",
        label: "Temprorary Password",
        disabled: this.props.urlparams.emp_id && true,
        // required: true,
        // rule: () => this.state.addrole.role_type == "digital-sakhi",
      },
      {
        type: "number",
        name: "monthly_salary",
        label: "Monthly Salary",
      },
      {
        type: "select",
        name: "supervisor_name",
        label: "Supervisor Name",
        options: [],
        onChange: (...args) => this.handleSupervisorChange(...args),
        required: true,
        // disabled: this.props.urlparams.emp_id && true,
      },
      {
        type: "text",
        name: "supervisor_id",
        label: "Supervisor ID",
        required: true,
        readOnly: true,
      },
      {
        type: "select",
        name: "district",
        label: "District",
        options: [],
        required: true,
        rule: () =>
          this.state.addrole.role_type == "cluster-co-ordinator" ||
          this.state.addrole.role_type == "digital-sakhi",
        onChange: (...args) => this.handleDistrictChange(...args),
      },
      {
        type: "select",
        name: "cluster_code",
        label: "Cluster Code",
        options: [],
        required: true,
        rule: () =>
          this.state.addrole.role_type == "cluster-co-ordinator" ||
          this.state.addrole.role_type == "digital-sakhi",
        onChange: (...args) => this.handleClusterCodeChange(...args),
      },
      {
        type: "select",
        name: "village_id",
        label: "Village ID",
        // options: _enum.VIILAGE_IDS,
        options: [],
        multi: true,
        defaultValue: [],
        required: true,
        rule: () => this.state.addrole.role_type == "digital-sakhi",
        onChange: (...args) => this.handleVillageIdChange(...args),
      },
      {
        type: "text",
        name: "village_name",
        label: "Village Name",
        required: true,
        rule: () => this.state.addrole.role_type == "digital-sakhi",
      },
      {
        type: "textarea",
        name: "address",
        label: "Address",
        className: "col-md-12",
        controlClass: "!min-h-[150px]",
        required: true,
        rule: () =>
          this.state.addrole.role_type == "digital-sakhi" ||
          this.state.addrole.role_type == "cluster-co-ordinator" ||
          this.state.addrole.role_type == "manager",
      },
    ];
  }
  componentDidMount() {
    this.fetchDistrict();
    // console.log("Mounted Basic Details");
    this.getEditData();
  }
  getEditData() {
    if (this.props.urlparams.emp_id) {
      // console.log("edit flow");

      let payload = {
        employee_id: this.props.urlparams.emp_id,
      };

      this.api.apiGetEmployeeById(payload).then(({ data }) => {
        // console.log({ editData: data });
        this.setState({ editData: data });
        this.getSupervisors(data["employee_role"]);
      });
    }
  }

  fetchDistrict() {
    let payload = {
      ...this.state.search,
    };
    this.api.getDistrict(payload).then((res) => {
      // console.log({ getDistrict: data });
      this.setState({ districts: res.data });
    });
  }

  handleDistrictChange([value]) {
    // console.log({ handleDistrictChange: value });
    this.setState({
      search: {
        ...this.state.search,
        district: value.key,
      },
    });
    this.fetchCluster(value.key);
  }

  fetchCluster(district) {
    let payload = {
      ...Object.except(this.state.search, ["search"]),
    };
    this.api.getCluster(payload, { district: district }).then(({ data }) => {
      this.setState({ clusters: data });
    });
  }

  handleClusterCodeChange([value]) {
    let { search } = this.state;
    if (!value) {
      return;
    }
    this.setState({
      search: {
        ...this.state.search,
        cluster: value.key,
      },
    });

    let payload = {
      ...Object.except(this.state.search, ["district", "search", "cluster"]),
    };

    this.api
      .getVillageList(payload, {
        district: this.state.search.district,
        cluster: value.key,
      })
      .then((res) => {
        let options = res?.data?.map((item) => {
          return {
            key: item.village_id,
            label: item.village_name,
          };
        });
        let optionsWithKey = res?.data?.map((item) => {
          return {
            key: item.village_id?.trim(),
            label: item.village_id?.trim(),
          };
        });

        this.setState({ villageData: optionsWithKey });
        this.setState({ villageNamesData: options });
      });
  }

  handleVillageIdChange(values) {
    // console.log({ handleVillageIdChange: values });

    let villageNamesArr = [];

    // console.log("villageNamesData", this.state.villageNamesData);

    const filteredVillageNames = this.state.villageNamesData.filter((el) => {
      return values.some((f) => {
        return f.key === el.key;
      });
    });

    filteredVillageNames.map((item) => {
      villageNamesArr.push(item.label);
    });
    // console.log("villageNamesArr", villageNamesArr);
    this.setState({
      editData: {
        ...this.state.editData,
        village_name: villageNamesArr,
      },
      villageNames: villageNamesArr,
    });
  }

  handleEmployeeRoleChange([val]) {
    // console.log({ val });
    if (val) {
      this.setState((state) => ({
        ...state,
        clusters: [],
        villageData: [],
        villageNamesData: [],
        villageNames: [],
        supervisorOptions: [],
        addrole: {
          role_type: val.key,
        },
      }));
      let supervisorName = document.querySelector(
        "[name='appinput-supervisor_name'].form-group"
      );
      console.log(supervisorName);
      let { values: supervisorNames = [] } =
        supervisorName.vnode.refs.select.state;
      if (supervisorNames.length) {
        supervisorName.vnode.refs.select.clearAll();
      }
      this.getSupervisors(val.key);
      // console.log({
      //   supervisorNames,
      //   val,
      // });
    }
  }

  handleEmployeeIdChange(e) {
    let value = e.target.value;
    const pattern = /^[^\\/]*$/;
    const isValid = pattern.test(value);

    console.log(isValid);
    var input = document.querySelector("#errMsgFor-employee_id");
    if (!isValid) {
      this.setState({
        isFormValid: false,
      });
      input.classList.remove("d-none");
      input.innerHTML = "/ and \\ are not allowed";
    } else {
      this.setState({
        isFormValid: true,
      });
      input.classList.add("d-none");
      input.innerHTML = "";
    }
  }

  getSupervisors(role) {
    // console.log("getSupervisors role: ", role);

    let roleType = _enum.EMP_ROLE_MAP[role];

    if (roleType) {
      this.api
        .apiGetEmployeeByRole(roleType)
        .then((res) => {
          //console.log("list of supervisors: ", res);

          if (res.length) {
            console.log("list of supervisors: ", res);
            let filteredArr = res.map((value) => {
              return {
                key: value.employee_id,
                label: value.employee_name + ` ( ${value.employee_id} ) `,
              };
            });
            switch (role) {
              case "cluster-co-ordinator":
                this.setState({
                  supervisorOptions: filteredArr,
                });
                break;
              case "manager":
                this.setState({
                  supervisorOptions: filteredArr,
                });
                break;
              default:
                this.setState({
                  supervisorOptions: filteredArr,
                });
                break;
            }

            console.log("filteredArr", filteredArr, role, roleType);
            if (role === "manager") {
              let selectedSuperOpt = filteredArr.filter(
                (v) => v.key === this.state.editData.supervisor_id
              );
              this.setState({
                selectedSupervisorName: selectedSuperOpt,
              });
            } else {
              let selectedSuperOpt = filteredArr.filter(
                (v) => v.key === this.state.editData.supervisor_id
              );
              console.log("selectedOpt: ", selectedSuperOpt);
              this.setState({
                selectedSupervisorName: selectedSuperOpt,
              });
            }
          }
        })
        .catch((err) => {
          // console.log({ err });
          this.props.onError && this.props.onError(err);
          //alert(err.message);
        });
    }
  }

  handleSupervisorChange(val) {
    let { editData } = this.state;
    console.log("handleSupervisorChange", val);
    if (val.length) {
      this.setState({
        editData: {
          ...editData,
          supervisor_id: val[0].key,
        },
        supervisorID: val[0].key,
      });
    } else {
      this.setState({
        supervisorID: "",
      });
    }
  }

  updateFields(fields) {
    fields.find((o) => o.name === "district").options =
      // this.state.districts.options("_id", "district");
      this.state.districts.map((v) => v.district).options();

    fields.find((o) => o.name === "cluster_code").options = this.state.clusters
      .map((v) => v.cluster)
      .options();

    fields.find((o) => o.name === "village_id").options =
      this.state.villageData;

    fields.find((o) => o.name === "village_name").defaultValue =
      this.state.villageNames;

    fields.find((o) => o.name === "supervisor_name").options =
      this.state.supervisorOptions;

    fields.find((o) => o.name === "supervisor_id").defaultValue =
      this.state.supervisorID;

    fields.find((o) => o.name === "age").defaultValue =
      this.state?.editData?.date_of_birth?.datetoAge();

    return fields;
  }
  getBackLink() {
    let backLink;
    if (Object.keys(this.props.urlparams).includes("emp_id")) {
      backLink = `/admin/profile/employee-profile?${Object.QueryString({
        emp_id: this.props.urlparams.emp_id,
        emp_name: this.props.urlparams.emp_name,
      })}`;
    } else {
      backLink = "/admin/employee";
    }

    return backLink;
  }
  render() {
    let { fields } = this;
    let { editData, isFormValid } = this.state;
    fields = this.updateFields(fields);

    // console.log("fields: ", fields);
    // console.log("editData: ", editData);

    if (editData && editData?.supervisor_id) {
      editData["supervisor_name"] = editData["supervisor_id"];
    }

    fields = fields?.map((field) => {
      field.defaultValue = field.defaultValue;

      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      return field;
    });

    return (
      <form
        onSubmit={(...e) => (this.props?.onSubmit || (() => ""))(...e)}
        className={"form"}
        autoComplete="off"
      >
        <div className="row">
          {fields
            ?.filter((field) => (field?.rule || (() => true)).bind(this)())
            .map((field, i) => (
              <GapFields
                {...{ field, i, readOnly: true }}
                key={i}
                readOnly={this.props.urlparams.readonly ? true : false}
              />
            ))}
        </div>
        <div className="flex mt-4">
          <div className="grow"></div>
          <button
            type="submit"
            className={Object.className({
              "btn btn-primary add-btn": true,
              disabled: !isFormValid,
            })}
          >
            {this.props.urlparams.emp_id ? "Update" : "Submit"}
          </button>
          <Link
            to={this.getBackLink()}
            className="btn btn_primary grey ms-2 me-2"
          >
            Cancel
          </Link>
        </div>
      </form>
    );
  }
}

export default connect(BasicDetails);

export default {
    apiCreateRegisterForm(data) {
        let payload = {
            job_name: data.form_name,
            job_role: data.role,
            job_lang: data.language.split(","),
            job_desc: data.form_description,
            job_fields: Object.values(data?.fields || []).filter(v => v).map((field) => ({
                field_label: field.field_name,
                field_type: field.field_type,
                ...(field?.possible_values) && { field_options: (field?.possible_values || '').split('|').filter(v => v) },
                field_constraints: (field?.field_constraint || '').split(','),
            }))
        };
        return this.request(this.api().candidateRegisterForm, payload).exec().log().get();
    },
    apiUpdateRegisterForm(data, urldata = mixins.params()) {
        let payload = {
            job_name: data.form_name,
            job_role: data.role,
            job_lang: data.language.split(","),
            job_desc: data.form_description,
            job_fields: Object.values(data?.fields || []).filter(v => v).map((field) => ({
                field_label: field.field_name,
                field_type: field.field_type,
                ...(field?.possible_values) && { field_options: (field?.possible_values || '').split('|').filter(v => v) },
                field_constraints: (field?.field_constraint || '').split(','),
            }))
        };
        return this.request(this.api().updateCandidateRegisterForm, payload).urltransform(urldata).exec().log().get();
    },
    apiCandidateRegistrationFormList(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().candidateRegisterFormList, payload).exec().log()
            .mapresults((response) => {
                console.log({ apiCandidateRegistrationFormList: response.data });
                return {
                    data: response.data[0].records.map((form) => ({
                        id: form.job_id,
                        formName: form.job_name,
                        role: form.job_role,
                        updatedOn: form.updatedAt,
                        updatedBy: form.last_updated_by,
                        response: form.numberOfResponse,
                    })),
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetCandidateRegistrationForm(data = {}, urldata = app.mixin.params()) {
        let payload = {
            ...data
        };
        return this.request(this.api().getCadidateRegisterForm, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let data = response.data;
                let dfields = _enum.MANDATORY_FIELDS.map((key, i) => {
                    //debugger
                    let field = _enum.MANDATORY_OBJS[key];
                    return ({
                        _id: 'mandatory' + i,
                        field_name: field.name,
                        field_label: field.field_name,
                        field_type: field.field_type,
                        field_constraints: field.field_constraint.split(','),
                        possible_values: field.possible_values,
                    })
                });//data[`${key}_field`]
                return {
                    form_name: data.job_name,
                    role: data.job_role,
                    language: data.job_lang.join(','),
                    form_description: data.job_desc,
                    fields: dfields.concat(data?.job_fields || []).map((field) => ({
                        field_id: field._id,
                        name: field.field_name,
                        field_name: field.field_label,
                        field_type: field.field_type,
                        field_constraint: (field.field_constraints || []).join(','),
                        possible_values: (field?.field_options || [])?.join(','),
                    }))
                };
            }).get();
    },
    apiDeleteCandidateRegistrationForm(urldata) {
        return this.request(this.api().deleteCadidateRegisterForm)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiCreateCandidateTest(data) {
        let payload = {
            assessment_name: data.assessment_name,
            assessment_role: data.role,
            assessment_lang: data.language.split(","),
            assessment_desp: data.assessment_description,
            assessment_questions: Object.values(data?.qfields || []).filter(v => v).map((field) => ({
                q_label: field.question,
                q_type: field.question_type,
                q_options: (field?.possible_values || '').split('|').filter(v => v),
                q_level: field.difficulty_level,
                a_value: field.correct_answer
            }))
        };
        return this.request(this.api().candidateAssementTest, payload).exec().log().get();
    },
    apiUpdateCandidateTest(data, urldata = mixins.params()) {
        let payload = {
            assessment_role: data.role,
            assessment_lang: data.language.split(","),
            assessment_desp: data.assessment_description,
            assessment_questions: Object.values(data?.qfields || []).filter(v => v).map((field) => ({
                q_label: field.question,
                q_type: field.question_type,
                q_options: (field?.possible_values || '').split('|').filter(v => v),
                q_level: field.difficulty_level,
                a_value: field.correct_answer
            }))
        };
        return this.request(this.api().updateCandidateAssementTest, payload).urltransform(urldata).exec().log().get();
    },
    apiCandidateAssementTestList(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().candidateAssementTestList, payload).exec().log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records.map((test) => ({
                        id: test.assessment_id,
                        assessment_name: test.assessment_name,
                        role: test.assessment_role,
                        language: test.assessment_lang
                            .map(lang => _enum.APPLICANT_LANGUAGE_LIST.find(v => v.key == lang)?.label || "")
                            .join(","),
                        assessment_description: test.assessment_desp,
                        response: test.assessment_questions.length,
                        qfields: test.assessment_questions.map((qfield) => ({
                            id: 1,
                            question: "Some Question Name 1",
                            question_type: "textarea",
                            possible_values: "",
                            difficulty_level: "simple",
                        }))
                    })),
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetCandidateAssementTest(data = {}, urldata = mixins.params()) {
        let payload = {
            ...data
        };
        return this.request(this.api().getCadidateAssementTest, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiGetCandidateAssementTest: response });
                let data = response.data;
                return {
                    assessment_name: data.assessment_name,
                    role: data.assessment_role,
                    language: data.assessment_lang.join(','),
                    assessment_description: data.assessment_desp,
                    qfields: (data.assessment_questions || []).map((qfield) => ({
                        id: qfield._id,
                        question: qfield.q_label,
                        question_type: qfield.q_type,
                        possible_values: qfield.q_options.join(','),
                        difficulty_level: qfield.q_level,
                        answer: qfield.a_value || ""
                    }))
                };
            }).get();
    },
    apiGetCadidateAssementTestSubmited(data, form) {
        let urldata = mixins.params();
        return this.request(this.api().getCadidateAssementTestSubmited, {
            limit: 1,
            page: 1,
            order_by: 'updatedAt',
            order_direction: 'DESC',
            //applicant_id: urldata.id,
            //assessment_id: data.assessment_id
        })
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                console.log({ apiGetCadidateAssementTestSubmited: response, form })
                let record = response.data;
                return record;
            })
            .get();
    },
    apiDeleteCandidateAssementTest(urldata) {
        return this.request(this.api().deleteCadidateAssementTest)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiSaveCadidateAssementTest(data = {}) {
        let payload = {
            "job_id": data.job_id,
            "assessment_id": data.assessment_id,
            "applicant_id": data.applicant_id,
            "answers": Object.entries(data.questions).map(([key, val]) => {
                if (val?.answer?.originFileObj) {
                    return {
                        _id: val.id,
                        q_label: val.question,
                        q_answer: val.answer.originFileObj
                    }
                } else {
                    return {
                        _id: val.id,
                        q_label: val.question,
                        q_answer: val.answer
                    }
                }
            })
        };
        console.log({ payload });
        return this.request(this.api().saveCadidateAssementTest, payload)
            .urltransform()
            .exec().log().get()

    },
    apiGetCadidateList(data = {}, urldata = mixins.params()) {
        let payload = {
            job_id: urldata.id,
            limit: data.limit || 10,
            page: data.page || 1,
            order_by: "updatedAt",
            order_direction: "DESC",
            ...(data.status ? { status: data.status } : {})
        };
        return this.request(this.api().candidateList, payload)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                let data = response.data[0];
                return {
                    data: data.records.map(record => ({
                        id: record.applicant_id,
                        name: record.name,
                        status: record.status,
                        call_status: record.call_status,
                        comments: record.comments,
                        phoneNumber: record.mobile_no,
                        emailID: record.email,
                        others: record.answers,
                        applicant_id: record.applicant_id
                    })),
                    total: data?.pagination[0]?.totalRecords
                }
            })
            .get();
    },
    apiSelectCadidateForm(urldata) {
        return this.request(this.api().selectCadidateForm)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiRejectCadidateForm(urldata) {
        return this.request(this.api().rejectCadidateForm)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiUpdateCandidateResponse(data, urldata = mixins.params()) {
        console.log(data);
        let payload = {
            ...data
        };
        console.log(payload);

        return this.request(this.api().updateCandidateResponse, payload).urltransform(urldata).exec().log().get();
    },
    apiDeleteCadidateForm(urldata) {
        return this.request(this.api().deleteCadidateForm)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiGetResponce(urldata = mixins.params()) {
        return this.request(this.api().getResponce)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiTest2Response(urldata = {}) {
        return this.request(this.api().test2response)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiFileUploader(data, urldata = mixins.params()) {
        return this.request(this.api().fileuploader, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },
    apiFileRemover(data, urldata = mixins.params()) {
        return this.request(this.api().filedeleter, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },
    apiFileDownloader(data, urldata = mixins.params()) {
        return this.request(this.api().fileDownloader, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },
    apiCandidateOfferUpdate(data = {}, urldata = mixins.params()) {
        return this.request(this.api().candidateofferupdate, data)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiTestIdsByRole(data = {}, urldata = mixins.params()) {
        return this.request(this.api().testIdsByRole, data)
            .urltransform(urldata)
            .exec()
            .log()
            .get();
    },
    apiDashboardFunnelData(data = {}) {
        return this.request(this.api().dashboardFunnelData, data)
            .exec()
            .log()
            .get();
    },
    apiDashboardTotalData(data = {}) {
        return this.request(this.api().dashboardTotalData, data)
            .exec()
            .log()
            .get();
    },
    apiDashboardJobwiseData(data = {}) {
        return this.request(this.api().dashboardJobwiseData, data)
            .exec()
            .log()
            .get();
    },
}
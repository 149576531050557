import { Component } from "react";
import { Helmet } from "react-helmet";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: [],
      rowcount: 0,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
  }

  componentDidMount() {
    this.props.api.apiWomenEntrepreneurDashboardDataMoke().then((data) => {
      console.log({ data });
      let mergedData = [
        {
          label: "Total Entrepreneur",
          value: data.total_entrepreneur,
          icon: imagepaths.multiUserIcon,
        },
        {
          label: "Total Active",
          value: data.total_active,
          icon: imagepaths.userCrossIcon,
        },
        {
          label: "Total Inactive",
          value: data.total_inactive,
          icon: imagepaths.userOutIcon,
        },
        {
          label: "No. Of Vendors",
          value: data.no_of_vendors,
          icon: imagepaths.rightRoundIcon,
        },
      ];
      this.setState({
        dashboardData: mergedData,
      });
    });
  }

  render() {
    let { dashboardData } = this.state;
    return (
      <>
        <Helmet>
          <title>Dashboard - Access Livelihoods</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Women Entrepreneur</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            {dashboardData?.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div className="card dash-widget cursor-pointer">
                  <div className="card-body">
                    <span className="dash-widget-icon">
                      <img src={o.icon} alt="" />
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.value}</h3>
                      <span>{o.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default connect(Dashboard);

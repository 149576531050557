import React, { Component } from "react";
export class DocsUploadModal extends Component {
  render() {
    let { props } = this;
    let {
      id,
      title,
      onClose,
      list: arrlist = [],
      size = 2,
      submitText = "SUBMIT",
    } = props;
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    return (
      <AppModalBs
        id={id}
        title={title}
        contentClass={"w-3/6 m-auto"}
        onClose={onClose}
      >
        <AppForm>
          {arrlist.map(({ label, name, type, required }, i) => (
            <>
              <Row className="mx-0 mb-3" key={i}>
                <Col className="col-12">
                  <AppInput
                    name={label}
                    label={label}
                    type={type}
                    required={required}
                  ></AppInput>
                </Col>
              </Row>
            </>
          ))}

          <Row className="pt-4">
            <Col className="text-center d-flex">
              <button type="submit" className="btn add-btn d-block mx-auto">
                {submitText}
              </button>
            </Col>
          </Row>
        </AppForm>
      </AppModalBs>
    );
  }
}

export default DocsUploadModal;

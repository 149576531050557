import React, { Component } from "react";
import LayoutWrapper from "./LayoutWrapper";
import SchemeSteps from "./schemeSteps";
import AlterScheme from "./alterScheme";
import SchemeSearch from "./schemeSearch";

export class Schemes extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Schemes");
    // Binding methods
    this.getSchemeDetails = this.getSchemeDetails.bind(this);
    this.state = {
      data: [],
      showStepper: false,
      isActive: false,
      editData: {},
      viewMode: false,
      editMode: false,
      showEditData: false,
      search: {
        limit: 50,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "_id",
          title: "Scheme ID",
        },
        {
          dataIndex: "name",
          title: "Scheme Name",
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          dataIndex: "type",
          title: "Scheme Type ",
          className: "cursor-pointer",
          render: (text) => {
            return (
              <>{text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}</>
            );
          },
          onHeaderCell: () => {
            return {
              onClick: () => {
                this.handleHeaderClick();
              },
            };
          },
        },
        {
          dataIndex: "implementationDate",
          title: "Implementation Date",
          render: (text) => {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          },
          sorter: (a, b) =>
            new Date(a.implementationDate) - new Date(b.implementationDate),
        },
        {
          dataIndex: "isActive",
          title: "Status",
          render: (text, record) => {
            // console.log(text, 'text')
            return (
              <span
                className={`badge light border-0 ${
                  record.isActive ? "badge-success" : "badge-warning"
                } `}
              >
                {record.isActive ? "Active" : "Deactive"}
              </span>
            );
          },
          sorter: (a, b) => a.isActive - b.isActive,
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  handleHeaderClick() {
      if(this.state.search.order_direction == "DESC") {
        this.setState({
          search: {...this.state.search, order_by:"type", order_direction: "ASC"}
        })
      } else {
        this.setState({
          search: {...this.state.search, order_by:"type", order_direction: "DESC"}
        })
      }
    this.props.api.apiGetAllSchemes(this.state.search).then(({ data }) => {
      let filteredAry = data?.items[0].records;
      filteredAry.sort((a, b) => a.type.length - b.type.length);
      this.setState({
        data: filteredAry,
        rowcount: data.items[0].pagination[0].totalRecords,
      });
    });
  }

  fetchList(data) {
    let payload = {
      ...this.state.search,
      search: data,
    };
    for (let key in payload) {
      if (payload[key] === undefined || "") {
        delete payload[key];
      }
    }
    this.props.api
      .apiGetAllSchemes(payload)
      .then(({ data }) => {
        this.setState({
          data: data.items[0].records,
          rowcount: data.items[0].pagination[0].totalRecords,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showDataToEdit = (value) => {
    this.setState(
      {
        showEditData: true,
      },
      this.getSchemeDetails(value)
    );
  };

  getSchemeDetails(schemeId) {
    this.props.api
      .apiGetSchemeDetailsById({ schemeId: schemeId })
      .then((response) => {
        console.log({ apiGetSchemeDetailsById: response });
        this.setState({
          editData: response.data,
          isActive: response.data.isActive,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  isDeactivated(record) {
    return record.isDeleted ? "Deactivate" : "Activate";
  }

  deleteAction(value) {}

  deactiveAction(value) {
    console.log(value, "delete values");

    if (value.isDeleted) {
      let deleteData = {
        isActive: true,
        isDeleted: false,
      };
      this.props.api
        .apiUpdateScheme(deleteData, { schemeId: value._id })
        .then((res) => {
          this.fetchList();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let payload = {
        schemeId: value._id,
      };

      confirm(`Do you really want to deactivate this record?`).promise.then(
        () => {
          this.api.apiDeleteScheme(payload).then(() => {
            this.fetchList();
          });
        }
      );
    }
  }

  editAction(v) {
    // console.log({ edit: v });
    this.getSchemeDetails(v._id);
    this.setState({
      editData: v,
      viewMode: false,
      editMode: true,
    });
  }

  viewAction(v) {
    console.log({ view: v });
    this.getSchemeDetails(v._id);
    // this.props.navigate(
    //   "/schemes?" +
    //     Object.QueryString({
    //       scheme_id: v.scheme_id,
    //     })
    // );
    this.setState({
      editData: v,
      viewMode: true,
      editMode: false,
    });
  }

  onSearch(data) {
    // console.log({ onSearch: data });
    this.fetchList(data);
  }

  resetSearchForm() {
    // this.setState(
    //   {
    //     search: {
    //       ...this.state.search,
    //     },
    //   },
    //   () => this.fetchList()
    // );
    location.reload();
  }

  render() {
    let {
      columns,
      data,
      rowcount,
      viewMode,
      editMode,
      editData,
      showStepper,
      isActive,
      showEditData,
    } = this.state;
    return (
      <>
        <LayoutWrapper title="Scheme Form" back="Configuration">
          {showStepper || (editMode && !isActive) ? (
            <SchemeSteps
              editData={editData}
              viewMode={viewMode}
              editMode={editMode}
              isActive={isActive}
              showEditData={showEditData}
              showDataToEdit={this.showDataToEdit}
            />
          ) : viewMode || (editMode && isActive) ? (
            <AlterScheme
              editData={editData}
              viewMode={viewMode}
              editMode={editMode}
              isActive={isActive}
              getSchemeDetails={this.getSchemeDetails}
            />
          ) : (
            <>
              <div className="row d-flex justify-content-between">
                <div className="col mb-4 d-flex flex-row h-25">
                  <button
                    className="btn add-btn"
                    onClick={() => this.setState({ showStepper: true })}
                  >
                    <i className="fa fa-plus" /> Add Scheme Form
                  </button>
                </div>
                <div className="col d-flex flex-row-reverse">
                  <div>
                    <SchemeSearch
                      inactive={this.props.inactive}
                      onSearch={(...arg) => this.onSearch(...arg)}
                    />
                    <div className="d-flex justifty-content-end">
                      <button
                        type="button"
                        className="btn clear_filter_btn ml-auto d-block mb-3 text-decoration-underline"
                        onClick={(...args) => this.resetSearchForm(...args)}
                      >Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row lms">
                <div className="col-12">
                  {!!data.length && (
                    <AppTable
                      data={data}
                      columns={columns}
                      onNext={() => this.onNext()}
                      onPrev={() => this.onPrev()}
                      onChange={(...arg) => this.onPageChange(...arg)}
                      total={rowcount}
                      reorder={true}
                      deleteAction={(v) => this.deleteAction(v)}
                      editAction={(v) => this.editAction(v)}
                      viewAction={(v) => this.viewAction(v)}
                      deactiveAction={(v) => this.deactiveAction(v)}
                      isDeactivated={(v) => this.isDeactivated(v)}
                      deactivatable={true}
                      deletable={false}
                      targetType="tap"
                    ></AppTable>
                  )}
                  {data.length == 0 && (
                    <div className="empty_layout schemes"></div>
                  )}
                </div>
              </div>
            </>
          )}
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(Schemes);

import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import AssessmentAttendeeSearch from "./AssessmentAttendeeSearch";

export class AssessmentAttendees extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AssessmentAttendees");
    this.state = {
      data: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "employee_name",
          title: "Name",
        },
        {
          dataIndex: "employee_role",
          title: "Role",
        },
        {
          dataIndex: "assessment_question_count",
          title: "# of Questions",
        },
        {
          dataIndex: "assessment_answer_count",
          title: "# of Answers",
          render: (text, record) => {
            if (text) {
              return (
                <>
                  <Link
                    to={`/lms/assessmentreview/assessmentdetail?${Object.QueryString(
                      {
                        evaluation_id: record?.evaluation_id,
                        assessment_id: this.props?.urlparams?.assessment_id,
                        assessment_name: this.props?.urlparams?.assessment_name,
                        attendee_name: record?.employee_name,
                      }
                    )}`}
                    className="link_btn"
                  >
                    {text}
                  </Link>
                </>
              );
            } else {
              return <>-</>;
            }
          },
        },
        {
          dataIndex: "createdAt",
          title: "Submitted On",
          render: (text) => {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          },
        },
      ],
    };
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    let payload = {
      assessment_id: this.props.urlparams.assessment_id,
    };
    this.props.api.apiGetEvaluationById(payload).then(({ data, total }) => {
      console.log({ apiGetEvaluationById: data });
      this.setState({
        data,
        rowcount: total,
      });
    });
  }

  onSearch(data) {
    console.log("onSearch", data);
    if (Object.keys(data).length === 0) {
      this.setState(
        {
          search: {
            ...data,
            page: this.state.search.page,
            limit: 10,
          },
        },
        () => this.fetchList()
      );
    } else {
      this.setState(
        {
          search: {
            ...data,
            page: this.state.search.page,
            limit: 10,
          },
        },
        () => this.fetchList(data)
      );
    }
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.props.api.apiGetAllCourses({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      totalrows = totalrows.map((item) => {
        return {
          ...item,
          createdAt: new Date(item.createdAt).dateToDDMMYYYY(
            new Date(item.createdAt)
          ),
        };
      });
      // console.log({ totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "course_name",
        "course_type",
        "course_difficulty_level",
        "course_description",
        "created_by",
        "createdAt",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `lms_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  getBack() {
    let back = `Assessment Review / ${this.props?.urlparams?.assessment_name}`;
    return back;
  }
  getBackLink() {
    let backLink = `/lms/assessmentreview`;
    return backLink;
  }

  render() {
    let { columns, data = [], rowcount } = this.state;
    let { isAdmin } = this;
    return (
      <LayoutWrapper
        title="Assessment Review"
        back={this.getBack()}
        backlink={this.getBackLink()}
      >
        <AssessmentAttendeeSearch
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row lms">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                viewable={false}
                deletable={false}
                editable={false}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout lms"></div>}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(AssessmentAttendees);

import React, { Component } from "react";
import { Helmet } from "react-helmet";

export class LayoutWrapper extends Component {
  render() {
    let { props } = this;
    let { title = "", className = "" } = props;
    return (
      <div className={"content container-fluid relative px-0" + className}>
        <Helmet>
          <title>{title} - Access Livelihoods</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="flex flex-col h-screen public_container">
          <div className="top_banner -mx-6 w-auto px-5 mt-5">
            <h2 className="m-0 text-center">Welcome</h2>
          </div>
          {props.children}
        </div>
      </div>
    );
  }
}

export default connect(LayoutWrapper);

export default {
    createProject: {
        url: "/project",
        method: "post"
    },
    dashboardTotal: {
        url: "/project/dashboard/total",
        method: "get"
    },
    dashboardBudget: {
        url: "/project/dashboard/budget",
        method: "get"
    },
    projectList: {
        url: "/project/",
        method: "get"
    },
    getProject: {
        url: "/project/{project_id}/",
        method: "get"
    },
    updateProject: {
        url: "/project/{project_id}",
        method: "put"
    },
    deleteProject: {
        url: "/project/{project_id}",
        method: "delete"
    },
    createTasks: {
        url: "/project/task",
        method: "post"
    },
    addFinancialYears: {
        url: "/project/fy",
        method: "post"
    },
    fileuploader: {
        url: "/project/upload",
        method: "post"
    },
    filedeleter: {
        url: "/project/upload/{file_id}",
        method: "delete"
    },
    fileDownloader: {
        url: "/project/upload/{attachmentId}/",
        method: "get"
    },
    getTasks: {
        url: "/project/task/tasks/{project_id}/{project_fy_id}/",
        method: "get"
    },
    addTask: {
        url: "/project/task/",
        method: "post"
    },
    editTask: {
        url: "/project/task/{task_id}",
        method: "put"
    },
    deleteTask: {
        url: "/project/task/{task_id}",
        method: "delete"
    },
    getBudgets: {
        url: "/project/budget/project/{project_id}",
        method: "get"
    },
    getBudgetsByTask: {
        url: "/project/budget/task/{task_id}",
        method: "get"
    },
    addBudget: {
        url: "/project/budget/",
        method: "post"
    },
    getBudgetById: {
        url: "/project/budget/{budget_id}",
        method: "get"
    },
    editBudget: {
        url: "/project/budget/{budget_id}",
        method: "put"
    },
    deleteBudget: {
        url: "/project/budget/{budget_id}",
        method: "delete"
    },
    getFinancial: {
        url: "/project/fy/{project_id}",
        method: "get"
    },
    addFinacial: {
        url: "/project/fy/",
        method: "post"
    },
    editFinacial: {
        url: "/project/fy/{project_id}/{project_fy_id}",
        method: "put"
    },
    deleteFinacial: {
        url: "/project/fy/{project_id}/{project_fy_id}",
        method: "delete"
    },
    getEmployeeResources: {
        url: "/project/resource/employee",
        method: "get"
    },
    addEmployeeResources: {
        url: "/project/resource/employee",
        method: "post"
    },
    updateEmployeeResources: {
        url: "/project/resource/employee/{resource_id}",
        method: "put"
    },
    deleteEmployeeResources: {
        url: "/project/resource/employee/{resource_id}",
        method: "delete"
    },
    getMaterialResources: {
        url: "/project/resource/material",
        method: "get"
    },
    addMaterialResources: {
        url: "/project/resource/material",
        method: "post"
    },
    updateMaterialResources: {
        url: "/project/resource/material/{resource_id}",
        method: "put"
    },
    deleteMaterialResources: {
        url: "/project/resource/material/{resource_id}",
        method: "delete"
    },
    budgetPlanner: {
        url: "/project/planner/{project_id}/{project_fy_id}",
        method: "get"
    },
    //EXPENSES
    createExpense: {
        url: "/project/expenses",
        method: "post"
    },
    expenseList: {
        url: "/project/expenses/project",
        method: "get"
    },
    expenseListById: {
        url: "/project/expenses/{expense_id}",
        method: "get"
    },
    updateExpense: {
        url: "/project/expenses/{expense_id}",
        method: "put"
    },
    deleteExpense: {
        url: "/project/expenses/{expense_id}",
        method: "delete"
    },

}
import moment from "moment";
import React, { Component } from "react";
import { renderWorkingHours } from "../../../../../mixins/functions/calculations";
import LayoutWrapper from "../../LayoutWrapper";

// import mixins from "../mixins";

// export const tabs = mixins.menulinks;

export class AttendanceDetails extends Component {
  get daylist() {
    let { monthDate = new Date() } = this.state;
    return new Date(monthDate).dateList().map((o) => ({
      name: (
        <div>
          <div className="font-bold text-center">{o.getDate()}</div>
          <div
            className={Object.className({
              "text-sm text-center": true,
              "text-red-500": o.getDayName() == "SUN",
              "text-gray-500": o.getDayName() != "SUN",
            })}
          >
            {o.getDayName().toLowerCase().toTitleCase()}
          </div>
        </div>
      ),
      key: o.getDate(),
    }));
  }

  get monthDays() {
    let dayListArr = Object.entries(this.daylist);
    return dayListArr.map(([i, daynum]) => ({ key: daynum.key, index: i }));
  }
  constructor(props) {
    super(props);
    this.state = {
      monthDate: new Date(),
      attendanceData: [],
      employeeDataObj: {},
      search: {
        page: 1,
      },
    };

    this.URLstr = mixins.params().emp_id;
    this.URLstrArr = this.URLstr.match(/([^_]+)/g);
    this.targetEmpId = this.URLstrArr[0];
    this.targetEmpName = this.URLstrArr[1];
    this.fields = [
      {
        name: "employee_id",
        label: "Employee ID",
        type: "text",
        className: "select-focus",
      },
      {
        name: "month",
        label: "Select Month",
        type: "month",
        value: new Date().getMonthDisplay(),
        className: "select-focus",
      },
      {
        name: "location",
        label: "Location",
        type: "select",
        options: "worklocationList",
        className: "select-focus",
      },
    ];
    // this.links = tabs;
    // this.setColumns();

    this.colors = {
      late_punch:"text-yellow-500",
      present: "text-green-500",
      absent: "text-red-500",
      leave: "text-orange-500",
      holiday: "text-primary",
      sunday: "text-gray-300",
    };
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (text) => moment(text).format("MMMM D, YYYY"),
      },
      {
        title: "Sign in",
        dataIndex: "in_time",
        render: (text, record) => this.renderIntime(record),
      },
      {
        title: "Sign out",
        dataIndex: "out_time",
        render: (text, record) => this.renderOuttime(record),
      },
      {
        title: "Working hours",
        dataIndex: "working_hours",
        render: (text, record) => renderWorkingHours(record),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text,record) => this.renderStatus(text,record),
      },
    ];
  }
  renderIntime(obj) {
    if (obj.in_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else {
      return obj.in_time;
    }
  }
  renderOuttime(obj) {
    if (obj.in_time === obj.out_time) {
      return "-";
    } else if (obj.in_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else if (obj.out_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else {
      return obj.out_time;
    }
  }
  renderStatus(sts,record) {
    sts = sts.toLowerCase();
    let inPunchTime = "10:15 AM".to24Time().time2date().getTime();
    let punchTime = record.in_time.to24Time().time2date().getTime();
    if (sts === "present") {
      if(punchTime>inPunchTime) {
        sts = "late_punch";
        return <b className={`${this.colors[sts]} text-capitalize`}>{sts.toTitleCase()}</b>;
      } else {
        return <b className={`${this.colors[sts]} text-capitalize`}>{sts}</b>;
      }
      
    }
    if (sts === "absent") {
      return <b className={`${this.colors[sts]} text-capitalize`}>{sts}</b>;
    }
  }
  timeDiff(start, end) {
    start = start.split(":");
    end = end.split(":");
    if (Number(start[0]) > Number(end[0])) {
      var num = Number(start[0]);
      var countTo = Number(end[0]);
      var count = 0;
      for (var i = 1; num != countTo; ) {
        num = num + i;
        if (num > 24) {
          num = 0;
        }
        count++;
      }
      var hours = count - 1;
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      if (startDate.getMinutes() > endDate.getMinutes()) {
        var hours = count - 2;
        var diff = 60 - (startDate.getMinutes() - endDate.getMinutes());
      } else {
        var diff = endDate.getMinutes() - startDate.getMinutes();
      }
      var minutes = diff;
    } else {
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
    }

    if (isNaN(hours)) {
      return "-";
    }
    var returnValue =
      (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
    let mStr = moment(returnValue, "HH:mm:ss").format("hh:mm");
    let formattedTime =
      mStr.split(":")[0] + " hrs" + " " + mStr.split(":")[1] + " mins";
    returnValue = formattedTime;
    return returnValue;
  }
  setColumns() {
    let { daylist, monthDays } = this;
    let year = this.state.monthDate.getFullYear();
    let month = this.state.monthDate.getMonth();
    this.colors = {
      present: "text-green-500",
      absent: "text-red-500",
      leave: "text-orange-500",
      holiday: "text-primary",
      sunday: "text-gray-300",
    };
    this.status = Object.map(this.colors, (value, key) => {
      switch (key) {
        case "leave":
          return <b className={`${this.colors[key]}`}>L</b>;
        case "holiday":
          return <b className={`${this.colors[key]}`}>H</b>;
        case "sunday":
          return <i className={`fa fa-circle ${this.colors[key]} text-xs`}></i>;
        case "absent":
          return <i className={`fa fa-close ${this.colors[key]}`}></i>;
        default:
          return <i className={`fa fa-check ${this.colors[key]}`}></i>;
      }
    });
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        dataIndex: "employee_id",
        display: false,
      },
      {
        dataIndex: "name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <Link
              to={
                "/admin/profile/AttendanceDetails?emp_id=" + record.employee_id
              }
            >
              {text} <span>{record.employee_id}</span>
            </Link>
          </h2>
        ),
      },
      ...monthDays.map(({ key, index }) => ({
        dataIndex: key,
        title: daylist[index]?.name,
        sorter: false,
        render: (text, record) => {
          let dateobj = new Date(year, month, key);
          let holidaydate = app.props.store.holidayList.find(
            (o) => new Date(o.holiday_date).toDate() == dateobj.toDate()
          );
          return (
            <div className="text-center" title={holidaydate?.holiday_name}>
              {this.status[text] || this.status["absent"]}
            </div>
          );
        },
      })),
    ];
  }
  getAttendaceData() {
    console.log(
      "monthDate in getAttendaceData:",
      this.state.monthDate.toDate()
    );
    setTimeout(() => {
      this.props.api
        .apiEmployeeAttendaceList({
          monthDate: this.state.monthDate.toDate(),
        })
        .then((data) => {
          let employeeData = data.employeeAttendances?.filter(
            (val) =>
              val.employee_id === this.targetEmpId &&
              val.name.replace(/\s/g, "").toLowerCase() === this.targetEmpName
          );
          console.log("Target Employee Attendances: ", employeeData);

          this.setState({
            attendanceData: employeeData[0].attendances,
          });
        });
    });
  }
  componentDidMount() {
    window.LeaveRequests = this;
    this.fetchData();
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {
    if (field.name == "month") {
      this.setState({
        monthDate: new Date(firstDate),
      });
      setTimeout(() => {
        this.fetchData();
      }, 0);
    }
  }
  fetchData(extra = {}) {
    let data = this.refs.form.form.current.getData();
    data = { ...data, ...extra };
    let payload = {
      ...Object.except(data, ["month"]),
      year: new Date(data.month).getFullYear(),
      month: new Date(data.month).getMonth() + 1,
      ...this.state.search,
    };
    console.log(payload);
    this.props.api
      .apiEmployeeAttendenceDeatils(payload, this.props.urlparams)
      .then((response) => {
        console.log({ response });
        this.setState({
          employeeDataObj: response,
        });
      });
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    let { links, columns = [] } = this;
    let { attendanceData, dummyResData } = this.state;
    return (
      <LayoutWrapper
        title={`${
          this.state.employeeDataObj?.employee_name || "Employee Name"
        }`}
        back="Team Attendance"
        backlink="/admin/attendance"
      >
        <AppForm className="row" ref="form">
          {this.fields.map((field, i) => (
            <div className="col-sm-6 col-md-3" key={i}>
              <AppInputFocus
                {...field}
                onChange={(...arg) => this.onFilterChange(field, ...arg)}
              ></AppInputFocus>
            </div>
          ))}
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.fetchData()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={this.state.employeeDataObj?.employeeAttendances}
              // data={dummyResData}
              columns={columns}
              editable={false}
              deletable={false}
              reorder={true}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(AttendanceDetails);

import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import ExpenseSearch from "./ExpenseSearch";
import * as XLSX from "xlsx";

export class ExpenseManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ExpenseManagement");
    this.state = {
      data: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.columns = [
      {
        dataIndex: "expense_name",
        title: "Expense Name",
      },
      {
        dataIndex: "budget_name",
        title: "Expense Category",
      },
      {
        dataIndex: "expense_amount",
        title: "Expense Amount",
        render: (text) => {
          return <>{text.cur("", "INR", "en-US")}</>;
        },
      },
      {
        dataIndex: "expense_date",
        title: "Expense Date",
        render: (text) => {
          return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
        },
      },
      {
        dataIndex: "project_name",
        title: "Project Name",
        render: (text, record) => {
          return (
            <span
              className="text_truncate cursor-pointer"
              title={record?.project_name}
              onClick={() => alert(record?.project_name)}
            >
              {record?.project_name}
            </span>
          );
        },
      },
      {
        dataIndex: "task_name",
        title: "Task Name",
      },
      {
        dataIndex: "attachments",
        title: "Attachments",
        render: (text) => {
          if (text[0]) {
            let { attachmentId, attachmentName } = text[0];
            console.log({ attachmentId, attachmentName });
            return (
              <>
                <a
                  className="link_btn"
                  href={this.downloadFile(attachmentId)}
                  target="_blank"
                >
                  Download
                </a>
              </>
            );
          } else {
            return <>-</>;
          }
        },
      },
      {
        dataIndex: "created_by",
        title: "Submitted By",
        display: this.currentUserName === "admin" ? true : false,
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get currentUserName() {
    return this.props?.store.user?.employee_role;
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList(data) {
    let payload = {
      ...data,
    };
    console.log("payload", payload);
    this.api
      .apiGetExpenses(this.state.search, payload)
      .then(({ data, total }) => {
        this.setState({
          data: [...data],
          rowcount: total,
        });
      });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }
  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  deleteExpenseAction(v) {
    console.log("deleteExpense ", v);
    let payload = {
      expense_id: v.expense_id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteExpenses(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editExpenseAction(v) {
    console.log("edit: ", v);
    this.props.navigate(
      "/projectmanagement/addexpense?" +
        Object.QueryString({
          expense_id: v.expense_id,
        })
    );
  }

  viewExpenseAction(v) {
    this.props.navigate(
      "/projectmanagement/addexpense?" +
        Object.QueryString({
          expense_id: v.expense_id,
        }) +
        "&readonly=1"
    );
  }

  onSearch(reset, data) {
    this.setState({ search: data }, () => {
      this.fetchList(data);
    });
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.props.api.apiGetExpenses({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      totalrows = totalrows.map((item) => {
        return {
          ...item,
          expense_amount: item?.expense_amount?.cur("", "INR", "en-US"),
          expense_date: new Date(item?.expense_date).dateToDDMMYYYY(
            new Date(item?.expense_date)
          ),
        };
      });
      // console.log({ totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "expense_name",
        "budget_name",
        "expense_amount",
        "expense_date",
        "project_name",
        "task_name",
        "created_by",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `expense_management_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  render() {
    let { data = [], rowcount } = this.state;
    let { columns, isAdmin } = this;
    return (
      <LayoutWrapper title="Expense Management" back="Dashboard">
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <Link to="/projectmanagement/addexpense" className="btn add-btn">
              Add
            </Link>
            <button
              type="button"
              className={Object.className({
                "btn add-btn mx-2": true,
                hidden: !isAdmin,
              })}
              onClick={() => this.getDownloadData()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
        </div>
        <ExpenseSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row expense_management">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                deleteAction={(v) => this.deleteExpenseAction(v)}
                editAction={(v) => this.editExpenseAction(v)}
                viewAction={(v) => this.viewExpenseAction(v)}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout"></div>}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(ExpenseManagement);

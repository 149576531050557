import { Component } from "react";

export class DocumentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  labelMaps = {
    ctc: "CTC",
  };

  render() {
    let { labelMaps, props } = this;
    let { data } = this.state;
    let { onEdit } = props;
    let config = {
      updateapi: "apiUpdateEmployeeProfileBasic",
      type: "EditDocList",
    };
    return (
      <>
        <div className="mb-4 flex">
          <div>
            <img className="h-10 w-10 m-auto" src={imagepaths.docverified} />
          </div>
          <div className="text-xl mx-2 font-semibold m-auto">Docs Details</div>
        </div>
        <div className="flex flex-wrap">
          {!!this.props.data.length &&
            this.props.data?.map((row, i) => (
              <div className="flex flex-col min-w-fit px-5 mx-2" key={i}>
                <img src={imagepaths.docCard} className="m-auto h-13"></img>
                <div className="text-center py-2 text-base font-semibold">
                  {row.doc_name.toTitleCase()}
                </div>
                <a
                  className="text-center font-semibold primary_link cursor-pointer"
                  href={this.props.api.apiDownloadFile(row)}
                  target="_blank"
                >
                  <span>
                    <em className="la la-download"></em>
                    Download
                  </span>
                </a>
              </div>
            ))}

          {this.props.data.length == 0 && (
            <div className="empty_layout empty_docs"></div>
          )}
        </div>
      </>
    );
  }
}

export default connect(DocumentDetails);

import React, { Component } from "react";
export class ProjectManagementSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      projectOptions: [],
      taskOptions: [],
      projectListData: [],
      projectDetails: {},
      taskDetails: {},
      budgetOptions: [],
    };
    this.fields = [
      {
        name: "project_status",
        label: "Project Status",
        type: "select",
        options: [],
        className: "select-focus",
        onChange: (v) => this.handleProjectChange(v),
      },
    ];
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data);
    data = Object.filter(data, (val) => val);
    console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  componentDidMount() {}

  setProjects() {
    this.props.api.apiGetProjectsList().then((projects) => {
      console.log("projects", projects);

      this.setState({
        projectOptions: projects.data,
      });
    });
  }

  updateFields(fields) {
    // fields.find((o) => o.name === "project_status").options =
    //   this.state.projectOptions.options("project_status", "project_id");
    return fields;
  }

  render() {
    let {} = this.state;
    let { fields } = this;

    fields = this.updateFields(this.fields);

    return (
      <AppForm className="row filter-row" ref="form">
        {this.props.hideStatus
          ? fields
              .filter((v) => v.name !== "status")
              .map((field, i) => (
                <div className="col-sm-6 col-md-3" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))
          : fields.map((field, i) => (
              <div className="col-sm-6 col-md-3" key={i}>
                <AppInputFocus {...field}></AppInputFocus>
              </div>
            ))}
        <div className="col-sm-6 col-md-3">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onSearch()}
          >
            Search
          </button>
        </div>
      </AppForm>
    );
  }
}

export default connect(ProjectManagementSearch);

/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./dashboard.scss";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
    };
  }

  componentDidMount() {
    this.props.api.apiDcDashboardData().then(({ data }) => {
      let mergedData = [
        {
          name: "total_projects",
          label: "Total Projects",
          value: data.items[0].totalProject,
          iconWhite: imagepaths.totalProjIconWhite,
          iconOrange: imagepaths.totalProjIconOrange,
        },
        {
          name: "total_forms",
          label: "Total Forms",
          value: data.items[0].totalForms,
          iconWhite: imagepaths.totalFormsIconWhite,
          iconOrange: imagepaths.totalFormsIconOrange,
        },
        {
          name: "open_forms",
          label: "Open Forms",
          value: data.items[0].openForms,
          iconWhite: imagepaths.openFormsIconWhite,
          iconOrange: imagepaths.openFormsIconOrange,
        },
        {
          name: "closed_forms",
          label: "Disabled Forms",
          value: data.items[0].closeForm,
          iconWhite: imagepaths.closedFormsIconWhite,
          iconOrange: imagepaths.closedFormsIconOrange,
        },
      ];
      this.setState({
        cardData: mergedData,
      });
    });
  }

  render() {
    let { cardData } = this.state;
    return (
      <>
        <Helmet>
          <title>Dashboard - Access Livelihoods</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Data Collection Forms</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row mb-4">
            {cardData.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div
                  className="card dash-widget cursor-pointer orangeCard dc_dashboard_card"
                  onClick={() => this.cardAction(o, i)}
                >
                  <div className="card-body">
                    <span className="dash-widget-icon light_bg img_icon_outer">
                      <img
                        src={o.iconWhite}
                        className="imgicon white"
                        alt="dash image"
                      />
                      <img
                        src={o.iconOrange}
                        className="imgicon orange"
                        alt="dash image"
                      />
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.value}</h3>
                      <span>{o.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default connect(AdminDashboard);

import moment from "moment";
import { renderWorkingHours } from "../functions/calculations";

export default {
    apiExceptionApprovalListMoke() {
        let data = [
            { id: 1, name: "John Doe", employee_id: "0077766", office_location: "Location", exception_date: "1 Jan 2022", reason: "Going to Hospital", remarks: "Remarks", status: "Approved", role: "Web Designer" },
            { id: 2, name: "John Doe", employee_id: "0077766", office_location: "Location", exception_date: "1 Jan 2022", reason: "Going to Hospital", remarks: "Remarks", status: "Approved", role: "Web Designer" },
            { id: 3, name: "John Doe", employee_id: "0077766", office_location: "Location", exception_date: "1 Jan 2022", reason: "Going to Hospital", remarks: "Remarks", status: "Approved", role: "Web Designer" },
            { id: 4, name: "John Doe", employee_id: "0077766", office_location: "Location", exception_date: "1 Jan 2022", reason: "Going to Hospital", remarks: "Remarks", status: "Approved", role: "Web Designer" },
            { id: 5, name: "John Doe", employee_id: "0077766", office_location: "Location", exception_date: "1 Jan 2022", reason: "Going to Hospital", remarks: "Remarks", status: "Approved", role: "Web Designer" }
        ];
        return this.mokeapi(() => (data), "Getting attendance list please wait...", 2000);
    },
    apiExceptionApprovalList(data) {
        let payload = {};
        payload = {
            employee_id: data.employeeId,
            name: data.employeeName,
            location: data.location,
            ...((!["ALL", ""].includes(data.status)) ? { status: data.status } : { status: ['APPROVED', 'PENDING', 'REJECTED'] }),
            page: data.page || 1,
            limit: data.limit || 10,
        };
        // console.log({ payload, data });
        return this.request(this.api().exceptionRequestList, payload).exec().log().mapresults(responce => ({
            ...responce,
            data: responce.result.map(res => ({
                id: res._id,
                name: res.employee_name,
                office_location: res.work_location,
                exception_date: res.exception_date,
                reason: res.reason,
                sign_in_time: res.in_time || '-',
                sign_out_time:
                    moment(res.in_time, "H:mm:ss A").isSameOrAfter(moment(res.out_time, "H:mm:ss A")) ? '-' : res.out_time,
                working_hours: (res.in_time && res.in_time) ? renderWorkingHours(res) : '-',
                remark: res.remark,
                status: res.status,
                //employee_id:res.employee_id
            }))
        })).get();
    },
    apiUpdateExceptionRequest(data, urldata = {}) {
        return this.request(this.api().updateExceptionRequest, data)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiEmployeeAttendaceListMoke({ monthDate }) {
        console.log({ monthDate });
        let dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let status = dayNames.reduce((o, n) => ({ ...o, [n]: 'present' }), {});
        status["SUN"] = "sunday";
        let attendance = new Date(monthDate).dateList().reduce((o, n) => ({
            ...o,
            [n.getDate()]: status[n.getDayName()]
        }), {});
        let days = Object.keys(attendance);
        let data = [
            { id: 1, name: "John Doe", employee_id: "0077766", ...attendance },
            { id: 2, name: "John Doe", employee_id: "0077766", ...attendance },
            { id: 3, name: "John Doe", employee_id: "0077766", ...attendance },
            { id: 4, name: "John Doe", employee_id: "0077766", ...attendance },
            { id: 5, name: "John Doe", employee_id: "0077766", ...attendance }
        ];
        data.forEach((v, i) => {
            data[i][(1).rand(days.length)] = "absent";
            data[i][(1).rand(days.length)] = "leave";
            data[i][(1).rand(days.length)] = "holiday";
        });
        console.log({ attendance, days, data });
        return this.mokeapi(() => (data), "Getting attendance list please wait...", 1000);
    },
    apiEmployeeAttendenceDeatils(data, urldata = {}) {
        return this.request(this.api().employeeAttendenceDeatils, data)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiEmployeeAttendaceList(payload = {}) {
        let dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let statuses = dayNames.reduce((o, n) => ({ ...o, [n]: 'nostatus' }), {});
        statuses["SUN"] = "sunday";
        let holidays = app.props.store.holidayList.map(o => new Date(o.holiday_date).toDate());
        // payload.limit = 100;
        return this.request(this.api().attendanceList, payload)
            .exec("Getting the attendance please wait...").mapresults((responce) => {
                return {
                    ...responce,
                    data: responce.employeeAttendances.map(obj => {
                        let attendance = new Date(payload.year, payload.month - 1, 1).dateList().reduce((o, n) => {
                            let status = statuses[n.getDayName()] || "nostatus";
                            status = (holidays.includes(n.toDate()) && "holiday") || status;
                            status = (obj.attendances.filter(o => o.status == "PRESENT").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "present") || status;
                            status = (obj.attendances.filter(o => (o.status == "PRESENT" && o.in_time >= '10:15')).map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "late_punch") || status;
                            status = (obj.attendances.filter(o => o.status == "ABSENT").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "absent") || status;
                            status = (obj.leaves.filter(o => o.status == "REJECTED").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "sunday") || status;
                            status = (obj.leaves.filter(o => o.status == "REJECTED").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "nostatus") || status;
                            status = (obj.leaves.filter(o => o.status == "APPROVED").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "leave") || status;
                            // status = (obj.attendances.filter(o => o.status == "PRESENT").map(o => o.in_time >= '10:15').map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "leave" ) || status;
                            // console.log(obj.attendances.filter(o => o.in_time >= '10:15'));
                            return {
                                ...o,
                                [n.getDate()]: status
                            }
                        }, {});
                        // console.log(obj.attendances.filter(o => o.in_time >= '10:15'));
                        return {
                            name: obj.name,
                            employee_id: obj.employee_id,
                            id: obj._id,
                            absent_count: Object.values(attendance).filter(status => status == 'absent').length,
                            present_count: Object.values(attendance).filter(status => status == 'present').length,
                            leave_count: Object.values(attendance).filter(status => status == 'present').length,
                            late_punch_count: Object.values(attendance).filter(status => status == 'late_punch').length,
                            working_days: new Date(payload.year, payload.month - 1, 1).dateList().length,
                            ...attendance
                        };
                    })
                };
            }).log().get();
    },

    apiEmployeeAttendaceListMonthly(payload = {}) {
        console.log({ apiEmployeeAttendaceListMonthly: payload });
        let dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let statuses = dayNames.reduce((o, n) => ({ ...o, [n]: 'nostatus' }), {});
        statuses["SUN"] = "sunday";
        console.log({ statuses });
        // let holidays = app.props.store.holidayList.map(o => new Date(o.holiday_date).toDate());
        let today = new Date();
        return this.request(this.api().attendanceListMonthly, payload)
            .exec("Getting the attendance please wait...")
            .mapresults((res) => {
                return {
                    data: res.data[0].records.map(obj => {
                        let attendance = new Date(payload.year, payload.month, 1).dateList().reduce((o, n) => {
                            // let status = statuses[n.getDayName()] || "nostatus";
                            let status = "absent";
                            if ((n.getDayName() === "SUN" && "sunday")) {
                                // console.log((n.getDayName() === "SUN" && "sunday"));
                                console.log(n.getDayName(), status);
                            }
                            // if (n.getDayName() === "SUN") {
                            //     console.log({ n });
                            //     status = "sunday"
                            // }
                            // status = (holidays.includes(n.toDate()) && "holiday") || status;
                            status = (obj.attendances.filter(o => o.status == "PRESENT" || o.status == "WIP").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "present") || status;
                            status = (obj.attendances?.filter(o => o.status == "ABSENT").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "absent") || status;
                            // status = (obj.attendances?.filter(o => o.status == "WIP") && "absent") || status;
                            status = (n.getDayName() === "SUN" && "sunday") || status;
                            status = (n > today && "nostatus") || status;
                            // status = (n < today && statuses[n.getDayName()]) || status;
                            // status = (obj.leaves?.filter(o => o.status == "REJECTED").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "sunday") || status;
                            // status = (obj.leaves?.filter(o => o.status == "APPROVED").map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "leave") || status;
                            // status = (obj.attendances.filter(o => o.status == "PRESENT").map(o => o.in_time >= '10:15').map(o => (o.date || "").date().toDate()).includes(n.toDate()) && "leave" ) || status;
                            // console.log(obj.attendances.filter(o => o.in_time >= '10:15'));
                            return {
                                ...o,
                                [n.getDate()]: status
                            }
                        }, {});
                        return {
                            employee_id: obj.employee_id,
                            employee_name: obj.employee_name,
                            ...attendance
                        };
                    }),
                    total: res.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .log()
            .get();
    },

    apiEmployeeAttendaceListDownload(payload = {}) {
        console.log({ apiEmployeeAttendaceListDownload: payload });
        return this.request(this.api().attendanceListDownload, payload)
            .exec("Getting the attendance please wait...")
            .mapresults((res) => {
                return {
                    data: res.data,
                }
            })
            .log()
            .get();
    },

    apiGetPlainEmployeeAttendance(payload = {}) {
        console.log({ apiEmployeeAttendaceListMonthly: payload });
        let dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let statuses = dayNames.reduce((o, n) => ({ ...o, [n]: 'nostatus' }), {});
        statuses["SUN"] = "sunday";
        console.log({ statuses });
        let today = new Date();
        return this.request(this.api().attendanceListMonthly, payload)
            .exec()
            .mapresults((res) => {
                return {
                    data: res.data[0].records.map(obj => {
                        let attendance = new Date(payload.year, payload.month, 1).dateList().map(v => (v.toDate())).reduce((o, n) => {
                            let status;
                            status = (obj.attendances.filter(o => o.status == "PRESENT").map(o => (o.date || "").date().toDate()).includes(n) && "present") || status;
                            status = (obj.attendances?.filter(o => o.status == "ABSENT").map(o => (o.date || "").date().toDate()).includes(n) && "absent") || status;
                            status = (n === "SUN" && "sunday") || status;
                            status = (n > today && "nostatus") || status;
                            return {
                                ...o,
                                [n]: status
                            }
                        }, {});
                        return {
                            employee_id: obj.employee_id,
                            employee_name: obj.employee_name,
                            ...attendance
                        };
                    }),
                    total: res.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .log()
            .get();
    },

    apiLeaveApprovalListMoke() {
        let data = [
            { id: 1, name: "John Doe", employee_id: "0077766", leave_type: "casual_leave", from: "2019-02-24", to: "2019-02-24", no_of_days: "2", reason: "Going to Hospital", office_location: "Location", status: "Approved", role: "Web Designer" },
            { id: 2, name: "John Doe", employee_id: "0077766", leave_type: "casual_leave", from: "2019-02-24", to: "2019-02-24", no_of_days: "2", reason: "Going to Hospital", office_location: "Location", status: "Approved", role: "Web Designer" },
            { id: 3, name: "John Doe", employee_id: "0077766", leave_type: "casual_leave", from: "2019-02-24", to: "2019-02-24", no_of_days: "2", reason: "Going to Hospital", office_location: "Location", status: "Approved", role: "Web Designer" },
            { id: 4, name: "John Doe", employee_id: "0077766", leave_type: "casual_leave", from: "2019-02-24", to: "2019-02-24", no_of_days: "2", reason: "Going to Hospital", office_location: "Location", status: "Approved", role: "Web Designer" },
            { id: 5, name: "John Doe", employee_id: "0077766", leave_type: "casual_leave", from: "2019-02-24", to: "2019-02-24", no_of_days: "2", reason: "Going to Hospital", office_location: "Location", status: "Approved", role: "Web Designer" }
        ];
        return this.mokeapi(() => (data), "Getting leave approval list please wait...", 2000);
    },
    apiLeaveApprovalList(data) {
        data.leave_status = data.leave_status != "ALL" ? data.leave_status : '';
        return this.request(this.api().leaveRequests, data).exec()
            .mapresults((responce) => ({
                ...responce,
                result: responce.result.map((row) => ({
                    //...row,
                    id: row._id,
                    name: row.employee_name,
                    employee_id: row.employee_id,
                    leave_type: row.leave_type,
                    from: row.from_date,
                    to: row.to_date,
                    no_of_days: row.no_of_days,
                    reason: "",
                    office_location: "",
                    status: row.status,
                    role: "",
                    file_key: row.file_key

                }))
            })).log().get();
    },
    apiUpdateLeaveRequest(data = {}, urldata = {}) {
        return this.request(this.api().updateLeaveRequest, data)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiCurrentYearHolidayListMoke() {
        let data = [
            { sl_no: 1, holiday_name: "Holiday Name Here", holiday_date: "2019-02-24", day: "Sunday" },
            { sl_no: 2, holiday_name: "Holiday Name Here", holiday_date: "2019-02-24", day: "Sunday" },
            { sl_no: 3, holiday_name: "Holiday Name Here", holiday_date: "2019-02-24", day: "Sunday" },
            { sl_no: 4, holiday_name: "Holiday Name Here", holiday_date: "2019-02-24", day: "Sunday" },
            { sl_no: 5, holiday_name: "Holiday Name Here", holiday_date: "2019-02-24", day: "Sunday" },
        ];
        return this.mokeapi(() => (data), "Getting leave approval list please wait...", 2000);
    },

    apiCurrentYearHolidayList() {
        return this.request(this.api().leavePolicies)
            .exec().mapresults((data) => (data.map((o) => ({
                title: o.question,
                description: o.answer
            })))).log().get();
    },

    apiLeavePolicyList() {
        return this.request(this.api().leavePolicies)
            .exec().mapresults((data) => (data.map((o) => ({
                title: o.question,
                description: o.answer
            })))).log().get();
    },

    apiAttendanceToday() {
        return this.request(this.api().attendanceToday)
            .exec().log().get();
    },
    apiTodaysPresentAttendanceList(data) {
        return this.request(this.api().todaysAttendenceList, data)
            .exec()
            .mapresults((responce) => {
                return {
                    data: responce.present_employees
                }
            }).log().get();
    },
    apiTodaysAbsentAttendanceList(data) {
        return this.request(this.api().todaysAttendenceList, data)
            .exec()
            .mapresults((responce) => {
                return {
                    data: responce.absent_employees
                }
            }).log().get();
    }
}
export default {
    menulinks: [
        {
            path: "/admin/attendance/daywiseattendace",
            label: "Today's Present List"
        },
        {
            path: "/admin/attendance/daywiseattendaceabsent",
            label: "Today's Absent List"
        },
        {
            path: "/admin/attendance",
            label: "Overall Team Attendance"
        },
        {
            path: "/admin/attendance/exceptionapprovals",
            label: "Exception Approvals"
        },
        {
            path: "/admin/attendance/approvedexceptions",
            label: "Approved Exceptions"
        },
        {
            path: "/admin/attendance/attendance_overview",
            label: "Attendance Overview"
        },
    ]
}
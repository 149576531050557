import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";
import "./addfinancialyear.scss";

export class FinancialYear extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("FinancialYear");
    this.state = {
      columns: [
        {
          dataIndex: "financial_year_name",
          title: "Financial Year Name",
        },
        {
          dataIndex: "financial_year",
          title: "Financial Year",
          render: (text) => "FY" + text,
        },
        {
          dataIndex: "comments",
          title: "Comments",
          render: (text, record) => {
            return (
              <span
                className="text_truncate cursor-pointer"
                title={record?.comments}
                onClick={() => alert(record?.comments)}
              >
                {record?.comments}
              </span>
            );
          },
        },
      ],
      data: [],
      editMode: false,
      viewMode: false,
      addMode: false,
      editData: {},
      rowcount: 0,
      project: {},
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
        filter: {
          project_id: props?.urlparams?.project_id,
        },
      },
    };
    this.formFields = [
      {
        type: "select",
        name: "project_id",
        label: "Project Name",
        defaultValue: props?.urlparams?.project_id,
        disabled: true,
        fieldclass: Object.className({
          hidden: !!props.urlparams.project_id || true,
        }),
      },
      {
        type: "text",
        name: "financial_year_name",
        label: "Financial Year Name",
        maxLength: "50",
        required: true,
      },
      {
        type: "select",
        name: "financial_year",
        label: "Financial Year",
        required: true,
        options: [],
      },
      {
        type: "textarea",
        label: "Comments",
        name: "comments",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-8",
      },
    ];
    this.tablinks = mixins.tablinks;
  }

  get listMode() {
    return !(this.state.editMode || this.state.addMode);
  }

  get firstRecord() {
    return (this.state.data || []).find((o) => true) || { id: "" };
  }

  get activeRecord() {
    let now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();
    if (month >= 3) {
      year++;
    }

    return (
      (this.state.data || []).find((o) => +o.financial_year == year) || {
        id: "",
      }
    );
  }

  addRecord() {
    this.setState({
      addMode: true,
    });
  }

  sumitForm(event, data, form) {
    console.log({ addFinancialYear: data });

    data = {
      ...data,
      project_id: this.props.urlparams.project_id,
    };

    const resolveList = () => {
      this.fetchData().then(() => {
        this.setState({
          editMode: false,
          addMode: false,
          editData: {},
        });
      });
      form.reset();
    };

    if (this.state.editMode) {
      console.log("edit mode data", this.state.editData);
      this.api
        .apiEditFinancialYear(data, { project_fy_id: this.state.editData.id })
        .then(resolveList);
    } else {
      this.setState({
        editData: data,
      });
      this.api.apiAddFinancialYear(data).then(resolveList);
    }
  }

  componentDidMount() {
    this.fetchData();
    this.getProjectDetails();
  }

  getProjectDetails() {
    let payload = {
      project_id: this.props?.urlparams?.project_id,
    };
    console.log("FinancialYear", payload);

    this.api.apiGetProject(payload).then((data) => {
      this.setState({ project: data });
    });
  }

  fetchData() {
    return new Promise((resolve, reject) => {
      this.api
        .apiGetFinancialYears(this.state.search)
        .then(({ data, total }) => {
          console.log(data);
          this.setState({
            data: [...data],
            rowcount: total,
          });
          resolve();
        });
    });
  }

  editFinancialAction(v) {
    console.log("edit fy", v);
    this.setState({
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }

  viewFinancialAction(v) {
    this.setState({
      editMode: true,
      viewMode: true,
      editData: v,
    });
  }

  deleteFinancialAction(v) {
    console.log("delete fy", v);
    let data = {
      project_id: this.props?.urlparams?.project_id,
      project_fy_id: v.id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteFinancial(data).then(() => {
        this.fetchData();
      });
    });
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  getFinancialYears(start, end) {
    //FY24 for period of 1st April  2023 to 31st March 2024
    let startDate = new Date(start);
    let startMonth = startDate.getMonth();
    let startYear = new Date(start).getFullYear();
    if (startMonth >= 3) {
      startYear++;
    }
    let endDate = new Date(end);
    let endMonth = endDate.getMonth();
    let endYear = new Date(end).getFullYear();
    if (endMonth >= 3) {
      endYear++;
    }
    // let endYear = new Date(end).getFullYear();

    let options = [];
    for (let i = startYear; i <= endYear; i++) {
      let myr = +(i + "").slice(2);
      options.push({ key: i, label: `FY${i}` });
    }
    console.log("getFinancialYears: ", options);
    return options;
  }

  updateFormFields() {
    let { formFields } = this;
    let { project } = this.state;
    if (project && project.project_start_date && project.project_end_date) {
      let financialYears = this.getFinancialYears(
        project.project_start_date,
        project.project_end_date
      );
      formFields.find((field) => field.name == "financial_year").options =
        financialYears;
      formFields.find((field) => field.name == "financial_year").defaultValue =
        new Date().getFullYear().toString();
    }
    return formFields;
  }

  getBreadCums() {
    if (this.props.urlparams.project_id) {
      // return "Project / Financial Year " + new Date().getFinacialYear();
      return `${this.state.project.project_name} / Financial Year`;
    } else {
      return "Financial Years";
    }
  }

  getBackLink() {
    if (this.props.urlparams.project_id) {
      let backLink =
        "/projectmanagement/addproject" +
        Object.encodeuri({
          project_id: this.props.urlparams.project_id,
          ...(this.props.urlparams.readonly
            ? { readonly: this.props.urlparams.readonly }
            : {}),
        });
      return backLink;
    }
  }

  inFinancialYears(v) {
    let now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();
    if (month >= 3) {
      year++;
    }
    return v.financial_year == year;
  }

  render() {
    let { tablinks, formFields, props } = this;
    let { columns, data = [], rowcount, editData } = this.state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      } else {
        field.defaultValue = "";
      }
      return field;
    });
    formFields = this.updateFormFields();
    return (
      <LayoutWrapper
        title="Financial Year"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
        endname={false}
      >
        <AppCircleStepper data={tablinks}></AppCircleStepper>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        {(props?.urlparams?.project_id && (
          <>
            {!this.listMode && (
              <div className="row m-auto">
                <AppForm
                  className="flex flex-col gap-4 bg-white p-4"
                  onSubmit={(...args) => this.sumitForm(...args)}
                >
                  <div className="row">
                    {formFields?.map((field, i) => (
                      <div
                        className={
                          field.fieldclass
                            ? field.fieldclass
                            : "col-12 col-md-6 col-lg-4"
                        }
                        key={"field" + i}
                      >
                        <AppInput
                          className="grow"
                          {...field}
                          disabled={this.state.viewMode}
                        ></AppInput>
                      </div>
                    ))}
                  </div>
                  <div className="row no-gutters">
                    <button
                      className={Object.className({
                        "btn add-btn col col-md-1 ml-auto": true,
                        hidden: this.state.viewMode,
                      })}
                    >
                      {this.state.editMode ? "Update" : "Add"}
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ editMode: false, addMode: false })
                      }
                      className={Object.className({
                        "btn btn_primary col col-md-1 grey me-2 ms-2":
                          !this.state.viewMode,
                        "btn btn_primary col col-md-1 grey me-2 ml-auto":
                          this.state.viewMode,
                      })}
                    >
                      Cancel
                    </button>
                  </div>
                </AppForm>
              </div>
            )}
            {this.listMode && (
              <div className="row">
                <div className="col-12 mb-4">
                  <button
                    className={Object.className({
                      "btn add-btn col col-md-1 ml-auto": true,
                      hidden: this.props.urlparams.readonly == 1 ? true : false,
                    })}
                    onClick={() => this.addRecord()}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}

            <div className="row addfinancialyear">
              <div className="col-12">
                {!!data.length && this.listMode && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    deleteAction={(v) => this.deleteFinancialAction(v)}
                    editAction={(v) => this.editFinancialAction(v)}
                    viewAction={(v) => this.viewFinancialAction(v)}
                    editable={this.props.urlparams.readonly == 1 ? false : true}
                    deletable={
                      this.props.urlparams.readonly == 1 ? false : true
                    }
                    pageSize={10}
                    targetType="tap"
                    renderAction={(v) => this.inFinancialYears(v)}
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>

            {this.listMode && data.length && (
              <div className="row">
                <div className="col-12 mt-4">
                  <Link
                    to={
                      "/projectmanagement/addtask?" +
                      Object.QueryString({
                        ...this.props.urlparams,
                        project_fy_id: this.activeRecord.id,
                      })
                    }
                    className="btn add-btn"
                  >
                    Next
                  </Link>
                </div>
              </div>
            )}
          </>
        )) ||
          ""}
      </LayoutWrapper>
    );
  }
}

export default connect(FinancialYear);

import React, { Component } from "react";

import BasicDetails from "../Addemployee/BasicDetails";
import { LayoutWrapper } from "../../LayoutWrapper";
export const tabs = [
  {
    path: "/admin/employee/onboarding",
    label: "Personal Info",
    name: "personal_info",
  },
  {
    path: "/admin/employee/onboarding/documents",
    label: "Docs Upload",
    name: "docs_upload",
  },
];

export const getEmpId = function (props, defEmpId = 0) {
  let empId = props.location.href.url().params.emp_id || "";
  return empId != 0 ? empId : defEmpId;
};

export const checkOnboardTab = function (props) {
  let onboarding = props.store?.onborading?.flow || "personal_info";
  let empId = props.store?.onborading?.id || 0;
  let route = tabs.find((o) => o.name == onboarding);
  let isLive = props.location.pathname == route?.path;
  let hasEmpId = getEmpId(props, 0);
  if ((!isLive && route) || !hasEmpId) {
    props.navigate(route.path + `?emp_id=${empId}`);
  }
};

export const currentRouteIndex = function (props) {
  let onboarding = props.store?.onborading?.flow || "personal_info";
  return tabs.findIndex((o) => o.name == onboarding);
};

export const nextRoute = function (props) {
  let index = currentRouteIndex(props);
  return tabs[index + 1];
};

export const navigateNextTab = function (props, defEmpId) {
  let empId = getEmpId(props, defEmpId);
  let route = nextRoute(props);
  if (route) {
    props.setstore("onborading", { flow: route.name, id: empId });
    props.navigate(route.path + `?emp_id=${empId}`);
  }
};
export class OnBoarding extends Component {
  componentDidMount() {
    // checkOnboardTab(this.props);
    window.OnBoarding = this;
  }
  handleSubmit(e) {
    e.preventDefault();

    let formData = e.target.getData();

    if (formData.employee_role === "manager") {
      formData.address = formData.district;
      delete formData.district;
      delete formData.village_id;
      delete formData.village_name;
    } else if (formData.employee_role === "cluster-co-ordinator") {
      delete formData.village_id;
      delete formData.village_name;
    } else {
      formData.village_id = formData.village_id?.splitBy(",");
      formData.village_name = formData.village_name?.splitBy(",");
    }

    console.log("formData", formData);

    if (this.props.urlparams.emp_id) {
      this.props.api
        .apiUpdateEmployee(formData, {
          employee_id: this.props.urlparams.emp_id,
        })
        .then(({ data }) => {
          let url =
            "/admin/employee?" +
            (this?.props?.urlparams?.is_deleted ? "is_deleted=true" : "");
          console.log({ editData: data, url });
          navigate(url);
        });
    } else {
      this.props.api.apiCreateEmployee(formData).then((res) => {
        console.log({ apiCreateEmployee: res });
        alert(
          "Employee Created Successfully please continue to fill the other details"
        );
        let empId = res.employee_id;
        navigateNextTab(this.props, empId);
      });
    }
  }

  getBack() {
    let back;
    if (Object.keys(this.props.urlparams).includes("emp_id")) {
      back = `Employee / ${this.props?.urlparams?.emp_name} / Edit Personal Info.`;
    } else {
      back = "Employee";
    }
    return back;
  }
  getBackLink() {
    let backLink;
    if (Object.keys(this.props.urlparams).includes("emp_id")) {
      backLink = `/admin/profile/employee-profile?${Object.QueryString({
        emp_id: this.props.urlparams.emp_id,
        emp_name: this.props.urlparams.emp_name,
      })}`;
    } else {
      backLink = "/admin/employee";
    }

    return backLink;
  }

  render() {
    return (
      <LayoutWrapper
        title="Employee Onboarding"
        back={this.getBack()}
        backlink={this.getBackLink()}
      >
        {/* <AppCircleStepper data={tabs}></AppCircleStepper> */}
        <BasicDetails
          onSubmit={(...args) => this.handleSubmit(...args)}
        ></BasicDetails>
      </LayoutWrapper>
    );
  }
}

export default connect(OnBoarding);

import lmsMapcolumns from "../lms/lms.mapcolumns";

export default {
    apiGetDepartmentList(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().departmentList, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    // total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiCreateScheme(data) {
        let payload =
        {
            ...lmsMapcolumns.mapPayload('schemeDetails', data),
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().schemeDetails, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiUpdateScheme(data, urldata = {}) {
        return this.request(this.api().updateSchemeDetails, data)
        .urltransform(urldata).exec().log().get();
    },
    apiGetAllSchemes(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().schemesList, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    // total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetParameterList(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().parameterList, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    // total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetSchemeDetailsById(urldata = {}) {
        return this.request(this.api().schemeDetailsById)
            .urltransform(urldata)
            .exec()
            .mapresults((response) => {
                return {
                    data: response.data,
                }
            })
            .log()
            .get();
    },
    apiCreateCriteria(data, urldata = {}) {
        return this.request(this.api().createCriteria, data)
        .urltransform(urldata)
        .exec().log()
        .mapresults((response) => {
            return response.data
        })
        .get();
    },
    apiGetCriterias(data, urldata = {}) {
        return this.request(this.api().getCriterias, data)
            .urltransform(urldata)
            .exec()
            .mapresults((response) => {
                return {
                    data: response.data?.items[0]?.records,
                    total: response.data?.items[0]?.pagination[0]?.totalRecords,
                }
            })
            .log()
            .get();
    },
    apiDeleteScheme(urldata = mixins.params()) {
        return this.request(this.api().deleteScheme)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiDeleteCriteria(urldata = mixins.params()) {
        return this.request(this.api().deleteCriteria)
            .urltransform(urldata)
            .exec().log().get();
    },
    apiUpdateCriteria(data, urldata = {}) {
        let payload =
        {
            ...lmsMapcolumns.mapPayload('updateCriteria', data),
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().updateCriteria, payload)
        .urltransform(urldata).exec().log().get();
    },
}
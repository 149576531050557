import onboardapi from "./devapis/onboardapi";
import datacollectionapi from "./devapis/datacollectionapi";
import projectmanagementapi from "./devapis/projectmanagementapi";
import lmsapi from "./devapis/lmsapi";
import womnentrapi from "./devapis/womnentrapi";
import schemesapi from "./devapis/schemesapi";

export const devapis = {
    ...onboardapi,
    datacollection: datacollectionapi,
    projectmanagement: projectmanagementapi,
    "public/dcfresponse": datacollectionapi,
    lms: lmsapi,
    womenentrepreneur: womnentrapi,
    ...schemesapi,

    ifsccode: {
        url: "https://ifsc.razorpay.com/ICIC0001417",
        method: "get"
    },
    login: {
        //url: "/login/as-employer",
        url: "/auth/login",
        method: "post"
    },
    getUser: {
        url: "/hrms/employee/me",
        method: "get"
    },
    otpLogin: {
        url: "/auth/otp/{mobile_num}",
        method: "get"
    },
    otpLoginVerify: {
        url: "/auth/verify",
        method: "post"
    },
    listEmployee: {
        url: "/hrms/employee",
        method: "get"
    },
    listArchiveEmployee: {
        url: "/hrms/employee/archive",
        method: "get"
    },
    // DASHBOARD API calls
    adminDashborad: {
        url: "/hrms/dashboard/admin",
        method: "get"
    },
    employeeDashborad: {
        url: "/hrms/dashboard/employee",
        method: "get"
    },
    createEmployee: {
        url: "/hrms/employee",
        method: "post"
    },
    getEmployeeById: {
        url: "/hrms/employee/id/{employee_id}",
        method: "get"
    },
    getEmployeeByrole: {
        url: "/hrms/employee/{employee_role}",
        method: "get"
    },
    getEmployeeByMultirole: {
        url: "/hrms/employee/role",
        method: "post"
    },
    getEmployeeBySearch: {
        url: "/hrms/employee",
        method: "get"
    },
    updateEmployee: {
        url: "/hrms/employee/{employee_id}",
        method: "put"
    },
    getEmployeeByDelta: {
        url: "/hrms/delta/employee/{delta_employee_id}",
        method: "get"
    },
    updateEmployeeRemark: {
        url: "/hrms/delta/response/{delta_employee_id}",
        method: "put"
    },
    deleteEmployee: {
        url: "/hrms/employee/{employee_id}",
        method: "delete"
    },
    softDeleteEmployee: {
        url: "/hrms/employee/{employee_id}",
        method: "put"
    },
    createEmployeeAdmin: {
        url: "/hrms/employee/admin",
        method: "post"
    },
    employeeInfo: {
        url: `/hrms/employee/me`,
        method: "get"
    },
    employeeBankInfo: {
        url: `/employer/employee/{emp_id}/bank-info`,
        method: "get"
    },
    employeeSalaryInfo: {
        url: `/employer/employee/{emp_id}/salary-details`,
        method: "get"
    },
    employeeDocList: {
        url: `/employer/employee/{emp_id}/docs`,
        method: "get"
    },
    // deleteSoftEmployee: {
    //     url: `/employer/employee/soft/{emp_id}`,
    //     method: "patch"
    // },
    designationList: {
        url: `/employer/settings/designations`,
        method: "get"
    },
    createDesignation: {
        url: `/employer/settings/designations`,
        method: "post"
    },
    editDesignation: {
        url: `/employer/settings/designations/{designa_id}`,
        method: "put"
    },
    deleteDesignation: {
        url: `/employer/settings/designations/{designa_id}`,
        method: "delete"
    },
    departmentsList: {
        url: `/employer/settings/departments`,
        method: "get"
    },
    worklocationList: {
        url: `/employer/settings/work-locations`,
        method: "get"
    },
    createWorklocation: {
        url: `/employer/settings/work-locations`,
        method: "post"
    },
    updateWorklocation: {
        url: `/employer/settings/work-locations/{loc_id}`,
        method: "put"
    },
    deleteWorklocation: {
        url: `/employer/settings/work-locations/{loc_id}`,
        method: "delete"
    },
    holidayList: {
        url: "/employer/settings/holidays",
        method: "get"
    },
    addHoliday: {
        url: "/employer/settings/holidays",
        method: "post"
    },
    editHoliday: {
        url: "/employer/settings/holidays/{hol_id}",
        method: "put"
    },
    deleteHoliday: {
        url: "/employer/settings/holidays/{hol_id}",
        method: "delete"
    },
    uploadDocuments: {
        url: `/employer/employee/{emp_id}/docs`,
        method: "post"
    },
    removeDocuments: {
        url: `/employee/remove-file`,
        method: "delete"
    },
    removeEmployeeDocuments: {
        url: `/employer/employee/{emp_id}/docs/{docs_id}`,
        method: "delete"
    },
    updateEmployeeProfile: {
        url: `/employer/employee/{emp_id}`,
        method: "put"
    },
    updateEmployeeAccountInfo: {
        url: `/employer/employee/{emp_id}/bank-info`,
        method: "post"
    },
    updateEmployeeAccountInfoPatch: {
        url: `/employer/employee/{emp_id}/bank-info`,
        method: "put"
    },
    updateEmployeeSalaryInfo: {
        url: `/employer/employee/{emp_id}/salary-details`,
        method: "post"
    },
    updateEmployeeSalaryInfoPatch: {
        url: `/employer/employee/{emp_id}/salary-details`,
        method: "put"
    },
    downloadFile: {
        url: `/download-documents`,
        method: "post"
    },
    leavePolicies: {
        url: "/employer/settings/leave-policies",
        method: "get"
    },
    leaveRequests: {
        url: "/employer/hrm/leave-requests",
        method: "get"
    },
    updateLeaveRequest: {
        url: "/employer/hrm/leave-requests/{req_id}",
        method: "put"
    },
    attendanceToday: {
        url: "/employer/hrm/attendance/today",
        method: "get"
    },
    attendanceList: {
        url: "/employer/hrm/team-attendance",
        method: "get"
    },
    attendanceListMonthly: {
        url: "/hrms/attendance/monthly",
        method: "get"
    },
    attendanceListDownload: {
        url: "/hrms/attendance/download",
        method: "get"
    },
    attendanceActivityDownload: {
        url: "/hrms/attendance/activity",
        method: "get"
    },
    exceptionRequestList: {
        url: "/employer/hrm/attendance/exception-requests",
        method: "get"
    },
    updateExceptionRequest: {
        url: "/employer/hrm/attendance/exception-requests/{req_id}",
        method: "put"
    },
    dashboardStatsList: {
        url: "/employer/hrm/dashboard-stats",
        method: "get"
    },
    refreshToken: {
        url: "/auth/refresh",
        method: "post"
    },
    salaryBreakdown: {
        url: "/employer/payroll/salary-breakdown",
        method: "get"
    },
    runPayroll: {
        url: "/employer/payroll/run-payroll",
        method: "post"
    },
    getPayroll: {
        url: "/employer/payroll/get-payroll",
        method: "get"
    },
    masterData: {
        url: "/employer/payroll/master-data",
        method: "get"
    },
    updatePaySlip: {
        url: "/employer/payroll/payslip-action/{payslip_id}",
        method: "put"
    },
    updateProfilePic: {
        url: "/employer/employee/{emp_id}/upload-profile-pic",
        method: "post"
    },
    allowPayslip: {
        url: "/employer/payroll/payslips/{payslip_id}/generate",
        method: "put"
    },
    bankList: {
        url: "/employer/settings/employer-banks",
        method: "get"
    },
    bankCreate: {
        url: "/employer/settings/employer-banks",
        method: "post"
    },
    bankUpdate: {
        url: "/employer/settings/employer-banks",
        method: "put"
    },
    bankDelete: {
        url: "/employer/settings/employer-banks",
        method: "delete"
    },
    todaysAttendenceList: {
        url: "/employer/hrm/daywise-attendance",
        method: "get"
    },
    employeeAttendenceDeatils: {
        url: "/employer/hrm/{emp_id}/report",
        method: "get"
    },
    createMasterData: {
        url: "/hrms/master",
        method: "post"
    },
    //MASTER DATA - DISTRICT API ENDPOINTS
    addMasterDistrict: {
        url: "/hrms/master/district",
        method: "post"
    },
    getMasterDistrict: {
        url: "/hrms/master/district",
        method: "get"
    },
    getDistrictLocation: {
        url: "/hrms/master/getdistrict",
        method: "get"
    },
    updateMasterDistrict: {
        url: "/hrms/master/district/{district_name}",
        method: "put"
    },
    deleteMasterDistrict: {
        url: "/hrms/master/district/{district_name}",
        method: "delete"
    },
    //MASTER DATA - CLUSTER API ENDPOINTS
    addMasterCluster: {
        url: "/hrms/master/cluster",
        method: "post"
    },
    getMasterCluster: {
        url: "/hrms/master/cluster/{district}",
        method: "get"
    },
    getAllClusters: {
        url: "/hrms/master/cluster",
        method: "get"
    },
    updateMasterCluster: {
        url: "/hrms/master/cluster/{district}/{cluster_name}",
        method: "put"
    },
    deleteMasterCluster: {
        url: "/hrms/master/cluster/{district}/{cluster_name}",
        method: "delete"
    },
    //MASTER DATA - VILLAGE API ENDPOINTS
    addMasterVillage: {
        url: "/hrms/master/village",
        method: "post"
    },
    getMasterVillage: {
        url: "/hrms/master/village/{district}/{cluster}",
        method: "get"
    },
    updateMasterVillage: {
        url: "/hrms/master/village/{village_id}",
        method: "put"
    },
    deleteMasterVillage: {
        url: "/hrms/master/village/{village_id}",
        method: "delete"
    },
    moveVillage: {
        url: "/hrms/master/village/cluster",
        method: "put"
    },
    // MEETING MINUTES API ENDPOINTS
    addMeetingMinutes: {
        url: "/hrms/meetingminutes",
        method: "post"
    },
    getMeetingMinutes: {
        url: "/hrms/meetingminutes",
        method: "get"
    },
    getMeetingMinutesById: {
        url: "/hrms/meetingminutes/get/{mom_id}",
        method: "get"
    },
    updateMeetingMinutes: {
        url: "/hrms/meetingminutes/{mom_id}",
        method: "put"
    },
    deleteMeetingMinutes: {
        url: "/hrms/meetingminutes/{mom_id}",
        method: "delete"
    },


}

export default devapis;
import React, { Component } from "react";

import DocsUpload from "../../Addemployee/DocsUpload";
import { LayoutWrapper } from "../../../LayoutWrapper";
import { tabs, checkOnboardTab, navigateNextTab } from "../onboarding";

export class UploadDocuments extends Component {
  componentDidMount() {
    checkOnboardTab(this.props);
    window.UploadDocuments = this;
  }
  handleSubmit(e) {
    e.preventDefault();
    success("Employee Onboarding Successfull.");
    this.props.navigate("/admin/employee");
    console.log(e);
  }
  render() {
    return (
      <LayoutWrapper title="Employee Onboarding" back="Employee / Docs Upload">
        <AppCircleStepper data={tabs}></AppCircleStepper>
        <DocsUpload
          onSubmit={(...args) => this.handleSubmit(...args)}
        ></DocsUpload>
      </LayoutWrapper>
    );
  }
}

export default connect(UploadDocuments);

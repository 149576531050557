// import React from "react";
// import Employeeslist from "../employee/employeeslist/employeeslist";

// const inactiveEmployeeRoute = ({ location }) => (
//   <LayoutWrapper title="Employee List (Inactive)" back="Employees">
//     <Employeeslist inactive={true} />
//   </LayoutWrapper>
// );

// export default connect(inactiveEmployeeRoute);

import React, { Component } from "react";
import { LayoutWrapper } from "../LayoutWrapper";
import Addemployee from "../employee/Addemployee/Addemployee";
import Deleteemployee from "../employee/Deleteemployee";
import Editemployee from "../employee/Editemployee";
import InactiveEmployeeList from "./InactiveEmployeeList";

export class InactiveEmployeeslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };

    this.links = [
      {
        path: "/admin/attendance/exceptionapprovals",
        label: "Exception Approvals",
      },
      {
        path: "/admin/attendance",
        label: "Overall Team Attendance",
      },
    ];
  }

  render() {
    let { links } = this;
    return (
      <>
        <LayoutWrapper title="Employee List (Inactive)" back="Employees">
          <InactiveEmployeeList />
          {/* /Page Content */}
          {/* Add Employee Modal */}
          <Addemployee />
          {/* /Add Employee Modal */}
          {/* Edit Employee Modal */}
          <Editemployee />
          {/* /Edit Employee Modal */}
          {/* Delete Employee Modal */}
          <Deleteemployee />
          {/* /Delete Employee Modal */}
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(InactiveEmployeeslist);

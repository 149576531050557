import React, { Component } from "react";
import AccountDetails from "../../Addemployee/AccountDetails";
import { LayoutWrapper } from "../../../LayoutWrapper";
import { tabs, checkOnboardTab, navigateNextTab } from "../onboarding";
export class AccountDetailsView extends Component {
  componentDidMount() {
    checkOnboardTab(this.props);
    window.AccountDetailsView = this;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.api
      .apiUpdateEmployeeProfileAccount(e.target.getData())
      .then((data) => {
        console.log({ apiUpdateEmployeeProfileAccount: data });
        navigateNextTab(this.props);
      });
    console.log(e);
  }

  render() {
    return (
      <LayoutWrapper title="Employee Onboarding" back="Employee">
        <AppLinkTabs data={tabs}></AppLinkTabs>
        <AccountDetails
          onSubmit={(...args) => this.handleSubmit(...args)}
        ></AccountDetails>
      </LayoutWrapper>
    );
  }
}

export default connect(AccountDetailsView);

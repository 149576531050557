export const pmMapcolumns = {
    attachments: {
        attachment_id: "attachmentId",
        file_name: "attachmentName",
    },
    dashboardTotal: {
        totalProjects: "totalProjects",
        totalTasks: "totalTasks",
        totalEmployeeResources: "totalEmployeeResources",
        totalMaterialResources: "totalMaterialResources"
    },
    projects: {
        project_id: "project_id",
        project_name: "project_name",
        project_desc: "project_description",
        project_start_date: "project_start_date",
        project_end_date: "project_end_date",
        project_status: "project_status",
        updatedAt: "updatedAt",
        updated_by: "updatedBy",
        attachments: {
            key: "attachments",
            value: (attachments) =>
                attachments.map((attachment) => pmMapcolumns.mapFields("attachments", attachment)),
            reversevalue: (attachments, { data }) => {
                if (attachments instanceof Array) {
                    return attachments;
                } else if (attachments) {
                    return [
                        {
                            attachment_id: attachments,
                            file_name: "Download",
                        },
                    ];
                } else {
                    delete data["attachments"];
                }
            },
        },
    },
    financial_years: {
        project_fy_id: "id",
        project_fy_name: "financial_year_name",
        project_fy_year: "financial_year",
        comments: "comments"
    },
    tasks: {
        project_id: "project_id",
        project_fy_id: "project_fy_id",
        _id: "task_id",
        task_name: "task_name",
        task_desc: "task_desc",
        task_status: "task_status",
        task_priority: "task_priority",
        attachments: {
            key: "attachments",
            value: (attachments) =>
                attachments.map((attachment) => pmMapcolumns.mapFields("attachments", attachment)),
            reversevalue: (attachments, { data }) => {
                if (attachments instanceof Array) {
                    return attachments;
                } else if (attachments) {
                    return [
                        {
                            attachment_id: attachments,
                            file_name: "task_doc",
                        },
                    ];
                } else {
                    delete data["attachments"];
                }
            },
        },
    },
    budget_planner: {
        "annual": "anual_budget_amount"
    },
    budget: {
        "budget_id": "budget_id",
        project_id: "project_id",
        project_fy_id: "project_fy_id",
        task_id: "task_id",
        task_name: "task_name",
        task_desc: "task_desc",
        task_status: "task_status",
        task_priority: "task_priority",
        created_by: "created_by",
        last_updated_by: "last_updated_by",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        unit_cost: "unit_cost",
        comment: "comments",
        budget_planner: {
            key: "budget_planner",
            rkeys: ["anual_budget_amount", "quarter", "monthly"],
            value: (value, collector) => {
                value = pmMapcolumns.mapFields("budget_planner", value);
                Object.keys(value).forEach(key => {
                    if (typeof value[key] == "object") {
                        Object.keys(value[key]).forEach((ikey) => {
                            collector[`${key}.${ikey}`] = value[key][ikey];
                        });
                    } else {
                        collector[key] = value[key];
                    }
                })
                return value;
            },
            reversevalue: (value, { collector, data, mapper }) => {
                // console.log({ collector, data, mapper, value });
                if (mapper.mapkey) {
                    let budgetPlanner = pmMapcolumns.mapPayload("budget_planner", { [mapper.mapkey]: data[mapper.mapkey] });
                    let iniBudgetPlanner = (collector[`budget_planner`] || {}).value;
                    collector[`budget_planner`].value = {
                        ...iniBudgetPlanner,
                        ...budgetPlanner
                    };
                    delete data[mapper.mapkey];
                } else {
                    return value;
                }
            }
        },
        attachments: {
            key: "attachments",
            value: (attachments) =>
                attachments.map((attachment) => pmMapcolumns.mapFields("attachments", attachment)),
            reversevalue: (attachments, { data }) => {
                if (attachments instanceof Array) {
                    return attachments;
                } else if (attachments) {
                    return [
                        {
                            attachment_id: attachments,
                            file_name: "task_doc",
                        },
                    ];
                } else {
                    delete data["attachments"];
                }
            },
        },
    },
    resources: {
        project_id: "project_id",
        project_fy_id: "project_fy_id",
        resource_id: "resource_id",
        resource_type: "resource_type",
        employee_id: "employee_id",
        employee_name: "employee_name",
        employee_role: "employee_role",
        project_on_boarding_date: "project_on_boarding_date",
        project_off_boarding_date: "project_off_boarding_date",
        comments: "comments",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    expenses: {
        expense_id: "expense_id",
        expense_name: "expense_name",
        expense_description: "expense_description",
        budget_id: "budget_id",
        expense_amount: "expense_amount",
        expense_date: "expense_date",
        project_name: "project_name",
        project_id: "project_id",
        project_fy_id: "project_fy_id",
        task_name: "task_name",
        task_id: "task_id",
        event_name: "event_name",
        attachments: {
            key: "attachments",
            value: (attachments) =>
                attachments.map((attachment) => pmMapcolumns.mapFields("attachments", attachment)),
            reversevalue: (attachments, { data }) => {
                if (attachments instanceof Array) {
                    return attachments;
                } else if (attachments) {
                    return [
                        {
                            attachment_id: attachments,
                            file_name: "Download",
                        },
                    ];
                } else {
                    delete data["attachments"];
                }
            },
        },
        created_by: "created_by",
        last_updated_by: "last_updated_by",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
    },
    mapFields(name, data) {
        let fields = Object.keys(data);
        let mapper = this[name] || {};
        // console.log(fields);
        return fields.reduce((c, key) => {
            let mapobj = mapper[key];
            let newkey = mapobj || key;
            newkey = typeof newkey === "string" ? newkey : newkey.key;
            newkey = newkey || key;
            let mapvalue = data[key];
            if (mapobj && mapobj.value instanceof Function) {
                mapvalue = mapobj.value(mapvalue, c);
            } else if (mapobj && ![undefined, null].includes(mapobj.value)) {
                mapvalue = mapobj.value;
            }
            return {
                ...c,
                [newkey]: mapvalue,
            };
        }, {});
    },
    mapPayload(name, data, excepts = []) {
        let payload = { ...data };
        let mapper = this[name] || {};
        mapper = Object.entries(mapper).reduce((c, [key, value]) => {
            if (value.rkeys && value.rkeys instanceof Array) {
                let commonMap = {};
                value.rkeys.forEach(key => {
                    if (Object.keys(payload).includes(key)) {
                        c[key] = {
                            commonMap,
                            key: value.key,
                            value: value.value,
                            reversevalue: value.reversevalue,
                            mapkey: key,
                        };
                    }
                })
            }
            return c
        }, mapper);
        // console.log({ mapper });
        if (mapper) {
            let mapdata = Object.entries(mapper).reduce((c, [key, value]) => {
                let newkey = value || key;
                newkey = typeof newkey === "string" ? newkey : newkey.key;
                newkey = newkey || key;
                if (excepts.includes(newkey)) {
                    key = newkey;
                }
                let reversevalue = value.reversevalue || ((v) => v);
                return {
                    ...c,
                    [key]: {
                        mapkey: newkey,
                        value: reversevalue(data[newkey], { collector: c, data: payload, mapper: value })
                    },
                };
            }, {});
            let filterMapData = Object.filter(mapdata, (v) => ![undefined].includes(v.value));
            let retData = Object.map(filterMapData, (v) => v.value);
            let filterMapKeys = Object.values(filterMapData).map(v => v.mapkey);
            let restData = Object.filter(payload, (v, k) => !filterMapKeys.includes(k));
            return {
                ...restData,
                ...retData
            };
        } else {
            return data;
        }
    },
};
window.pmMapcolumns = {
    ...(window.pmMapcolumns || {}),
    ...pmMapcolumns,
};
export default pmMapcolumns;

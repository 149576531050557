/**
 * Signin Firebase
 */

import { Component } from "react";
import { Helmet } from "react-helmet";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import "./dashboard.scss";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AdminDashboard");
    this.state = {
      cardData: [],
      budgetChartData: [],
      budgetByIdChartData: [],
      projectListData: [],
      projectMiniData: [],
      search: {
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      chart1: true,
      chart2: true,
    };
    this.barchartdata = [
      { y: "2006", "Total Income": 100, "Total Outcome": 90 },
      { y: "2007", "Total Income": 75, "Total Outcome": 65 },
    ];
  }
  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  componentDidMount() {
    this.getDashboardTotal();
    this.getDashboardBudget();
    this.getProjectList();
  }

  getDashboardTotal() {
    this.props.api.apiGetDashboardTotal().then((res) => {
      // console.log("getDashboardTotal", res);
      let mergedData = [
        {
          name: "total_projects",
          label: "Total Projects",
          value: res.totalProjects,
          icon: "las la-lightbulb",
          iconWhite: imagepaths.totalProjPMIconWhite,
          display: true,
        },
        {
          name: "total_tasks",
          label: "Total Tasks",
          value: res.totalTasks,
          icon: "las la-user-plus",
          iconWhite: imagepaths.totalPMTaskIconWhite,
          display: true,
        },
        {
          name: "total_employee",
          label: "Total Employee",
          value: res.totalEmployeeResources,
          icon: "la la-users",
          iconWhite: imagepaths.totalPMTotalEmpIconWhite,
          display: this.isAdmin,
        },
        {
          name: "total_material",
          label: "Total Material",
          value: res.totalMaterialResources,
          icon: "las la-laptop",
          iconWhite: imagepaths.totalPMTotalMaterialIconWhite,
          display: this.isAdmin,
        },
      ];
      this.setState({
        cardData: mergedData,
      });
    });
  }
  getDashboardBudget() {
    this.props.api.apiGetDashboardBudget().then((res) => {
      if (res) {
        if (res.length === 0) {
          this.setState({
            chart1: false,
          });
        } else {
          this.setState({
            chart1: true,
          });
        }
        let chartData = res.map((item) => {
          return {
            y: item.project_fy_year,
            "Budget Amount": item.budget_amount,
            Expenses: item.expenses,
          };
        });
        // console.log("chartData ", chartData);
        this.setState({ budgetChartData: chartData });
      }
    });
  }

  getProjectList() {
    this.props.api
      .apiGetProjectsList(this.state.search)
      .then(({ data, total }) => {
        // console.log({ apiGetProjectsList: data });
        let projecList = data.map((item) => {
          return {
            key: item.project_id,
            label: item.project_name,
          };
        });
        // console.log({ projecList: projecList });
        this.setState({ projectListData: projecList, projectMiniData: data });

        let currentProjectObj = [projecList[0]];
        if (currentProjectObj) {
          this.getDashboardBudgetById(currentProjectObj);
        }
      });
  }
  getDashboardBudgetById(data) {
    // console.log("project id: ", data[0].key);

    let payload = {
      project_id: data[0].key,
    };
    this.props.api.apiGetDashboardBudgetById(payload).then((res) => {
      // console.log("getDashboardBudgetById: ", res);
      if (res.length === 0) {
        this.setState({
          chart2: false,
        });
      } else {
        this.setState({
          chart2: true,
        });
      }
      let chartData = res?.map((item) => {
        return {
          y: item.project_fy_year,
          "Budget Amount": item.budget_amount,
          Expenses: item.expenses,
        };
      });
      // console.log("chartData ", chartData);
      this.setState({ budgetByIdChartData: chartData });
    });
  }

  render() {
    let { barchartdata } = this;
    let {
      cardData,
      budgetChartData,
      budgetByIdChartData,
      projectListData,
      projectMiniData,
      chart1,
      chart2,
    } = this.state;
    let { isAdmin } = this;
    return (
      <>
        <Helmet>
          <title>Dashboard - Access Livelihoods</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Project Management</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row mb-4">
            {cardData.map(
              (o, i) =>
                o.display && (
                  <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                    <div className="card dash-widget cursor-pointer orangeCard">
                      <div className="card-body">
                        <span className="dash-widget-icon light_bg">
                          <img
                            src={o.iconWhite}
                            className="imgicon white"
                            alt="dash image"
                          />
                        </span>
                        <div className="dash-widget-info">
                          <h3>{o.value}</h3>
                          <span>{o.label}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          {isAdmin ? (
            <div className="row chartrow">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body chart1">
                        <h3 className="card-title">Plan Budget & Expense</h3>
                        {chart1 ? (
                          <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                              barSize={50}
                              data={budgetChartData}
                              margin={{
                                top: 5,
                                right: 5,
                                left: 5,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid />
                              <XAxis dataKey="y" />
                              <YAxis />

                              <Legend />
                              <Bar dataKey="Budget Amount" fill="#FF7C21" />
                              <Bar dataKey="Expenses" fill="#5F160F" />
                            </BarChart>
                          </ResponsiveContainer>
                        ) : (
                          <img
                            src={imagepaths.fyEmptyBg}
                            className="mt-4"
                            alt="empty bg"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mb-5">
                          <div className="col-6"></div>
                          <div className="col-6 text-start">
                            <AppInput
                              label="Project Name"
                              type="select"
                              options={projectListData}
                              name="projectName"
                              required="true"
                              defaultValue={projectListData[0]?.key}
                              onChange={(...args) =>
                                this.getDashboardBudgetById(...args)
                              }
                            ></AppInput>
                          </div>
                        </div>
                        <h3 className="card-title">
                          Plan Budget & Expense By Project
                        </h3>
                        {chart2 ? (
                          <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                              barSize={50}
                              data={budgetByIdChartData}
                              margin={{
                                top: 5,
                                right: 5,
                                left: 5,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid />
                              <XAxis dataKey="y" />
                              <YAxis />

                              <Legend />
                              <Bar dataKey="Budget Amount" fill="#FF7C21" />
                              <Bar dataKey="Expenses" fill="#5F160F" />
                            </BarChart>
                          </ResponsiveContainer>
                        ) : (
                          <img
                            src={imagepaths.fyEmptyBg}
                            className="mt-4"
                            alt="empty bg"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row g-3 p-0 m-0">
              {projectMiniData.map((item, i) => {
                return (
                  <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3 card_container">
                    <div className="project_mini_card">
                      <table>
                        <tr>
                          <td className="title">Project Name</td>
                          <td>-</td>
                          <td className="content">{item.project_name}</td>
                        </tr>
                        <tr>
                          <td className="title">No of Task</td>
                          <td>-</td>
                          <td className="content">04</td>
                        </tr>
                        <tr>
                          <td className="title">Start Date</td>
                          <td>-</td>
                          <td className="content">{item.project_start_date}</td>
                        </tr>
                        <tr>
                          <td className="title">End Date</td>
                          <td>-</td>
                          <td className="content">{item.project_end_date}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect(AdminDashboard);

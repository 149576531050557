import { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import "./courses.scss";
import { Link } from "react-router-dom";

export class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
  }

  componentDidMount() {
    console.log(this.props.store.user?.employee_role);
    this.fetchList();
  }

  fetchList() {
    this.props.api
      .apiGetAllCourses(this.state.search)
      .then(({ data, total }) => {
        // console.log({ apiGetAllCourses: data });
        // console.log(this.props.store.user?.employee_role);

        let filteredCourseData = data?.filter((v) =>
          v.roles.includes(this.props.store.user?.employee_role)
        );
        // console.log({ filteredCourseData });
        this.setState({
          data: filteredCourseData,
          rowcount: total,
        });
      });
  }
  truncateTextByWords(text, maxWords) {
    const words = (text || "").split(" ");

    if (words.length <= maxWords) {
      return text;
    }

    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(" ");
    let result = truncatedText + "...";
    return result;
  }
  render() {
    let { data } = this.state;
    return (
      <>
        <LayoutWrapper title="Courses" back="Courses">
          <div className="row courses g-4 mx-auto">
            {data?.map((item, i) => {
              let desc = this.truncateTextByWords(item.course_description, 20);
              return (
                <div className="col-12 col-md-4 col-xl-3" key={i}>
                  <div className="courses_card">
                    <h3 className="title">{item.course_name}</h3>
                    <p className="description">{desc}</p>
                    <Link
                      to={`/lms/courses/module?course_name=${item?.course_name?.snakeCase()}&course_id=${
                        item.course_id
                      }`}
                      className="btn add-btn m-0 float-none"
                    >
                      Explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(Courses);

import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";

export class AddModule extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddModule");
    this.state = {
      editData: {},
      projectEndDate: new Date(),
    };
    this.formFields = [
      {
        type: "text",
        name: "module_name",
        label: "Module Name",
        maxLength: "30",
        required: true,
      },
      {
        type: "textarea",
        name: "module_description",
        label: "Module Description (Max 250 words)",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12",
      },
    ];
    this.tablinks = mixins.tablinks;
  }

  get has_edit() {
    return !!this.props.urlparams.module_id;
  }

  getGradeLevelParams() {
    return {
      grade: this.props.urlparams.grade,
      level: this.props.urlparams.level,
    };
  }

  getPillarParams() {
    return this.props.urlparams.pillar;
  }

  sumitForm(event, data) {
    console.log("add module: ", data);
    if (this.mixin.params().module_id && !this.mixin.params().readonly) {
      let payload = {
        ...data,
      };
      this.api
        .apiUpdateModule(payload, { module_id: this.props.urlparams.module_id })
        .then((res) => {
          // console.log({ apiUpdateModule: res });
          let nextLink =
            "/lms/module/addvideos" +
            Object.encodeuri({
              module_id: this.props.urlparams.module_id,
              course_id: this.props.urlparams.course_id,
              course_name: this.props.urlparams.course_name,
            });
          this.props.navigate(nextLink);
        });
    } else if (this.mixin.params().readonly) {
      console.log("view flow");

      let nextLink =
        "/lms/module/addvideos" +
        Object.encodeuri({
          module_id: this.props.urlparams.module_id,
          course_id: this.props.urlparams.course_id,
          course_name: this.props.urlparams.course_name,
          readonly: this.mixin.params().readonly,
        });
      this.props.navigate(nextLink);
    } else {
      let payload = {
        ...data,
        course_id: this.props.urlparams.course_id,
      };
      this.api.apiCreateModule(payload).then((res) => {
        // console.log({ apiCreateModule: res });
        let nextLink =
          "/lms/module/addvideos" +
          Object.encodeuri({
            course_id: this.props.urlparams.course_id,
            course_name: this.props.urlparams.course_name,
            module_id: res.module_id,
          });
        this.props.navigate(nextLink);
      });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    if (this.props.urlparams.module_id) {
      this.props.api
        .apiGetModuleById({ module_id: this.props.urlparams.module_id })
        .then((res) => {
          console.log({ editData: res });
          this.setState({ editData: res });
        });
    }
  }

  getBreadCums() {
    if (this.props.urlparams.course_name) {
      return `${this.props.urlparams.course_name
        .spaceCase()
        .toTitleCase()} / Module Details`;
    } else {
      return "Course Name / Module Details";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.mixin.params().readonly) {
      let backLink = `/lms/module?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}`;
      return backLink;
    } else if (this.mixin.params().readonly) {
      let backLink = `/lms/module?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}&readonly=${this.props.urlparams.readonly}`;
      return backLink;
    } else {
      return "/lms/module";
    }
  }

  render() {
    let { tablinks, formFields, state, has_edit } = this;
    let { editData } = state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        field.defaultValue = editData[field.name];
      }
      return field;
    });
    console.log({ formFields });
    return (
      <LayoutWrapper
        title="Course Configure"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppLinkTabs data={tablinks} className="mb-0"></AppLinkTabs>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <AppForm
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.sumitForm(...args)}
          >
            <div className="row">
              {formFields?.map((field, i) => (
                <div
                  className={
                    field.fieldclass
                      ? field.fieldclass
                      : "col-12 col-md-6 col-lg-4"
                  }
                  key={"field" + i}
                >
                  <AppInput
                    {...field}
                    {...(field.name === "module_id" && this.mixin.params().new
                      ? {
                          disabled: true,
                        }
                      : { disabled: this.props.urlparams.readonly })}
                  ></AppInput>
                </div>
              ))}
            </div>
            <div className="row no-gutters">
              {this.props.urlparams.readonly ? (
                <>
                  <Link
                    to={this.getBackLink()}
                    className="btn btn_primary col col-md-1 ml-auto grey ml-auto me-2"
                  >
                    Cancel
                  </Link>
                  <button className="btn add-btn col col-md-1">Next</button>
                </>
              ) : (
                <>
                  <button className="btn add-btn col col-md-1 ml-auto">
                    Save & Next
                  </button>
                  <Link
                    to={this.getBackLink()}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Cancel
                  </Link>
                </>
              )}
            </div>
          </AppForm>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(AddModule);

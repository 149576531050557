import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";

export class AddExpense extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddExpense");
    this.state = {
      projectOptions: [],
      taskOptions: [],
      editData: {},
      projectDetails: {},
      taskDetails: {},
      budgetOptions: [],
      financialYears: [],
      projectFyData: [],
      budget_name: "",
      projectSearch: {
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.formFields = [
      {
        type: "text",
        name: "expense_name",
        label: "Expense Name",
        maxLength: "30",
        required: true,
      },
      {
        type: "select",
        name: "project_id",
        label: "Project Name",
        required: true,
        onChange: (v) => this.handleProjectNameChange(v),
      },
      {
        type: "select",
        name: "project_fy_id",
        label: "Financial Years",
        required: true,
        onChange: (v) => this.handleFyChange(v),
      },
      {
        type: "select",
        name: "task_id",
        label: "Task Name",
        required: true,
        onChange: (v) => this.handleTaskNameChange(v),
      },
      {
        type: "select",
        name: "budget_id",
        label: "Expense Category",
        onChange: (v) => this.handleBudgetIdChange(v),
        required: true,
      },
      {
        type: "number",
        name: "expense_amount",
        label: "Expense Amount",
        required: true,
      },
      {
        type: "date",
        name: "expense_date",
        label: "Expense Date",
        disabledDates: (date) => {
          // if (this.state.financialYears.length > 0) {
          //   let fYear =
          //     this.state.financialYears.find(
          //       (o) => o.id == this.formData.project_fy_id
          //     )?.financial_year || new Date().getFullYear();
          //   let statDate = new Date(fYear - 1, 3, 1);
          //   let endDate = new Date(fYear, 3, 1);
          //   console.log({ fYear, statDate, endDate });
          //   return (
          //     date.getTime() <= statDate.getTime() ||
          //     date.getTime() >= endDate.getTime()
          //   );
          // } else {
          // }
          return new Date().toDate().date().getTime() < date.getTime();
        },
        required: true,
      },
      {
        type: "file",
        name: "attachments",
        label: "Attachments",
        fileType: "project_file",
        onFileRemoved: () => this.handleFileRemoved(),
      },
      {
        type: "textarea",
        label: "Expense Description (Max 250 words)",
        name: "expense_description",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12",
      },
    ];
    this.tablinks = mixins.tablinks;
  }

  get formData() {
    return this.refs?.form?.form?.current?.getData() || {};
  }
  get currentLoginUserRole() {
    return this.props?.store.user?.employee_role;
  }

  handleFileRemoved() {
    this.preserveNavigation = true;
    setTimeout(() => {
      if (this.props.urlparams.expense_id) {
        this.submitForm().finally(() => {
          success("File Remove successfully");
          this.preserveNavigation = false;
        });
      }
    }, 1000);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setProjects();
    if (this.props.urlparams.expense_id) {
      this.api.apiGetExpensesById().then((data) => {
        this.setState({ editData: data });
      });
    }
  }

  setProjects() {
    this.api.apiGetProjectsList(this.state.projectSearch).then((projects) => {
      // this.formFields.find((field) => field.name === "project_id").options =
      //   projects.data.options("project_id", "project_name");

      this.setState({
        projectOptions: projects.data,
      });
    });
  }

  getFinancialYearsList(projectId) {
    this.api
      .apiFinancialYears({}, { project_id: projectId })
      .then(({ data, total }) => {
        this.setState({ financialYears: data });
      });
  }

  handleFyChange(val) {
    console.log("fy ID: ", val);
    // console.log("Project ID: ", this.state.projectDetails);

    this.setState({
      projectFyData: val[0],
    });

    let apiData = {
      project_id: this.state.projectDetails.projectId,
      project_fy_id: val[0].id,
    };
    console.log("apiData: ", apiData);

    this.setTasks(apiData);
  }

  handleProjectNameChange(data) {
    this.getFinancialYearsList(data[0]?.project_id);
    // console.log("handleProjectNameChange: ", data[0]?.project_id);
    this.setState({
      projectDetails: {
        projectName: data[0].project_name,
        projectId: data[0]?.project_id,
      },
    });
  }

  getBudgetsByTaskId(taskId) {
    this.api
      .apiGetBudgetsByTask({ task_id: taskId })
      .then(({ data, total }) => {
        console.log("login user role: ", this.currentLoginUserRole, data);

        let filteredData;

        if (this.currentLoginUserRole !== "admin") {
          filteredData = data?.filter((obj) =>
            obj?.budget_role?.includes(this.currentLoginUserRole)
          );
        } else {
          filteredData = data;
        }

        // console.log({ filteredData: filteredData });

        this.setState({
          budgetOptions: filteredData?.filter((v) => v.task_id === taskId),
        });
      });
  }

  handleTaskNameChange(data) {
    this.setState({
      taskDetails: {
        taskName: data[0]?.task_name,
      },
    });
    let expenseCategory = document.querySelector(
      "[name='appinput-budget_id'].form-group"
    );
    console.log(expenseCategory);
    let { values: expenseCategories = [] } =
      expenseCategory.vnode.refs.select.state;
    if (expenseCategories.length) {
      expenseCategory.vnode.refs.select.clearAll();
    }
    this.getBudgetsByTaskId(data[0].task_id);
  }
  handleBudgetIdChange(data) {
    console.log({ handleBudgetIdChange: data });
    if (data.length > 0) {
      this.setState({ budget_name: data[0]?.budget_name });
    } else {
      this.setState({ budget_name: "" });
    }
  }
  setTasks(payload) {
    // console.log("setTasks: ", payload);

    this.api
      .apiGetTasks({ ...this.state.projectSearch }, payload)
      .then((tasks) => {
        // console.log("setTasks: ", tasks);

        this.formFields.find((field) => field.name === "task_id").options =
          tasks.data.options("task_id", "task_name");
        this.setState({
          taskOptions: tasks.data.options(),
        });
      });
  }

  submitForm(event = null, data = this.formData) {
    let resolver = {};
    let promise = new Promise((resolve, reject) => {
      resolver = { resolve, reject };
    });
    // console.log("submit data: ", data);
    data = {
      ...data,
      project_name: this.state.projectDetails.projectName,
      task_name: this.state.taskDetails.taskName,
      budget_name: this.state.budget_name,
    };

    console.log("add expense formdata: ", data);

    if (this.props.urlparams.expense_id) {
      this.api
        .apiEditExpenses(data)
        .then(() => {
          if (this.preserveNavigation) {
            return;
          }
          success("Expenses edited successfully");
          this.props.navigate("/projectmanagement/list/expense_management");
        })
        .finally(() => resolver.resolve());
    } else {
      this.api
        .apiAddExpenses(data)
        .then(() => {
          success("Expenses added successfully");
          this.props.navigate("/projectmanagement/list/expense_management");
        })
        .finally(() => resolver.resolve());
    }
    return promise;
  }

  updateFields(fields) {
    fields.find((o) => o.name === "project_id").options =
      this.state.projectOptions.options("project_id", "project_name");
    fields.find((o) => o.name === "budget_id").options =
      this.state.budgetOptions.options("budget_id", "budget_name");
    fields.find((o) => o.name === "project_fy_id").options =
      this.state.financialYears.options("id", "financial_year");

    return fields;
  }

  getBackLink() {
    return "/projectmanagement/list/expense_management";
  }

  render() {
    let { tablinks, formFields } = this;
    let { editData } = this.state;

    formFields = this.updateFields(formFields);

    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      return field;
    });
    // console.log("editData: ", editData);
    return (
      <LayoutWrapper
        title="Expense"
        back="Expense / Create Expense"
        backlink={this.getBackLink()}
        endname={false}
      >
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <AppForm
            ref="form"
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.submitForm(...args)}
          >
            <div className="row">
              {formFields?.map((field, i) => (
                <div
                  className={
                    field.fieldclass
                      ? field.fieldclass
                      : "col-12 col-md-6 col-lg-4"
                  }
                  key={"field" + i}
                >
                  <AppInput
                    className="grow"
                    {...field}
                    disabled={this.props.urlparams.readonly && true}
                  ></AppInput>
                </div>
              ))}
            </div>
            <div className="row no-gutters">
              <button
                className={Object.className({
                  "btn add-btn col col-md-1 ml-auto": true,
                  "d-none": this.props.urlparams.readonly,
                })}
              >
                Save & Next
              </button>
              <Link
                to="/projectmanagement/list/expense_management"
                className={Object.className({
                  "btn btn_primary col col-md-1 ml-auto grey ms-2 me-2":
                    !this.props.urlparams.readonly,
                  "btn btn_primary col col-md-1 ml-auto grey ml-auto":
                    this.props.urlparams.readonly,
                })}
              >
                Cancel
              </Link>
            </div>
          </AppForm>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(AddExpense);

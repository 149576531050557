import React from 'react';

export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
}

export function onShowSizeChange(current, pageSize) {
  // console.log(current, pageSize);
}
import { Component } from "react";
import { Link } from "react-router-dom";

export class ClusterHeader extends Component {
  isActive(path, activeClass = "active") {
    return location.pathname == path ? activeClass : "";
  }

  render() {
    return (
      <div className="page-header employee-header absolute top-8 right-8">
        <div className="row align-items-center">
          <div
            className="col-auto float-end ml-auto"
            onClick={() => this.props.setstore("onborading", false)}
          >
            <Link
              to="/admin/masterdata/cluster/addclusterdata"
              className="btn add-btn"
            >
              <i className="fa fa-plus" />
              Add Data
            </Link>
            <button
              type="button"
              className="btn add-btn border_btn mx-2"
              onClick={() => this.props.handleDownloadList()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(ClusterHeader);

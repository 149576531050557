import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";

export class Course extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Course");
    this.state = {
      editData: {},
      projectEndDate: new Date(),
    };
    this.formFields = [
      {
        type: "text",
        name: "course_name",
        label: "Course Name",
        maxLength: "30",
        required: true,
      },
      {
        type: "select",
        name: "roles",
        multi: true,
        options: _enum.LMS_ROLES,
        label: "Role",
        required: true,
        defaultValue: [],
      },
      {
        type: "select",
        name: "course_type",
        label: "Course Type",
        className: "select-focus",
        options: _enum.COURSE_TYPES,
        required: true,
      },
      {
        type: "select",
        name: "course_difficulty_level",
        label: "Course Difficulty Level",
        options: _enum.DIFFICULTY_LEVEL,
      },
      {
        type: "textarea",
        label: "Course Description (Max 250 words)",
        name: "course_description",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12 col-lg-8",
      },
    ];
  }

  get has_edit() {
    return !!this.props.urlparams.course_id;
  }
  sumitForm(event, data) {
    if (this.mixin.params().course_id && !this.mixin.params().readonly) {
      console.log({ "submit form data: ": data });

      this.api
        .apiUpdateCourse(data, { course_id: this.props.urlparams.course_id })
        .then((res) => {
          console.log({ apiUpdateCourse: res });
          let nextLink =
            "/lms/module" +
            Object.encodeuri({
              course_id: this.props.urlparams.course_id,
              course_name: data?.course_name
                ?.replace(/\s+/g, "_")
                .toLowerCase(),
            });
          this.props.navigate(nextLink);
        });
    } else if (this.mixin.params().readonly) {
      console.log("view flow");

      let payload = {
        course_id: this.props.urlparams.course_id,
      };

      this.api.apiGetCourseById(payload).then((data) => {
        let nextLink =
          "/lms/module" +
          Object.encodeuri({
            course_id: this.props.urlparams.course_id,
            course_name: data?.course_name?.replace(/\s+/g, "_").toLowerCase(),
            readonly: this.mixin.params().readonly,
          });
        this.props.navigate(nextLink);
      });
    } else {
      this.api.apiCreateCourse(data).then((res) => {
        console.log({ apiCreateCourse: res });
        let nextLink =
          "/lms/module" +
          Object.encodeuri({
            course_id: res.course_id,
            course_name: res?.course_name?.replace(/\s+/g, "_").toLowerCase(),
          });

        this.props.navigate(nextLink);
      });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    if (this.props.urlparams.course_id) {
      let payload = {
        course_id: this.props.urlparams.course_id,
      };
      this.api.apiGetCourseById(payload).then((data) => {
        console.log({ editData: data });
        this.setState({ editData: data });
      });
    }
  }

  render() {
    let { tablinks, formFields, state, has_edit } = this;
    let { editData } = state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        field.defaultValue = editData[field.name];
      }
      if (field.name == "course_type") {
        field = {
          ...field,
          defaultValue: "online",
        };
      }
      return field;
    });
    console.log({ formFields });
    return (
      <LayoutWrapper
        title="Course Configure"
        back="Welcome to Access Livelihoods Upload page"
        backlink="/lms"
      >
        <div className="row">
          <div className="col"></div>
          <div className="">
            <Link
              to={""}
              className={Object.className({
                "btn add-btn": true,
                // "d-none": this.props.urlparams.readonly,
                "d-none": true,
              })}
            >
              {/* <i className="fa fa-plus"></i> */}
              Add
            </Link>
          </div>
        </div>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        <div className="row m-auto">
          <AppForm
            className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.sumitForm(...args)}
          >
            <div className="row">
              {formFields?.map((field, i) => (
                <div
                  className={
                    field.fieldclass
                      ? field.fieldclass
                      : "col-12 col-md-6 col-lg-4"
                  }
                  key={"field" + i}
                >
                  <AppInput
                    {...field}
                    {...(field.name === "course_type"
                      ? {
                          disabled: true,
                        }
                      : { disabled: this.props.urlparams.readonly })}
                  ></AppInput>
                </div>
              ))}
            </div>
            <div className="row no-gutters">
              {this.props.urlparams.readonly ? (
                <>
                  <Link
                    to={"/lms"}
                    className="btn btn_primary col col-md-1 ml-auto grey ml-auto me-2"
                  >
                    Cancel
                  </Link>
                  <a
                    href={`${window.location.pathname}?course_id=${this.props.urlparams.course_id}`}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Edit
                  </a>
                  <button className="btn add-btn col col-md-1">Next</button>
                </>
              ) : (
                <>
                  <button className="btn add-btn col col-md-1 ml-auto">
                    Next
                  </button>
                  <Link
                    to={"/lms"}
                    className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                  >
                    Cancel
                  </Link>
                </>
              )}
            </div>
          </AppForm>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Course);

import React, { Component } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom";

export class ApplicantCard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ApplicantCard");
    this.state = {
      status: props.status,
      applicantStatus: "",
    };
    this.shortlistref = React.createRef();
  }
  componentDidMount() {
    this.setState({
      applicantStatus: this.props.data.status,
    });
  }
  commentChange(e) {
    const makeChange = () => {
      let comments = this.props.data.comments || "";
      let fcomments = this.formteCommentsArr(comments);
      let comment = e.target.value;
      let addedcomment = false;
      if (comment) {
        addedcomment = true;
        fcomments.push(this.getComments(comment));
      }
      fcomments = fcomments.map((comment) => {
        return `[${comment.date}] ${comment.comments}`;
      });
      comment = fcomments.join("\n");
      if (addedcomment) {
        let ret =
          this.props.onCommentChange && this.props.onCommentChange(comment);
        if (ret instanceof Promise) {
          ret.then(() => {
            e.target.value = "";
          });
        } else {
          e.target.value = "";
        }
      }
    };
    app.mixin.debounce(makeChange, 500, "applicant-card-comment");
  }
  openNotification(placement) {
    notification.open({
      message: "This Candidate is being Shortlisted",
      placement,
      duration: 3,
    });
  }

  handleStatusChange(values) {
    let value = values[0];
    this.setState({
      status: value,
    });
    this.props.onStatusChange && this.props.onStatusChange(value);
  }

  rejectCadidateForm() {
    return new Promise((reslove, reject) => {
      if (this.props.data.status == "rejected") {
        return alert("This is allready rejected");
      }
      let myconfirm = confirm("Please confirm reject action for candidate");
      myconfirm.promise.then(() => {
        this.api
          .apiRejectCadidateForm({
            applicant_id: this.props.data.applicant_id,
          })
          .then((response) => {
            reslove(response);
            this.props.onFetchData().then((res) => {
              console.log(res);
              this.setState({
                applicantStatus: res.data[0].status,
              });
            });
          })
          .then(reject);
      });
    });
  }
  isFile(str) {
    return !!str.match(new RegExp(_enum.FILEIDPAT));
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      this.api
        .request(this.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  candidateDelete(id) {
    let myconfirm = confirm("Please confirm delete action for candidate");
    myconfirm.promise.then(() => {
      this.api
        .apiDeleteCadidateForm({
          applicant_id: id,
        })
        .then(() => {
          success("Candidate deleted successfully");
          this.props.onFetchData();
        });
    });
  }

  get APPLICANT_CARD_STATUS() {
    let { call_status = "unspecified" } = this?.props?.data || {};
    return _enum.APPLICANT_CARD_STATUS.filter((o) =>
      o.has.includes(call_status)
    );
  }
  getComments(line, appenddate = true) {
    const regex = /^\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\]\s(.*)$/;
    const matches = line.match(regex);
    if (matches) {
      const date = matches[1];
      const comments = matches[2];
      return { date, comments };
    } else if (appenddate) {
      return { date: new Date().toDateTime(), comments: line };
    } else {
      return { date: "date not mentioned", comments: line };
    }
  }

  formteCommentsArr(comments) {
    let arr = comments.split("\n");
    let fcomments = arr.map((line) => {
      let comment = this.getComments(line);
      return comment;
    });

    fcomments = fcomments.sort(
      (a, b) => a.date.date().getTime() - b.date.date().getTime()
    );
    fcomments = fcomments.filter((o) => o.comments.length);
    return fcomments;
  }

  formteComments(comments) {
    let arr = comments.split("\n");
    let fcomments = arr.map((line) => {
      let comment = this.getComments(line);
      return comment;
    });

    fcomments = fcomments.sort(
      (a, b) => a.date.date().getTime() - b.date.date().getTime()
    );
    fcomments = fcomments.filter((o) => o.comments.length);
    let last = fcomments.reverse()[0] || {};
    if (last.comments || !comments) {
      fcomments.push(this.getComments(""));
    }
    fcomments = fcomments.map((comment) => {
      return `[${comment.date}] ${comment.comments}`;
    });

    return fcomments.join("\n");
  }

  render() {
    let { data: item, index } = this.props;
    let {
      name,
      status,
      phoneNumber,
      emailID,
      others = [],
      applicant_id,
      comments = "",
      call_status,
    } = item;
    let { APPLICANT_CARD_STATUS } = this;

    let { onSubmit, onSelect = () => "" } = this.props;
    let { state } = this;
    let { data = {}, fields = {}, title, type, applicantStatus } = state;
    let config = { updateapi: "testAPIpoint", component: this };
    let commentsarr = [];
    if (comments) {
      commentsarr = this.formteCommentsArr(comments);
    }
    return (
      <>
        <div className="applicant_card card">
          <div className="card-header border-0">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => onSelect(e, item, index)}
            />
            <div className="title_box">
              <p className="name">{name}</p>
              <span className="mx-2">-</span>
              <span className={`stats ${status}`}>{status.toTitleCase()}</span>
            </div>
            <div className="dropdown dropdown-action text-end">
              <a
                href="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => this.candidateDelete(applicant_id)}
                >
                  <i className="fa fa-trash-o m-r-5" /> Delete
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="row mx-auto">
              <div className="col-12 col-md-6">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <div className="flex gap-2">
                          <div className="w-28">Phone Number</div>
                          <div>:</div>
                          <div>{phoneNumber}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex gap-2">
                          <div className="w-28">Email ID</div>
                          <div>:</div>
                          <div>{emailID}</div>
                        </div>
                      </td>
                    </tr>
                    {others.map((field, i) => (
                      <tr>
                        <td>
                          <div className="flex gap-2">
                            <div
                              className="w-28 cursor-pointer"
                              title={field.field_label}
                              onClick={() => alert(field.field_label)}
                            >
                              {field.field_label.length > 30
                                ? field.field_label.substr(0, 27) + "..."
                                : field.field_label}
                            </div>
                            <div>:</div>
                            <div className="grow">
                              {this.isFile(field.field_response) ? (
                                <a
                                  href={this.downloadFile(field.field_response)}
                                  className="link_btn"
                                  target="_blank"
                                >
                                  Download link
                                </a>
                              ) : (
                                <AppInput
                                  value={field.field_response}
                                  type="textarea"
                                  disabled={true}
                                ></AppInput>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="form-group mb-4">
                  <label className="table_label mb-2">Comments</label>
                  {commentsarr.length > 0 && (
                    <div className="comment_box">
                      {commentsarr.map((comment) => (
                        <div className="flex gap-2">
                          <div>[{comment.date}]</div>
                          <div className="grow">{comment.comments}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  <textarea
                    name="comments"
                    placeholder="Add Comments"
                    onBlur={(e) => this.commentChange(e)}
                  ></textarea>
                </div>
                {applicant_id && status === "online_assessment_review_pending" && (
                  <Link
                    to={`/public/candidatetest?id=${applicant_id}&preview=1`}
                    target="_blank"
                    className="redirect_link"
                  >
                    Preview Online Assessment
                  </Link>
                )}
              </div>
              <div className="col-12 col-md-6">
                <AppForm
                  className="applicant_details"
                  onSubmit={(...args) =>
                    this.props.onFormSubmit(
                      this.shortlistref,
                      this.props.data,
                      ...args
                    )
                  }
                >
                  <div className="user_name">
                    {name
                      .split(" ")
                      .map((name) => name[0])
                      .join("")
                      .toUpperCase()}
                  </div>
                  {/* <p className="desc">{desc}</p> */}
                  {(APPLICANT_CARD_STATUS.length && status !== "rejected" && (
                    <>
                      <AppInput
                        type="select"
                        name="status"
                        onChange={(...args) => this.handleStatusChange(...args)}
                        placeholder="Call Status"
                        options={APPLICANT_CARD_STATUS}
                        disabled={
                          this.state.applicantStatus === "rejected" && true
                        }
                      ></AppInput>
                      <div className="text-center">
                        <button
                          className="btn inline-block cursor-pointer border-0"
                          disabled={!state.status}
                        >
                          <a
                            ref={this.shortlistref}
                            style={{ pointerEvents: "none" }}
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal_call_status"
                            className="btn btn_primary"
                          >
                            Shortlist
                          </a>
                        </button>
                        <button
                          type="button"
                          onClick={() => this.rejectCadidateForm()}
                          className="btn btn_primary grey ms-2"
                          href="#"
                          disabled={applicantStatus === "rejected" && true}
                        >
                          Reject
                        </button>
                      </div>
                    </>
                  )) ||
                    ""}
                </AppForm>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(ApplicantCard);

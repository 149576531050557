import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import mixins from "../mixins";
import { Link } from "react-router-dom";

export class apttest extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("OnboardConfiguration");
    this.state = {
      mounted: false,
      rendered: false,
      form: {
        language: "en",
      },
      qfields: [{}],
      disabled: _enum.MANDATORY_FIELDS,
    };
  }

  get has_edit() {
    return !!this.props.urlparams.id;
  }
  get $ele() {
    return this.refs.form.form.current;
  }

  get formEle() {
    return this.refs?.form?.form?.current;
  }

  get formData() {
    let formData = this.formEle?.getData() || {};
    let parsedData = parseInput(formData);
    console.log({ parsedData });
    parsedData.qfields = (parsedData.qfields || []).map((field) => {
      let { isSelect, FIELD_CONSTRAINTS } = this.checkFieldTypes({
        ...field,
        key: field.question_type,
      });
      let { isNumeric, hasSubCategory } = this.checkConstraints(field);
      console.log({ FIELD_CONSTRAINTS });
      return {
        ...field,
        FIELD_CONSTRAINTS,
        hasSelect: isSelect,
        isNumeric,
        hasSubCategory,
      };
    });

    return parsedData;
  }

  checkConstraints(field) {
    let isNumeric = (field.fieldConstraint || "")
      .split(",")
      .includes("numeric");
    return {
      isNumeric,
    };
  }
  checkFieldTypes(field) {
    let isFreeText = ["text", "textarea"].includes(field.key);
    let isSelect = ["select", "checkbox", "radio"].includes(field.key);
    let isFile = ["file"].includes(field.key);
    let isDate = ["date"].includes(field.key);
    let FIELD_CONSTRAINTS = _enum.FIELD_CONSTRAINTS;
    if (isFreeText) {
      FIELD_CONSTRAINTS = _enum.FIELD_CONSTRAINTS.exclude(
        ["future_dates_only", "past_dates_only"],
        "key"
      );
    } else if (isDate) {
      FIELD_CONSTRAINTS = _enum.FIELD_CONSTRAINTS.only(
        ["mandatory", "future_dates_only", "past_dates_only"],
        "key"
      );
    } else {
      FIELD_CONSTRAINTS = _enum.FIELD_CONSTRAINTS.only(["mandatory"], "key");
    }
    return {
      FIELD_CONSTRAINTS,
      hasSelect: isSelect,
      isFile,
      isSelect,
      isDate,
      isFreeText,
    };
  }
  addField(e) {
    let { qfields } = this.state;
    qfields = [...qfields, {}];
    console.log({ qfields });
    this.setState({
      qfields,
    });
  }

  deleteField(e, index) {
    e.preventDefault();
    const removeLocal = () => {
      let { qfields } = this.state;
      qfields = qfields.filter((v, i) => i != index);
      this.setState({
        qfields,
      });
    };
    removeLocal();
  }

  sumitForm(e, data, form) {
    let parsedPayload = parseInput(data);
    if (!parsedPayload.language) {
      alert("Atleast one language should be selected");
    }
    if (this.mixin.params().id) {
      this.api.apiUpdateCandidateTest(parsedPayload).then(() => {
        this.navigate("/recruitment/test_applications");
      });
    } else {
      this.api.apiCreateCandidateTest(parsedPayload).then(() => {
        this.navigate("/recruitment/test_applications");
      });
    }
    console.log({ parsedPayload });
  }

  componentDidMount() {
    if (this.mixin.params().id) {
      this.api.apiGetCandidateAssementTest().then((data) => {
        console.log({ apiGetCandidateAssementTest: data });
        this.setState({
          form: data,
          qfields: data.qfields.map((o) => {
            let isSelect = ["select", "checkbox", "radio"].includes(
              o.question_type
            );
            let hasQuestionUpload = ["questionfile"].includes(o.question_type);
            let multiselect = o.question_type === "checkbox" ? true : false;
            return {
              ...o,
              mounted: true,
              hasSelect: isSelect,
              hasQuestionUpload,
              multiselect,
            };
          }),
        });
      });
    } else {
      this.setState({
        mounted: true,
      });
    }
  }

  handleFieldTypeChange(v, i) {
    console.log(v, i);
    if (!v.length) {
      return;
    }
    const setFields = (state) => {
      let isSelect = ["select", "checkbox", "radio"].includes(v[0].key);
      let hasQuestionUpload = ["questionfile"].includes(v[0].key);
      let multiselect = v[0].key === "checkbox" ? true : false;

      let fields = Object.setNested(state.qfields, `${i}.hasSelect`, isSelect);

      fields = Object.setNested(
        fields,
        `${i}.hasQuestionUpload`,
        hasQuestionUpload
      );

      fields = Object.setNested(fields, `${i}.multiselect`, multiselect);

      return fields;
    };
    this.setState((state) => ({
      qfields: setFields(state),
    }));
    console.log(this.state.qfields);
  }

  getElement(name) {
    return document.querySelector(`[name='${name}']`);
  }

  getElementValue(name) {
    return this.getElement(name)?.value || "";
  }

  getElementOptions(name) {
    console.log({ name: name });
    return this.getElementValue(name)
      .split(",")
      .filter((v) => v)
      .map((v) => ({
        key: v,
        label: v,
      }));
  }

  removeConsecutiveCommas(str) {
    var parts = str.split(",");

    var filteredParts = parts.filter(function (part) {
      return part !== "";
    });

    var result = filteredParts.join(",");
    return result;
  }

  handlePossibleValuesChange(field, e) {
    this.mixin.debounce(() => {
      e.target.value = this.removeConsecutiveCommas(e.target.value);
      this.setState({
        qfields: [...this.state.qfields],
      });
    }, 2000);
  }

  getMergeData(obj1 = {}, obj2 = {}, empty = [undefined, null, ""]) {
    const isempty = (v1, v2) => (empty.includes(v1) ? v2 : v1);
    return Object.keys({
      ...obj1,
      ...obj2,
    }).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.props.urlparams.id
          ? isempty(obj1[key], obj2[key])
          : isempty(obj2[key], obj1[key]),
      }),
      {}
    );
  }

  render() {
    let { props, has_edit } = this;
    let { qfields, form, disabled, mounted, rendered } = this.state;
    if (mounted && !rendered) {
      this.setState({ rendered: true });
    }
    console.log("formData: ", this.formData, qfields);
    // let { qfields: formFields = [] } = this.formData;
    let { qfields: formFields = [] } = this.formData;
    let hasForm = qfields?.length == 0;
    let maxFields = hasForm ? formFields : qfields;
    if (mounted && rendered) {
      qfields = maxFields.map((v, i) =>
        this.getMergeData(qfields[i], formFields[i])
      );
    }

    const links = mixins.menulinks;
    return (
      <LayoutWrapper title="Aptitude Test" back="Onboarding">
        {/* <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs> */}
        <AppForm
          className="flex flex-col gap-4"
          onSubmit={(...args) => this.sumitForm(...args)}
          ref={"form"}
        >
          <div className="flex flex-wrap gap-4">
            <div style={{ flex: "0.3" }}>
              <AppInput
                type="text"
                label="Test Name"
                name="assessment_name"
                maxLength="50"
                pattern="^[A-Za-z][a-zA-Z0-9\/ \\ ]*$"
                invalidmessage="Value Should be Valid Aphanumeric Content"
                defaultValue={form?.assessment_name}
                disabled={props.urlparams.readonly || has_edit}
                required={true}
              ></AppInput>
              <AppInput
                type="select"
                label="Role"
                name="role"
                options={_enum.APPLICANT_ROLES}
                defaultValue={form?.role}
                disabled={props.urlparams.readonly}
                required={true}
              ></AppInput>
              <AppInput
                type="select"
                label="Language"
                name="language"
                multi={true}
                options={_enum.APPLICANT_LANGUAGE_LIST}
                defaultValue={form?.language?.split(",") || []}
                disabled={props.urlparams.readonly}
                required={true}
              ></AppInput>
            </div>
            <div className="grow">
              <AppInput
                controlClass="!min-h-[220px]"
                type="textarea"
                maxLength="250"
                invalidmessage="Value Should Not Exceed 250 Characters"
                label="Test Description"
                name="assessment_description"
                defaultValue={form?.assessment_description}
                disabled={props.urlparams.readonly}
                required={true}
              ></AppInput>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {qfields.map((qfield, i) => (
              <div className="flex flex-wrap gap-4" key={"field" + i}>
                <AppInput
                  label="Question"
                  type="text"
                  name={`qfields.${i}.question`}
                  className="grow"
                  // invalidmessage="Value Should be Valid Aphanumeric Content"
                  defaultValue={qfield?.question}
                  required={true}
                  disabled={props.urlparams.readonly}
                ></AppInput>
                <AppInput
                  label="Question Type"
                  type="select"
                  name={`qfields.${i}.question_type`}
                  options={_enum.QUESTION_FIELD_TYPES}
                  className="grow"
                  onChange={(v) => this.handleFieldTypeChange(v, i)}
                  defaultValue={qfield?.question_type}
                  disabled={props.urlparams.readonly}
                  required={true}
                ></AppInput>
                {this.state.qfields[i].hasQuestionUpload && (
                  <>
                    <AppInput
                      type="file"
                      label="Upload Question Sheet"
                      name={`qfields.${i}.possible_values`}
                      className="grow"
                      disabled={
                        props.urlparams.readonly ||
                        disabled.includes(qfield.name || qfield.field_name)
                      }
                      defaultValue={qfield?.possible_values}
                    ></AppInput>
                  </>
                )}
                {this.state.qfields[i].hasSelect && (
                  <>
                    <AppInput
                      label="Possible Values"
                      type="text"
                      name={`qfields.${i}.possible_values`}
                      className="grow"
                      required={true}
                      defaultValue={qfield?.possible_values || []}
                      disabled={
                        props.urlparams.readonly ||
                        disabled.includes(qfield.name || qfield.field_name)
                      }
                      onChange={(...args) =>
                        this.handlePossibleValuesChange(qfield, ...args)
                      }
                      note="possible value should be comma separated"
                    ></AppInput>
                    <AppInput
                      label="Correct Answer"
                      type="select"
                      name={`qfields.${i}.correct_answer`}
                      className="grow"
                      options={
                        qfield?.possible_values
                          ?.splitBy(",")
                          .options("", "", (v) => v) ||
                        this.getElementOptions(`qfields.${i}.possible_values`)
                      }
                      defaultValue={
                        this.state.qfields[i].multiselect && qfield?.answer
                          ? [qfield?.answer].splitBy(",")
                          : [qfield?.answer].filter((v) => v)
                      }
                      disabled={props.urlparams.readonly}
                      multi={this.state.qfields[i].multiselect}
                      required={true}
                    ></AppInput>
                  </>
                )}

                <AppInput
                  label="Difficulty Level"
                  type="select"
                  name={`qfields.${i}.difficulty_level`}
                  options={_enum.TEST_DIFFICULTY}
                  className="grow"
                  defaultValue={qfield?.difficulty_level}
                  disabled={props.urlparams.readonly}
                  required={true}
                ></AppInput>
                {!props.urlparams.readonly && (
                  <button
                    onClick={(e) => this.deleteField(e, i)}
                    className="btn bg-red-500 hover:bg-red-500 !m-auto !mb-3 text-white"
                  >
                    <i className="fa fa-trash-o text-error" />
                  </button>
                )}
              </div>
            ))}
            <div>
              {!props.urlparams.readonly && (
                <button
                  className="btn bg-transparent flex gap-2 text-primary"
                  type="button"
                  onClick={(e) => this.addField(e)}
                >
                  <i className="fa fa-plus m-auto" />
                  <span className="font-bold">Add More Fields</span>
                </button>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="grow"></div>
            <Link
              to={"/recruitment/test_applications"}
              className="btn btn_primary grey ms-2 me-2"
            >
              Cancel
            </Link>
            {!props.urlparams.readonly && (
              <>
                <button className="btn btn-primary add-btn">SUBMIT</button>
              </>
            )}
            {props.urlparams.readonly && (
              <>
                <a
                  className="btn btn-primary add-btn"
                  href={`${window.location.pathname}?id=${props.urlparams.id}`}
                >
                  Edit
                </a>
              </>
            )}
          </div>
        </AppForm>
      </LayoutWrapper>
    );
  }
}

export default connect(apttest);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import CriteriaModal from "./criteriaModal";
import _enum from "src/mixins/enum";

export class EligibilityCriteria extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EligibilityCriteria");
    this.state = {
      data: [],
      popupdata: {},
      popupfields: {},
      editData: {},
      viewMode: false,
      editMode: false,
      parameterName: [],
      criteriaId: "",
      eligibleSchemes: [],
      criterias: [{ parameterName: "", operator: "", values: [] }],
      editParameterValues: false,
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "_id",
          title: "Criteria ID",
        },
        {
          dataIndex: "name",
          title: "Criteria Name",
        },
        {
          dataIndex: "createdAt",
          title: "Created On",
          render: (text, record) => {
            if(text != "N/A"){
              let date = new Date(text).dateToDDMMYYYY(new Date(text));
              return date;
            } else {
              return text;
            }
          },
        },
        {
          dataIndex: "updatedAt",
          title: "Updated On",
          render: (text) => {
            return <>{new Date(text).dateToDDMMYYYY(new Date(text))}</>;
          },
        },
      ],
    };
    this.formFields = [
      {
        type: "text",
        name: "name",
        label: "Criteria Name",
        required: true,
        customcolspan: "12"
      },
      {
        type: "select",
        name: "parameterName",
        label: "Parameters",
        placeholder: "Select",
        options: [],
        required: true,
        onChange: (e, index) => {
          const { name, value } = e.target;
          const list = [...this.formFields];
          list[index][name] = value;
          setModalFields(list);
      }
      },
      {
        type: "select",
        name: "operator",
        label: "Ops",
        // options: _enum.OPERATOR_TYPES,
        placeholder: "Select",
        className: "select-focus",
        required: true,
      },
      {
        type: "select",
        name: "values",
        label: "Value",
        required: true,
      },
    ];
    this.criteriaModal = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  componentDidMount() {
    this.fetchParameterList();
    this.fetchCriterias();
    this.fetchSchemeList();
  }

  openModal = () => {
    this.criteriaModal.current.click();
  }

  closeModal = () => {
    document.querySelector("#criteria_modal #bsmodalclose")?.click();
  }

  fetchSchemeList() {
    this.props.api.apiGetAllSchemes(this.state.search).then((response) => {
      this.setState({
        eligibleSchemes: response?.data?.items[0]?.records,
      })
    })
  }

  fetchParameterList() {
    this.props.api
      .apiGetParameterList(this.state.search)
      .then(({ data, total }) => {
        console.log({apiGetParameterList: data});
        let parameterValues =  data?.items.map(item => {
          const newName = item.name.replace(/([A-Z])/g, ' $1').trim();
          const camelCaseName = newName.charAt(0).toUpperCase() + newName.slice(1);
          return {
              ...item,
              name: camelCaseName
          };
      });
        this.setState({
          parameterName: parameterValues?.options("_id", "name"),
        })
      }).catch((error) => {
        console.log(error);
      });
  }

  fetchCriterias() {
    let scheme_id = !this.props.editMode && !this.props.viewMode ?  localStorage.getItem("scheme_id") : this.props.editData._id;
    this.props.api
    .apiGetCriterias(this.state.search, {schemeId: scheme_id})
    .then(({ data, total }) => {
      this.setState({
        data,
        rowcount: total,
      })
    }).catch((err) => console.log(err));
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    let parsedPayload = parseInput(data);
    console.log("formData", data, parsedPayload);

    let payload = {
      ...parsedPayload,
    };

    payload.criterias?.forEach(criteria => {
      let values = [];
      if (criteria.operator == "EQUAL" || criteria.operator == "NOT_EQUAL") {
        values.push(criteria.values);
        if(values[0]?.includes("|END|")) {
          criteria.values = values[0].split("|END|");
        } else if(values[0]?.includes(",")) {
          criteria.values = values[0].split(",");
        } else {
          values
        }
        criteria.values = criteria.values.map((value) => {
          if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
            return JSON.parse(value.toLowerCase());
          } else {
            return value.toUpperCase().split(" ").join("_")
          }
        })
      }
      if (criteria.operator === "EQUAL" || criteria?.min === "" || criteria.operator === "NOT_EQUAL") {
        delete criteria.min;
      }
      if (criteria.operator === "EQUAL" || criteria?.max === "" || criteria.operator === "NOT_EQUAL") {
        delete criteria.max;
      }
      let matchedStateData = this.state.parameterName.find(item => item.key === criteria.parameterName);
      criteria.parameterId = matchedStateData.key;
      criteria.parameterName = matchedStateData.label;
      if (criteria.operator == "INBETWEEN") delete criteria.values;

      let schemeNames = this.state.eligibleSchemes.map(scheme => scheme.name.toUpperCase().split(' ').join('_'));
      let updatedValues = criteria?.values?.map(value => {
        let index = schemeNames.indexOf(value);
        if (index !== -1) {
            return this.state.eligibleSchemes[index]._id;
        }
        return value;
      });
      if(updatedValues) {
        criteria.values = [...updatedValues];
      }
    });

    if (this.state.editMode) {
      this.props.api
        .apiUpdateCriteria(payload, { criteriaId: this.state.criteriaId })
        .then(() => {
          this.setState({
            editParameterValues: false,
          });
          this.closeModal();
          this.fetchCriterias();
        })
        .catch((error) => console.log(error));
    } else {
      let scheme_id = !this.props.editMode ? localStorage.getItem("scheme_id") : this.props.editData._id;
      this.props.api
        .apiCreateCriteria(payload, { schemeId: scheme_id })
        .then(() => {
          this.closeModal();
          this.fetchCriterias();
          this.setState({
            editParameterValues: false,
          })
        })
        .catch((error) => console.log(error));
    }
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchCriterias()
    );
  }

  addField = (e) => {
    if (this.state.editMode) {
      let { _id, name, criterias } = this.state.editData;
      let filteredData = { _id, name };
      filteredData.criterias = [...criterias, { parameterName: "", operator: "", values: [] }]; 
      this.setState({ editData: filteredData });
    } else {
      this.setState({ criterias: [...this.state.criterias, { parameterName: "", operator: "", values: [] }] });
    }
  };

  handleCriteriaValue = (index, values) => {
    // updating the state of a specific index object values
    this.setState(prevState => {
      const criterias = [...prevState.criterias];
      criterias[index].values = values;
      return { criterias };
    });
  }

  deleteField = (e, index,form) => {
    let {editMode, viewMode, editData, criterias} = this.state;
    e.preventDefault();
    if(editMode && !viewMode){
      let list;
      list = [...editData?.criterias];
      const remove = list.filter((_, indexFilter) => !(indexFilter === index));

      let { _id, name } = editData;
      let filteredData = { _id, name };

      filteredData.criterias = remove;
      this.setState({ editData: filteredData });
    } else if(!viewMode) {
      let list = [...criterias];
      const formData = form.current.form.current.getData();

      list.forEach((item, i) => {
          if (i >= index) {
          form.current.form.current.elements[`criterias.${i}.parameterName`].value =
              formData[`criterias.${i + 1}.parameterName`];
          form.current.form.current.elements[`criterias.${i}.operator`].value =
              formData[`criterias.${i + 1}.operator`];
          form.current.form.current.elements[`criterias.${i}.values`].value =
              formData[`criterias.${i + 1}.values`];
          }
      });

      if (index >= 0 && index < list.length) {
          list.splice(index, 1);
        }
      this.setState({
        criterias: list,
        editData: editData,
      })
    }
  };

  addModalFields = () => {
    let fields = (this.formFields || []).reduce(
        (o, n) => ({ ...o, [n.name]: n }),
        {}
    );
    let formdata = Object.map(fields, (v, k) => "");

    console.log("formdata: ", fields, formdata);
    this.setState({
      popupdata: formdata,
      popupfields: fields,
    })
  }

  handleParameters() {
    this.setState({
      editParameterValues: true,
    })
  }

  deleteAction(value) {
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.props.api;
      this.api.apiDeleteCriteria({ criteriaId: value._id }).then(() => {
        this.fetchCriterias();
      });
    });
  }

  async editAction(v) {
    console.log(v, "editData");
    this.setState({
      criteriaId: v._id,
    })

    let { name, criterias } = v;
    let filteredData = { name };
    filteredData.criterias = [];

    for (let item of criterias) {
      let lowerCaseString = item.parameterName.toLowerCase();
      let convertedString = lowerCaseString.replace(/ (.)/g, function (match, group1) {
        return group1.toUpperCase();
      });
      let payload = {
        name: convertedString,
      };

      try {
        let { data } = await this.props.api.apiGetParameterList(payload);

        if (data && data.values) {
          const valuesArray = item?.values.map((value) => {
            if (/\d/.test(value)) {
              let scheme = this.state.eligibleSchemes.map(scheme => {
                if(scheme._id === value) {
                  return scheme?.name
                }
              });
              let selectedValue = scheme.filter(item => item !== undefined && item !== null);
              console.log(selectedValue, "items.values"); 
              value = selectedValue[0];
            }
            let option = typeof(value) === "boolean" ? JSON.stringify(value) : value;
            const words = option?.toLowerCase().split('_');
            const transformedWords = words.map((word, index) => {
              if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              } else {
                return word;
              }
            });
            return transformedWords.join(' ');
          });

          let optionsArray = [];
          if (data?.values.length > 0) {
            optionsArray = data?.values.map((ops) => {
              let option = typeof ops === "boolean" ? JSON.stringify(ops) : ops;
              const words = option?.toLowerCase().split("_");
              const transformedWords = words.map((word, index) => {
                if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                } else {
                  return word;
                }
              });
              return transformedWords.join(" ");
            });
          } else {
            optionsArray = this.state.eligibleSchemes?.map((ops) => {
              const words = ops?.name?.toLowerCase().split("_");
              const transformedWords = words.map((word, index) => {
                if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                } else {
                  return word;
                }
              });
              return transformedWords.join(" ");
            });
          }

          let criteriaItem = {
            parameterId: item.parameterId,
            parameterName: item.parameterName,
            operator: item.operator,
            values: valuesArray,
            min: item.min,
            max: item.max,
            options: optionsArray,
          };

          filteredData.criterias.push(criteriaItem);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    }

    this.setState({
      editMode: true,
      viewMode: false,
      editData: filteredData,
    });

    this.openModal();
  } 

  async viewAction(v) {
    this.setState({
      criteriaId: v._id,
    })

    let { name, criterias } = v;
    let filteredData = { name };
    filteredData.criterias = [];

    for (let item of criterias) {
      let lowerCaseString = item.parameterName.toLowerCase();
      let convertedString = lowerCaseString.replace(/ (.)/g, function (match, group1) {
        return group1.toUpperCase();
      });
      let payload = {
        name: convertedString,
      };

      try {
        let { data } = await this.props.api.apiGetParameterList(payload);

        if (data && data.values) {
          const valuesArray = item?.values.map((value, index) => {
            if (/\d/.test(value)) {
              let scheme = this.state.eligibleSchemes.map(scheme => {
                if(scheme._id === value) {
                  return scheme?.name
                }
              });
              let selectedValue = scheme.filter(item => item !== undefined && item !== null);
              console.log(selectedValue, "items.values"); 
              value = selectedValue[0];
            }
            let option = typeof(value) === "boolean" ? JSON.stringify(value) : value;
            const words = option?.toLowerCase().split('_');
            const transformedWords = words.map((word, index) => {
              if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              } else {
                return word;
              }
            });
            return transformedWords.join(' ');
          });

          let optionsArray = [];
          if (data?.values.length > 0) {
            optionsArray = data?.values.map((ops) => {
              let option = typeof ops === "boolean" ? JSON.stringify(ops) : ops;
              const words = option?.toLowerCase().split("_");
              const transformedWords = words.map((word, index) => {
                if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                } else {
                  return word;
                }
              });
              return transformedWords.join(" ");
            });
          } else {
            optionsArray = this.state.eligibleSchemes?.map((ops) => {
              const words = ops?.name?.toLowerCase().split("_");
              const transformedWords = words.map((word, index) => {
                if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                } else {
                  return word;
                }
              });
              return transformedWords.join(" ");
            });
          }

          let criteriaItem = {
            parameterId: item.parameterId,
            parameterName: item.parameterName,
            operator: item.operator,
            values: valuesArray,
            min: item.min,
            max: item.max,
            options: optionsArray,
          };

          filteredData.criterias.push(criteriaItem);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    }

    this.setState({
      editMode: false,
      viewMode: true,
      editData: filteredData,
    });

    this.openModal();
  }

  render() {
    let { columns, data, rowcount, viewMode, editMode, popupfields, popupdata, editData, parameterName, criterias, eligibleSchemes } = this.state;
    let { isAdmin } = this;
    return (
      <>
      <div className="bg-white">
        <div className="row">
          <div className="step-title mt-5 ms-5 mb-0 row-6">Eligibility Criteria</div>
          <div className="mb-4 row-6">
            <Link
              ref={this.criteriaModal}
              data-bs-toggle="modal"
              data-bs-target="#criteria_modal"
              onClick={(...args) => this.addModalFields(...args)}
              className="btn add-btn me-5"
            >
              Add Criteria
            </Link>
          </div>
        </div>
        <div className="row lms">
          <div className="col-12">
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                deleteAction={(v) => this.deleteAction(v)}
                editAction={(v) => this.editAction(v)}
                viewAction={(v) => this.viewAction(v)}
                editable={(!this.props.editMode && !this.props.viewMode) || this.props.editMode ? true : false}
                viewable={(!this.props.editMode && !this.props.viewMode) || this.props.viewMode ? true : false}
                targetType="tap"
              ></AppTable>
            )}
            {data.length == 0 && <div className="empty_layout schemes"></div>}
            <div className="row no-gutters my-5 me-5 d-flex justify-end">
              <>
                {((!this.props.editMode && !this.props.viewMode) || (this.props.editMode && !this.props.isActive)) && <button className="btn add-btn col col-md-1 ml-auto" onClick={() => this.props.onCriteriaSubmit()}>
                  Save & Next
                </button>}
                <button
                  onClick={() => location.reload()}
                  className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                >
                  Cancel
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
        <CriteriaModal
          id="criteria_modal"
          modalSize="default"
          title={`${editMode ? "Edit Criteria" : viewMode? "View Criteria" : "Add Criteria"}`}
          submitText={`${editMode ? "Update" : "Save"}`}
          editData={editData}
          editMode={editMode}
          viewMode={viewMode}
          data={popupdata}
          fields={popupfields}
          criterias={criterias}
          parameterName={parameterName}
          eligibleSchemes={eligibleSchemes}
          contentClass="criteriaModalBox"
          disableallfields={viewMode ? true : false}
          addField={(...args) => this.addField(...args)}
          deleteField={(...args) => this.deleteField(...args)}
          onSubmit={(...args) => this.handleSubmit(...args)}
          handleParameters={() => this.handleParameters()}
          handleCriteriaValue={(...args) => this.handleCriteriaValue(...args)}
          editParameterValues={this.state.editParameterValues}
          onClose={() =>
            this.setState({
              popupdata: {},
              popupfields: {},
              editMode: false,
              viewMode: false,
              editData: {},
              criterias: [{ parameterName: "", operator: "", values: [] }],
            })
          }
        />
      </>
    );
  }
}

export default connect(EligibilityCriteria);

import pmMapcolumns from "./pm.mapcolumns";
export default {

    apiGetDashboardTotal() {
        return this.request(this.api().dashboardTotal)
            .exec().log()
            .mapresults((response) => {
                let filteredRes = {};
                let empRole = window?.store?.getState().root?.user?.employee_role;
                if (empRole !== "admin") {
                    filteredRes = {
                        ...Object.only(response.data, ["totalTasks", "totalProjects"])
                    }
                } else {
                    filteredRes = response.data
                }
                return filteredRes;
            })
            .get();
    },
    apiGetDashboardBudget() {
        return this.request(this.api().dashboardBudget)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiGetDashboardBudgetById(data) {
        return this.request(this.api().dashboardBudget, data)
            .exec().log()
            .mapresults((response) => {
                let data = response.data;
                return data
            }).get();
    },
    apiGetProjectsList(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().projectList, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records.map((record) => pmMapcolumns.mapFields('projects', record)),
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetProjectById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().projectById)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
    apiAddProject(data) {
        console.log({ apiAddProject: data });
        let payload =
        {
            ...pmMapcolumns.mapPayload('projects', data),
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().createProject, payload).exec().log().get();
    },

    apiEditProject(data) {
        console.log({ apiEditProject: data });
        let urldata = {
            project_id: data.project_id,
        }

        let payload = {
            ...Object.except(pmMapcolumns.mapPayload('projects', data), ["project_id"])
        }
        payload = Object.filter(payload, (v) => v);

        return this.request(this.api().updateProject, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteProject(urldata = mixins.params()) {

        return this.request(this.api().deleteProject)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiGetProject(urldata = app.mixin.params()) {
        return this.request(this.api().getProject)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let data = response.data;
                return pmMapcolumns.mapFields('projects', data);
            }).get();
    },

    apiGetRoles(payload) {
        console.log({ apiGetRoles: payload });
        return this.mokeapi(() => ({
            data: mokeresults.ROLE_LIST,
            total: mokeresults.ROLE_LIST.length
        }));
    },

    apiGetFinancialYears(payload, urldata = app.mixin.params()) {
        return this.request(this.api().getProject)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let data = response.data;
                return {
                    data: data.financial_years.map(v => pmMapcolumns.mapFields('financial_years', v)),
                    total: data.financial_years.length
                };
            }).get();
    },
    apiEditFinancialYear(payload, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };


        payload = pmMapcolumns.mapPayload('financial_years', payload);
        payload = {
            ...Object.except(payload, ["project_id", "project_fy_year"])
        };
        // console.log({ apiEditFinancialYear: payload, urldata });

        return this.request(this.api().editFinacial, payload).urltransform(urldata).exec().log().get();
    },
    apiFinancialYears(payload, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getFinancial, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: (response.data?.financial_years || []).map(v => pmMapcolumns.mapFields('financial_years', v)),
                };
                return result;
            }).get();
    },
    apiAddFinancialYear(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            ...data,
            ...Object.only(urldata, ["project_id"])
        };
        payload = pmMapcolumns.mapPayload('financial_years', payload);

        // console.log({ apiAddFinancialYear: payload });
        return this.request(this.api().addFinacial, payload).exec().log().mapresults(({ data }) => {
            return new Promise((resolve, reject) => {
                console.log("Financial Year: ", data);
                // add miscellaneous task
                let newTaskPayload = {
                    project_id: data.project_id,
                    project_fy_id: data.financial_years.find(v => v.project_fy_year === payload.project_fy_year)?.project_fy_id,
                    task_name: "Miscellaneous",
                    task_desc: "Task Description",
                    task_status: "open",
                    task_priority: "low",
                };
                console.log("payload for create task: ", newTaskPayload);
                this.request(this.api().addTask, newTaskPayload).exec().log().get().then(taskres => {
                    resolve(taskres);
                })
            })
        }).get();
    },
    apiDeleteFinancial(payload) {
        console.log({ apiDeleteFinancial: payload });
        return this.request(this.api().deleteFinacial).urltransform(payload).exec().log().get();
    },

    apiGetTasks(data, urldata = {}) {
        // console.log("apiGetTasks: ", urldata);

        return this.request(this.api().getTasks, data)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: (response?.data[0]?.records || []).map(v => pmMapcolumns.mapFields('tasks', v)),
                    total: response?.data[0]?.pagination[0]?.totalRecords || 0
                };
                return result;
            }).get();

    },
    apiEditTask(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            ...Object.except(data, ["project_id", "project_fy_year"])
        };
        payload = pmMapcolumns.mapPayload('tasks', payload);
        console.log({ apiEditTask: payload, urldata });
        return this.request(this.api().editTask, payload).urltransform(urldata).exec().log().get();
    },
    apiAddTask(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        // let newobj = Object.deepClone({ id: (10).uniqueId(), ...payload });
        // mokeresults.TASK_LIST.push(newobj);
        // return this.mokeapi(() => (newobj));
        let payload = {
            ...data,
            ...Object.only(urldata, ["project_id", "project_fy_id"])
        };
        payload = pmMapcolumns.mapPayload('tasks', payload);

        console.log({ apiAddTask: payload });
        return this.request(this.api().addTask, payload).exec().log().get();

    },
    apiDeleteTask(payload) {
        // return this.mokeapi(() => {
        //     mokeresults.TASK_LIST = mokeresults.TASK_LIST.filter(task => task.id != id);
        //     return mokeresults.TASK_LIST.find(task => task.id == id)
        // });
        return this.request(this.api().deleteTask).urltransform(payload).exec().log().get();
    },
    apiGetTask() {
        return this.mokeapi(() => (mokeresults.TASK_LIST.find(task => task.id == mixins.params().task_id)));
    },


    apiGetBudgets(payload, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        // console.log({ apiGetBudgets: payload, urldata });

        return this.request(this.api().getBudgets, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: (response?.data[0]?.records || []).map(v => pmMapcolumns.mapFields('budget', v)),
                    total: response?.data[0]?.pagination[0]?.totalRecords || 0
                };
                return result;
            }).get();
    },
    apiGetBudgetsByTask(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        // console.log({ apiGetBudgets: payload, urldata });

        return this.request(this.api().getBudgetsByTask)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: (response?.data[0]?.records || []).map(v => pmMapcolumns.mapFields('budget', v)),
                    total: response?.data[0]?.pagination[0]?.totalRecords || 0
                };
                return result;
            }).get();
    },
    apiEditBudget(data, urldata = {}) {
        console.log({ apiEditBudget: data, urldata });
        urldata = { ...mixins.params(), ...urldata };
        let payload = {
            ...Object.except(data, ["project_fy_id", "project_id"])
        };
        payload = pmMapcolumns.mapPayload('budget', payload);
        payload = {
            ...payload,
            budget_role: payload.budget_role.split(',')
        }
        return this.request(this.api().editBudget, payload).urltransform(urldata).exec().log().get();
    },
    apiGetBudgetById(urldata = {}) {
        // console.log({ apiGetBudgetById: urldata });
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getBudgetById).urltransform(urldata).exec().log().get();
    },
    apiAddBudget(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        // console.log({ apiAddBudget: payload });

        let payload = {
            ...data,
            ...Object.only(urldata, ["project_fy_id"])
        };
        payload = pmMapcolumns.mapPayload('budget', payload);
        payload = {
            ...Object.except(payload, ["project_id"]),
            budget_role: payload.budget_role.split(',')
        }
        console.log({ apiAddBudget: payload });
        return this.request(this.api().addBudget, payload).exec().log().get();

    },
    apiDeleteBudget(payload) {
        console.log(payload);
        return this.request(this.api().deleteBudget).urltransform(payload).exec().log().get();

    },
    apiGetBudget() {
        return this.mokeapi(() => (mokeresults.BUDGET_LIST.find(budget => budget.id == mixins.params().budget_id)));
    },

    apiGetEmployeeResources(payload, urldata = {}) {
        console.log({ apiGetEmployeeResources: payload });
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getEmployeeResources, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: (response?.data[0]?.records || []).map(v => pmMapcolumns.mapFields('resources', v)),
                    total: response?.data[0]?.pagination[0]?.totalRecords || 0
                };
                console.log({ result });
                return result;
            }).get();
    },

    apiGetMaterialResources(payload, urldata = {}) {
        console.log({ apiGetMaterialResources: payload });
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getMaterialResources, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: (response?.data[0]?.records || []).map(v => pmMapcolumns.mapFields('resources', v)),
                    total: response?.data[0]?.pagination[0]?.totalRecords || 0
                };
                console.log({ result });
                return result;
            }).get();
    },

    apiAddEmployeeResource(data) {
        let payload = data.map((item, i) => ({
            ...(Object.only(mixins.params(), ['project_id', 'project_fy_id'])),
            ...item,
            project_on_boarding_date: i > 0 ? data[0]["project_on_boarding_date"] : item.project_on_boarding_date,
            project_off_boarding_date: i > 0 ? data[0]["project_off_boarding_date"] : item.project_off_boarding_date,
            employee_role: i > 0 ? data[0]["employee_role"] : item.employee_role,
        }));
        return this.request(this.api().addEmployeeResources, payload).exec().log().get();
    },

    apiEditEmployeeResource(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            // ...pmMapcolumns.mapPayload('resources', data),
            ...data
        }
        return this.request(this.api().updateEmployeeResources, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteEmployeeResource(payload) {
        console.log(payload);
        return this.request(this.api().deleteEmployeeResources).urltransform(payload).exec().log().get();

    },

    apiAddMaterialResource(data) {
        console.log({ apiAddMaterialResource: data });
        let payload = data.map((item, i) => ({
            ...(Object.only(mixins.params(), ['project_id', 'project_fy_id'])),
            ...item,
            project_on_boarding_date: i > 0 ? data[0]["project_on_boarding_date"] : item.project_on_boarding_date,
            project_off_boarding_date: i > 0 ? data[0]["project_off_boarding_date"] : item.project_off_boarding_date,
        }));
        return this.request(this.api().addMaterialResources, payload).exec().log().get();
    },

    apiEditMaterialResource(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        let payload = {
            ...data,
        }
        return this.request(this.api().updateMaterialResources, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteMaterialResource(payload) {
        console.log(payload);
        return this.request(this.api().deleteMaterialResources).urltransform(payload).exec().log().get();

    },


    apiGetExpenses(payload, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        payload = {
            ...payload,
            ...urldata,
        }
        console.log({ apiGetExpenses: payload });

        return this.request(this.api().expenseList, payload)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                // console.log({ apiGetExpenses: response });
                return {
                    data: response.data[0].records.map((record) => pmMapcolumns.mapFields('expenses', record)),
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiGetExpensesById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };

        return this.request(this.api().expenseListById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiAddExpenses(payload) {
        console.log({ apiAddExpenses: payload });

        payload = pmMapcolumns.mapPayload('expenses', payload);

        return this.request(this.api().createExpense, payload).exec().log().get();
    },

    apiEditExpenses(data, urldata = {}) {

        urldata = { ...mixins.params(), ...urldata };
        let payload = pmMapcolumns.mapPayload('expenses', data);
        return this.request(this.api().updateExpense, payload).urltransform(urldata).exec().log().get();

    },

    apiDeleteExpenses(urldata = mixins.params()) {
        return this.request(this.api().deleteExpense)
            .urltransform(urldata)
            .exec().log().get();
    },


    apiDeleteExpences(id) {
        return this.mokeapi(() => {
            mokeresults.EXPENCES_LIST = mokeresults.EXPENCES_LIST.filter(expense => expense.id != id);
            return mokeresults.EXPENCES_LIST.find(expense => expense.id == id)
        });
    },
    apiGetExpences() {
        return this.mokeapi(() => (mokeresults.EXPENCES_LIST.find(expense => expense.id == mixins.params().expense_id)));
    },
    apiGetBudgetPlannerReport(payload, urldata) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().budgetPlanner)
            .urltransform(payload)
            .exec().log()
            .mapresults((response) => {
                let result = {
                    data: response?.data?.task.reduce((c, v) => ([
                        ...c,
                        ...(v.budget.map(o => ({
                            ...o,
                            task_id: v.task_id,
                            task_name: v.task_name
                        })))]), []),
                    total: response?.count || 0
                };
                console.log({ result });
                return result;
            }).get();

    },
    apiGetBudgetPlannerReportMoke() {
        let row = this.mokeapi(() => (mokeresults.REPORT_DATA));
        return row;
    }
}
import React, { Component } from "react";
import Employeelist from "../Employeelist";

export class Employeeslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };

    this.links = [
      {
        path: "/admin/attendance/exceptionapprovals",
        label: "Exception Approvals",
      },
      {
        path: "/admin/attendance",
        label: "Overall Team Attendance",
      },
    ];
  }

  render() {
    let { links } = this;
    return (
      <>
        <Employeelist inactive={this.props.inactive} />
      </>
    );
  }
}

export default connect(Employeeslist);

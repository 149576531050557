import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";
import "./addbudget.scss";

export class Budget extends Component {
  get monthIndexs() {
    return new Date().getMonthList().map((o, i) => ({ ...o, index: i + 1 }));
  }
  get quaterIndexs() {
    return new Date()
      .getMonthList()
      .map((o, i) => ({ ...o, index: i + 1 }))
      .chunk(3)
      .map((item, index) =>
        this.formFields.find((o) => o.name === `quarter.q${index + 1}`)
      );
  }
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Budget");
    this.state = {
      columns: [
        {
          dataIndex: "budget_name",
          title: "Budget Name",
        },
        {
          dataIndex: "unit_cost",
          title: "Unit Cost",
          render: (text, record) => text.cur("", "INR", "en-US"),
        },
        {
          dataIndex: "unit_of_measure",
          title: "Unit of Measure",
        },
        {
          dataIndex: "comments",
          title: "Budget Description",
          render: (text, record) => {
            return (
              <span
                className="text_truncate cursor-pointer"
                title={record?.comments}
                onClick={() => alert(record?.comments)}
              >
                {record?.comments}
              </span>
            );
          },
        },
        {
          dataIndex: "task_name",
          title: "Task Name",
        },
      ],
      data: [],
      editMode: false,
      viewMode: false,
      addMode: false,
      editData: {},
      rowcount: 0,
      project: {},
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        project_fy_id: this.props?.urlparams?.project_fy_id,
      },
    };
    this.formFields = [
      {
        type: "select",
        name: "project_id",
        label: "Project Name",
        defaultValue: props?.urlparams?.project_id,
        disabled: true,
        fieldclass: Object.className({
          hidden: !!props.urlparams.project_id || true,
        }),
      },
      {
        type: "text",
        name: "budget_name",
        label: "Budget Name",
        maxLength: "50",
        required: true,
      },
      {
        type: "select",
        name: "budget_role",
        multi: true,
        options: _enum.PM_PROJECT_ROLES,
        label: "Budget Role",
        required: true,
        defaultValue: [],
      },
      {
        type: "select",
        name: "task_id",
        options: [],
        label: "Task Name",
        required: true,
      },
      {
        type: "select",
        name: "unit_of_measure",
        label: "Unit Of Measure",
        options: _enum.PM_UNIT_OF_MESURE,
        required: true,
        onChange: (...args) => this.onUnitChange(...args),
      },
      {
        type: "number",
        name: "unit_cost",
        label: "Budget Amount",
        required: true,
        step: "any",
        onChange: (...args) => this.onBudgetChange(...args),
      },
      {
        type: "label",
        fieldclass: "col-12",
        label: <hr />,
      },
      {
        type: "number",
        name: "anual_budget_amount",
        label: "Anual Budget",
        required: true,
        readOnly: true,
      },
      {
        type: "label",
        fieldclass: "col-12",
        label: <span></span>,
      },
      ...this.monthIndexs.chunk(3).map((item, index) => ({
        type: "number",
        name: `quarter.q${index + 1}`,
        label: `Q${index + 1}`,
        required: true,
        readOnly: true,
      })),
      ...this.monthIndexs
        .chunk(3)
        .transpose()
        .reduce((c, a) => c.concat(a))
        .map((item, index) => ({
          type: "number",
          name: `monthly.m${item.index}`,
          label: item.label,
          required: true,
          step: "any",
          onChange: (...args) => this.onMonthBudgetChange(...args),
        })),
      {
        type: "textarea",
        label: "Comments (Max 250 words)",
        name: "comments",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12",
      },
    ];
    this.tablinks = mixins.tablinks;
  }

  get listMode() {
    return !(this.state.editMode || this.state.addMode);
  }

  addRecord() {
    this.setState({
      addMode: true,
      editData: {},
    });
  }

  get monthList() {
    let monthlist = this.monthIndexs.map((item, index) =>
      this.formFields.find((o) => o.name === `monthly.m${item.index}`)
    );
    return monthlist;
  }
  computeValues() {
    let { columns, data = [], rowcount, editData = {} } = this.state;
    let formdata = this.refs.appform.form.current.getData();
    let value = formdata.unit_cost;
    let quaters = this.quaterIndexs;
    let monthlist = this.monthList;
    monthlist.forEach((item, index) => {
      let mvalue = _enum.PM_UNIT_OF_MESURE_MVALUE[formdata.unit_of_measure];
      if (mvalue) {
        editData[item.name] = +(value * mvalue).toFixed(2);
      }
    });
    let mntarr = this.monthList.chunk(3);
    console.log({ mntarr });
    quaters.forEach((item, index) => {
      let mvalue = mntarr[index].reduce(
        (val, o) => val + (editData[o.name] || 0),
        0
      );
      editData[item.name] = (+mvalue).toFixed(2);
    });
    editData["anual_budget_amount"] = this.monthList
      .map((o) => this.state.editData[o.name] || 0)
      .sum();
    this.setState({
      editData: { ...editData },
    });
  }

  onMonthBudgetChange(event) {
    let { columns, data = [], rowcount, editData = {} } = this.state;
    let formdata = this.refs.appform.form.current.getData();
    console.log("onMonthBudgetChange", event);
    editData[event.target.name] = event.target.value;
    editData["anual_budget_amount"] = this.monthList
      .map((o) => formdata[o.name] || 0)
      .sum();
    let quaters = this.quaterIndexs;
    let mntarr = this.monthList.chunk(3);
    quaters.forEach((item, index) => {
      let mvalue = mntarr[index].reduce(
        (val, o) => val + +(editData[o.name] || 0),
        0
      );
      editData[item.name] = (+mvalue).toFixed(2);
    });
    this.setState({
      editData: { ...editData },
    });
  }

  onBudgetChange(event) {
    let { columns, data = [], rowcount, editData = {} } = this.state;
    let value = event.target.value;
    value = +(+value).toFixed(0);
    event.target.value = value;
    editData[event.target.name] = value;
    let formdata = this.refs.appform.form.current.getData();
    let mvalue = _enum.PM_UNIT_OF_MESURE_MVALUE[formdata.unit_of_measure];
    let quaters = this.quaterIndexs;
    if (mvalue) {
      this.monthList.forEach((item, index) => {
        // console.log({ [item.name]: +(value * mvalue).toFixed(2) });
        editData[item.name] = +(value * mvalue).toFixed(2);
      });
    }
    console.log({ editData, quaters, mvalue });
    this.setState({
      editData,
    });
    setTimeout(() => {
      this.computeValues();
    }, 0);
  }

  onUnitChange() {
    this.computeValues();
  }

  sumitForm(event, data, form) {
    let { editData } = this.state;
    data = parseInput(data);
    data["task_name"] = this.state.taskOptions.find(
      (o) => o.key === data["task_id"]
    ).label;

    console.log({ submitForm: data });
    const resolveList = () => {
      this.fetchData().then(() => {
        this.setState({
          editMode: false,
          addMode: false,
          editData: {},
        });
      });
      form.reset();
    };
    if (this.state.editMode) {
      this.api
        .apiEditBudget(data, { budget_id: editData.budget_id })
        .then(resolveList);
    } else {
      this.setState({
        editData: data,
      });
      this.api.apiAddBudget(data).then(resolveList);
    }
  }

  setTasks() {
    let payload = {
      project_id: this.props?.urlparams?.project_id,
      project_fy_id: this.props?.urlparams?.project_fy_id,
    };

    this.api
      .apiGetTasks(
        { ...Object.except(this.state.search, "project_fy_id") },
        payload
      )
      .then((tasks) => {
        console.log("tasks: ", tasks);
        this.formFields.find((field) => field.name === "task_id").options =
          tasks.data.options("task_id", "task_name");
        this.setState({
          taskOptions: tasks.data.options("task_id", "task_name"),
        });
      });
  }

  componentDidMount() {
    this.fetchData();
    this.getProjectDetails();
  }

  getProjectDetails() {
    let payload = {
      project_id: this.props?.urlparams?.project_id,
    };
    console.log("FinancialYear", payload);

    this.api.apiGetProject(payload).then((data) => {
      this.setState({ project: data });
    });
  }

  fetchData() {
    this.setTasks();
    return new Promise((resolve, reject) => {
      this.api.apiGetBudgets(this.state.search).then(({ data, total }) => {
        console.log(data);
        this.setState({
          data: [...data],
          rowcount: total,
        });
        resolve();
      });
    });
  }

  deleteBudgetAction(data) {
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteBudget(data).then(() => {
        this.fetchData();
      });
    });
  }

  editBudgetAction(v) {
    console.log({ editBudgetAction: v });
    this.api.apiGetBudgetById({ budget_id: v.budget_id }).then((res) => {
      // console.log({ getBudgetById: res.data });
      v["task_id"] = res.data.task_id;
      this.setState({
        editMode: true,
        viewMode: false,
        editData: v,
      });
    });
  }
  viewBudgetAction(v) {
    this.setState({
      editMode: true,
      viewMode: true,
      editData: v,
    });
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  getBreadCums() {
    if (this.props.urlparams.project_id) {
      return `${
        this.state.project.project_name
      } / Financial Year - ${new Date().getFinacialYear()} `;
    } else {
      return "Project / Financial Year / Task ";
    }
  }

  getBackLink() {
    if (this.props.urlparams.project_id) {
      let backLink =
        "/projectmanagement/addtask" +
        Object.encodeuri({
          project_id: this.props.urlparams.project_id,
          project_fy_id: this.props.urlparams.project_fy_id,
          ...(this.props.urlparams.readonly
            ? { readonly: this.props.urlparams.readonly }
            : {}),
        });
      return backLink;
    }
  }

  render() {
    let { tablinks, formFields, props } = this;
    let {
      columns,
      data = [],
      rowcount,
      editData = {},
      budgetEditData,
    } = this.state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      } else if (editData[field.name]) {
        field.defaultValue = editData[field.name];
      } else {
        field.defaultValue = "";
      }
      return field;
    });
    return (
      <LayoutWrapper
        title="Budget"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
        className="addbudget"
      >
        <AppCircleStepper data={tablinks}></AppCircleStepper>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        {(props?.urlparams?.project_id && (
          <>
            {!this.listMode && this.state.editData && (
              <div className="row m-auto">
                <AppForm
                  ref={"appform"}
                  className="flex flex-col gap-4 bg-white p-4"
                  onSubmit={(...args) => this.sumitForm(...args)}
                >
                  <div className="row">
                    {formFields?.map((field, i) => (
                      <div
                        className={Object.className({
                          "col-12 col-md-6 col-lg-3": !field.fieldclass,
                          [field.fieldclass]: field.fieldclass,
                          [field.name]: true,
                        })}
                        key={"field" + i}
                      >
                        <AppInput
                          className="grow"
                          {...field}
                          disabled={this.state.viewMode}
                        ></AppInput>
                      </div>
                    ))}
                  </div>
                  <div className="row no-gutters">
                    <button
                      className={Object.className({
                        "btn add-btn col col-md-1 ml-auto": true,
                        hidden: this.state.viewMode,
                      })}
                    >
                      {this.state.editMode ? "Update" : "Add"}
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ editMode: false, addMode: false })
                      }
                      className={Object.className({
                        "btn btn_primary col col-md-1 grey me-2 ms-2":
                          !this.state.viewMode,
                        "btn btn_primary col col-md-1 grey me-2 ml-auto":
                          this.state.viewMode,
                      })}
                    >
                      Cancel
                    </button>
                  </div>
                </AppForm>
              </div>
            )}
            {this.listMode && (
              <div className="row">
                <div className="col-12 mb-4">
                  <button
                    className={Object.className({
                      "btn add-btn col col-md-1 ml-auto": true,
                      hidden: this.props.urlparams.readonly == 1 ? true : false,
                    })}
                    onClick={() => this.addRecord()}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}

            <div className="row addbudget">
              <div className="col-12">
                {!!data.length && this.listMode && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    pageSize={10}
                    reorder={true}
                    deleteAction={(v) => this.deleteBudgetAction(v)}
                    editAction={(v) => this.editBudgetAction(v)}
                    viewAction={(v) => this.viewBudgetAction(v)}
                    editable={this.props.urlparams.readonly == 1 ? false : true}
                    deletable={
                      this.props.urlparams.readonly == 1 ? false : true
                    }
                    targetType="tap"
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>

            {this.listMode && (
              <div className="row">
                <div className="col-12 mt-4">
                  <Link
                    to={
                      "/projectmanagement/addresources?" +
                      Object.QueryString({
                        ...this.props.urlparams,
                        tab: "employee",
                      })
                    }
                    className="btn add-btn"
                  >
                    Next
                  </Link>
                </div>
              </div>
            )}
          </>
        )) ||
          ""}
      </LayoutWrapper>
    );
  }
}

export default connect(Budget);

import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import { tabs } from "../leavemanagment";

export class LeavePolicies extends Component {
  constructor(props) {
    super(props);
    this.links = tabs;
    this.state = {
      accordianList: [],
    };
  }

  componentDidMount() {
    this.props.api.apiLeavePolicyList().then((data) => {
      console.log("accordianList data", data);
      this.setState({
        accordianList: data,
      });
    });
  }

  render() {
    let { links } = this;
    let { accordianList = [] } = this.state;
    return (
      <LayoutWrapper title="LeavePolicies" back="Leave Management">
        <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs>
        <div className="flex mb-6">
          <div className="ml-auto ">
            <button className="btn add-btn">+ Add New Policy</button>
          </div>
        </div>
        <AppAccordian
          data={accordianList.map((o) => ({
            Title: o.title && (() => o.title),
            Content: o.description && (() => o.description),
          }))}
        ></AppAccordian>
      </LayoutWrapper>
    );
  }
}

export default connect(LeavePolicies);


export default {

    PM_PROJECT_RESOURCE_TYPES: [
        { key: "employee", label: "Employees" },
        { key: "material", label: "Material" },
    ],
    PM_PROJECT_STATUS: [
        { key: "open", label: "Open" },
        { key: "in_progress", label: "In Progress" },
        { key: "completed", label: "Completed" },
    ],
    PM_PRIORITY_STATUS: [
        { key: "high", label: "High" },
        { key: "medium", label: "Medium" },
        { key: "low", label: "Low" },
    ],
    PM_UNIT_OF_MESURE: [
        { key: "monthly", label: "Monthly" },
        { key: "quaterly", label: "Quaterly" },
        { key: "annually", label: "Annually" },
    ],
    PM_UNIT_OF_MESURE_QVALUE: {
        "monthly": 4,
        "quaterly": 1,
        "annually": (1 / 4),
    },
    PM_UNIT_OF_MESURE_MVALUE: {
        "monthly": 1,
        "quaterly": (1 / 3),
        "annually": (1 / 12),
    },
    PM_PROJECT_ROLES: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-Ordinator" },
        // { key: "woman-entrepreneur", label: "Woman Entrepreneur" },
        { key: "others", label: "Central Team" }
    ],
    PM_PROJECT_EMPLOYEES: [
        { key: "employee1", label: "Employee 1" },
        { key: "employee2", label: "Employee 2" },
        { key: "employee3", label: "Employee 3" },
        { key: "employee4", label: "Employee 4" },
        { key: "employee5", label: "Employee 4" }
    ],
    PM_BUDGET_CATEGORIES: [
        { key: "personnel_costs", label: "Personnel Categories" }
    ],
    PM_STATUS: [
        { key: "open", label: "Open", colorCode: "#FF7C21" },
        { key: "in_progress", label: "In-Progress", colorCode: "#692E20" },
        { key: "completed", label: "Completed", colorCode: "#00AB26" },
    ],
}
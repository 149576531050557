import React, { Component } from "react";
export class VendorManagementSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {};
    this.fields = [
      {
        name: "vendor_name",
        label: "Name",
        type: "text",
      },
      {
        name: "services",
        label: "Services",
        type: "text",
      },
      {
        name: "vendor_location",
        label: "Location",
        type: "text",
      },
    ];
  }

  onSearch(reset = false) {
    console.log({ reset });
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data);
    if (reset) {
      data = {};
    } else {
      data = Object.filter(data, (val) => val);
      console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data);
  }

  componentDidMount() {}

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    return (
      <>
        <AppForm className="row filter-row flex-auto" ref="form">
          {this.props.hideStatus
            ? this.fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-3" key={i}>
                    <AppInputFocus {...field}></AppInputFocus>
                  </div>
                ))
            : this.fields.map((field, i) => (
                <div className="col-sm-6 col-md-3" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i class="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(VendorManagementSearch);

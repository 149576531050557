export default [
    {
        path: "/lms/dashboard",
        icon: "la la-dashboard",
        label: "Dashboard",
        id: "dashboard",
    },
    {
        path: "/lms",
        icon: "la la-file-text",
        label: "Course Configure",
        id: "course_configure",
    },
    {
        path: "/lms/courses",
        icon: "la la-file-text",
        label: "Courses",
        id: "courses",
    },
    {
        path: "/lms/assessmentreview",
        icon: "la la-file-text",
        label: "Assessment Review",
        id: "assessmentreview",
    },
];
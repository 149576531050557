export default [
    {
        path: "/projectmanagement/dashboard",
        icon: "la la-dashboard",
        label: "Dashboard",
        id: "dashboard",
    },
    {
        path: "/projectmanagement",
        icon: "la la-file-text",
        label: "Project",
        id: "projectmanagement",
    },
    {
        path: "/projectmanagement/list/expense_management",
        icon: "la la-file-text",
        label: "Expense Management",
        id: "expense_management",
    },
    {
        path: "/projectmanagement/reportsummary",
        icon: "la la-file-text",
        label: "Reports",
        id: "reportsummary",
    },
];
export const dashboardService = {
    apiDashboardTotalCardDataMoke() {
        let data = [
            {
                name: "total_registration_form",
                label: "Total Registration Form",
                value: 100,
                icon: "la la-user"
            },
            {
                name: "total_aptitude_test",
                label: "Total Aptitude Test",
                value: 50,
                icon: "la la-user-check"
            },
            {
                name: "total_applicants_applied",
                label: "Total Applicants Applied",
                value: 30,
                icon: "la la-users"
            },
            {
                name: "total_applicants_rejected",
                label: "Total Applicants Rejected",
                value: 20,
                icon: "la la-user-slash"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiDashboardTotalApplicantsDataMoke() {
        let data = [
            {
                name: "digital_sakhi",
                label: "Digital Sakhi",
                value: 20,
                icon: "la la-user"
            },
            {
                name: "manager",
                label: "Manager",
                value: 5,
                icon: "la la-user-check"
            },
            {
                name: "cluster_co_ordinator",
                label: "Cluster Co-Ordinator",
                value: 3,
                icon: "la la-users"
            },
            {
                name: "others",
                label: "Others",
                value: 2,
                icon: "la la-user-slash"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiDashboardProjectsDataMoke() {
        let data = [
            {
                formName: "Developer",
                total_applicants: 100,
                selected_applicants: 50,
                rejected_applicants: 20,
            },
            {
                formName: "Desginer",
                total_applicants: 100,
                selected_applicants: 50,
                rejected_applicants: 20,
            },
            {
                formName: "Content Writer",
                total_applicants: 100,
                selected_applicants: 50,
                rejected_applicants: 20,
            },
            {
                formName: "Developer",
                total_applicants: 100,
                selected_applicants: 50,
                rejected_applicants: 20,
            },
            {
                formName: "Desginer",
                total_applicants: 100,
                selected_applicants: 50,
                rejected_applicants: 20,
            },
            {
                formName: "Content Writer",
                total_applicants: 100,
                selected_applicants: 50,
                rejected_applicants: 20,
            },
        ]
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);

    },
    apiDashboardRecruitmentDataMoke() {
        let data = [
            {
                name: "total_applicants",
                label: "Total No. of Applicants",
                value: 50,
            },
            {
                name: "online_assessment_pending",
                label: "Online Assessment Pending",
                value: 10,
            },
            {
                name: "online_assessment_review_pending",
                label: "Online Assessment Review Pending",
                value: 5,
            },
            {
                name: "F2F_interview_pending",
                label: "F2F Interview Pending",
                value: 10,
            },
            {
                name: "selected",
                label: "Selected",
                value: 5,
            }
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);

    },
    apiDashboardCardData() {
        //return this.apiDashboardTotalCardDataMoke();
        let icons = {
            "total_employees": "la la-user",
            "active_employees": "la la-user-check",
            "today_present": "la la-users",
            "today_absent": "la la-user-slash",
            "this_month_leave_request": "la la-user-minus",
            "this_month_approved_leaves": "la la-check-circle",
            "this_month_pending_exceptions": "la la-arrow-alt-circle-right",
            "this_month_approved_exceptions": "la la-file",
            "leave_applied_for_today": "la la-user-times"
        };
        let labels = {
            "total_employees": "Total Employees",
            "active_employees": "Active Employees",
            "today_present": "Today's Present",
            "today_absent": "Today's Absent",
            "this_month_leave_request": "Leave Approvals",
            "this_month_approved_leaves": "On Leave",
            "this_month_pending_exceptions": "Exception Approvals",
            "this_month_approved_exceptions": "Exceptions",
            "leave_applied_for_today": "Leave Applied For Today"
        }
        return this.request(this.api().dashboardStatsList)
            .exec().log().mapresults(data => {
                app.props.setstore('dashboard_stats', data);
                return Object.entries(data).map(([key, value]) => {
                    if (key == "this_month_exception_approvals") {
                        value = data.this_month_exception_approvals - data.this_month_approved_exceptions;
                    }
                    return ({
                        name: key,
                        label: labels[key],
                        value,
                        icon: icons[key]
                    })
                }).filter(o => !["leave_applied_for_today", "this_month_exception_approvals", "this_month_rejected_exceptions"].includes(o.name))
            }).get();
    },

    apiDashboardDataCollectionDataMoke() {
        let data = [
            {
                name: "total_projects",
                label: "Total Projects",
                value: 40,
                icon: "las la-lightbulb"
            },
            {
                name: "total_forms",
                label: "Total Forms",
                value: 30,
                icon: "las la-user-plus"
            },
            {
                name: "open_forms",
                label: "Open Forms",
                value: 10,
                icon: "la la-users"
            },
            {
                name: "closed_forms",
                label: "Closed Forms",
                value: 20,
                icon: "las la-laptop"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiDataCollectionAttendanceDataMoke() {
        let data = [
            {
                name: "todays_present",
                label: "Today's Present",
                value: 234,
                icon: "la la-user"
            },
            {
                name: "todays_absent",
                label: "Today's Absent",
                value: 234,
                icon: "las la-user-alt-slash"
            },
            {
                name: "on_leave",
                label: "On Leave",
                value: 234,
                icon: "la la-user"
            },
            {
                name: "pending_approvals",
                label: "Pending Approvals",
                value: 234,
                icon: "las la-arrow-circle-right"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiDataCollectionBudgetDataMoke() {
        let data = [
            {
                name: "allocated_budget",
                label: "Allocated Budget",
                value: '1,00,000',
                icon: "las la-project-diagram"
            },
            {
                name: "utilized_budget",
                label: "Utilized Budget",
                value: '60,000',
                icon: "la la-user-check"
            },
            {
                name: "remaining_budget",
                label: "Remaining Budgete",
                value: '40,000',
                icon: "la la-user"
            },
            {
                name: "monthly_average",
                label: "Monthly Average",
                value: '20,000',
                icon: "las la-chart-bar"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiDataCollectionTrainingsDataMoke() {
        let data = [
            {
                name: "trainings_planned",
                label: "Trainings Planned",
                value: 50,
                icon: "las la-tasks"
            },
            {
                name: "trainings_conducted",
                label: "Trainings Conducted",
                value: 20,
                icon: "las la-chalkboard-teacher"
            },
            {
                name: "upcoming_trainings",
                label: "Upcoming Trainings",
                value: 10,
                icon: "las la-clipboard-list"
            },
            {
                name: "beyond_TAT",
                label: "Beyond TAT",
                value: 20,
                icon: "las la-clipboard-check"
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiLMSDashboardDataMoke() {
        let data = [
            {
                formName: "Total Courses",
                count: 10,
                icon: "las la-lightbulb",
            },
            {
                formName: "Total Modules",
                count: 35,
                icon: "las la-user-plus",
            },
            {
                formName: "Total Videos",
                count: 150,
                icon: "la la-users",
            },
            {
                formName: "Total PPT",
                count: 38,
                icon: "las la-laptop",
            },
        ]
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiWomenEntrepreneurDashboardDataMoke() {
        let data = {
            total_entrepreneur: 234,
            total_active: 234,
            total_inactive: 234,
            no_of_vendors: 234,
        }
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
}

export default dashboardService;
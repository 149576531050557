import React, { Component } from 'react';
import { Link } from "react-router-dom";
import GoogleTranslator from './GoogleTranslator';

export class header extends Component {
    componentDidMount() {
        
    }
    render() {
        const {
            transperent
        } = imagepaths;
        return (
            <div className="header" style={{ right: "0px" }}>
                <div className="header-left flex">
                    <Link to="/" className="logo">
                        <img src={transperent} alt="" />
                    </Link>
                    <GoogleTranslator languages={[]} />
                </div>
            </div>

        )
    }
}

export default header
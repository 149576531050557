export default {
    candidateRegisterFormList: {
        url: "/recruitment/job",
        method: "get"
    },
    candidateRegisterForm: {
        url: "/recruitment/job",
        method: "post"
    },
    updateCandidateRegisterForm: {
        url: "/recruitment/job/{id}",
        method: "put"
    },
    getCadidateRegisterForm: {
        url: "/recruitment/job/{id}",
        method: "get"
    },
    deleteCadidateRegisterForm: {
        url: "/recruitment/job/{id}",
        method: "delete"
    },
    getResponce: {
        url: "/recruitment/applicant/{id}",
        method: "get"
    },
    saveFormResponce: {
        url: "/recruitment/applicant",
        method: "post"
    },
    candidateList: {
        url: "/recruitment/applicant",
        method: "get"
    },
    selectCadidateForm: {
        url: "/recruitment/applicant/select/{applicant_id}",
        method: "patch"
    },
    rejectCadidateForm: {
        url: "/recruitment/applicant/reject/{applicant_id}",
        method: "patch"
    },
    updateCandidateResponse: {
        url: "/recruitment/applicant/{id}",
        method: "put"
    },
    deleteCadidateForm: {
        url: "/recruitment/applicant/{applicant_id}",
        method: "delete"
    },
    candidateAssementTestList: {
        url: "/recruitment/assessment",
        method: "get"
    },
    candidateAssementTest: {
        url: "/recruitment/assessment",
        method: "post"
    },
    updateCandidateAssementTest: {
        url: "/recruitment/assessment/{id}",
        method: "put"
    },
    getCadidateAssementTest: {
        url: "/recruitment/assessment/{id}",
        method: "get"
    },
    deleteCadidateAssementTest: {
        url: "/recruitment/assessment/{id}",
        method: "delete"
    },
    saveCadidateAssementTest: {
        url: "/recruitment/evaluation",
        method: "post"
    },
    getCadidateAssementTestSubmited: {
        url: "/recruitment/evaluation/{id}",
        method: "get"
    },
    test2response: {
        url: "/recruitment/evaluation/assessment/{assessment_id}",
        method: "get"
    },
    fileuploader: {
        url: "/recruitment/upload",
        method: "post"
    },
    filedeleter: {
        url: "/recruitment/upload/{file_id}",
        method: "delete"
    },
    fileDownloader: {
        url: "/recruitment/upload/{attachmentId}",
        method: "get"
    },
    fileHRMSDownloader: {
        url: "/hrms/upload/{attachmentId}",
        method: "get"
    },
    candidateofferupdate: {
        url: "/recruitment/applicant/{id}",
        method: "put"
    },
    testIdsByRole: {
        url: "/recruitment/assessment/role/{job_role}",
        method: "get"
    },
    // DASHBOARD API calls
    dashboardFunnelData: {
        url: "/recruitment/dashboard/funnel",
        method: "get",
    },
    dashboardTotalData: {
        url: "/recruitment/dashboard/total",
        method: "get",
    },
    dashboardJobwiseData: {
        url: "/recruitment/dashboard/job-applicants",
        method: "get",
    },
    // LOGBOOK API calls
    addLogs: {
        url: "/hrms/logs",
        method: "post"
    },
    getLogs: {
        url: "/hrms/logs",
        method: "get"
    },
    getLogsByMonth: {
        url: "/hrms/logs/month",
        method: "get"
    },
    getAllAdminLogs: {
        url: "/hrms/logs/all/admin",
        method: "get"
    },
    getLogBook: {
        url: "/hrms/logs/{log_book_id}",
        method: "get"
    },
    deleteLogBook: {
        url: "/hrms/logs/{log_book_id}",
        method: "put"
    },
    // VEHICLE API calls
    addVehicle: {
        url: "/hrms/vehicle",
        method: "post"
    },
    getVehicles: {
        url: "/hrms/vehicle",
        method: "get"
    },
    getVehicleById: {
        url: "/hrms/vehicle/{vehicle_id}",
        method: "get"
    },
    deleteVehicleById: {
        url: "/hrms/vehicle/{vehicle_id}",
        method: "put"
    },
    // TASK MANAGEMENT API calls
    addHrmsTask: {
        url: "/hrms/task",
        method: "post"
    },
    getHrmsTask: {
        url: "/hrms/task",
        method: "get"
    },
    getHrmsTaskById: {
        url: "/hrms/task/{task_id}",
        method: "get"
    },
    updateHrmsTask: {
        url: "/hrms/task/{task_id}",
        method: "put"
    },
    deleteHrmsTask: {
        url: "/hrms/task/{task_id}",
        method: "delete"
    },
    // 
    getTaskNotifications: {
        url: "/hrms/notification/",
        method: "get"
    },
    updateTaskNotification: {
        url: "/hrms/notification/read/{taskIds}",
        method: "put"
    },

}
/**
 * Signin Firebase
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Loginpage = (props) => {
  const schema1 = yup.object({
    mobile: yup.string().min(6).required("Number is required").trim(),
  });
  const schema2 = yup.object({
    // email: yup
    //   .string()
    //   .matches(window?.constant?.emailrgx, "Email is required")
    //   .required("Email is required")
    //   .trim(),
    password: yup.string().min(6).required("Password is required").trim(),
  });

  const [eye, seteye] = useState(true);
  const [loginToggle, setLoginToggle] = useState(true);
  const [otpToggle, setOtpToggle] = useState(true);
  const [passwordToggle, setPasswordToggle] = useState(false);

  const {
    control: control1,
    handleSubmit: handleOTPSubmit,
    setError: setError1,
    formState: { errors1 },
  } = useForm({
    resolver: yupResolver(schema1),
  });

  const {
    control: control2,
    handleSubmit: handlePasswordSubmit,
    setError: setError2,
    clearErrors,
    formState: { errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });

  const onOTPSubmit = (data) => {
    console.log("onOTPSubmit", data);

    props.api
      .apiOTPLogin(data)
      .then((res) => {
        console.log({ onOTPSubmit: res });
        props.setstore("OTPresponses", res);

        // props.setstore("auth", res.auth);
        // props.setstore("user", res.user);
        clearErrors("mobile");
        navigate("/open/otp");
      })
      .catch((err) => {
        console.log("error", err);
        // alert("error", err.message);
        setError1("mobile", {
          message: err.message,
        });
      });
  };

  const onPasswordSubmit = (data) => {
    console.log("onPasswordSubmit", data);
    // props.setstore("auth", true);
    // /*
    props.api
      .apiLogin({
        email_address: data.email,
        password: data.password,
      })
      .then((res) => {
        console.log({ res });
        props.setstore("logged", res.logged);
        props.setstore("auth", res.auth);
        props.setstore("user", res.user);

        clearErrors("password");
      })
      .catch(() => {
        setError2("password", {
          message: "login failed",
        });
      });
    //*/
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  const handleLoginToggle = (val) => {
    if (val === "otp") {
      setOtpToggle(true);
      setPasswordToggle(false);
      setLoginToggle(true);
    }
    if (val === "password") {
      setOtpToggle(false);
      setPasswordToggle(true);
      setLoginToggle(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - Access Livelihoods</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="account-content">
        {/* <Link to="/applyjob/joblist" className="btn btn-primary apply-btn">
					Apply Job
				</Link> */}
        <div className="container">
          {/* /Account Logo */}
          <div className="account-box">
            <div className="account-wrapper">
              {/* Account Logo */}
              <div className="account-logo">
                <Link>
                  <img src={imagepaths.Applogo} alt="" />
                </Link>
              </div>
              <h3 className="account-title">Login</h3>
              <p className="account-subtitle">Access to explore more</p>

              {/* <div className="login_toggle_container my-4">
                <button
                  type="button"
                  onClick={() => handleLoginToggle("otp")}
                  className={otpToggle ? "btn active" : "btn"}
                >
                  OTP
                </button>
                <button
                  type="button"
                  onClick={() => handleLoginToggle("password")}
                  className={passwordToggle ? "btn active" : "btn"}
                >
                  Password
                </button>
              </div> */}

              {/* Account Form */}
              <div>
                {/* {loginToggle && (
                  <form
                    className="otp_form"
                    key={1}
                    onSubmit={handleOTPSubmit(onOTPSubmit)}
                  >
                    <div className="form-group">
                      <label>Email or Mobile no.</label>
                      <Controller
                        name="mobile"
                        control={control1}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control  ${
                              errors1?.mobile ? "error-input" : ""
                            }`}
                            type="number"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                            placeholder="Enter here"
                            required={true}
                          />
                        )}
                      />
                      <small>{errors1?.mobile?.message}</small>
                    </div>

                    <div className="form-group text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit"
                      >
                        Send OTP
                      </button>
                    </div>
                  </form>
                )}

                {!loginToggle && (
                  <form
                    className="email_form"
                    key={2}
                    onSubmit={handlePasswordSubmit(onPasswordSubmit)}
                  >
                    <>
                      <div className="form-group">
                        <label>Email Address</label>
                        <Controller
                          name="email"
                          control={control2}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control  ${
                                errors2?.email ? "error-input" : ""
                              }`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                              placeholder="Enter your email here"
                              required={true}
                            />
                          )}
                        />
                        <small>{errors2?.email?.message}</small>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label>Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgotpassword">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <Controller
                          name="password"
                          control={control2}
                          render={({ field: { value, onChange } }) => (
                            <div className="pass-group">
                              <input
                                type={eye ? "password" : "text"}
                                className={`form-control  ${
                                  errors2?.password ? "error-input" : ""
                                }`}
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                                placeholder="Enter your password here"
                                required={true}
                              />
                              <span
                                onClick={onEyeClick}
                                className={`fa toggle-password" ${
                                  eye ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </div>
                          )}
                        />
                        <small>{errors2?.password?.message}</small>
                      </div>

                      <div className="form-group text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </>
                  </form>
                )} */}
                <form
                  className="email_form"
                  key={2}
                  onSubmit={handlePasswordSubmit(onPasswordSubmit)}
                >
                  <>
                    <div className="form-group">
                      <label>Email Address</label>
                      <Controller
                        name="email"
                        control={control2}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control  ${
                              errors2?.email ? "error-input" : ""
                            }`}
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                            placeholder="Enter your email here"
                            required={true}
                          />
                        )}
                      />
                      <small>{errors2?.email?.message}</small>
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label>Password</label>
                        </div>
                        <div className="col-auto">
                          <Link className="text-muted" to="/forgotpassword">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <Controller
                        name="password"
                        control={control2}
                        render={({ field: { value, onChange } }) => (
                          <div className="pass-group">
                            <input
                              type={eye ? "password" : "text"}
                              className={`form-control  ${
                                errors2?.password ? "error-input" : ""
                              }`}
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                              placeholder="Enter your password here"
                              required={true}
                            />
                            <span
                              onClick={onEyeClick}
                              className={`fa toggle-password" ${
                                eye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                      />
                      <small>{errors2?.password?.message}</small>
                    </div>

                    <div className="form-group text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </>
                </form>
              </div>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(Loginpage);

import React, { Component } from "react";
import { useState } from "react";
import { GapFields } from "./BasicDetails";

const SalaryComponents = ({ label, fields, index }) => (
  <div className="col-3">
    <div className="row mb-4">
      <div className="col text-lg text-black font-bold">{label}</div>
    </div>
    <div className="row">
      {fields.map((field, i) => (
        <div className="col-12" key={i + label}>
          <AppInput
            {...field}
            className={"pr-2 " + (field?.className || "")}
            key={i}
          ></AppInput>
        </div>
      ))}
    </div>
  </div>
);
export const SalaryDetailsFields = [
  {
    type: "number",
    name: "ctc",
    label: "Annual CTC",
    onChange: (e) => {
      let value = e.target.value;
      let fixed = document.querySelector("[name='fixed']");
      fixed.max = value;
    },
  },
  {
    type: "number",
    name: "fixed",
    label: "Fixed",
    onChange: (e) => {
      let value = e.target.value;
      let ctc = document.querySelector("[name='ctc']");
      let fixed = document.querySelector("[name='fixed']");
      fixed.max = ctc.getAttribute("value") || ctc.value || fixed.max;
      app.mixin.debounce(() => {
        getBreakupDetails(value);
      });
    },
  },
  {
    type: "number",
    name: "variable",
    label: "Variable",
    disabled: true,
  },
  {
    type: "number",
    name: "monthlySalary",
    label: "Monthly Salary",
    disabled: true,
    //required:true
  },
  {
    type: "number",
    name: "basicSalary",
    label: "Basic Salary",
    disabled: true,
  },
  {
    type: "number",
    name: "conveyance",
    label: "Conveyance",
    disabled: true,
  },
  {
    type: "number",
    name: "hra",
    label: "HRA",
    disabled: true,
  },
  {
    type: "number",
    name: "otherAllowances",
    label: "Other Allowance",
    disabled: true,
    //required:true
  },
  {
    type: "number",
    name: "totalEarnings",
    label: "Total Earnings",
    disabled: true,
    //required:true
  },
  {
    type: "number",
    name: "pf",
    label: "PF",
    disabled: true,
  },
  {
    type: "number",
    name: "tds",
    label: "TDS",
    disabled: true,
  },
  {
    type: "number",
    label: "Professional Tax",
    name: "professionalTax",
    disabled: true,
  },
  {
    type: "number",
    name: "others",
    label: "Other",
    disabled: true,
    //required:true
  },
  {
    type: "number",
    name: "totalDeductions",
    label: "Total Deductions",
    disabled: true,
    //required:true
  },
];

const getBreakupDetails = (value) => {
  app.props.api.apiSalaryBreakdown(value).then((responce) => {
    document.querySelector("[name='basicSalary']").value =
      responce.earnings.basic_salary;
    document.querySelector("[name='conveyance']").value =
      responce.earnings.conveyance;
    document.querySelector("[name='hra']").value = responce.earnings.hra;
    document.querySelector("[name='otherAllowances']").value =
      responce.earnings.other_allowance;
    document.querySelector("[name='totalEarnings']").value =
      responce.total_earnings;
    document.querySelector("[name='professionalTax']").value =
      responce.deductions.professional_tax;
    document.querySelector("[name='pf']").value = responce.deductions.pf;
    document.querySelector("[name='tds']").value = responce.deductions.tds;
    document.querySelector("[name='others']").value =
      responce.deductions.others;
    document.querySelector("[name='totalDeductions']").value =
      responce.total_deductions;
    document.querySelector("[name='monthlySalary']").value =
      responce.monthly_salary;
    let netSalaryEle = document.querySelector("[name='net_salary']");
    netSalaryEle && (netSalaryEle.value = responce.net_salary);
    let anualctc = document.querySelector("[name='ctc']").value;
    if (anualctc && Number(anualctc) && value && Number(value)) {
      document.querySelector("[name='variable']").value =
        Number(anualctc) - Number(value);
    }
  });
};
const SalaryDetails = (props) => {
  let [fields] = useState(
    SalaryDetailsFields.only(["ctc", "fixed", "variable"], "name")
  );
  let [salarydata] = useState({
    earnings: SalaryDetailsFields.only(
      ["basicSalary", "conveyance", "hra", "otherAllowances", "totalEarnings"],
      "name"
    ),
    deductions: SalaryDetailsFields.only(
      ["pf", "tds", "professionalTax", "others", "totalDeductions"],
      "name"
    ),
  });
  const salaryDataArray = Object.entries(salarydata);

  return (
    <form
      onSubmit={(...e) => (props?.onSubmit || (() => ""))(...e)}
      className={"form"}
    >
      <div className="row mb-4">
        {fields.map((field, i) => (
          <GapFields {...{ field, i }} key={i} />
        ))}
      </div>
      <div className="row mb-2">
        <label className="text-primary text-lg col font-bold">
          Salary Components
        </label>
      </div>
      <div className="row mb-4">
        {salaryDataArray.map(([name, fields], i) => (
          <SalaryComponents
            key={name}
            index={name + i}
            label={name.toTitleCase()}
            fields={fields}
          ></SalaryComponents>
        ))}
      </div>
      <div className="row mb-2">
        <div className="col-6">
          <AppInput
            type="number"
            disabled={true}
            name="monthlySalary"
            label="Monthly Salary"
            labelClass="text-primary font-bold"
          />
        </div>
        <div className="col-6">
          <AppInput
            type="number"
            disabled={true}
            name="net_salary"
            label="Net Salary"
            labelClass="text-primary font-bold"
          />
        </div>
      </div>
      <div className="row">
        <button className="btn add-btn col col-md-1 ml-auto">
          Save & Next
        </button>
        <div className="col-12 col-md-3"></div>
      </div>
    </form>
  );
};

export default connect(SalaryDetails);

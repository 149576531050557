import React, { Component } from "react";

export class LogBookSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("LogBookSearch");
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "employee_id",
        label: "Employee ID",
        type: "text",
      },
      {
        name: "month_year",
        label: "Select Month",
        type: "month",
        className: "select-focus",
      },
      {
        name: "employee_role",
        label: "Role",
        type: "select",
        options: _enum.EMP_ROLES,
        className: "select-focus",
      },
    ];
    this.form = React.createRef();
  }

  onSearch(reset) {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);
    let payload;
    data = Object.filter(data, (val) => val);

    if (reset) {
      payload = {
        ...this.state.search,
      };
    } else {
      payload = {
        ...data,
        month_year: data.month_year ? this.convertDate(data.month_year) : "",
      };
    }
    console.log("data on search", payload);
    this.props.onSearch && this.props.onSearch(reset, payload);
  }

  convertDate(str) {
    const dateString = str;

    // Split the string into parts based on the hyphen "-"
    const parts = dateString.split("-");

    // Map the month abbreviation to a numeric value
    const monthAbbreviations = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };

    // Get the numeric month
    const month = monthAbbreviations[parts[0]];
    // Get the numeric year
    const year = parseInt(parts[1]);

    return month + "/" + year;
  }
  componentDidMount() {}

  updateFields(fields) {
    return fields;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    let { fields } = this;
    fields = this.updateFields(fields);
    return (
      <>
        <AppForm
          className="row filter-row"
          ref="form"
          // onSubmit={(...args) => this.onSearch(...args)}
        >
          {this.props.hideStatus
            ? fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-2" key={i}>
                    <AppInputFocus {...field}></AppInputFocus>
                  </div>
                ))
            : fields.map((field, i) => (
                <div className="col-sm-6 col-md-2" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-2">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i class="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(LogBookSearch);

export default [
    {
        path: "/womenentrepreneur/dashboard",
        icon: "la la-dashboard",
        label: "Dashboard",
        id: "dashboard",
    },
    {
        path: "/womenentrepreneur",
        icon: "la la-file-text",
        label: "Women Entrepreneur",
        id: "womenentrepreneur",
    },
    {
        path: "/womenentrepreneur/vendormanagement",
        icon: "la la-file-text",
        label: "Vendor Management",
        id: "vendormanagement",
    },
    {
        path: "/womenentrepreneur/vendormanagement",
        icon: "la la-file-text",
        label: "Vendor",
        id: "vendor",
    },
    {
        path: "/womenentrepreneur/forum",
        icon: "la la-file-text",
        label: "Forum",
        id: "forum",
    },
    {
        path: "/lms/courses",
        icon: "la la-file-text",
        label: "LMS",
        id: "lms",
    },
];
import methods from "./methods";
import apis from "./apis";
import _enum from "./enum";
import mokeresults from "./mokeresults";
import Emitter from "../utils/emiters";
window._enum = _enum;
window.mokeresults = mokeresults;
window.emit = new Emitter();
const setInitials = ()=>{
    window._enum = {
        ..._enum,
        ...window.getpathkey(_enum)
    };
    window.mokeresults = {
        ...mokeresults,
        ...window.getpathkey(mokeresults)
    }
    console.log({setInitials:window._enum});
};
export const mixins =  {
    ...methods,
    api:apis,
    setInitials,
    _enum:()=>(window._enum),
    emit:window.emit
}

export const subscribeinputs = (names=[],{callback=((e)=>('')),root=document, getvalue={},getter,setter})=>{
    console.log("subscribeinputs",{names,root,setter});
    names.map((name)=>{
        let relove = null;
        let promise =  new Promise((res)=>{relove = res});
        let ele = root.querySelector(`[name='${name}']`);
        console.log("subscribeinputs",ele);
        if(!ele) {relove(); return};
        let tagName = ele.tagName;
        let listner = "change";
        let event2value = (($e)=>$e.target.value);
        if(["INPUT"].includes(tagName)) {
            let type = ele.getAttribute('type');
            listner  = !['checkbox','radio','color'].includes(type)?"input":listner;
            event2value  = ['checkbox','radio'].includes(type)?(($e)=>$e.target.checked):event2value;
        }
        let config = getvalue[name] || {listner,event2value};
        config.event2value = config.event2value || event2value;
        if(ele.subscribeinputs) { ele.removeEventListener(config.listner,ele.subscribeinputs.onaction); }
        ele.subscribeinputs = ele.subscribeinputs || {};
        ele.subscribeinputs.onaction = (e)=>{
            let event2value = config.event2value;
            let value = event2value(e);
            callback(name,value);
            let updater = ()=>{
                let payload = {...getter,[name]:value};
                console.log("onaction",name,value,config.listner,payload,root,setter);
                setter(payload);
            };
            if(["input"].includes(config.listner)) {
                clearTimeout(ele.subscribeinputs.debounce);
                ele.subscribeinputs.debounce = setTimeout(updater,500);
            } else {
                updater();
            }
            relove({name,value});
        };
        console.log("subscribeinputs",config.listner,ele.subscribeinputs.onaction);
        ele.addEventListener(config.listner,ele.subscribeinputs.onaction);
        return promise;
    })
};

window.onload = ()=>{
    console.log("webpageloaded");
    //setInitials();
};
export default mixins;
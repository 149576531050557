import weMapColumns from "./womenentrepreneur.mapcolumns"
export default {
    apiWomenEntrepreneurDataMoke() {
        let data = [
            {
                name: "Sikha",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                business_type: "Optional",
            },
            {
                name: "Keerthi",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                business_type: "Optional",
            },
            {
                name: "Kaveri",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                business_type: "Optional",
            },
            {
                name: "Yasha",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                business_type: "Optional",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiVendorDataMoke() {
        let data = [
            {
                name: "Suresh",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                services: "IT",
            },
            {
                name: "Manish",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                services: "Recruiter",
            },
            {
                name: "Saurabh",
                mobile_number: "9998886666",
                email_address: "Emialid@gmail.com",
                location: "Location",
                services: "IT",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },

    apiAddEntrepreneur(data) {
        return this.request(this.api().createEmployee, data)

            .transform((post) => (
                {
                    employee_id: post?.employee_id,
                    employee_role: post?.employee_role,
                    employee_name: post?.employee_name,
                    mobile_num: post?.mobile_num,
                    email_address: post?.email_address,
                    entrepreneur_location: post?.entrepreneur_location,
                    entrepreneur_business_type: post?.entrepreneur_business_type,
                }
            ))
            .exec().log()
            .get();
    },

    apiGetEntrepreneurs(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getEntrepreneurs, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data.items[0].records,
                    total: response.data.items[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiUpdateEntrepreneur(data, urldata = {}) {
        console.log({ apiUpdateVendor: data, urldata });
        let payload = {
            // employee_name: data?.employee_name,
            mobile_num: data?.mobile_num,
            email_address: data?.email_address,
            entrepreneur_location: data?.entrepreneur_location,
            entrepreneur_business_type: data?.entrepreneur_business_type,
        }
        return this.request(this.api().updateEntrepreneur, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteEntrepreneur(urldata = {}) {
        return this.request(this.api().deleteEntrepreneur)
            .urltransform(urldata)
            .exec().log().get();
    },
    // VENDORS

    apiGetVendors(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getVendors, payload)
            .exec().log()
            .mapresults((response) => {
                console.log(response);
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiAddVendor(data) {
        // console.log({ apiAddVendor: data });
        let payload = {
            ...data
        }
        return this.request(this.api().addVendor, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiUpdateVendor(data, urldata = {}) {
        console.log({ apiUpdateVendor: data, urldata });
        let payload = {
            vendor_name: data?.vendor_name,
            mobile_num: data?.mobile_num,
            email_address: data?.email_address,
            vendor_location: data?.vendor_location,
            services: data?.services,
        }
        return this.request(this.api().updateVendor, payload).urltransform(urldata).exec().log().get();
    },

    apiDeleteVendor(urldata = {}) {
        return this.request(this.api().deleteVendor)
            .urltransform(urldata)
            .exec().log().get();
    },

    // FORUM

    apiGetForums(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getForumPosts, payload)
            .exec().log()
            .mapresults((response) => {
                console.log(response);
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiAddForumPost(data) {
        let payload = {
            ...data
        }
        return this.request(this.api().addForumPost, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiGetForumDetailsById(urldata = {}) {
        console.log({ apiGetForumDetailsById: urldata });

        return this.request(this.api().getForumPostById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data
            })
            .get();

    },

    apiDeleteForumPost(urldata = {}) {
        return this.request(this.api().deleteForumPost)
            .urltransform(urldata)
            .exec().log().get();
    },

    // FORUM POST COMMENT

    apiGetForumComments(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getForumComments, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiGetForumCommentReplies(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getForumCommentReply, payload)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiCreateForumComment(data) {
        let payload = {
            ...data
        }
        return this.request(this.api().addForumComment, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiDeleteForumComment(urldata = {}) {
        return this.request(this.api().deleteForumComment)
            .urltransform(urldata)
            .exec().log().get();
    },

    // FORUM LIKE
    apiCreateForumPostLike(data) {
        let payload = {
            ...data
        }
        return this.request(this.api().addForumLike, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    // FORUM SAVE
    apiSaveForumPost(data) {
        let payload = {
            ...data
        }
        return this.request(this.api().addForumSave, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },

    apiGetForumPostSave(data) {
        let payload = {
            ...data
        };
        return this.request(this.api().getForumSave, payload)
            .exec().log()
            .mapresults((response) => {
                console.log(response);
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    // FORUM REPORT
    apiCreateForumPostReport(data) {
        let payload = {
            ...data
        }
        return this.request(this.api().addForumReport, payload)
            .exec().log()
            .mapresults((response) => {
                return response.data
            })
            .get();
    },
}
import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import EmployeeGrid from '../EmployeeGrid';
import Addemployee from "../Addemployee/Addemployee";
import Editemployee from '../Editemployee';
import Deleteemployee from '../Deleteemployee';
import EmployeeSearch from '../EmployeeSearch';

import { LayoutWrapper } from '../../LayoutWrapper';


export class AllEmployees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }
    componentDidMount() {
        if ($('.select').length > 0) {
            $('.select').select2({
                minimumResultsForSearch: -1,
                width: '100%'
            });
        }
    }
    setMenu(menu) {
        this.setState({ menu });
    }

    toggleMobileMenu() {
        setMenu(!menu)
    }
    render() {
        return (
            <LayoutWrapper title="Employees" back="Dashboard">
                {/* Page Content */}
                <EmployeeGrid/>
                {/* /Page Content */}
                {/* Add Employee Modal */}
                <Addemployee />
                {/* /Add Employee Modal */}
                {/* Edit Employee Modal */}
                <Editemployee />
                {/* /Edit Employee Modal */}
                {/* Delete Employee Modal */}
                <Deleteemployee/>
                {/* /Delete Employee Modal */}
            </LayoutWrapper>
        );
    }
}

export default connect(AllEmployees);

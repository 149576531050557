import React, { Component } from "react";
import { LayoutWrapper } from "../../../LayoutWrapper";
import { Link } from "react-router-dom";

export class AddClusterData extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddClusterData");
    this.state = {
      editData: {},
      districts: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.clusterFields = [
      {
        type: "select",
        name: "district",
        label: "District",
        placeholder: "Enter District Name",
        options: [],
        required: true,
        disabled: this.props.urlparams.district && true,
        // onChange: (...args) => this.handleDistrictChange(...args),
      },
      {
        type: "text",
        name: "cluster_code",
        label: "Cluster Code",
        placeholder: "Enter Cluster Code",
        required: true,
      },
    ];
  }

  get formData() {
    return this.refs.form.form.current.getData();
  }

  componentDidMount() {
    this.fetchDistrict();
    this.getEditData();
  }

  getEditData() {
    if (this.props.urlparams.district) {
      let data = {
        district: this.props.urlparams.district,
        cluster_code: this.props.urlparams.cluster_code,
      };
      // console.log({ editData: data });
      this.setState({ editData: data });
    }
  }

  fetchDistrict() {
    let payload = {
      ...this.state.search,
    };
    this.api.getDistrict(payload).then((res) => {
      this.setState({ districts: res.data });
    });
  }

  findDifferentProperties(obj1, obj2) {
    const differentProperties = {};
    let propName = [];

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          propName.push(key);
          differentProperties[key] = {
            oldValue: obj1[key],
            newValue: obj2[key],
          };
        }
      }
    }

    return propName;
  }

  handleSubmit(e, data) {
    let formData = data;
    // console.log("Master form data: ", formData);
    formData = {
      ...formData,
    };

    if (this.props.urlparams.cluster_code) {
      console.log("edit master data");

      const resolveList = () => {
        let url = "/admin/masterData/cluster";
        this.props.navigate(url);
      };
      let clusterPayload = {
        cluster: formData.cluster_code,
        district: this.props.urlparams.district,
      };
      console.log({ clusterPayload });
      this.api
        .updateCluster(clusterPayload, {
          cluster_name: this.props.urlparams.cluster_code,
          district: this.props.urlparams.district,
        })
        .then(resolveList);
    } else {
      let addClusterPayload = {
        ...formData,
      };
      console.log({ addClusterPayload });
      let { district } = formData;

      this.api.postCluster(addClusterPayload).then(() => {
        let url = "/admin/masterData/cluster";
        this.props.navigate(url);
        success("Cluster created successfully");
        this.fetchCluster(district);
      });
    }
  }

  updateFields(fields) {
    fields.find((o) => o.name === "district").options = this.state.districts
      .map((v) => v.district)
      .options();
    return fields;
  }

  getBreadCums() {
    if (this.props.urlparams.district) {
      return `Master Data / Edit Cluster`;
    } else {
      return "Master Data / Add Cluster";
    }
  }
  getBackLink() {
    return "/admin/masterdata/cluster";
  }

  render() {
    let { clusterFields } = this;
    let { editData } = this.state;
    clusterFields = this.updateFields(clusterFields);

    clusterFields = clusterFields?.map((field) => {
      field.defaultValue = field.defaultValue;

      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      return field;
    });
    return (
      <LayoutWrapper
        title="Master Data"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppForm
          onSubmit={(...args) => this.handleSubmit(...args)}
          className={"form"}
          autoComplete="off"
          ref="form"
        >
          <div className="row">
            {clusterFields
              .filter((f) => {
                return !this.props.urlparams.district
                  ? f.name !== "new_district" && f.name !== "new_cluster"
                  : f;
              })
              .map((field, i) => (
                <GapFields {...{ field, i }} key={i} />
              ))}
          </div>
          <div className="row no-gutters mt-4">
            <button className="btn add-btn col col-md-1 ml-auto">
              {this.props.urlparams.district ? "Update" : "Add"}
            </button>
            <Link
              to={"/admin/masterdata/cluster"}
              className="btn btn_primary grey col col-md-1 ms-2 me-2"
            >
              Cancel
            </Link>
          </div>
        </AppForm>
      </LayoutWrapper>
    );
  }
}

export const GapFields = ({
  field,
  i,
  className = "col-12 col-md-3 px-2 ",
}) => (
  <>
    <AppInput
      {...field}
      className={className + (field?.className || "")}
      key={i}
    ></AppInput>
    {i != 0 && i % 3 == 2 && (
      <div className="col-12 col-md-3 px-2" key={"div" + i}></div>
    )}
  </>
);

export default connect(AddClusterData);

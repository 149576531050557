import datacollectionEnum from "./enums/datacollection.enum"
import projectmanagement from "./enums/projectmanagement.enum"
import lms from "./enums/lms.enum"

export default {
    "public/dcfresponse": datacollectionEnum,
    "datacollection": datacollectionEnum,
    "projectmanagement": projectmanagement,
    "lms": lms,
    FILEIDPAT: "^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$",
    MANDATORY_OBJS: {
        "name": {
            name: "name",
            field_name: "Name",
            field_type: "text",
            field_constraint: "mandatory,character",
            possible_values: []
        },
        "email": {
            name: "email",
            field_name: "Email",
            field_type: "text",
            field_constraint: "mandatory,email",
            possible_values: []
        },
        "mobile_no": {
            name: "mobile_no",
            field_name: "Mobile No",
            field_type: "text",
            field_constraint: "mandatory,phonenumber",
            possible_values: []
        }
    },
    MANDATORY_FIELDS: ["name", "email", "mobile_no"],
    APPLICANT_FILTERSTATUS: [
        {
            label: "Open",
            key: "open",
        },
        {
            label: "Online Assessment Pending",
            key: "online_assessment_pending",
        },
        {
            label: "Online Assessment Review Pending",
            key: "online_assessment_review_pending",
        },
        {
            label: "F2F interview Pending",
            key: "face2face_interview_pending",
        },
        {
            label: "Selected",
            key: "selected",
        },
        {
            label: "Rejected",
            key: "rejected",
        },
    ],

    APPLICANT_DASHBOARD_FUNNEL_STATUS: {
        selected: "Selected",
        interviewPending: "F2F interview Pending",
        onlineAssessmentReviewPending: "Online Assessment Review Pending",
        onlineAssessmentPending: "Online Assessment Pending",
        applicantApplied: "Total No. of Applicants",
    },

    APPLICANT_ROLES: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-Ordinator" },
        // { key: "woman-entrepreneur", label: "Woman Entrepreneur" },
        { key: "others", label: "Others" },
    ],

    APPLICANT_LANGUAGE_LIST: [
        { key: "ta", label: "Tamil" },
        { key: "te", label: "Telugu" },
        { key: "kn", label: "Kannada" },
        { key: "hi", label: "Hindi" },
        { key: "en", label: "English" },
        { key: "ml", label: "Malayalam" },
    ],
    FIELD_TYPES: [
        { key: "text", label: "Free Text" },
        { key: "textarea", label: "Free Text Area" },
        { key: "select", label: "Dropdown" },
        { key: "checkbox", label: "Multi Select : Check Box" },
        { key: "radio", label: "Single Select : Radio Button" },
        { key: "file", label: "Upload" },
        { key: "date", label: "Calendar" }
    ],
    QUESTION_FIELD_TYPES: [
        { key: "text", label: "Free Text" },
        { key: "checkbox", label: "Multi Select : Check Box" },
        { key: "radio", label: "Single Select : Radio Button" },
        { key: "file", label: "Upload" },
        { key: "questionfile", label: "Upload Questions", inkey: "file" },
    ],
    FIELD_CONSTRAINTS: [
        { key: "numeric", label: "Numeric" },
        { key: "character", label: "Character" },
        { key: "phonenumber", label: "Phone Number" },
        { key: "email", label: "Email" },
        { key: "future_dates_only", label: "Future Dates Only" },
        { key: "past_dates_only", label: "Past Dates Only" },
        { key: "mandatory", label: "Mandatory" }
    ],
    CONSTRAINTS_MAP: {
        numeric: "^(0|[1-9][0-9]+)$",
        character: "^.*$",
        email: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        phonenumber: "^[0-9]{10}$",
        mandatory: "^(.+)$",
        future_dates_only: (date) => (date.getTime() >= new Date().toDate().date().getTime()),
        past_dates_only: (date) => (date.getTime() <= new Date().toDate().date().getTime())
    },
    ID_REGEXP: {
        gst: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        pan: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        aadhaar: /\b(\d{4}\s\d{4}\s\d{4})\b|\b(\d{12})\b|\b(\d{4}-\d{4}-\d{4})\b/,
        no_slash_and_backslash: /^[^\\/]*$/,
    },
    TEST_DIFFICULTY: [
        { key: "Easy", label: "Easy" },
        { key: "simple", label: "Simple" },
        { key: "medium", label: "Medium" },
        { key: "complex", label: "Complex" },
    ],
    APPLICANT_STATUS: [
        { key: "open", label: "Open" },
        { key: "selected", label: "Selected" },
        { key: "rejected", label: "Rejected" },
    ],
    TASK_MANAGEMENT_STATUS: [
        { key: "open", label: "Open" },
        { key: "completed", label: "Completed" },
        // { key: "work_in_progress", label: "Work in progress" },
    ],
    TASK_MANAGEMENT_STATUS_MAP: {
        "open": "Open",
        "completed": "Completed",
        // "work_in_progress": "Work in progress"
    },
    SORT_BY_FILTER: [
        { key: "date", label: "Apply Date" },
        { key: "time", label: "Apply Time" },
    ],
    APPLICANT_CARD_STATUS: [
        { key: "send_online_assessment", label: "Send Online Assessment", has: ["unspecified"] },
        { key: "call_for_face2face_interview", label: "F2F Interview", has: ["unspecified", "send_online_assessment"] },
        // { key: "release_offer", label: "Offer Letter", has: ["unspecified", "send_online_assessment", "call_for_face2face_interview"] },
    ],
    INTERVIEW_TYPE: [
        { key: "virtual", label: "Virtual" },
        { key: "in_person", label: "In-Person" }
    ],
    SALARYBREAKUP_MAP: {
        "professional_tax": "Professional TAX",
        "basic_salary": "Basic Salary",
        "others": "Others",
        "hra": "HRA",
        "tds": "TDS",
        "conveyance": "Conveyance",
        "pf": "PF",
        "other_allowance": "Other Allowance",
        "esi": "ESI",
        "monthly_salary": "Monthly Salary",
        "total_earnings": "Total Earnings",
        "total_deductions": "Total Deductions",
        "net_salary": "Net Salary",
        "variable_component": "Variable Pay"
    },
    // HRMS ENUMS
    EMP_ROLES: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Project manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-ordinator" },
        { key: "woman-entrepreneur", label: "Women Entrepreneur" },
    ],
    EMP_ROLES_FOR_HRMS: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Project manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-ordinator" },
    ],
    EMPLOYEE_DELETED_STATUS: [
        { key: "false", label: "Active" },
        { key: "true", label: "Deleted" },
    ],
    EMPLOYEE_STATUS_COLOR_MAP: {
        "PENDING": "yellow",
        "APPROVED": "green",
        "REJECTED": "red",
    },
    EMPLOYEE_STATUS_TEXT_MAP: {
        "PENDING": "Pending",
        "APPROVED": "Approve",
        "REJECTED": "Reject",
    },
    YES_NO_OPTIONS: [
        { key: true, label: "Yes" },
        { key: false, label: "No" },
    ],
    VEHICLE_TYPES: {
        "two_wheeler": "Two Wheeler",
        "four_wheeler": "Four Wheeler",
    },
    EMP_ROLE_MAP: {
        "digital-sakhi": "cluster-co-ordinator",
        "cluster-co-ordinator": "manager",
        "manager": "admin",
        "woman-entrepreneur": "manager"
    },
    EMP_ROLE_MAP_REVERSE: {
        "admin": "manager",
        "manager": "cluster-co-ordinator",
        "cluster-co-ordinator": "digital-sakhi",
    },
    SUPERVISOR_FOR_DS: [
        { key: "cluster-co-ordinator-1", label: "Cluster Co-Ordinator - 1" },
        { key: "cluster-co-ordinator-2", label: "Cluster Co-Ordinator - 2" },
        { key: "cluster-co-ordinator-3", label: "Cluster Co-Ordinator - 3" },
    ],
    SUPERVISOR_FOR_PM: [
        { key: "central-team-1", label: "Central Team - 1" },
    ],
    SUPERVISOR_FOR_CC: [
        { key: "project-manager-1", label: "Project Manager - 1" },
        { key: "project-manager-2", label: "Project Manager - 2" },
        { key: "project-manager-3", label: "Project Manager - 3" },
    ],
    EMP_DISTRICT_LIST: [
        { key: "delhi", label: "Delhi" },
        { key: "punjab", label: "Pubjab" },
    ],
    CLUSTER_CODES: [
        { key: "cluster_01", label: "Cluster-01" },
        { key: "cluster_02", label: "Cluster-02" },
        { key: "cluster_03", label: "Cluster-03" },
    ],
    VIILAGE_IDS: [
        { key: "VID1_120723", label: "VID1_120723" },
        { key: "VID2_120723", label: "VID2_120723" },
        { key: "VID3_120723", label: "VID3_120723" },
    ],
    VIILAGE_NAMES: [
        { key: "village_A", label: "Village1" },
        { key: "village_B", label: "Village2" },
        { key: "village_C", label: "Village3" },
    ],
    MOM_STATUS: [
        { key: "open", label: "Open" },
        // { key: "work_in_progress", label: "Work In Progress" },
        { key: "completed", label: "Completed" },
    ],
    TASK_TYPE: [
        { key: "individual_task", label: "Individual Task" },
        { key: "group_task", label: "Group Task" },
    ],
    TASK_PRIORITY_LEVEL: [
        { key: "low", label: "Low" },
        { key: "medium", label: "Medium" },
        { key: "high", label: "High" },
    ],
    MOM_FPR: [
        { key: "user01", label: "Temp user" },
    ],
    SCHEMES_TYPES: [
        { key: "CENTRAL", label: "Central" },
        { key: "STATE", label: "State" }
    ],
    OPERATOR_TYPES: [
        { key: "EQUAL", label: "Equal" },
        { key: "NOT_EQUAL", label: "Not Equal" },
        { key: "INBETWEEN", label: "In-Between" }
    ],
    SCHEMES_CATEGORY: [
        { key: "LIVELIHOODS", label: "Livelihoods" },
        { key: "SKILL_DEVELOPMENT", label: "Skill Development" },
        { key: "ENTREPRENEURSHIP", label: "Entrepreneurship" },
        { key: "EDUCATION", label: "Education" },
        { key: "BASIC_ENTITLEMENTS", label: "Basic Entitlements" },
        { key: "OTHERS", label: "Others" },
    ],
}
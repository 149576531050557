import { Component } from "react";
import { Link } from "react-router-dom";

export class VendorManagementHeader extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("VendorManagementHeader");
  }

  render() {
    return (
      <div className="page-header employee-header absolute top-8 right-8">
        <div className="row align-items-center">
          <div className="col-auto">
            <button
              type="button"
              className="btn add-btn border_btn"
              onClick={() => this.props.handleDownloadList()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
          <div
            className="col-auto float-end ml-auto"
            onClick={() => this.props.setstore("onborading", false)}
          >
            <Link
              // to="/admin/employee/onboarding"
              className="btn add-btn"
              ref={this.props.vendorModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_vendor_modal"
              onClick={(...args) => this.props.addModalFields(...args)}
            >
              <i className="fa fa-plus" />
              Add Vendor
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(VendorManagementHeader);

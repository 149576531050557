export default {
    tablinks: [
        {
            path: "/lms/module/addmodule",
            label: "Module Details"
        },
        {
            path: "/lms/module/addvideos",
            label: "Videos"
        },
        {
            path: "/lms/module/addppt",
            label: "PPT"
        },
        {
            path: "/lms/module/addassessment",
            label: "Assessments"
        },
    ]
}
export default {
    tablinks: [
        {
            path: "/lms/courses/module/assessmentspage",
            label: "Assessment - 1"
        },
        {
            path: "/lms/courses/module/assessmentspage",
            label: "Assessment - 2"
        },
    ]
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../LayoutWrapper";

export class Module extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Module");
    this.state = {
      data: [],
      modulesData: [],
      sessiondetails: {},
      search: {
        // limit: 10,
        // page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
    };
    this.medialinks = [{}];
  }

  componentDidMount() {
    this.fetchList();
    this.setState({
      sessiondetails: {
        course_id: this.props.urlparams.course_id,
        course_name: this.props.urlparams.course_name,
        readonly: this.props.urlparams.readonly ? true : false,
        pathFromModule: true,
      },
    });
  }

  fetchList() {
    if (this.props.urlparams.course_id) {
      let payload = {
        course_id: this.props.urlparams.course_id,
      };
      this.props.api
        .apiGetModules(this.state.search, payload)
        .then(({ data, total }) => {
          let modifiedData = [];
          if (data.length > 0) {
            data.forEach((obj, i) => {
              this.props.api
                .apiGetAllModuleStatus({
                  course_id: obj.course_id,
                  module_id: obj.module_id,
                })
                .then((res) => {
                  modifiedData.push({
                    ...obj,
                    completion_status: Math.round(res.completion_status),
                  });
                  this.setState({
                    modulesData: modifiedData,
                  });
                });
            });
          }
        });
    }
  }

  handleView(e, value) {
    e.preventDefault();
    e.stopPropagation();
    console.log("view", value);

    this.props.navigate(
      `/lms/module/addmodule?course_id=${this.props.urlparams.course_id}&` +
        Object.QueryString({
          course_name: this.props.urlparams.course_name,
          module_id: value.module_id,
          readonly: 1,
        })
    );
  }
  handleEdit(e, value) {
    e.preventDefault();
    e.stopPropagation();
    console.log("edit", value);

    this.props.navigate(
      `/lms/module/addmodule?course_id=${this.props.urlparams.course_id}&` +
        Object.QueryString({
          course_name: this.props.urlparams.course_name,
          module_id: value.module_id,
        })
    );
  }

  handleDelete(e, value) {
    e.preventDefault();
    e.stopPropagation();
    console.log("delete", value);

    let payload = {
      module_id: value.module_id,
    };

    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteModule(payload).then(() => {
        this.fetchList();
      });
    });
  }

  handleToggle(checked, e, obj) {
    e.preventDefault();
    e.stopPropagation();
    console.log("toggle:", obj, checked);
    let payload = {
      is_active: checked,
    };
    console.log({ apiUpdateModule: payload });
    this.api
      .apiUpdateModule(payload, { module_id: obj.module_id })
      .then((res) => {
        console.log({ apiUpdateModule: res });
        this.fetchList();
      });
  }

  getModuleStatus(item) {
    let payload = {
      // course_id: item.course_id,
      module_id: item.module_id,
    };

    this.api.apiGetModuleById(payload).then((res) => {
      console.log("status res: ", res);
      this.setState({
        moduleStatusData: data,
      });
    });
  }

  getBreadCums() {
    if (this.props.urlparams.course_name) {
      return `${this.props.urlparams.course_name
        .replace(/\s+/g, " ")
        .toTitleCase()} / Modules`;
    } else {
      return "Course Name / Modules";
    }
  }

  getBackLink() {
    if (this.props.urlparams.course_id && !this.mixin.params().readonly) {
      let backLink = `/lms/course?course_id=${this.props.urlparams.course_id}`;
      return backLink;
    } else if (this.mixin.params().readonly) {
      let backLink = `/lms/course?course_id=${this.props.urlparams.course_id}&readonly=${this.props.urlparams.readonly}`;
      return backLink;
    } else {
      return "/lms/module";
    }
  }

  render() {
    let { modulesData } = this.state;
    return (
      <LayoutWrapper
        title="Course Configure"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <Link
              to={`/lms/module/addmodule?course_id=${this.props.urlparams.course_id}&course_name=${this.props.urlparams.course_name}`}
              className={Object.className({
                "btn add-btn": true,
                "d-none": this.props.urlparams.readonly,
              })}
            >
              <i className="fa fa-plus"></i>
              Add Module
            </Link>
          </div>
        </div>
        {modulesData && (
          // this.getModuleStatus(item)
          <AppAccordian
            data={modulesData?.map((item, i) => ({
              Title: item.module_name && (() => item.module_name),
              Status: item.status && (() => item.status),
              Percent: item.completion_status && (() => item.completion_status),
              Content:
                item.module_description && (() => item.module_description),
              Links: item.links && (() => item.links),
              record: item,
              sessiondetails:
                this.state.sessiondetails && this.state.sessiondetails,
            }))}
            handleView={(...args) => this.handleView(...args)}
            handleEdit={(...args) => this.handleEdit(...args)}
            handleDelete={(...args) => this.handleDelete(...args)}
            handleToggle={(...args) => this.handleToggle(...args)}
            showlinks={true}
            // crudaction={false}
            toggleaction={!this.props.urlparams.readonly}
            readonly={!this.props.urlparams.readonly}
          ></AppAccordian>
        )}
        {modulesData.length == 0 && <div className="empty_layout"></div>}
      </LayoutWrapper>
    );
  }
}

export default connect(Module);

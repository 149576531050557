import React, { Component } from "react";
import * as XLSX from "xlsx";
import AddWEModal from "./AddWEModal";
import LayoutWrapper from "../LayoutWrapper";
import VendorManagementHeader from "./VendorManagementHeader";
import VendorManagementSearch from "./VendorManagementSearch";

export class VendorManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      data: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
    };
    this.actionFormShow = false;
    this.columns = [
      {
        title: "Name",
        dataIndex: "vendor_name",
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile_num",
      },

      {
        title: "Email ID",
        dataIndex: "email_address",
      },
      {
        title: "Services",
        dataIndex: "services",
      },
      {
        title: "Location",
        dataIndex: "vendor_location",
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "vendor_name",
        label: "Name",
        required: true,
      },
      {
        type: "text",
        name: "services",
        label: "Services",
        required: true,
      },
      {
        type: "text",
        name: "mobile_num",
        label: "Mobile No.",
        pattern: "[0-9]{10}",
        onInvalid: (e) =>
          e.target.setError(
            "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
          ),
        onInput: (e) => e.target.setMessage(),
        required: true,
      },
      {
        type: "email",
        name: "email_address",
        label: "Email ID",
        required: true,
      },
      {
        type: "text",
        name: "vendor_location",
        label: "Location",
        required: true,
      },
    ];
    this.vendorModalRef = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }
  get currentLoginUser() {
    return this.props?.store.user;
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList(params = {}) {
    let payload = {
      ...params,
      ...mixins.params(),
      ...this.state.search,
    };

    console.log({ payload, search: this.state.search, params });

    this.props.api.apiGetVendors(payload).then(({ data, total }) => {
      console.log({ apiGetVendors: data });
      this.setState({
        data,
        rowcount: total,
      });
    });
  }

  componentDidMount() {
    console.log({ componentDidMount: "Vendor Management" });
    if (this.props.inactive === true) {
      this.onSearch({ status: "disabled" });
    } else {
      this.fetchList();
    }
  }

  onSearch(data) {
    console.log("onSearch", data);
    if (Object.keys(data).length === 0) {
      this.setState(
        {
          search: {
            ...data,
            page: this.state.search.page,
            limit: 10,
          },
        },
        () => this.fetchList()
      );
    } else {
      this.setState(
        {
          search: {
            ...data,
            page: this.state.search.page,
            limit: 10,
          },
        },
        () => this.fetchList(data)
      );
    }
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {}

  onPageChange(pageData) {
    let payload = this.props.inactive === true ? { status: "disabled" } : {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList(payload)
    );
  }

  deleteAction(value) {
    let payload = {
      vendor_id: value.vendor_id,
    };

    confirm(`Do you really want to delete these vendor?`).promise.then(() => {
      this.props.api.apiDeleteVendor(payload).then(() => {
        this.fetchList();
      });
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openVendorModal();
    this.setState({
      popupdata: Object.only(v, [
        "vendor_name",
        "services",
        "mobile_num",
        "email_address",
        "vendor_location",
      ]),
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }

  viewAction(v) {
    console.log("view: ", v);
    this.openVendorModal();
    this.setState({
      popupdata: Object.only(v, [
        "vendor_name",
        "services",
        "mobile_num",
        "email_address",
        "vendor_location",
      ]),
      editMode: false,
      viewMode: true,
      editData: v,
    });
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    let payload = {
      ...this.state.search,
    };

    promises = promises.map((v, i) =>
      this.props.api.apiGetVendors({
        ...payload,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      // let { data } = this.processData(totalrows);
      console.log({ results, totalrows });
      totalrows = totalrows.map((item) => {
        return {
          ...item,
          date_of_joining: new Date(item?.date_of_joining).dateToDDMMYYYY(
            new Date(item?.date_of_joining)
          ),
        };
      });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "vendor_name",
        "mobile_num",
        "email_address",
        "vendor_location",
        "services",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `vendors_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  openVendorModal() {
    this.vendorModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#add_vendor_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Vendor updated successfully"
            : "Vendor added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    if (this.state.editMode) {
      let updateVendorPayload = {
        ...data,
      };
      console.log({ updateVendorPayload });
      this.props.api
        .apiUpdateVendor(updateVendorPayload, {
          vendor_id: this.state.editData.vendor_id,
        })
        .then(resolveList);
    } else {
      let payload = {
        ...data,
      };
      console.log({ payload });
      this.props.api.apiAddVendor(payload).then(resolveList);
    }
  }

  updateFields(fields) {
    // fields.find((o) => o.name === "admin_name").options =
    // 	this.state.schoolAdmins;

    return fields;
  }

  render() {
    let { data, editData, popupdata, popupfields, editMode, viewMode } =
      this.state;
    let { columns, isAdmin, modalFields } = this;
    modalFields = this.updateFields(modalFields);

    return (
      <LayoutWrapper
        title={`${isAdmin ? "Vendor Management" : "Vendor"}`}
        back={`Dashboard / ${isAdmin ? "Vendor Management" : "Vendor"}`}
      >
        {isAdmin && (
          <VendorManagementHeader
            addModalFields={(...args) => this.addModalFields(...args)}
            handleDownloadList={() => this.getDownloadData()}
            vendorModalRef={this.vendorModalRef}
          />
        )}
        <VendorManagementSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={data}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              {...(isAdmin
                ? {
                    viewAction: (v) => this.viewAction(v),
                    editAction: (v) => this.editAction(v),
                    deleteAction: (v) => this.deleteAction(v),
                  }
                : {
                    viewable: false,
                    editable: false,
                    deletable: false,
                  })}
              pageSize={10}
            ></AppTable>
          </div>
        </div>

        <AddWEModal
          id="add_vendor_modal"
          title={`${editMode ? "Update Vendor" : "Add Vendor"}`}
          submitText={`${editMode ? "Update Vendor" : "Add Vendor"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          // disablefields={["email"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
      </LayoutWrapper>
    );
  }
}

export default connect(VendorManagement);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../LayoutWrapper";
export class Thankyouquiz extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Thankyouquiz");
  }
  proceed() {
    window.close();
  }
  componentDidMount() {
    if (mixins.params().id) {
      this.api.apiGetResponce().then((candidate) => {
        this.api
          .apiGetCandidateAssementTest({}, { id: candidate.data.assessment_id })
          .then((form) => {
            this.store("translateLang", form.language.split(","));
          });
      });
    }
  }
  render() {
    let { props } = this;
    return (
      <LayoutWrapper>
        <div className="thanks_card">
          <div className="container py-5">
            <p>
              Thanks, Your assessment has been submitted successfully. We will
              get back to you.
            </p>
            <img
              src={imagepaths.thanksImg}
              alt="thanksImg"
              className="img-fluid"
            />
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Thankyouquiz);

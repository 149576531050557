export default {

    apiGetLogs(data) {
        console.log({ apiGetEmployees: data });
        return this.request(this.api().getLogs, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },
    apiGetLogsByMonth(data) {
        // console.log({ apiGetEmployees: data });
        return this.request(this.api().getLogsByMonth, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response?.data[0]?.records?.map(item => {
                        return {
                            ...item,
                            vehicle_type: item?.vehicle?.vehicle_type,
                            vehicle_number: item?.vehicle?.vehicle_registration_no,
                        }
                    }),
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiGetAllAdminLogs(data) {
        console.log({ apiGetAllAdminLogs: data });
        return this.request(this.api().getAllAdminLogs, data)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiAddLogs(data) {
        return this.request(this.api().addLogs, data)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiAddLogs: response });
                return response.data
            })
            .get();
    },

    apiGetLogsById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        // console.log({ apiGetLogsById: data, urldata });
        return this.request(this.api().getLogBook,)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((res) => {
                return {
                    date: res?.data?.date,
                    totalKilometer: res?.data?.totalKilometer,
                    totalAmount: res?.data?.totalAmount,
                    from_location: res?.data?.from_location,
                    to_location: res?.data?.to_location,
                    start_meter: res?.data?.start_meter,
                    end_meter: res?.data?.end_meter
                };
            })
            .get();
    },

    apiDeleteLogbook(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().deleteLogBook, data)
            .urltransform(urldata)
            .exec().log().get();
    },

}
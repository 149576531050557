import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";

import mixins from "../mixins";

export const tabs = mixins.menulinks;

export class LeaveApprovals extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      {
        name: "name",
        label: "Employee Name",
        type: "text",
      },
      {
        name: "leave_type",
        label: "Leave Type",
        type: "select",
        options: ["Casual Leave", "Sick Leave"].options(),
        className: "select-focus",
      },
      {
        name: "from_date",
        label: "From",
        type: "date",
        className: "select-focus",
      },
      {
        name: "to_date",
        label: "To",
        type: "date",
        className: "select-focus",
      },
    ];
    this.links = tabs;

    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <a href="#">
              {text}
              <span>{record.employee_id}</span>
            </a>
          </h2>
        ),
      },
      {
        title: "Leave Type",
        dataIndex: "leave_type",
        render: (text) => text.toTitleCase(),
      },
      {
        title: "From",
        dataIndex: "from",
        render: (text) => (text || "").date().moment().format("DD MMM YYYY"),
      },
      {
        title: "To",
        dataIndex: "to",
        render: (text) => (text || "").date().moment().format("DD MMM YYYY"),
      },
      {
        title: "No Of Days",
        dataIndex: "no_of_days",
        render: (text) => text + " days",
      },
      {
        title: "Attachments",
        dataIndex: "file_key",
        render: (text, row) => (
          <a
            className={Object.className({
              "text-center font-semibold text-blue-400 cursor-pointer": true,
              hidden: !text,
            })}
            href={this.props.api.apiDownloadFile(row)}
            target="_blank"
          >
            <span>
              <em className="la la-download"></em>
              Download
            </span>
          </a>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let status = ["APPROVED", "PENDING", "REJECTED"];
          let icons = {
            APPROVED: "text-success",
            PENDING: "text-danger",
            REJECTED: "text-info",
          };
          let isApproved = text == "APPROVED";
          return (
            <div className="dropdown action-label text-center">
              <a
                className={Object.className({
                  "btn btn-white btn-sm btn-rounded": true,
                  "dropdown-toggle": !isApproved,
                })}
                href="#"
                data-bs-toggle={!isApproved && "dropdown"}
                aria-expanded="false"
              >
                <i
                  className={Object.className({
                    "fa fa-dot-circle-o": true,
                    [icons[text]]: true,
                  })}
                />
                {text.toLowerCase().toTitleCase()}
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {status.map((value) => (
                  <div
                    className="dropdown-item"
                    onClick={() => this.updateValue(value, record, "status")}
                  >
                    <i className={"fa fa-dot-circle-o " + icons[value]} />
                    {value.toLowerCase().toTitleCase()}
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    ];

    this.state = {
      search: {
        page: 1,
      },
      rowcount: 0,
      approvalList: [],
      attendanceToday: {
        total_employees: 0,
        total_present: 0,
        planned_leaves: 0,
        pending_requests: 0,
      },
    };
  }

  updateValue(value, record, name) {
    confirm(`Updating the ${name} of request...?`).promise.then(() => {
      this.props.api
        .apiUpdateLeaveRequest(
          { [name]: value },
          {
            req_id: record.id,
          }
        )
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  componentDidMount() {
    window.LeaveRequests = this;

    this.props.api.apiAttendanceToday().then((data) => {
      this.setState({
        attendanceToday: data,
      });
    });
    this.fetchData();
  }

  fetchData() {
    let data = {
      ...this.refs.searchform.form.current.getData(),
      ...this.state.search,
      leave_status: "APPROVED",
    };
    this.props.api.apiLeaveApprovalList(data).then((responce) => {
      this.setState({
        rowcount: responce.total_count,
        approvalList: responce.result,
      });
    });
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    let { links, columns = [] } = this;
    let { approvalList = [], attendanceToday } = this.state;
    return (
      <LayoutWrapper title="LeaveApprovals" back="Leave Management">
        <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs>
        <div className="flex flex-wrap gap-4">
          <div className="card text-center p-10 flex-grow">
            <div>
              <b>Today Presents</b>
            </div>
            <div className="text-3xl">
              <b>
                {attendanceToday.total_present}/
                {attendanceToday.total_employees}
              </b>
            </div>
          </div>
          <div className="card text-center p-10 flex-grow">
            <div>
              <b>Planned Leaves</b>
            </div>
            <div className="text-3xl">
              <b>
                {attendanceToday.planned_leaves}{" "}
                <span className="text-gray-500 text-sm">Today</span>
              </b>
            </div>
          </div>
          <div className="card text-center p-10 flex-grow">
            <div>
              <b>Pending Requests</b>
            </div>
            <div className="text-3xl">
              <b>{attendanceToday.pending_requests}</b>
            </div>
          </div>
        </div>
        <AppForm className="flex filter-row flex-wrap gap-2" ref="searchform">
          {this.fields.map((field, i) => (
            <div className="min-w-[200px] flex-grow" key={i}>
              <AppInputFocus {...field}></AppInputFocus>
            </div>
          ))}
          <div className="flex-grow">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.fetchData()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={approvalList}
              columns={columns}
              reorder={true}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              deletable={false}
              editable={false}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(LeaveApprovals);

import React, { Component } from "react";
export class EmployeeSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "employee_id",
        label: "Employee ID",
        type: "text",
      },
      {
        name: "employee_name",
        label: "Employee Name",
        type: "text",
      },
      {
        name: "employee_role",
        label: "Role",
        type: "select",
        options: _enum.EMP_ROLES,
        className: "select-focus",
      },
    ];
  }

  onSearch(reset = false) {
    let data = this.refs.form.form.current.getData();
    console.log("onSearch formData", data,reset);
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
      // console.log("data on search", data);
    }
    this.props.onSearch && this.props.onSearch(data, reset);
  }

  componentDidMount() {
    console.log({ componentDidMount: "EmployeeSearch" });
    window.EmployeeSearch = this;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    return (
      <>
        <AppForm className="row filter-row flex-auto" ref="form">
          {this.props.hideStatus
            ? this.fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-3" key={i}>
                    <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                  </div>
                ))
            : this.fields.map((field, i) => (
                <div className="col-sm-6 col-md-3" key={i}>
                  <AppInputFocus ref="inputname" {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i class="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(EmployeeSearch);

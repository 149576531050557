export default {
  menulinks: [
    {
      path: "/projectmanagement/dashboard",
      icon: "la la-dashboard",
      label: "Dashboard",
    },
    {
      path: "/projectmanagement/project",
      icon: "la la-file-text",
      label: "Project",
    },
    {
      path: "/projectmanagement/expensemanagement",
      icon: "la la-file-text",
      label: "Expense Management",
    },
  ],
};

import { Component } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import LayoutWrapper from "../LayoutWrapper";

class MOM extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("MOM");
    this.state = {
      rowcount: 0,
      data: [],
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.columns = [
      {
        title: "SL No.",
        dataIndex: "id",
        render: (id, record, index) => {
          ++index;
          return index;
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (text) => {
          return <>{text.replace(/\//g, "-")}</>;
        },
      },
      {
        title: "Meeting Agenda",
        dataIndex: "meeting_agenda",
        render: (text, record) => {
          return (
            <span
              className="text_truncate cursor-pointer"
              title={record?.meeting_agenda}
              onClick={() => alert(record?.meeting_agenda)}
            >
              {record?.meeting_agenda}
            </span>
          );
        },
      },
      {
        title: "No. of Points Discussed",
        dataIndex: "numberOfPointsDiscussed",
      },
      {
        title: "No. of Actionable",
        dataIndex: "numberOfActionables",
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  fetchList() {
    this.props.api
      .apiGetMeetingMinutes(this.state.search)
      .then(({ data, total }) => {
        this.setState({
          data,
          rowcount: total,
        });
      });
  }

  componentDidMount() {
    this.fetchList();
  }

  onPageChange(pageData) {
    let payload = this.props.inactive === true ? { status: "disabled" } : {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList(payload)
    );
  }

  editMomData(record) {
    console.log({ editMomData: record });
    let url =
      "/admin/mom/addNewMom" +
      Object.encodeuri({
        id: record.mom_id,
      });
    this.props.navigate(url);
  }

  deleteMomData(record) {
    let payload = {
      mom_id: record.mom_id,
    };
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteMeetingMinutes(payload).then(() => {
        this.fetchList();
      });
    });
  }

  viewMomData(record) {
    console.log({ viewMomData: record });
    let url =
      "/admin/mom/addNewMom" +
      Object.encodeuri({
        id: record.mom_id,
        readonly: true,
      });
    this.props.navigate(url);
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.props.api.apiGetMeetingMinutes({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      // let { data } = this.processData(totalrows);
      totalrows = totalrows.map((item) => {
        return {
          ...item,
          date: item?.date.replace(/\//g, "-"),
        };
      });
      console.log({ totalrows });
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        "date",
        "meeting_agenda",
        "numberOfPointsDiscussed",
        "numberOfActionables",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `mom_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };
  render() {
    let { data } = this.state;
    let { columns, isAdmin } = this;
    return (
      <LayoutWrapper title="MOMs" back="MOMs">
        {isAdmin && (
          <div className="page-header employee-header absolute top-8 right-8">
            <div className="row align-items-center">
              <div className="col-auto float-end ml-auto">
                <button
                  type="button"
                  className="btn add-btn border_btn"
                  onClick={() => this.getDownloadData()}
                >
                  <i className="fa fa-download me-2"></i>
                  Download
                </button>
              </div>
              <div className="col-auto float-end ml-auto">
                <Link to="/admin/mom/addNewMom" className="btn add-btn">
                  <i className="fa fa-plus" />
                  Create MOM
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={data}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              editAction={(data) => this.editMomData(data)}
              deleteAction={(data) => this.deleteMomData(data)}
              viewAction={(data) => this.viewMomData(data)}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(MOM);

import React, { Component } from 'react'

export class EditProfile extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.EditProfile = this;
    }

    render() {
        let { props } = this;
        let { id,title,onClose } = props;
        let attrs = Object.except(props,['id','title','type','onClose']);

        return (
            <AppModalBs id={id} title={title} contentClass={"w-3/6 m-auto"} onClose={onClose} >
                <AppFormData {...attrs} >

                </AppFormData>
            </AppModalBs>
        )
    }
}

export default EditProfile
import { Component } from "react";
import { Link } from "react-router-dom";

export class WomenEntrepreneurHeader extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("WomenEntrepreneurHeader");
  }

  render() {
    let { selectedToggleList } = this.props;
    return (
      <div className="page-header employee-header absolute top-8 right-8">
        <div className="row align-items-center">
          <div className="col-auto">
            <button
              type="button"
              className="btn add-btn border_btn"
              onClick={() => this.props.handleDownloadList()}
            >
              <i className="fa fa-download me-2"></i>
              Download
            </button>
          </div>
          <div
            className="col-auto float-end ml-auto"
            onClick={() => this.props.setstore("onborading", false)}
          >
            <Link to="/womenentrepreneur/onboarding" className="btn add-btn">
              <i className="fa fa-plus" />
              Add Women Entrepreneur
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(WomenEntrepreneurHeader);

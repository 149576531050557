import React, { Component } from "react";
import { ProfileCard, ColFields, ActionTag } from "./employee-profile";
// import { BasicDetailsFields } from "../../employee/Addemployee/BasicDetails";
import Avatar from "antd/lib/avatar/avatar";
import { UserOutlined } from "@ant-design/icons";
import AccountDetails from "./AccountDetails";
import DocumentDetails from "./DocumentDetails";
import { Link } from "react-router-dom";

export class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      showRemarks: false,
      showProfileAction: false,
      deltaId: null,
      employeeStatus: "",
      deltaStatus: "",
      accountDetails: {},
    };

    this.labelMaps = {
      email_address: "Email ID",
      designation: "Designation",
      employee_id: "Employee ID",
      date_of_birth: "Date of Birth",
      date_of_joining: "Date of Joining",
      mobile_num: "Mobile No",
      salaryType: "Salary Type",
      workLocation: "Work Location",
      pan_card_num: "PAN No",
      aadhar_card_num: "Aadhar No",
      supervisor_id: "Supervisor ID",
      ifscCode: "IFSC Code",
      accountNum: "Account No",
      bankName: "Bank Name",
      branchName: "Branch",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let payload = {
      employee_id: this.props.urlparams.emp_id,
    };
    this.props.api.apiGetEmployeeById(payload).then(({ data }) => {
      console.log({ employeeData: data });
      if (data?.status === "PENDING") {
        this.setState({
          showProfileAction: true,
        });
      }
      this.setState((state) => ({
        ...state,
        // data,
        deltaId: data?.current_delta_id,
        deltaStatus: data?.status,
        employeeStatus: data?.status,
        accountDetails: {
          accountNum: data?.bank_account_num,
          ifscCode: data?.bank_ifsc_code,
          bankName: data?.bank_name,
          branchName: data?.bank_branch_name,
        },
      }));
      if (data) {
        let deltaPayload = {
          delta_employee_id: data?.current_delta_id,
        };
        if (data?.current_delta_id) {
          this.props.api
            .apiGetEmployeeByDelta(deltaPayload)
            .then(({ data }) => {
              console.log({ employeeDeltaData: data });
              this.setState((state) => ({
                ...state,
                data,
                employeeDeltaData: data,
                accountDetails: {
                  accountNum: data?.bank_account_num,
                  ifscCode: data?.bank_ifsc_code,
                  bankName: data?.bank_name,
                  branchName: data?.bank_branch_name,
                },
              }));
            });
        } else {
          this.setState((state) => ({
            data,
          }));
        }
      }
    });
    //
  }

  valReducer(value, label) {
    if (["date_of_joining", "date_of_birth"].includes(label)) {
      return new Date(value).moment().format("DD MMM YYYY");
    } else if (
      ["gender", "workLocation", "designation", "salaryType"].includes(label)
    ) {
      return value?.toString()?.toTitleCase();
    }
    return value;
  }

  changeProfile(e) {
    let data = {
      file: e.target.files[0],
    };
    this.props.api.apiUploadProfilePic(data).then(() => this.fetchData());
  }

  toggleActive(target) {
    let profileBtns = document.querySelectorAll(".profile_btn");
    profileBtns?.forEach((profile) => profile.classList.remove("active"));
    target.classList.add("active");
  }

  onApprove(e) {
    e.preventDefault();
    e.stopPropagation();
    let target = e.target.parentElement;
    this.toggleActive(target);
    this.setState({
      deltaStatus: "APPROVED",
      showRemarks: true,
    });
  }

  onReject(e) {
    e.preventDefault();
    e.stopPropagation();
    let target = e.target.parentElement;
    this.toggleActive(target);
    this.setState({
      deltaStatus: "REJECTED",
      showRemarks: true,
    });
  }

  updateRemark(e, data) {
    let payload = {
      ...data,
      status: this.state.deltaStatus,
    };
    console.log({ payload });

    confirm(
      `Are you want to ${_enum.EMPLOYEE_STATUS_TEXT_MAP[
        this.state.deltaStatus
      ].toLowerCase()} this employee.?`
    ).promise.then(() => {
      this.props.api
        .apiUpdateEmployeeRemark(payload, {
          delta_employee_id: this.state.deltaId,
        })
        .then((res) => {
          console.log(res);
          success(
            `Profile ${this.state.deltaStatus?.toTitleCase()} Successfully`
          );
          let url = "/admin/employee?";
          navigate(url);
          this.fetchData();
        });
    });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileHRMSDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  render() {
    let { valReducer, props, labelMaps } = this;
    let { onEdit } = props;
    let { data, accountDetails } = this.state;

    let dataforcol = {};

    dataforcol.employee_id = data?.employee_id || "-";
    dataforcol.mobile_num = data?.mobile_num || "-1";
    dataforcol.pan_card_num = data?.pan_card_num || "-";
    //
    dataforcol.gender = data?.gender || "-";
    dataforcol.date_of_joining = data?.date_of_joining || "-";
    dataforcol.aadhar_card_num = data?.aadhar_card_num || "-";
    //
    dataforcol.date_of_birth = data?.date_of_birth || "-";
    dataforcol.email_address = data?.email_address || "-";
    dataforcol.supervisor_name = data?.supervisor_id || "-";
    //
    dataforcol.age = data?.date_of_birth?.datetoAge() || "-";
    dataforcol.address = data?.address || "-";
    dataforcol.supervisor_id = data?.supervisor_id || "-";

    let coldata = Object.only(dataforcol, [
      "employee_id",
      "mobile_num",
      "gender",

      "date_of_joining",
      "date_of_birth",
      "email_address",

      "address",
      "pan_card_num",
      "aadhar_card_num",

      "age",
      "supervisor_id",
      "supervisor_name",
    ]);

    let config = {
      updateapi: "apiUpdateEmployeeProfileBasic",
      component: this,
    };
    return (
      <>
        <ProfileCard
          ActionTag={ActionTag}
          onEdit={() => onEdit(data, [], "Personal Details", config)}
          profileaction={this.state.showProfileAction}
          onCheck={(...args) => this.onApprove(...args)}
          onDelete={(...args) => this.onReject(...args)}
          editable={this.state.employeeStatus === "PENDING" ? false : true}
        >
          <div className="d-flex">
            <div className="profile-img">
              <span className="user-img me-1 h-full p-2 rounded-md d-flex align-items-center justify-content-center">
                <input
                  type={"file"}
                  className={"hidden"}
                  ref="profilepic"
                  onChange={(v) => this.changeProfile(v)}
                ></input>
                <img
                  src={imagepaths.pencilplus}
                  className={"h-3 w-3 absolute right-2 cursor-pointer d-none"}
                  onClick={() => this.refs.profilepic.click()}
                />
                <Avatar
                  shape="square"
                  size={64}
                  icon={<UserOutlined />}
                  className=" d-flex align-items-center justify-content-center"
                />
                <img
                  className="rounded-full h-full object-cover d-none"
                  src={
                    this.props.api.apiProfileFile(data) || imagepaths.Avatar_21
                  }
                  alt=""
                />
              </span>
            </div>
            {empty(data, () => (
              <div className="w-full px-2 flex">
                <div className="my-auto">
                  <div className="text-xl font-semibold">
                    {data?.employee_name}
                  </div>
                  <div className="text-gray-500 text-base font-medium">
                    {(data?.employee_role || "").toTitleCase()}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <ColFields
            data={coldata}
            labelMaps={labelMaps}
            valueReduce={valReducer}
            size={8}
            colclass="col-12 col-md-4"
          />
          <hr />
          <Row>
            <Col className="col-12 col-md-6 col-lg-4 br-lg-2">
              <div className="mb-4 flex">
                <div>
                  <img className="h-10 w-10 m-auto" src={imagepaths.wallet} />
                </div>
                <div className="text-xl mx-2 font-semibold m-auto">
                  Account Details
                </div>
              </div>
              <ColFields data={accountDetails} labelMaps={labelMaps} />
            </Col>
            <Col className="col-12 col-md-6 col-lg-8">
              {/* <DocumentDetails data={data} /> */}
              <div className="mb-4 flex">
                <div>
                  <img
                    className="h-10 w-10 m-auto"
                    src={imagepaths.docverified}
                  />
                </div>
                <div className="text-xl mx-2 font-semibold m-auto">
                  Docs Details
                </div>
              </div>
              <div className="flex flex-wrap">
                {data?.pan_file_id && (
                  <div className="flex flex-col min-w-fit px-5 mx-2">
                    <img src={imagepaths.docCard} className="m-auto h-13"></img>
                    <div className="text-center py-2 text-base font-semibold">
                      PAN Card
                    </div>
                    <a
                      className="text-center font-semibold primary_link cursor-pointer"
                      href={this.downloadFile(data?.pan_file_id)}
                      target="_blank"
                    >
                      <span>
                        <em className="la la-download"></em>
                        Download
                      </span>
                    </a>
                  </div>
                )}
                {data?.aadhar_file_id && (
                  <div className="flex flex-col min-w-fit px-5 mx-2">
                    <img src={imagepaths.docCard} className="m-auto h-13"></img>
                    <div className="text-center py-2 text-base font-semibold">
                      Aadhar Card
                    </div>
                    <a
                      className="text-center font-semibold primary_link cursor-pointer"
                      href={this.downloadFile(data?.aadhar_file_id)}
                      target="_blank"
                    >
                      <span>
                        <em className="la la-download"></em>
                        Download
                      </span>
                    </a>
                  </div>
                )}
                {data?.bank_statement_file_id && (
                  <div className="flex flex-col min-w-fit px-5 mx-2">
                    <img src={imagepaths.docCard} className="m-auto h-13"></img>
                    <div className="text-center py-2 text-base font-semibold">
                      Bank Statement
                    </div>
                    <a
                      className="text-center font-semibold primary_link cursor-pointer"
                      href={this.downloadFile(data?.bank_statement_file_id)}
                      target="_blank"
                    >
                      <span>
                        <em className="la la-download"></em>
                        Download
                      </span>
                    </a>
                  </div>
                )}

                {!data?.pan_file_id &&
                  !data?.aadhar_file_id &&
                  !data?.bank_statement_file_id && (
                    <div className="empty_layout empty_docs"></div>
                  )}
              </div>
            </Col>
          </Row>
        </ProfileCard>
        {this.state.showRemarks && (
          <AppForm
            ref="remarksform"
            onSubmit={(...args) => this.updateRemark(...args)}
          >
            <AppInput name="remarks" label="Enter Remarks"></AppInput>
            <div className="flex mt-4">
              <div className="grow"></div>
              <button
                type="submit"
                className={Object.className({
                  "btn btn-primary add-btn": true,
                })}
              >
                Update
              </button>
              <Link
                to={"/admin/employee"}
                className="btn btn_primary grey ms-2 me-2"
              >
                Cancel
              </Link>
            </div>
          </AppForm>
        )}
      </>
    );
  }
}

export default connect(ProfileDetails);

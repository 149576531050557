import { Component } from "react";
import * as XLSX from "xlsx";
import ClusterHeader from "./ClusterHeader";
import ClusterSearch from "./ClusterSearch";
class ClusterList extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ClusterList");
    this.state = {
      rowcount: 0,
      masterData: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        district: "",
      },
      deleteAction: "temp",
      last_working_day: new Date(),
      disabled_remarks: "",
    };
    this.actionFormShow = false;
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Cluster Code",
        dataIndex: "cluster",
      },

      {
        title: "No of Village",
        dataIndex: "villages",
      },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get isManager() {
    return this.props.store.user?.employee_role === "manager";
  }

  fetchList(params = {}) {
    let { search, masterData } = this.state;
    if (search !== undefined && search.district) {
      let payload = {
        ...Object.except(this.state.search, ["district", "search"]),
      };

      this.api
        .getCluster(payload, { district: this.state.search.district })
        .then(({ data, total }) => {
          console.log({ getCluster: data });
          if (this.isAdmin || this.isManager) {
            this.setState({
              masterData: data,
              rowcount: total,
            });
          } else {
            let dt = this.props.store.user.district;
            let fdata = data?.filter((r) => r.district === dt);
            this.setState({
              masterData: fdata,
              rowcount: total,
            });
          }
        });
    } else {
      console.log("Reset");
      this.setState({ masterData: [], rowcount: 0 });
    }
  }

  componentDidMount() {
    console.log({ componentDidMount: "ClusterList" });
    this.fetchList();
  }

  onSearch(data) {
    console.log({ data });

    if (data !== undefined) {
      let searchPayload = {
        ...this.state.search,
      };
      searchPayload.district = data.district;
      searchPayload.search = data.district;
      console.log({ searchPayload });
      this.setState({ search: { ...searchPayload } }, () => {
        this.fetchList(data);
      });
    } else {
      let resetSearchPayload = {
        ...this.state.search,
        district: "",
      };
      this.setState(
        { search: { ...resetSearchPayload }, masterData: [] },
        () => {
          this.fetchList();
        }
      );
    }
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {
    if (field.name == "lastdate") {
      // console.log(value.toISOString().substring(0, 10));
      this.setState({
        ...this.state,
        last_working_day: value,
      });
    }
    if (field.name == "remarks") {
      this.setState({
        ...this.state,
        disabled_remarks: document.querySelector(`input[name=${field.name}]`)
          .value,
      });
    }
  }

  onPageChange(pageData) {
    let payload = this.props.inactive === true ? { status: "disabled" } : {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList(payload)
    );
  }

  editClusterData(record) {
    console.log({ editClusterData: record });
    let { district } = this.state.search;
    let url =
      "/admin/masterdata/cluster/addclusterdata" +
      Object.encodeuri({
        district: district,
        cluster_code: record.cluster,
      });
    this.props.navigate(url);
  }

  deleteClusterData(value) {
    console.log({ deleteClusterData: value });
    let payload = {
      district: value.district,
      cluster_name: value.cluster,
    };

    confirm(`Are you want to delete this Cluster. ?
    If you delete this cluster then all the villages inside this Cluster will be deleted !!`).promise.then(
      () => {
        this.api.deleteCluster(payload).then(() => {
          this.fetchList();
        });
      }
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    console.log(rowcount, search);
    this.props.api
      .getCluster(
        {
          ...Object.except(this.state.search, ["district", "search"]),
          page: 1,
        },
        { district: this.state.search.district }
      )
      .then((results) => {
        let totalrows = results.data;
        totalrows = totalrows.map((v) => {
          return {
            // district: this.state.search.district,
            cluster_code: v.cluster,
            no_of_villages: v.villages,
          };
        });
        console.log({ results, totalrows });
        this.handleDownload(totalrows);
      });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
        // "district",
        "cluster_code",
        "no_of_villages",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `masterdata_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };
  render() {
    let { masterData } = this.state;
    let { columns, isAdmin } = this;
    return (
      <>
        {isAdmin && (
          <ClusterHeader handleDownloadList={() => this.getDownloadData()} />
        )}
        <ClusterSearch
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={masterData}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              viewable={false}
              {...(isAdmin
                ? {
                    editAction: (v) => this.editClusterData(v),
                    deleteAction: (v) => this.deleteClusterData(v),
                  }
                : {
                    editable: false,
                    deletable: false,
                  })}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </>
    );
  }
}

export default connect(ClusterList);

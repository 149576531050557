import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import "./designations.scss";

export class Designations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      designationsData: [],
      search: {
        page: 1,
      },
    };
    let renderField =
      (name, edit = false) =>
      (text, record) =>
        (
          <div>
            <span>{record[name]}</span>
            {edit && (
              <img
                src={imagepaths.pencilplus}
                className={"h-3 w-3 float-right cursor-pointer"}
                onClick={() => this.getUpdateValue(record, name)}
                title={"Edit"}
              />
            )}
          </div>
        );
    this.columns = [
      ...["_id", "created_at"].map((dataIndex) => ({
        dataIndex,
        display: false,
      })),
      {
        dataIndex: "designation",
        title: "Designation",
        render: renderField("designation"),
      },
    ];

    this.addfields = [
      {
        name: "designation",
        label: "Designation",
      },
    ];
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  addLocation() {
    let payload = this.refs.addform.form.current.getData();
    let data = {
      designations: [payload.designation],
    };
    return this.props.api
      .apiCreateDesignation(data)
      .then(() => this.fetchData());
  }

  componentDidMount() {
    window.Designations = this;
    this.fetchData();
  }

  fetchData() {
    let loaderObj = loader("Setting Designations please wait...");
    setTimeout(() => {
      let responce = this.props.store.designationList;
      this.setState({
        designationsData: responce,
        rowcount: responce.length,
      });
      loaderObj.close();
    }, 0);
  }

  getUpdateValue(record, name) {
    prompt(`Please enter the ${name} value that need to update`, {
      defaultValue: record[name],
    }).promise.then((value) => {
      this.updateValue(value, record, name);
    });
  }

  updateValue(value, record, name) {
    this.updateValues(
      { [name]: value },
      record,
      `Updating the ${name} of work locations...?`
    );
  }

  updateValues(values, record, message = `Updating the of work location...?`) {
    confirm(message).promise.then(() => {
      this.props.api
        .apiUpdateWorkLocation(values, {
          loc_id: record._id,
        })
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  deleteDesignation(payload) {
    confirm(
      `Confirm the delete of ${payload.designation} from list...?`
    ).promise.then(() => {
      this.props.api
        .apiDeleteDesignation(
          {},
          {
            designa_id: payload._id,
          }
        )
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  editDesignation(payload) {
    prompt(
      `Edit`,
      {},
      <div id="prompt" className="row text-left">
        {this.addfields.map((field, i) => (
          <div className="col-sm-12" key={i}>
            <AppInput {...field} defaultValue={payload[field.name]}></AppInput>
          </div>
        ))}
      </div>
    ).promise.then(() => {
      let data = document.querySelector("#appmodal").getData();
      this.props.api
        .apiEditDesignation(data, {
          designa_id: payload._id,
        })
        .then((responce) => {
          this.fetchData();
        });
    });
  }

  render() {
    let { designationsData } = this.state;
    let { columns, props } = this;
    return (
      <LayoutWrapper title="Designations" back="Dashboard">
        <AppForm
          className="row mb-2"
          ref="addform"
          onSubmit={() => this.addLocation()}
        >
          {this.addfields.map((field, i) => (
            <div className="col-sm-6 col-md-3" key={i}>
              <AppInput {...field}></AppInput>
            </div>
          ))}
          {/* <div className='col-sm-6 col-md-6'></div> */}
          <div className="col-sm-6 col-md-3">
            <div className="form-group">
              <label className="col-form-label block">&nbsp;</label>
              <div className="flex">
                <button className="btn add-btn h-10 mr-auto"> Add </button>
              </div>
            </div>
          </div>
        </AppForm>
        <hr />
        <div className="row">
          <div className="col-12">
            <AppTable
              data={designationsData}
              columns={columns}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              reorder={true}
              deleteAction={(v) => this.deleteDesignation(v)}
              editAction={(v) => this.editDesignation(v)}
              targetType="tap"
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(Designations);

/**
 * Crm Routes
 */
/* eslint-disable */
import React from "react";
import { LayoutWrapper } from "../LayoutWrapper";
import Employeeslist from "./Employeelist";

const EmployeeRoute = ({ location }) => (
  <LayoutWrapper title="Employee" back="Employee">
    <Employeeslist />
  </LayoutWrapper>
);

export default connect(EmployeeRoute);

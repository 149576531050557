import React, { Component, lazy, Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import mixins from "./mixins";
import Layout from "./layout";
import AdminLayout from "./layout/admin";
import "./App.scss";
import routemap from "./routes/routemap";
import { data } from "jquery";
const Navigate = (props) => {
	let navigate = useNavigate();
	window.navigate = (...args) => {
		navigate(...args);
	};
	return props.children;
};

class App extends Component {
	constructor(props) {
		super(props);
		props.initialconstruct.bind(this)("app");
		this.store([
			{ name: 'requests', value: [] },
		]);
		this.state = {
			CustomLayout: AdminLayout
		};
	}
	get user() {
		return this.props.store?.user || {};
	}
	get role() {
		return this.user.employee_role;
	}
	get loggedpath() {
		switch (this.role) {
			case "woman-entrepreneur":
				return "/womenentrepreneur/vendormanagement";
			default:
				return "/welcome";
		}
	}
	get currentpath() { return window.location.pathname; }
	get token() { return this.props.store.auth; }
	get page() { return routemap.find(o => o.path === window.location.pathname || window.location.pathname.includes(o.path)) || {}; }
	get redirectpath() { return this.token ? this.loggedpath : "/open/login"; }
	get canNavigate() { return this.redirectpath !== this.currentpath; }
	get configStyleVars() {
		let { config = {} } = this.props.store;
		let { colors = {} } = config;
		let configkeys = Object.except(config, ['colors']);
		let logopath = configkeys.logo == 'self' ? imagepaths.Applogo : configkeys.logo;
		configkeys.logo = `url(${logopath})`;
		this.stylevars = colors;
		this.stylevars = configkeys;
		return;
	}
	set stylevars(data) {
		Object.entries(data).map(([key, val]) => {
			document.body.style.setProperty("--" + key.snakeCase(), val);
		});
	}

	checkLogin() {
		let navigate = window.navigate || (() => (''));
		let isOpenPage = this.currentpath.startsWith('/open');
		let gaurded = this.page.gaurded;
		let isGarded = ([undefined].includes(gaurded) ? !isOpenPage : gaurded);
		let isAdminWithoutToken = !(!isOpenPage && this.token);
		let pageGardedWithoutToken = !(this.page && this.page.gaurded && this.token);
		let isUnAuthorized = isGarded ? (isAdminWithoutToken && pageGardedWithoutToken) : false;
		let isLoggedIn = this.token && this.currentpath == "/open/login";
		let canNavigate = (isUnAuthorized || isLoggedIn) && this.canNavigate;
		//console.log("canNavigate",{canNavigate,isUnAuthorized, isGarded, pageGardedWithoutToken,isAdminWithoutToken});
		if (canNavigate) {
			navigate(this.redirectpath);
		}
	}

	getLayoutComponent(path) {
		let layoutName = this.currentpath.basename();
		// return AdminLayout
		if (layoutName == "open") {
			return Layout;
		} else {
			try {
				// console.log("require(`./layout/${layoutName}`)",layoutName,require(`./layout/${layoutName}`));
				return require(`./layout/${layoutName}`).default;
			} catch (err) {
				// console.log({err});
				return this.redirectpath == "/open/login" ? Layout : AdminLayout;
			}
		}
	}

	setConfigObject() {
		this.props.api.apiGetOrganisationConfig().then(data => {
			this.store("config", data);
		});
		let basename = window.app.currentpath.basename()
		if (app.props.store.user && basename == "admin") {
			// this.props.api.apiEmployeeDesignations();
			// this.props.api.apiBankList();
			// this.props.api.apiEmployeeDepartment();
			// this.props.api.apiEmployeeWorkLocation();
			// this.props.api.apiEmployeeHolidayList();
		}
	}

	componentDidMount() {
		this.setConfigObject();
		let CustomLayout = this.getLayoutComponent(window.location.pathname);
		this.setState({ CustomLayout });
	}

	componentDidUpdate() {
		this.checkLogin();
		let pathname = window.location.pathname;
		if (pathname != window.activeRoute) {
			window.activeRoute = window.location.pathname;
			let CustomLayout = this.getLayoutComponent(window.location.pathname);
			this.setState({ CustomLayout });
			console.log({ pathname: window.location.pathname });
		}
	}



	render() {
		let { CustomLayout } = this.state;
		//console.log({CustomLayout});
		return (
			<Navigate>
				{this.configStyleVars}
				{
					CustomLayout && (
						<Suspense>
							<CustomLayout>
								{this.props.children}
							</CustomLayout>
						</Suspense>
					)
				}
			</Navigate>
		);
	}
}

export default connect(App);
import React, { Component } from "react";

export class TaskManagementSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("TaskManagementSearch");
    this.state = {
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "employee_id",
        label: "Employee ID",
        type: "text",
      },
      {
        name: "status",
        label: "Status",
        type: "select",
        options: _enum.TASK_MANAGEMENT_STATUS,
        className: "select-focus",
      },
      {
        name: "employee_role",
        label: "Role",
        type: "select",
        options: _enum.EMP_ROLES,
        className: "select-focus",
      },
    ];
    this.form = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  onSearch(reset = false) {
    console.log({ reset });
    let data = this.refs.form.form.current.getData();
    if (reset) {
      data = {
        ...this.state.search,
      };
    } else {
      data = Object.filter(data, (val) => val);
    }
    console.log("onSearch formData", data);
    this.props.onSearch && this.props.onSearch(reset, data);
  }

  componentDidMount() {}

  updateFields(fields) {
    if (!this.isAdmin) {
      fields = fields.filter(
        (field) =>
          field.name !== "employee_id" && field.name !== "employee_role"
      );
    }
    return fields;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    let { fields, isAdmin } = this;
    fields = this.updateFields(fields);
    return (
      <>
        <AppForm className="row filter-row" ref="form">
          {this.props.hideStatus
            ? fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-3 col-lg-2" key={i}>
                    <AppInputFocus {...field}></AppInputFocus>
                  </div>
                ))
            : fields.map((field, i) => (
                <div className="col-sm-6 col-md-3 col-lg-2" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-3 col-lg-2">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i className="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(TaskManagementSearch);

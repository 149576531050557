import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import { Link } from "react-router-dom";
import mixins from "../mixins";
import "./addtask.scss";

export class Task extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Task");
    this.state = {
      columns: [
        {
          dataIndex: "task_id",
          display: false,
        },
        {
          dataIndex: "task_name",
          title: "Task Name",
        },
        {
          dataIndex: "task_desc",
          title: "Task Description",
          render: (text, record) => {
            return (
              <span
                className="text_truncate cursor-pointer"
                title={record?.task_desc}
                onClick={() => alert(record?.task_desc)}
              >
                {record?.task_desc}
              </span>
            );
          },
        },
        {
          dataIndex: "task_priority",
          title: "Task Priority",
        },
        {
          dataIndex: "attachments",
          title: "Attachments",
          render: (text) => {
            if (text[0]) {
              let { attachmentId, attachmentName } = text[0];
              return (
                <>
                  <a
                    className="link_btn"
                    href={this.downloadFile(attachmentId)}
                    target="_blank"
                  >
                    Download
                  </a>
                </>
              );
            } else {
              return <>-</>;
            }
          },
        },
        {
          dataIndex: "task_status",
          title: "Task Status",
          render: (text) => this.getStatus(text),
        },
      ],
      data: [],
      editMode: false,
      viewMode: false,
      addMode: false,
      editData: {},
      rowcount: 0,
      project: {},
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        filter: {
          project_id: props?.urlparams?.project_id,
          project_fy_id: this.props?.urlparams?.project_fy_id,
        },
      },
    };
    this.formFields = [
      {
        type: "select",
        name: "project_id",
        label: "Project Name",
        defaultValue: props?.urlparams?.project_id,
        disabled: true,
        fieldclass: Object.className({
          hidden: !!props.urlparams.project_id || true,
        }),
      },
      {
        type: "text",
        name: "task_name",
        label: "Task Name",
        maxLength: "50",
        required: true,
      },
      // {
      //   type: "date",
      //   name: "task_start_date",
      //   label: "Task Start Date",
      //   required: true,
      // },
      // {
      //   type: "date",
      //   name: "task_end_date",
      //   label: "Task End Date",
      //   required: true,
      // },
      {
        type: "file",
        name: "attachments",
        label: "Attachments (optional)",
        fileType: "task_file",
      },
      {
        type: "select",
        name: "task_priority",
        label: "Task Priority",
        options: _enum.PM_PRIORITY_STATUS,
        required: true,
      },
      {
        type: "select",
        name: "task_status",
        label: "Task Status",
        options: _enum.PM_PROJECT_STATUS,
        required: true,
      },
      {
        type: "textarea",
        label: "Task Description (Max 250 words)",
        name: "task_desc",
        maxLength: "250",
        invalidmessage: "Value Should Not Exceed 250 Characters",
        controlClass: "!min-h-[150px]",
        fieldclass: "col-12",
      },
    ];
    this.tablinks = mixins.tablinks;
  }

  get listMode() {
    return !(this.state.editMode || this.state.addMode);
  }

  addRecord() {
    this.setState({
      addMode: true,
    });
  }

  getStatus(status) {
    let enums = _enum.PM_STATUS;
    let targetItem = enums.filter((v) => v.key === status);

    return (
      <span style={{ color: `${targetItem[0].colorCode}` }}>
        {targetItem[0].label}
      </span>
    );
  }
  sumitForm(event, data, form) {
    let { editData } = this.state;
    const resolveList = () => {
      this.fetchData().then(() => {
        this.setState({
          editMode: false,
          addMode: false,
          editData: {},
        });
      });
      form.reset();
    };
    if (this.state.editMode) {
      this.api
        .apiEditTask(data, { task_id: editData.task_id })
        .then(resolveList);
    } else {
      this.setState({
        editData: data,
      });
      this.api.apiAddTask(data).then(resolveList);
    }
  }

  componentDidMount() {
    this.fetchData();
    this.getProjectDetails();
  }

  getProjectDetails() {
    let payload = {
      project_id: this.props?.urlparams?.project_id,
    };
    console.log("getProjectDetails", payload);

    this.api.apiGetProject(payload).then((data) => {
      this.setState({ project: data });
    });
  }

  fetchData() {
    let payload = {
      project_id: this.props?.urlparams?.project_id,
      project_fy_id: this.props?.urlparams?.project_fy_id,
    };
    return new Promise((resolve, reject) => {
      this.api
        .apiGetTasks({ ...Object.except(this.state.search, "filter") }, payload)
        .then(({ data, total }) => {
          console.log(data);
          this.setState({
            data: [...data],
            rowcount: total,
          });
          resolve();
        });
    });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }
  editTaskAction(v) {
    this.setState({
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }
  viewTaskAction(v) {
    this.setState({
      editMode: true,
      viewMode: true,
      editData: v,
    });
  }

  deleteTaskAction(value) {
    let payload = {
      task_id: value.task_id,
    };
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteTask(payload).then(() => {
        this.fetchData();
      });
    });
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchData()
    );
  }

  getBreadCums() {
    if (this.props.urlparams.project_id) {
      let fyId = this.props?.urlparams?.project_fy_id;
      console.log(fyId);
      return `${
        this.state.project.project_name
      } / Financial Year - ${new Date().getFinacialYear()}`;
    } else {
      return "Project / Financial Year ";
    }
  }

  getBackLink() {
    if (this.props.urlparams.project_id) {
      let backLink =
        "/projectmanagement/addfinancialyear" +
        Object.encodeuri({
          project_id: this.props.urlparams.project_id,
          ...(this.props.urlparams.readonly
            ? { readonly: this.props.urlparams.readonly }
            : {}),
        });
      return backLink;
    }
  }

  render() {
    let { tablinks, formFields, props } = this;
    let { columns, data = [], rowcount, editData } = this.state;
    formFields = formFields.map((field) => {
      field.defaultValue = field.defaultValue;
      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
          console.log("field: ", field.name);
        }
      } else {
        field.defaultValue = "";
      }
      return field;
    });

    return (
      <LayoutWrapper
        title="Task"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppCircleStepper data={tablinks}></AppCircleStepper>
        <div className="row m-auto">
          <div className="col"></div>
          <div className="mb-4"></div>
        </div>
        {(props?.urlparams?.project_id && (
          <>
            {!this.listMode && (
              <div className="row m-auto">
                <AppForm
                  className="flex flex-col gap-4 bg-white p-4"
                  onSubmit={(...args) => this.sumitForm(...args)}
                >
                  <div className="row">
                    {formFields?.map((field, i) => (
                      <div
                        className={
                          field.fieldclass
                            ? field.fieldclass
                            : "col-12 col-md-6 col-lg-4"
                        }
                        key={"field" + i}
                      >
                        <AppInput
                          className="grow"
                          {...field}
                          disabled={this.state.viewMode}
                        ></AppInput>
                      </div>
                    ))}
                  </div>
                  <div className="row no-gutters">
                    <button
                      className={Object.className({
                        "btn add-btn col col-md-1 ml-auto": true,
                        hidden: this.state.viewMode,
                      })}
                    >
                      {this.state.editMode ? "Update" : "Add"}
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ editMode: false, addMode: false })
                      }
                      className={Object.className({
                        "btn btn_primary col col-md-1 grey me-2 ms-2":
                          !this.state.viewMode,
                        "btn btn_primary col col-md-1 grey me-2 ml-auto":
                          this.state.viewMode,
                      })}
                    >
                      Cancel
                    </button>
                  </div>
                </AppForm>
              </div>
            )}
            {this.listMode && (
              <div className="row">
                <div className="col-12 mb-4">
                  <button
                    className={Object.className({
                      "btn add-btn col col-md-1 ml-auto": true,
                      hidden: this.props.urlparams.readonly == 1 ? true : false,
                    })}
                    onClick={() => this.addRecord()}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}

            <div className="row addtask">
              <div className="col-12">
                {!!data.length && this.listMode && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    deleteAction={(v) => this.deleteTaskAction(v)}
                    editAction={(v) => this.editTaskAction(v)}
                    viewAction={(v) => this.viewTaskAction(v)}
                    editable={this.props.urlparams.readonly == 1 ? false : true}
                    deletable={
                      this.props.urlparams.readonly == 1 ? false : true
                    }
                    pageSize={10}
                    targetType="tap"
                    renderAction={(v) => v.task_name !== "Miscellaneous"}
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>

            {this.listMode && (
              <div className="row">
                <div className="col-12 mt-4">
                  <Link
                    to={
                      "/projectmanagement/addbudget?" +
                      Object.QueryString({
                        ...this.props.urlparams,
                      })
                    }
                    className="btn add-btn"
                  >
                    Next
                  </Link>
                </div>
              </div>
            )}
          </>
        )) ||
          ""}
      </LayoutWrapper>
    );
  }
}

export default connect(Task);

/**
 * App Header
 */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfigPanel from "./configpanel";

const Header = (props) => {
  const { transperent, Avatar_21 } = imagepaths;
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClik = () => {
    props.onMenuClick();
  };

  const handleLogout = () => {
    props.api.applogout();
  };

  let pathname = location.pathname;

  const getTaskNotifications = () => {
    let payload = {
      // is_read: false,
    };

    let notificationBox = document.querySelector(
      "#taskmanagementNotificationPanel .notification-list"
    );

    let bellBox = document.querySelector(
      "#taskmanagementNotificationPanel .notification-counter"
    );

    props.api.apiGetHrmsTaskNotifications(payload).then(({ data }) => {
      notificationBox.innerHTML = "";
      if (data?.length > 0) {
        const isAllRead = data.every((obj) => obj.is_read === true);
        if (isAllRead) {
          bellBox.classList.add("d-none");
          bellBox.innerHTML = "";
        } else {
          bellBox.classList.remove("d-none");
          bellBox.innerHTML = `${data?.length}`;
        }
        data?.map((task) => {
          let completionDate = convertDate(task?.completion_date);
          let html = `
              <li class="notification-message" id="${task?.task_id}">
              <a href="#" id="${task?.task_id}"></a>
                <div class="avatar">${
                  task?.updatedBy
                    ?.split(" ")
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase() || "-"
                }</div>
                <div class="content">
                  <p class="detail">
                    ${
                      task?.updatedBy || "unknown user"
                    } has <span class="green">completed</span>
                    the task “${task?.task_name}”
                  </p>
                  <p class="date">on ${completionDate}</p>
                </div>
              </li>`;
          notificationBox.innerHTML += html;

          document
            .querySelectorAll(
              "#taskmanagementNotificationPanel .notification-list li"
            )
            .forEach((message) => {
              message.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                let taskId = e.target.id;
                // e.target.classList.toggle("hide");
                props.api
                  .apiUpdateHrmsTaskNotification({ taskIds: taskId })
                  .then((res) => {
                    getTaskNotifications();
                  });
              });
            });
        });
      } else {
        bellBox.classList.add("d-none");
        bellBox.innerHTML = "";
      }
    }).catch((error) => console.log(error));
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().substr(-2);
    const formattedDate = `${day}${
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th"
    } ${month}-${year}`;

    return formattedDate;
  };

  useEffect(() => {
    pathname != "/schemes" && getTaskNotifications();
  }, []);

  return (
    <div className="header" style={{ right: "0px" }}>
      <a
        id="toggle_btn"
        href="#"
        className="d-none"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </a>
      {/* Logo */}
      <div className="header-left">
        <Link to="/dashboard" className="logo">
          <img src={transperent} alt="" />
        </Link>
      </div>
      {/* /Logo */}
      {/* Header Title */}
      <div className="page-title-box d-none">
        <span className="left-attach-border"></span>
        <span className="font-bold text-base">{props.store.config.name}</span>
      </div>
      {/* /Header Title */}
      <a
        id="mobile_btn"
        className="mobile_btn"
        href="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </a>
      {/* Header Menu */}
      <ul className="nav user-menu" id="taskmanagementNotificationPanel">
        {/* Notifications */}
        <ConfigPanel />
        <li className="nav-item dropdown">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <i className="fa fa-bell-o" />
            <span className="badge badge-pill notification-counter d-none"></span>
          </a>
          <div className="dropdown-menu notifications">
            <div className="noti-content">
              <ul className="notification-list"></ul>
            </div>
          </div>
        </li>
        {/* /Message Notifications */}
        <li className="nav-item dropdown has-arrow main-drop mr-12">
          <a
            href="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <span className="user-img me-1">
              <img src={Avatar_21} alt="" />
              <span className="status online" />
            </span>
            <p className="user_box">
              <span>{props?.store?.user?.employee_name || ""}</span>
              <span>{props?.store?.user?.employee_role || ""}</span>
            </p>
          </a>
          <div className="dropdown-menu">
            {/* <Link className="dropdown-item" to="/app/profile/employee-profile"> */}
            {/* <Link className="dropdown-item" to="/app/profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="/settings/companysetting">
              Settings
            </Link> */}
            <Link className="dropdown-item" onClick={() => handleLogout()}>
              Logout
            </Link>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <a
          href="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <Link className="dropdown-item" to="/app/profile/employee-profile">
            My Profile
          </Link>
          <Link className="dropdown-item" to="/settings/companysetting">
            Settings
          </Link>
          <Link className="dropdown-item" onClick={() => handleLogout()}>
            Logout
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default connect(Header);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "../LayoutWrapper";

export class candidateTest extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("candidateTest");
    this.state = {
      form: null,
      rowcount: 0,
      errors: {},
      results: {},
      answers: [],
      candidate: {},
      expiry: "",
      expiryunit: "days",
      submited: false,
    };
  }

  get haspreview() {
    return this.props?.urlparams?.preview;
  }

  checkLangRender() {
    console.log("checkLangRender", window.langRender);
    if (window.langRender) {
      mixins.debounce(() => window.langRender());
    } else {
      setTimeout(() => {
        this.checkLangRender();
      }, 500);
    }
  }

  componentDidMount() {
    let loaded = loader("Loading data please wait....");
    console.log({ loaded });
    document.body.addEventListener("googleLangugeLoaded", (e) => {
      this.checkLangRender();
    });
    this.api
      .apiGetResponce()
      .then((candidate) => {
        this.api
          .apiGetCandidateAssementTest({}, { id: candidate.data.assessment_id })
          .then((form) => {
            this.store("translateLang", form.language.split(","));
            let expiry = candidate.data.expiry_date
              .date()
              .diff(new Date(), "days");
            let answers = form.qfields.reduce((answers, field) => {
              answers[field.id] = field.answer;
              return answers;
            }, {});
            //this.setState({results:answers});
            console.log({ expiry: expiry });
            if (expiry > 0) {
              this.setState({
                form: form,
                candidate: candidate.data,
                expiry,
                expiryunit: "days",
              });
            } else if (expiry == 0) {
              this.setState({
                form: form,
                candidate: candidate.data,
                expiry: "Today",
                expiryunit: "",
              });
            } else {
              mixins.debounce(() => {
                error(
                  "This Link is expired please contact administrator"
                ).promise.then(() => {
                  //navigate("/public/thankyouquiz");
                });
              });
              console.log("error trapped");
            }
            this.api
              .apiGetCadidateAssementTestSubmited(
                { assessment_id: candidate.data.assessment_id },
                form
              )
              .then((response) => {
                //console.log({ apiGetCadidateAssementTestSubmited: response });
                if (this.haspreview) {
                  this.setState({ answers: response[0]?.answers || [] });
                } else {
                  if (response.length) {
                    this.setState({ submited: true });

                    this.mixin.debounce(() => {
                      success(
                        "You have already submitted your assessment successfully thank you."
                      ).promise.then(() => {
                        navigate("/public/thankyou");
                      });
                    });
                    console.log("error trapped");
                  }
                  if (langRender) {
                    langRender();
                  }
                }
                loaded.close();
              })
              .catch(() => loaded.close());
          })
          .catch(() => loaded.close());
      })
      .catch(() => loaded.close());
  }

  onFieldFocus(field) {
    console.log("onFocus");
    langRender();
  }

  isCheckboxOrRadio(field) {
    return ["checkbox", "radio"].includes(field.question_type);
  }

  submitForm(e, data, form) {
    let { candidate } = this.state;
    let parsedPayload = parseInput(data);
    console.log({ parsedPayload, job_id: candidate.job_id });
    parsedPayload.job_id = candidate.job_id;
    (parsedPayload.applicant_id = app.props.urlparams.id),
      (parsedPayload.assessment_id = candidate.assessment_id);
    this.api.apiSaveCadidateAssementTest(parsedPayload).then(() => {
      success("Submit Successfully").promise.then(() => {
        navigate("/public/thankyou");
      });
    });
  }

  hasResult(field) {
    return false;
  }
  getAnswer(field, option = null) {
    let answer = this.state.results[field.id];
    let ret = {};

    switch (field.question_type) {
      case "checkbox":
        ret = {
          checked: answer == option,
        };
        break;
      case "radio":
        ret = {
          checked: answer == option,
        };
        break;
      default:
        ret = {
          defaultValue: answer,
        };
    }
    console.log({ answer, option, ret, id: field.id });
    return ret;
  }

  givenAnswer(field) {
    return this.state.answers.find((o) => o._id == field.id);
  }

  givenAnswerValue(field) {
    console.log("state", this.state.answers);
    console.log("givenAnswerValue", field);

    return field.answer;
    // return this.state.answers.find((o) => o._id == field.id)?.q_answer || "";
  }

  getGivenAnswer(field, option) {
    //below method not needed (givenAnswer)
    let answer = this.givenAnswer(field);

    console.log("answer: ", answer);
    // console.log("answer: ", field, answer);

    if (answer) {
      if (field.question_type == "checkbox") {
        let checked = (answer?.q_answer || "").split(",").includes(option);
        return checked;
      } else if (field.question_type == "radio") {
        return answer?.q_answer == option;
      } else {
        return answer?.q_answer || "";
      }
    } else {
      return undefined;
    }

    // if (answer) {
    //   if (answer.q_type == "checkbox") {
    //     let checked = (answer.q_answer || "").split(",").includes(option);
    //     return checked;
    //   } else if (answer.q_type == "radio") {
    //     return answer.q_answer == option;
    //   } else {
    //     return answer.q_answer || "";
    //   }
    // } else {
    //   return undefined;
    // }
  }

  getAnswerStatus(field) {
    let status = false;
    let answer = this.givenAnswer(field);
    if (answer) {
      if (answer.a_value == answer.q_answer) {
        status = true;
      }
    }
    return status;
  }

  handleFieldChange(field) {}

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  }

  render() {
    let { form, results, expiry, expiryunit, candidate, answers, submited } =
      this.state;
    let { haspreview } = this;
    let filetypemap = (type, field) => (type == "questionfile" ? "file" : type);
    return (
      <LayoutWrapper>
        <div className="flex mx-auto my-5 flex-column align-items-center justify-content-center min-w-fullscreen md:min-w-desktop sm:min-w-mobile">
          {form && (
            <>
              <div className="timer_container">
                <p className={`${haspreview ? "d-none" : "d-block"}`}>
                  This Link will expire in{" "}
                  <span>
                    {expiry} {expiryunit}
                  </span>
                  .
                </p>
              </div>
              <AppForm
                onSubmit={(...args) => this.submitForm(...args)}
                ref="form"
                className="questions_tab container"
              >
                {form.qfields.map((field, i) => (
                  <div className="question_card" key={i}>
                    <div className="card_header mb-b relative flex flex-wrap">
                      <input
                        type="hidden"
                        name={"questions." + field.id + ".question"}
                        value={field.question}
                      />
                      <input
                        type="hidden"
                        name={"questions." + field.id + ".id"}
                        value={field.id}
                      />
                      <p className="title">
                        Q:{i + 1} ) {field.question}
                      </p>
                      {(field.question_type == "questionfile" && (
                        <div className="w-full m-2 flex">
                          <a
                            className="ml-auto btn btn_primary"
                            href={this.downloadFile(field.possible_values)}
                            target="_blank"
                          >
                            Download Questions
                          </a>
                        </div>
                      )) ||
                        ""}
                      <span className="grow"></span>
                      {(this.haspreview &&
                        answers.length &&
                        this.givenAnswerValue(field) && (
                          <span className="text-green-500 text-2x font-bold">
                            Correct Answer ({this.givenAnswerValue(field) || ""}
                            )
                          </span>
                        )) ||
                        ""}
                    </div>
                    <div className="card_body">
                      {this.isCheckboxOrRadio(field) && (
                        <div className="list-group option_box">
                          {field.possible_values.split(",").map((option, j) => (
                            <AppInput
                              key={"option_" + i + "-" + j}
                              type={field.question_type}
                              // className="list-group-item"
                              name={"questions." + field.id + ".answer"}
                              onChange={() => this.handleFieldChange(field)}
                              value={option}
                              readOnly={this.haspreview || submited}
                              {...(this.haspreview
                                ? {
                                    checked: this.getGivenAnswer(field, option),
                                  }
                                : {})}
                            >
                              {option}
                            </AppInput>
                          ))}
                        </div>
                      )}
                      {!this.isCheckboxOrRadio(field) && (
                        <AppInput
                          name={"questions." + field.id + ".answer"}
                          type={filetypemap(field.question_type, field)}
                          className="list-group option_box candidatetest"
                          {...(field.question_type == "select"
                            ? {
                                onDropdownOpen: () => this.onFieldFocus(field),
                                onDropdownClose: () => this.onFieldFocus(field),
                                defaultValue: this.haspreview
                                  ? [this.getGivenAnswer(field)]
                                  : undefined,
                              }
                            : {})}
                          {...([
                            "text",
                            "textarea",
                            "file",
                            "date",
                            "questionfile",
                          ].includes(field.question_type)
                            ? {
                                defaultValue: this.haspreview
                                  ? this.getGivenAnswer(field)
                                  : undefined,
                              }
                            : {})}
                          options={
                            field.possible_values
                              ? field.possible_values
                                  .split(",")
                                  .options("", "", (v) => v)
                              : []
                          }
                          disabled={this.haspreview || submited}
                          filename="Answers.LIST"
                          // filename={field.question_type=='file'?results[field.id].filename:''}
                        ></AppInput>
                      )}
                    </div>
                  </div>
                ))}

                <div className="question_card mt-8">
                  <div className="card_footer flex">
                    <div className="ml-auto flex gap-2">
                      {!haspreview && (
                        <div className="flex gap-2">
                          <Link className={"btn btn_primary"} to={`/`}>
                            Cancel
                          </Link>
                          <button
                            disabled={submited}
                            type="submit"
                            className="btn btn_primary"
                          >
                            Submit & Next
                          </button>
                        </div>
                      )}
                      {haspreview && (
                        <button
                          className={"btn btn_primary"}
                          onClick={() => window.close()}
                        >
                          Close
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </AppForm>
            </>
          )}
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(candidateTest);

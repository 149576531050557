export default {
    apiBankList(data={}) {
        return this.request(this.api().bankList,data)
            .exec().log().mapresults((data) => {
                app.store("bankList",data.options('designation'))
                return data
            }).get();
    },
    apiCreateBank(data={}) {
        return this.request(this.api().bankCreate,data)
        .exec("Adding Bank please wait...").log().mapresults(async (data) => {
            await this.apiBankList();
            return data
        }).get();
    },
    apiEditBank(data={},urldata={}) {
        return this.request(this.api().bankUpdate,data)
        .urltransform(urldata)
        .exec("Updating Bank please wait...").log().mapresults(async (data) => {
            await this.apiBankList();
            return data
        }).get();
    },
    apiDeleteBank(data={},urldata={}) {
        return this.request(this.api().bankDelete,data)
        .urltransform(urldata)
        .exec("Deleting Bank please wait...").log().mapresults(async (data) => {
            await this.apiBankList();
            return data
        }).get();
    }
}
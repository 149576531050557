import React, { Component } from 'react';
import LayoutWrapper from '../LayoutWrapper';

export class WorkLocations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowcount:0,
            locationData:[],
            search:{
                page:1
            }
        }
        let renderField = (name,edit=false)=>((text,record)=><div>
            <span>{record[name]}</span>
            { edit && <img src={imagepaths.pencilplus} className={"h-3 w-3 float-right cursor-pointer"} onClick={()=>this.getUpdateValue(record,name)} title={"Edit"} /> }
        </div>);
        this.columns = [
            ...["_id","created_at"].map(dataIndex=>({dataIndex,display:false})),
            {
                dataIndex: "location",
                title: "Location",
                render:renderField('location')
            },
            {
                dataIndex: "office_name",
                title: "Office Name",
                render:renderField('office_name')
            },
            {
                dataIndex: "state",
                title: "State",
                render:renderField('state')
            },
            {
                dataIndex: "city",
                title: "City",
                render:renderField('city')
            },
            {
                dataIndex: "pincode",
                title: "Pincode",
                render:renderField('pincode')
            },
            {
                dataIndex: "latitude",
                title: "Latitude",
                render:renderField('latitude')
            },
            {
                dataIndex: "longitude",
                title: "Logitude",
                render:renderField('longitude')
            },
        ]

        this.addfields = [
            {
                name: "state",
                label: "State",
                type: "select",
                options:Object.keys(statecity).options(),
                onChange:(value)=>{
                    let city = this.addfields.find(o=>o.name=='city');
                    city.options = Object.assign(city.options,statecity[value[0].key].options());
                }
            },
            {
                name: "city",
                label: "City",
                type: "select",
                options:[]
            },
            {
                name: "office_name",
                label: "Office Name",
            },
            {
                name: "location",
                label: "Location",
            },
            {
                name:"pincode",
                label:"Picode"
            },
            {
                name: "latitude",
                label: "Latitude",
                type:"number",
                step:"any"
            },
            {
                name: "longitude",
                label: "Logitude",
                type:"number",
                step:"any"
            }
        ];

        this.fields = [
            {
                name: "state",
                label: "State",
                type: "text"
            },
            {
                name: "city",
                label: "City",
                type: "text"
            },
            {
                name: "office_name",
                label: "Office Name",
            },
            {
                name: "location",
                label: "Location",
            }
        ];
    }

    onPrev() {
        this.setState(state => ({ search: { ...state.search, page: state.search.page - 1 } }), () => {
            this.fetchData();
        });
    }

    onNext() {
        this.setState(state => ({ search: { ...state.search, page: state.search.page + 1 } }), () => {
            this.fetchData();
        });
    }

    addLocation() {
        let payload = this.refs.addform.form.current.getData();
        let data = {
            ...payload
        };
        return this.props.api.apiCreateWorkLocation(data)
        .then(()=>this.fetchData());
    }

    componentDidMount() {
        window.WorkLocations = this;
        this.fetchData();
    }

    fetchData(extrapayload={}) {
        let payload = this.refs.form.form.current.getData();
        let data = {
            ...payload,
            ...this.state.search,
            ...extrapayload
        };
        return this.props.api.apiEmployeeWorkLocation(data,false).then((responce)=>{
            this.setState({
                locationData: responce.result,
                rowcount: responce.data_count
            });
        });
    }

    getUpdateValue(record,name) {
        prompt(`Please enter the ${name} value that need to update`,{
            defaultValue:record[name]
        }).promise.then(value=>{
            this.updateValue(value,record,name);
        });
    }

    updateValue(value,record,name) {
        this.updateValues({ [name]:value },record,`Updating the ${name} of work locations...?`);
    }

    updateValues(values,record,message=`Updating the of work location...?`) {
        confirm(message).promise.then(()=>{
            this.props.api.apiUpdateWorkLocation(values,{
                loc_id:record._id
            }).then((responce)=>{
                this.fetchData();
            });
        })
    }

    deleteLocation(data) {
        confirm(`Confirm the delete of ${data.location} from list...?`).promise.then(()=>{
            this.props.api.apiDeleteWorkLocation({ },{
                loc_id:data._id
            }).then((responce)=>{
                this.fetchData();
            });
        })
    }

    editLocation(payload) {
        prompt(`Edit`,{},<div id="prompt" className='row text-left'>
            {
                this.addfields.map((field, i) => (
                    <div className="col-sm-6" key={i} >
                        <AppInput
                            {...field}
                            defaultValue={payload[field.name]}
                        >
                        </AppInput>
                    </div>
                ))
            }
        </div>).promise.then(()=>{
            let data = document.querySelector("#appmodal").getData();
            this.updateValues(data,payload);
        });
    }

    render() {
        let { locationData } = this.state;
        let { columns,props } = this;
        return (
            <LayoutWrapper title="Work Locations" back="Dashboard" >
                <AppForm className="row mb-2" ref="addform" onSubmit={() => this.addLocation()}>
                    {
                        this.addfields.map((field, i) => (
                            <div className="col-sm-6 col-md-3" key={i} >
                                <AppInput
                                    {...field}
                                >
                                </AppInput>
                            </div>
                        ))
                    }
                    <div className="col-sm-6 col-md-3">
                        <div className='form-group'>
                            <label className="col-form-label block">&nbsp;</label>
                            <div className='flex'><button className='btn add-btn h-10 m-auto '> Add </button></div>
                        </div>
                    </div>
                </AppForm>
                <hr/>
                <AppForm className="row filter-row mb-2" ref="form">
                    {
                        this.fields.map((field, i) => (
                            <div className="col-sm-6 col-md-2" key={i} >
                                <AppInputFocus
                                    {...field}
                                >
                                </AppInputFocus>
                            </div>
                        ))
                    }
                    <div className='col-2'></div>
                    <div className="col-sm-6 col-md-2">
                        <button className="btn btn-success btn-block w-100" onClick={() => this.fetchData()}> Search </button>
                    </div>
                </AppForm>
                <div className='row'>
                    <div className='col-12'>
                        <AppTable
                            data={locationData}
                            columns={columns}
                            onNext={() => this.onNext()}
                            onPrev={() => this.onPrev()}
                            total={this.state.rowcount}
                            reorder={true}
                            deleteAction={(v)=>this.deleteLocation(v)}
                            editAction={(v)=>this.editLocation(v)}
                            targetType="tap"
                        >
                        </AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default connect(WorkLocations);
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function AppCircleStepper(props) {
  let {
    className = "",
    data = [],
    overideClass = false,
    linkClass = "",
    disabled,
    params = props.urlparams,
  } = props;
  // console.log("AppLinkTabs", props);
  disabled = [undefined].includes(disabled)
    ? !!props?.urlparams?.new
    : disabled;
  const isDisabled = (link) => {
    if (typeof disabled === "boolean") return disabled;
    else if (disabled instanceof Object) return disabled[link.name] || false;
  };
  // useEffect(() => {
  //   console.log("data", data);
  // }, []);
  return (
    <>
      <div className="wrapper option-1 option-1-1">
        <ol className="c-stepper">
          {data
            .map((link, i) => ({
              ...link,
              compleated: data.findIndex(
                (o) => o.path == window.location.pathname
              ),
            }))
            .map((link, i) => (
              <Link
                key={i}
                className={Object.className({
                  "c-stepper__item stepper_tab_class": true,
                  "c-stepper__item_active": i < link.compleated,
                  "c-stepper__item_progress": i == link.compleated,
                  [linkClass]: true,
                })}
                href="#"
                disabled={isDisabled(link)}
                to={link.path + "?" + Object.QueryString(params)}
              >
                <h3 className="c-stepper__title">{link.label}</h3>
              </Link>
            ))}
        </ol>
      </div>
    </>
  );
}

export default connect(AppCircleStepper);

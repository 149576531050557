import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import mixins from "../mixins";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

export class assessment_applications extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("TestApplications");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "assessment_name",
          title: "Test Name",
          render: (text, record) => <b>{text}</b>,
        },
        {
          dataIndex: "role",
          title: "Role",
        },
        {
          dataIndex: "language",
          title: "Language",
        },
        {
          dataIndex: "assessment_description",
          title: "Description",
          render: (text, record) => {
            return (
              <span className="text_truncate cursor-pointer" title={record?.assessment_description} onClick={()=>alert(record?.assessment_description)} >
                {record?.assessment_description}
              </span>
            );
          },
        },
      ],
    };
  }

  onNext() {}

  onPrev() {}

  deleRegForm(form) {
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.props.api;
      this.api.apiDeleteCandidateAssementTest(form).then(() => {
        this.fetchList();
      });
    });
  }

  editRegForm(form) {
    this.navigate(`/recruitment/apttest?id=${form.id}`);
  }

  viewRegForm(form) {
    this.navigate(`/recruitment/apttest?id=${form.id}&readonly=1`);
    // let url = window.location.origin+`/public/candidatetest?id=${form.id}`;
    // window.open(url);
  }

  fetchList() {
    this.api
      .apiCandidateAssementTestList(this.state.search)
      .then(({ data, total }) => {
        this.setState({
          data: [...data],
          rowcount: total,
        });
      });
    // this.api
    // .mokeapi(
    //   () => mokeresults.ONBOARD_TEST_LIST,
    //   "Getting data please wait...",
    //   1000
    // )
    // .then((data) => {
    //   this.setState({
    //     data: data,
    //     rowcount: data.length,
    //   });
    // });
  }

  componentDidMount() {
    this.fetchList();
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList()
    );
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    let pageCount = Math.ceil(rowcount / search.limit);
    console.log(pageCount);
    let promises = [];
    promises.length = pageCount;
    promises.fill(0);
    console.log(promises);

    promises = promises.map((v, i) =>
      this.props.api.apiCandidateAssementTestList({
        ...this.state.search,
        page: i + 1,
      })
    );
    console.log(promises);
    Promise.all(promises).then((results) => {
      let totalrows = results.reduce((col, item) => col.concat(item.data), []);
      this.handleDownload(totalrows);
    });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, [
            "assessment_name",
            "role",
            "language",
            "assessment_description",
      ]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `aptitude_test_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  render() {
    const links = mixins.menulinks;
    let { columns, data, rowcount } = this.state;
    return (
      <LayoutWrapper title="Aptitude Tests" back="Dashboard">
        <div className="row">
          <div className="col"></div>
          <div className="mb-4">
            <Link to="/recruitment/apttest" className="btn add-btn">
              Add
            </Link>
            <button
              type="button"
              className="btn add-btn mx-2"
              onClick={() => this.getDownloadData()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AppTable
              data={data}
              columns={columns}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={rowcount}
              reorder={true}
              copyable={false}
              deleteAction={(v) => this.deleRegForm(v)}
              editAction={(v) => this.editRegForm(v)}
              viewAction={(v) => this.viewRegForm(v)}
              targetType="tap"
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(assessment_applications);

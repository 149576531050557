import React, { Component } from 'react';
import LayoutWrapper from '../../LayoutWrapper';

export class PayrollMasterData extends Component {
    constructor(props) {
        super(props);
        let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        this.state = {
            rowcount:0,
            search: {
                page:1
            },
            payrollMasterData: []
        }
        this.fields = [
            {
                name: "employee_id",
                label: "Employee ID",
                type: "text"
            },
            {
                name: "name",
                label: "Employee Name",
                type: "text"
            },
            {
                name: "location",
                label: "Location",
                type: "select",
                options: "worklocationList",
                className: "select-focus"
            }
        ];
        this.columns = [
            {
                dataIndex:"id",
                display:false
            },
            {
                title:"Name",
                dataIndex: "name",
                render: (text, record) => (
                    <h2 className="table-avatar">
                        <a href='#'>
                            {text}
                            <span>{record.role}</span>
                        </a>
                    </h2>
                ),
            },
            {
                title:"Last Drawn Salary",
                dataIndex:"lastDrawnSalary",
                render:text=>((text||'').cur().split('.')[0])
            },
            {
                title:"Last Drawn Month",
                dataIndex:"lastDrawnMonth",
                render:text=>((months[text]||'').toLowerCase().toTitleCase())
            },
            {
                title:"Date Of Joining",
                dataIndex:"doj",
                render:text=>text.moment().format("DD MMM YYYY")
            },
            {
                title:"Status",
                dataIndex: "status",
                render: (text, record) => (
                    <div className="dropdown action-label text-center">
                        <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                            <i
                                className={Object.className({
                                    "fa fa-dot-circle-o": true,
                                    "text-danger": text === "Pending",
                                    "text-success": text === "active",
                                    "text-info": text === "Returned",
                                })}
                            /> {text}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger" /> Pending</a>
                            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-success" /> Active</a>
                            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info" /> Returned</a>
                        </div>
                    </div>
                ),
            }
        ];
    }

    componentDidMount() {
        window.MasterData = this;
        this.fetchData();
    }

    fetchData() {
        let data = {
            ...this.refs.search.form.current.getData(),
            ...this.state.search
        };
        this.props.api.apiEmployeeMasterList(data).then((responce) => {
            this.setState({
                rowcount:responce.total_count,
                payrollMasterData: responce.data
            });
        });
    }

    onPrev() {
        this.setState(state=>({search:{...state.search,page:state.search.page-1}}),()=>{
            this.fetchData();
        });
    }

    onNext() {
        this.setState(state=>({search:{...state.search,page:state.search.page+1}}),()=>{
            this.fetchData();
        });
    }

    render() {
        let { columns } = this;
        let { payrollMasterData,rowcount } = this.state;
        return (
            <LayoutWrapper title="Employee Master Data" back="Payroll">
                <AppForm className="row filter-row" ref={"search"}>
                    {
                        this.fields.map((field, i) => (
                            <div className="col-sm-6 col-md-3" key={i} >
                                <AppInputFocus
                                    {...field}
                                >
                                </AppInputFocus>
                            </div>
                        ))
                    }
                    <div className="col-sm-6 col-md-3">
                        <button className="btn btn-success btn-block w-100" onClick={()=>this.fetchData()}> Search </button>
                    </div>
                </AppForm>
                <div className='row'>
                    <div className='col'>
                        <AppTable
                            data={payrollMasterData}
                            columns={columns}
                            total={rowcount}
                            onNext={()=>this.onNext()}
                            onPrev={()=>this.onPrev()}
                            editable={false}
                            deletable={false}
                        >
                        </AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default connect(PayrollMasterData);
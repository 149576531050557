export default {
    addEntrepreneur: {
        url: "/womnentr",
        method: "post"
    },
    getEntrepreneurs: {
        url: "/hrms/employee/we",
        method: "get"
    },
    updateEntrepreneur: {
        url: "/womnentr/{entrepreneur_id}",
        method: "put"
    },
    deleteEntrepreneur: {
        url: "/womnentr/{entrepreneur_id}",
        method: "delete"
    },

    // VENDOR
    addVendor: {
        url: "/womnentr/vendor",
        method: "post"
    },
    getVendors: {
        url: "/womnentr/vendor",
        method: "get"
    },
    updateVendor: {
        url: "/womnentr/vendor/{vendor_id}",
        method: "put"
    },
    deleteVendor: {
        url: "/womnentr/vendor/{vendor_id}",
        method: "delete"
    },

    // FORUM - POST 
    addForumPost: {
        url: "/womnentr/forum/post",
        method: "post"
    },
    getForumPosts: {
        url: "/womnentr/forum/post",
        method: "get"
    },
    getForumPostById: {
        url: "/womnentr/forum/post/{post_id}",
        method: "get"
    },
    updateForumPost: {
        url: "/womnentr/forum/post/{post_id}",
        method: "put"
    },
    deleteForumPost: {
        url: "/womnentr/forum/post/{post_id}",
        method: "delete"
    },

    // FORUM - COMMENT 
    addForumComment: {
        url: "/womnentr/forum/comment",
        method: "post"
    },
    getForumComments: {
        url: "/womnentr/forum/comment",
        method: "get"
    },
    getForumCommentReply: {
        url: "/womnentr/forum/comment/reply",
        method: "get"
    },
    deleteForumComment: {
        url: "/womnentr/forum/comment/{comment_id}",
        method: "delete"
    },

    // FORUM - LIKE 
    addForumLike: {
        url: "/womnentr/forum/like",
        method: "post"
    },

    // FORUM - REPORT 
    addForumReport: {
        url: "/womnentr/forum/report",
        method: "post"
    },
    getForumReports: {
        url: "/womnentr/forum/report",
        method: "get"
    },
    getForumReportById: {
        url: "/womnentr/forum/report/{report_id}",
        method: "get"
    },

    // FORUM - SAVE 
    addForumSave: {
        url: "/womnentr/forum/save",
        method: "post"
    },
    getForumSave: {
        url: "/womnentr/forum/save",
        method: "get"
    },

}
export default {
    apiGetForumDataMoke() {
        let data = [
            {
                id: 1,
                topic: "Marketing & Communication",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of.",
                replies: "2",
                views: "2.3 k",
                active: "10:40 am",
            },
            {
                id: 2,
                topic: "Marketing & Communication",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of.",
                replies: "2",
                views: "2.3 k",
                active: "2 Days ago",
            },
            {
                id: 3,
                topic: "Marketing & Communication",
                description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of.",
                replies: "2",
                views: "2.3 k",
                active: "4 Days ago",
            },
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
    apiGetForumDetailsDataMoke() {
        let data = [
            {
                title: "Marketing & Communication",
                posted_time: "4 hours ago",
                posted_by: "Tushar Pawar",
                post_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu scelerisque felis imperdiet proin fermentum. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Molestie ac feugiat sed lectus. Consequat semper viverra nam libero justo laoreet sit. Lacus vestibulum sed arcu non odio euismod lacinia at. Risus sed vulputate odio ut enim blandit volutpat maecenas. At imperdiet dui accumsan sit amet nulla. Imperdiet sed euismod nisi porta lorem mollis aliquam ut porttitor. Ultrices in iaculis nunc sed augue lacus viverra vitae. Senectus et netus et malesuada fames ac turpis egestas maecenas. Accumsan tortor posuere ac ut consequat semper viverra nam.Hac habitasse platea dictumst vestibulum rhoncus. Faucibus ornare suspendisse sed nisi lacus sed viverra tellus in. Vel elit scelerisque mauris pellentesque. Tellus id interdum velit laoreet id donec ultrices tincidunt. Tortor id aliquet lectus proin nibh nisl condimentum id venenatis. Magna fermentum iaculis eu non diam phasellus vestibulum lorem. Interdum consectetur libero id faucibus.",
                post_actions: {
                    total_likes: 589,
                    total_comments: 232,
                    total_saves: 657
                },
                comments: [
                    {
                        name: "Manish",
                        comment: "Discussions about factual events happening in the airline and general aviation industries. If it's happening in commercial aviation, you'll get the information and opinions here first. As well as a random Lipsum generator. reference site about Lorem Ipsum, giving."
                    },
                    {
                        name: "Me",
                        comment: "Discussions about factual events happening in the airline and general aviation industries. If it's happening in commercial aviation, you'll get the information and opinions here first. As well as a random Lipsum generator. reference site about Lorem Ipsum, giving."
                    },
                    {
                        name: "Suresh Vadla",
                        comment: "Discussions about factual events happening in the airline and general aviation industries. If it's happening in commercial aviation, you'll get the information and opinions here first. As well as a random Lipsum generator. reference site about Lorem Ipsum, giving."
                    },
                    {
                        name: "Saurabh Goel",
                        comment: "Discussions about factual events happening in the airline and general aviation industries. If it's happening in commercial aviation, you'll get the information and opinions here first. As well as a random Lipsum generator. reference site about Lorem Ipsum, giving."
                    },
                ]
            }
        ];
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    },
}
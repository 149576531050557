import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BreadCum } from "../../../../layout/admin";

export class EmployeeHeader extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EmployeeHeader");
  }

  render() {
    let { selectedToggleList } = this.props;
    return (
      <div className="page-header employee-header absolute top-8 right-8">
        <div className="row align-items-center">
          <div className="col-auto d-flex">
            {/* <div
              className="btn-group custom_radio_btn_group"
              role="group"
              aria-label="Toggle list"
            >
              <input
                type="radio"
                className=""
                name="togglelist"
                id="delete-false"
                autoComplete="off"
                checked={selectedToggleList === "delete-false"}
                onChange={(...args) => this.props.handleToggleList(...args)}
              />
              <label className="mx-2" for="delete-false">
                Active
              </label>

              <input
                type="radio"
                className=""
                name="togglelist"
                id="delete-true"
                autoComplete="off"
                checked={selectedToggleList === "delete-true"}
                onChange={(...args) => this.props.handleToggleList(...args)}
              />
              <label className="mx-2" for="delete-true">
                Deleted
              </label>
            </div> */}
            {/* <label className="me-2 fw-bold">
              Employee <br /> Status:
            </label> */}
            <AppInput
              type="select"
              name="togglelist"
              options={_enum.EMPLOYEE_DELETED_STATUS || []}
              onChange={(...args) => this.props.handleStatusChange(...args)}
              defaultValue={(!!this.props.is_deleted).toString()}
              className="select-focus m-0 custom_pill_select"
            ></AppInput>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn add-btn border_btn"
              onClick={() => this.props.handleDownloadList()}
            >
              <i class="fa fa-download me-2"></i>
              Download
            </button>
          </div>
          <div
            className="col-auto float-end ml-auto"
            onClick={() => this.props.setstore("onborading", false)}
          >
            <Link to="/admin/employee/onboarding" className="btn add-btn">
              <i className="fa fa-plus" />
              Add Employee
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(EmployeeHeader);

import React, { Component } from "react";
import LayoutWrapper from "../../LayoutWrapper";
import mixins from "../mixins";

export class DaywiseAttendance extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      form: React.createRef(),
    };
    this.state = {
      rowcount: 0,
      attendanceData: [],
      search: {
        page: 1,
        limit: 10,
      },
    };
    this.fields = [
      {
        name: "year",
        label: "Year",
        type: "number",
        defaultValue: new Date().getFullYear(),
      },
      {
        name: "month",
        label: "Month",
        type: "number",
        defaultValue: new Date().getMonth() + 1,
      },
      {
        name: "day",
        label: "Day",
        type: "number",
        defaultValue: new Date().getDate(),
      },
    ];
    this.columns = [
      {
        dataIndex: "name",
        title: "Name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <a href="#">
              {text}
              <span>{record.role}</span>
            </a>
          </h2>
        ),
      },
      // {
      //   dataIndex: "gender",
      //   title: "Gender",
      // },
      {
        dataIndex: "employee_id",
        title: "Employee ID",
      },
      {
        dataIndex: "email",
        title: "Email",
      },
      // {
      //   dataIndex: "blood_group",
      //   title: "Blood Group",
      // },
      // {
      //   dataIndex: "date_of_birth",
      //   title: "Date Of Birth",
      //   render: (text) => (text || "").date().moment().format("DD MMM YYYY"),
      // },
      // {
      //   dataIndex: "date_of_joining",
      //   title: "Date Of Joining",
      //   render: (text) => (text || "").date().moment().format("DD MMM YYYY"),
      // },
      {
        dataIndex: "department",
        title: "Department",
      },
      {
        dataIndex: "designation",
        title: "Designation",
      },
    ];
    this.links = mixins.menulinks;
  }

  componentDidMount() {
    window.DaywiseAttendance = this;
    this.fetchData();
  }

  fetchData() {
    let payload = this.refs.form.form.current.getData();
    this.props.api
      .apiTodaysAbsentAttendanceList({
        ...payload,
        ...this.state.search,
      })
      .then((resonce) => {
        this.setState({
          rowcount: resonce.data_count,
          attendanceData: resonce.data,
        });
      });
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    let { columns, links } = this;
    let { attendanceData } = this.state;
    console.log("rowcount", this.state.rowcount);
    return (
      <LayoutWrapper title="Today's Absent" back="Team Attendance">
        <AppLinkTabs data={links}></AppLinkTabs>
        <AppForm className="row filter-row" ref="form">
          {this.fields.map((field, i) => (
            <div className="col-sm-6 col-md-2" key={i}>
              <AppInputFocus {...field}></AppInputFocus>
            </div>
          ))}
          <div className="col-sm-6 col-md-1"></div>
          <div className="col-sm-6 col-md-3">
            <button
              className="btn btn-success btn-block w-100"
              onClick={() => this.fetchData()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <div className="row">
          <div className="col">
            <AppTable
              data={attendanceData}
              columns={columns}
              deletable={false}
              editable={false}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              reorder={true}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(DaywiseAttendance);

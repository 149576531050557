import React, { Component, useRef } from "react";
import LayoutWrapper from "../LayoutWrapper";
import ApplicantCard from "../AppApplicantCard";
import { CallStatusModal } from "../CallStatusModal";
import { modeledit as modeleditfun } from "./modeledit";
export class applicants_view extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ApplicantsView");
    this.state = {
      data: [],
      rowcount: 0,
      filter: {},
      page: 1,
      limit: 10,
      sort: "",
      popupdata: {},
      popuptitle: "",
      popuptype: "OnlineAssessment",
      popupfields: {},
      updateapi: "",
      selectedCadidates: [],
      shortlistedCadidateId: "",
      modeledit: modeleditfun(this),
    };
    this.filterfields = _enum.APPLICANT_FILTERSTATUS;
  }

  onNext() {}

  onPrev() {}

  deleRegForm() {}

  editRegForm() {}

  viewRegForm() {}

  copyRegForm() {}

  fetchData() {
    let { filter } = this.state;
    let filters = Object.entries(filter)
      .filter(([key, val]) => val)
      .map(([key]) => key);
    let payload = {
      status: filters.join(","),
      page: this.state.page,
      limit: this.state.limit,
    };
    console.log({ filters, payload });
    return new Promise((reslove, reject) => {
      this.api
        .apiGetCadidateList(payload)
        .then((response) => {
          console.log({ apiGetCadidateList: response });
          this.setState({
            data: [...response.data],
            rowcount: response.total,
          });
          reslove(response);
        })
        .then(reject);
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  editFilter(event, obj) {
    let filter = this.state.filter || {};
    filter[obj.key] = event.target.checked;
    this.setState({ filter });
    console.log({ filter });
    this.fetchData();
  }

  makeStatusChange(values) {
    let value = values.key;
    console.log(value);
  }

  makeCommentChange(item, value) {
    return new Promise((resolve, reject) => {
      let promise = loader("Updating comments please wait...");
      this.api
        .apiUpdateCandidateResponse(
          {
            comments: value,
          },
          {
            id: item.id,
          }
        )
        .then(() => {
          promise.close();
          this.fetchData();
          resolve();
        })
        .catch(() => {
          promise.close();
          resolve();
        });
    });
  }

  formSubmit(ref, candidate, e, data, form) {
    let { modeledit } = this.state;
    let parsedPayload = parseInput(data);
    let modalform = document.querySelector("#modal_call_status form");
    modalform.dataset.status = parsedPayload.status;
    modalform.dataset.candidate_id = candidate.id;
    console.log("status: " + parsedPayload.status);
    let fields = (modeledit[data.status] || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");

    this.setState({
      popupfields: fields,
      popupdata: formdata,
      popuptitle: _enum.APPLICANT_CARD_STATUS.find((o) => o.key == data.status)
        .label,
      popuptype: data.status,
    });

    console.log({ candidate, data });

    switch (data.status) {
      case "send_online_assessment":
        this.api.apiGetCandidateRegistrationForm().then((form) => {
          this.api
            .apiTestIdsByRole(
              {},
              {
                job_role: form.role,
              }
            )
            .then(({ data: response }) => {
              console.log({ apiTestIdsByRole: response });

              // let testresponse = response.forEach((item) => {
              //   return (item.updatedAt = new Date(
              //     item.updatedAt
              //   ).toTimeString());
              // });
              // console.log(testresponse);
              let options = response.map((item) => ({
                key: item.assessment_id,
                label: item.assessment_name,
              }));
              modeledit[data.status].find(
                (o) => o.name == "assessment_id"
              ).options = options;
              this.setState(
                {
                  modeledit,
                },
                () => {
                  ref.current.click();
                }
              );
            });
        });

        break;
      case "release_offer":
        Promise.all([this.api.apiGetCandidateRegistrationForm()]).then(
          ([form]) => {
            modeledit[data.status].find((o) => o.name === "role").defaultValue =
              form.role;
            this.setState(
              {
                modeledit,
              },
              () => {
                ref.current.click();
              }
            );
            //
          }
        );

        break;
    }
    // COMMON MODAL TRIGGER
    ref.current.click();
  }

  closeModal() {
    document.querySelector("#modal_call_status #bsmodalclose").click();
  }

  submitShortlist(e, data, form) {
    let promises = [];
    if (form.dataset.status == "release_offer") {
      let salary_breakup = Object.entries(
        Object.except(data, ["calendar", "role"])
      ).map(([key, value]) => ({ q_label: key, q_value: value }));
      let payload = {
        job_role: data.role,
        job_role_others: data.role,
        date_of_joining: data.calendar.date().moment().format(),
        call_status: form.dataset.status,
        salary_breakup,
      };
      promises.push(
        this.api.apiCandidateOfferUpdate(payload, {
          id: form.dataset.candidate_id,
        })
      );
    } else {
      promises.push(
        this.api.apiUpdateCandidateResponse(
          {
            ...Object.filter(data, (v) => v),
            call_status: form.dataset.status,
            calendar: data.calendar.date().moment().format(),
          },
          {
            id: form.dataset.candidate_id,
          }
        )
      );
    }
    Promise.all(promises).then(() => {
      this.fetchData().then(() => {
        this.closeModal();
      });
    });
  }

  onCandidateSelect(event, candidate, index) {
    let { selectedCadidates = [] } = this.state;
    if (event.target.checked) {
      selectedCadidates.push(candidate);
    } else {
      selectedCadidates = selectedCadidates.filter((c) => c.id != candidate.id);
    }
    this.setState({
      selectedCadidates: [...selectedCadidates],
    });
  }
  selectCadidateForm() {
    let len = this.state.selectedCadidates.length;
    let myconfirm = confirm(
      `Please confirm select action for  ${len} ${
        len > 1 ? "candidates" : "candidate"
      }`
    );
    console.log(this.state.selectedCadidates);
    myconfirm.promise.then(() => {
      let promises = this.state.selectedCadidates.map((candidate) =>
        this.api.apiSelectCadidateForm({
          applicant_id: candidate.id,
        })
      );
      Promise.all(promises).then(() => {
        this.fetchData();
      });
    });
  }

  rejectCadidateForm() {
    let len = this.state.selectedCadidates.length;
    let myconfirm = confirm(
      `Please confirm reject action for  ${len} ${
        len > 1 ? "candidates" : "candidate"
      }`
    );
    myconfirm.promise.then(() => {
      let promises = this.state.selectedCadidates.map((candidate) =>
        this.api.apiRejectCadidateForm({
          applicant_id: candidate.id,
        })
      );
      Promise.all(promises).then(() => {
        this.fetchData();
      });
    });
  }

  deleteCadidateForm() {
    let len = this.state.selectedCadidates.length;
    let myconfirm = confirm(
      `Please confirm delete action for ${len} ${
        len > 1 ? "candidates" : "candidate"
      }`
    );
    myconfirm.promise.then(() => {
      let promises = this.state.selectedCadidates.map((candidate) =>
        this.api.apiDeleteCadidateForm({
          applicant_id: candidate.id,
        })
      );
      success("Candidate deleted successfully");
      Promise.all(promises).then(() => {
        this.fetchData();
      });
    });
  }

  downloadCadidateList() {
    let timestamp = new Date().toDateTime();
    this.state.selectedCadidates
      .map((o) => ({
        ...Object.except(o, ["others", "id", "applicant_id"]),
        ...o.others.reduce(
          (c, n) => ({ ...c, [n.field_label]: n.field_response }),
          {}
        ),
      }))
      .csv()
      .dowload(`cadidates.${timestamp}.csv`);
  }

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
    setTimeout(() => {
      this.fetchData();
    });
  }

  prevPage() {
    this.setState({
      page: this.state.page - 1,
    });
    setTimeout(() => {
      this.fetchData();
    });
  }

  get hasPrevPage() {
    return this.state.page > 1;
  }

  get hasNextPage() {
    let { rowcount, page, limit } = this.state;
    return rowcount > page * limit;
  }

  render() {
    let { filterfields } = this;
    let {
      popupdata,
      data,
      popuptype,
      popupfields,
      popuptitle,
      selectedCadidates = [],
    } = this.state;
    return (
      <LayoutWrapper title="Applicants" back="Registration Forms">
        <div className="status_card mb-4">
          <div className="card_header mb-4">
            <p className="m-0 title">
              Showing {this.state.data.length} / {this.state.rowcount} Applies:
            </p>
          </div>
          <div className="card_body flex flex-wrap gap-4">
            {filterfields.map((obj, i) => {
              return (
                <AppInput
                  onChange={(v) => this.editFilter(v, obj)}
                  type="checkbox"
                  label={obj.label}
                  name={obj.key}
                  key={obj.key}
                ></AppInput>
              );
            })}
          </div>
        </div>
        <div className="applicant_header flex flex-wrap gap-4 mb-3">
          {(selectedCadidates.length && (
            <>
              <button type="button" onClick={() => this.selectCadidateForm()}>
                <i className="fa fa-times-circle-o text-success"></i>
                Select
              </button>
              <button type="button" onClick={() => this.rejectCadidateForm()}>
                <i className="fa fa-times-circle-o text-danger"></i>
                Reject
              </button>
              <button type="button" onClick={() => this.downloadCadidateList()}>
                <i className="fa fa-download"></i>
                Download
              </button>
              <button type="button" onClick={() => this.deleteCadidateForm()}>
                <i className="fa fa-trash-o"></i>Delete
              </button>
            </>
          )) ||
            ""}
          <div className="grow"></div>
          <div className="flex gap-2">
            {this.hasPrevPage && (
              <button
                className="btn inline-block cursor-pointer border-0"
                type="button"
                onClick={() => this.prevPage()}
              >
                Prev
              </button>
            )}
            <div>Page {this.state.page}</div>
            {this.hasNextPage && (
              <button
                className="btn inline-block cursor-pointer border-0"
                type="button"
                onClick={() => this.nextPage()}
              >
                Next
              </button>
            )}
          </div>
          {/* <AppInput
            type="select"
            label="Sort By:"
            name="sortBy"
            onChange={(values, value) => this.setState({ sort: values[0].key })}
            options={_enum.SORT_BY_FILTER}
          ></AppInput> */}
        </div>
        <div>
          {data.map((item, i) => {
            return (
              <ApplicantCard
                key={i}
                data={item}
                index={i}
                onSelect={(...arg) => this.onCandidateSelect(...arg)}
                onStatusChange={(...args) => this.makeStatusChange(...args)}
                onCommentChange={async (...args) =>
                  await this.makeCommentChange(item, ...args)
                }
                onFormSubmit={(...args) => this.formSubmit(...args)}
                onCandidateReject={() => this.rejectCadidateForm()}
                onFetchData={() => this.fetchData()}
              />
            );
          })}
        </div>
        {data.length > 0 && (
          <div className="applicant_header flex flex-wrap gap-4 mb-3">
            <div className="grow"></div>
            <div className="flex gap-2">
              {this.hasPrevPage && (
                <button
                  className="btn inline-block cursor-pointer border-0"
                  type="button"
                  onClick={() => this.prevPage()}
                >
                  Prev
                </button>
              )}
              <div>Page {this.state.page}</div>
              {this.hasNextPage && (
                <button
                  className="btn inline-block cursor-pointer border-0"
                  type="button"
                  onClick={() => this.nextPage()}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        )}

        {console.log({ popupdata, popupfields })}
        <CallStatusModal
          id="modal_call_status"
          data={popupdata}
          title={popuptitle}
          fields={popupfields}
          type={popuptype}
          onSubmit={(...arg) => this.submitShortlist(...arg)}
          onClose={() => this.setState({ popupdata: {} })}
        />
      </LayoutWrapper>
    );
  }
}

export default connect(applicants_view);

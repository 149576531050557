import React, { Component } from "react";

export class FamilyMemberModel extends Component {
  render() {
    let { props } = this;
    let {
      id,
      title,
      onClose,
      list: arrlist = [],
      size = 2,
      submitText = "SUBMIT",
    } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);
    let getField = (label, fields) => fields[label] || {};
    arrlist = arrlist.map((data) => ({
      ...data,
      group: Object.entries(data.group).chunk(size),
    }));
    return (
      <AppModalBs
        id={id}
        title={title}
        contentClass={"w-3/6 m-auto"}
        onClose={onClose}
      >
        <AppForm>
          {arrlist.map(({ group, groupfields, subtitle }) => (
            <>
              <Row className="m-0">
                <Col className="fw-bold fs-6">{subtitle}</Col>
              </Row>
              {group.map((row, i) => (
                <Row className="mx-0 mb-3" key={i}>
                  {row.map(([label, value], j) => (
                    <Col key={j} className="col-12 col-lg-6">
                      <AppInput
                        name={label}
                        label={label.toTitleCase()}
                        defaultValue={value}
                        {...getField(label, groupfields)}
                      ></AppInput>
                    </Col>
                  ))}
                </Row>
              ))}
            </>
          ))}

          <Row className="pt-4">
            {/* <Col className="text-right">
              <button type="submit" className="btn add-btn">
                {submitText}
              </button>
            </Col> */}
            <Col className="d-flex">
              <button
                type="button"
                className="btn add-btn d-block mx-auto"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </AppForm>
      </AppModalBs>
    );
  }
}

export default FamilyMemberModel;

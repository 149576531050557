import React, { Component } from "react";
import "./Addemployee.scss";

import BasicDetails from "./BasicDetails";
import AccountDetails from "./AccountDetails";
import SalaryDetails from "./SalaryDetails";
import DocsUpload from "./DocsUpload";

export class Addemployee extends Component {
  constructor(props) {
    super(props);
    this.steps = [
      {
        name: "personalinfo",
        label: "Personal Info",
        Content: BasicDetails,
        props: {
          onSubmit: (e, hasNext, next) => {
            if (hasNext) {
              next();
            }
            console.log({ Submited: e, hasNext, next });
          },
        },
      },
      {
        name: "docsupload",
        label: "Docs Upload",
        Content: (props) => <DocsUpload {...props} />,
        props: {
          onSubmit: (e, hasNext, next) => {
            if (hasNext) {
              next();
            }
            console.log({ Submited: e });
          },
        },
      },
    ];
  }

  componentDidMount() {
    console.log({ componentDidMount: "AddEmployee" });
  }

  render() {
    let { steps } = this;
    return (
      <>
        {/* Add Employee Modal */}
        <div
          id="add_employee"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mr-auto">Employee Onboarding</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="fa fa-close"></i>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <AppSteps steps={steps} />
              </div>
            </div>
          </div>
        </div>
        {/* /Add Employee Modal */}
      </>
    );
  }
}

export default connect(Addemployee);

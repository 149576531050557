export const ConditionType = {
	AND: "AND",
	OR: "OR",
};

export const OpeartionType = {
	EQ: "=",
	NQ: "<>",
	GT: ">",
	LT: "<",
	GTE: ">=",
	LTE: "<=",
	LIKE: "LIKE",
};

export class Condition {
	key = "";
	value = "";
	operator;
	constructor(...args) {
		this.setCondition(...args);
	}
	setCondition(key = undefined, value = [], operator = undefined) {
		this.key = key;
		this.value = value;
		this.operator = operator;
	}
}

export class ConditionModel {
	where = [];
	result = null;
	conditions = {};
	type;

	setWhere(name, value) {
		this.where = [...this.setObject(this.where, name, value)];
		return this.where;
	}

	setObject(obj, name, value) {
		let ret = {};
		const setValues = (state, payload) => {
			let old = state;
			let key = "";
			let arr = payload.name.split(".");
			if (!payload.name) {
				state = payload.value;
			} else if (arr.length > 1) {
				let tmp = state;
				arr.forEach((k, i) => {
					let nextKey = arr[i + 1];
					old = tmp;
					let defval = nextKey && !isNaN(nextKey) ? [] : {};
					tmp[k] = tmp[k] || defval;
					tmp = tmp[k];
					key = k;
				});
				old[key] = payload.value;
			} else {
				key = payload.name;
				old = state[key];
				state[key] = payload.value;
			}
			return state;
		};
		if (typeof name == "string") {
			ret = setValues(obj, { name, value });
		} else {
			ret = setValues(obj, name);
		}
		return ret;
	}

	getWhere(name) {
		return this.getObject(this.where, name);
	}

	getObject(obj = this.where, name, def = "") {
		let state = obj || {};
		let old = JSON.parse(JSON.stringify(state));
		if (!name) {
			return obj;
		}
		let key = name;
		let arr = name.split(".");
		if (arr.length > 1) {
			let tmp = old;
			arr.forEach((k) => {
				old = JSON.parse(JSON.stringify(tmp));
				tmp[k] = tmp[k] || {};
				tmp = tmp[k];
				key = k;
			});
			return old[key] || def;
		} else {
			return state[key] || def;
		}
	}

	log() {
		console.log(this.result);
		return this;
	}

	getASTbySQL(whereString) {
		let ast = {
			type: "root",
			conditions: [],
		};

		let stack = [];
		let currentExpression = ast;

		const pushExpression = (expression) => {
			stack.push(currentExpression);
			currentExpression.conditions.push(expression);
			currentExpression = expression;
		};

		const popExpression = () => {
			stack.pop();
			currentExpression = stack[stack.length - 1];
		};

		const processExpression = (expression) => {
			const trimmed = expression.trim();

			if (trimmed === "AND" || trimmed === "OR") {
				currentExpression.operator = trimmed;
			} else if (trimmed === "(") {
				let subExpression = {
					type: "subexpression",
					conditions: [],
				};
				pushExpression(subExpression);
			} else if (trimmed === ")") {
				popExpression();
			} else {
				let [column, operator, value] = trimmed.split(/([<>=]+)/).map((part) => part.trim());

				let condition = {
					type: "condition",
					column,
					operator,
					value,
				};

				currentExpression.conditions.push(condition);
			}
		};

		let tokens = whereString
			.split(/(\(|\)|AND|OR)/)
			.map((token) => token.trim())
			.filter((token) => token.length > 0);

		for (let token of tokens) {
			processExpression(token);
		}

		return ast;
	}

	getAST(data = this.where) {
		let [leftItem, ...rightItems] = data;
		let isNode = 	(typeof leftItem == "object") && leftItem.hasOwnProperty('operator');
		let condition = leftItem instanceof Array ? ConditionType.OR : ConditionType.AND;
		//console.log({leftItem,rightItems,isNode,condition});
		let left = leftItem;
		let right = {};
		if(isNode && rightItems.length==0) {
			return {
				operator:leftItem.operator,
				left:leftItem.key,
				right:leftItem.value
			};
		}
		if(!isNode&&leftItem instanceof Array) {
			console.log("OnLeft",{leftItem,rightItems,isNode,condition});
			left = this.getAST(leftItem);
			console.log({left});
		}
		if(!isNode&&leftItem.hasOwnProperty('value')) {
			left = this.getAST(leftItem.value);
		}
		if(rightItems.length) {
			console.log("OnRight",{leftItem,rightItems,isNode,condition});
			right = this.getAST(rightItems);
			console.log({right});
		} else {
			return null;
		}
		//console.log({operator:condition,left,right});
		return {
			operator:condition,
			left,
			right
		}
	}

	getAST2Where(data) {
		if (!data) {
			return [];
		}
		let { left, right, node, condition, operator } = data;
		if (node) {
			return {
				key: left,
				operator,
				value: right,
			};
		} else {
			let leftItem = this.getAST2Where(left);
			let rightItem = this.getAST2Where(right);
			if (condition == ConditionType.AND) {
				return [[leftItem], [rightItem]];
			} else {
				return [leftItem, rightItem];
			}
		}
		return JSON.stringify(data);
	}

	getAST2SQL(data) {
		let { left, right, condition, operator, key, value } = data;
		if (operator) {
			return `\`${left}\` ${operator} '${right}' `;
		} else {
			return `(${this.getAST2SQL(left)} ${condition}  ${this.getAST2SQL(right)})`;
		}
	}

	getSQL(data) {
		if (data instanceof Array) {
			let isOr = data[0] instanceof Array;
			let joinCondition = isOr ? ConditionType.OR : ConditionType.AND;
			return `(${data
				.map((value) => {
					return this.getSQL(value);
				})
				.join(` ${joinCondition} `)})`;
		} else if (!data.key && data.value) {
			return this.getSQL(data.value);
		} else {
			let { key, value, operator } = data;
			return `\`${key}\` ${operator} '${value}' `;
		}
	}

	save(key = "") {
		let result = this.result;
		let arr = key.split(".");
		let prekey = arr.slice(0, arr.length - 1).join(".");
		prekey = prekey.endsWith(".value") ? prekey.slice(0, -".value".length) : prekey;
		if (key) {
			this.setWhere(key, result);
		} else {
			this.where = result;
		}
		return this;
	}

	setBracket(conditions, type) {
		const execFunc = () => {
			if (conditions.length == 0) {
				conditions = [new Condition()];
			}
			if (type == ConditionType.OR) {
				return conditions.map((condition) => {
					if (condition instanceof Condition) {
						return [condition];
					} else {
						return condition;
					}
				});
			} else {
				return conditions.map((condition) => {
					if (condition instanceof Array) {
						return {
							value: condition,
						};
					} else {
						return condition;
					}
				});
			}
		};
		this.result = execFunc();
		this.type = type;
		return this;
	}
}
/*
const model = new ConditionModel();
//((A=1 AND B=2) AND ((C=3 OR F=4)  OR (D=5 & E=6 & G=8)))
model.setBracket([], ConditionType.AND).save();
console.log(JSON.stringify(model.where, null, "\t"));
model.setWhere("0.value.0", new Condition("A", "1", OpeartionType.EQ));
model.setWhere("0.value.1", new Condition("B", "2", OpeartionType.EQ));
console.log(JSON.stringify(model.where, null, "\t"));
*/

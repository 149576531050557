import React, { Component } from 'react'


import SalaryDetails from '../../Addemployee/SalaryDetails';
import { LayoutWrapper } from '../../../LayoutWrapper';
import { tabs, checkOnboardTab, navigateNextTab } from '../onboarding';

export class SalaryDetailsView extends Component {

    componentDidMount() {
        checkOnboardTab(this.props);
        window.SalaryDetailsView = this;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.api.apiUpdateEmployeeProfileSalary(e.target.getData()).then((data) => {
            console.log({ apiUpdateEmployeeProfileSalary: data });
            navigateNextTab(this.props);
        });
        console.log(e);
    }
    
    render() {
        return (
            <LayoutWrapper title="Employee Onboarding" back="Employee">
                <AppLinkTabs data={tabs}></AppLinkTabs>
                <SalaryDetails onSubmit={(...args) => this.handleSubmit(...args)} ></SalaryDetails>
            </LayoutWrapper>
        )
    }
}

export default connect(SalaryDetailsView);
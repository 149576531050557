import React, { Component } from 'react'
import LayoutWrapper from '../../LayoutWrapper';
import { tabs } from '../leavemanagment';

export class LeaveConfig extends Component {
    constructor(props) {
        super(props);
        this.links = tabs;
        this.columns = [
            {
                dataIndex:"_id",
                display:false
            },
            {
                dataIndex:"employer_obj_id",
                display:false
            },
            {
                dataIndex:"created_at",
                display:false
            },
            {
                dataIndex: "holiday_date",
                render: (text) => (text.date().moment().format("DD MMM YYYY"))
            }
        ];

        this.state = {
            holidayList: this.props.store.holidayList
        };
    }

    componentDidMount() {
        
    }

    render() {
        let { links, columns = [] } = this;
        let { holidayList = [] } = this.state;
        return (
            <LayoutWrapper title="LeaveConfig" back="Leave Management">
                <AppLinkTabs data={links} linkClass="!min-w-[200px]"></AppLinkTabs>
                <div className='flex mb-6'>
                    <AppInput type="radio" name="year_type">
                        <span className='px-2 text-base font-bold'>Calender Year</span>
                    </AppInput>
                    <AppInput type="radio" name="year_type">
                        <span className='px-2 text-base font-bold'>Fiancial Year</span>
                    </AppInput>
                    <div className='ml-auto '>
                        <button className='btn add-btn'>+ Add Another Leave</button>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col'>
                        <AppTable
                            data={holidayList}
                            columns={columns}
                        >
                        </AppTable>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default connect(LeaveConfig);
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Divider, Radio, Table } from "antd";
import EmployeeSearch from "../employee/EmployeeSearch";
import EmployeeHeader from "../employee/EmployeeHeader";
import moment from "moment";

export class InactiveEmployeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      employeeData: [],
      search: {
        page: 1,
        status: "disabled",
      },
      deleteAction: "temp",
      last_working_day: new Date(),
      disabled_remarks: "",
    };
    this.actionFormShow = false;
    this.fields = [
      {
        name: "lastdate",
        label: "Select Last Working Date",
        type: "date",
        value: new Date().toISOString().slice(0, 10),
        className: "select-focus",
      },
      {
        name: "remarks",
        label: "Remarks",
        type: "text",
        className: "select-focus",
      },
    ];
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <h2 className="table-avatar">
            <Link to={"/admin/profile/employee-profile?emp_id=" + record.id}>
              {text} <span>{record.designation}</span>
            </Link>
          </h2>
        ),
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        dataIndex: "image",
        display: false,
      },
      {
        dataIndex: "role",
        display: false,
      },
      {
        title: "Employee ID",
        dataIndex: "employee_id",
        sorter: (a, b) => a.employee_id.length - b.employee_id.length,
      },

      {
        title: "Email ID",
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },

      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.length - b.mobile.length,
      },

      {
        title: "Join Date",
        dataIndex: "joindate",
        sorter: (a, b) => a.joindate.length - b.joindate.length,
      },
      {
        title: "Last Working day",
        dataIndex: "last_working_day",
        render: (text) => moment(text).format("DD MMM YYYY"),
      },
      {
        title: "Department",
        dataIndex: "department",
      },
      {
        title: "Location",
        dataIndex: "work_location",
      },
      {
        title: "Remarks",
        dataIndex: "disabled_remarks",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text) => text.toTitleCase(),
      },
    ];
  }

  fetchList(params = {}) {
    let payload = {
      ...params,
      ...mixins.params(),
      ...this.state.search,
    };
    console.log({ payload, search: this.state.search, params });
    this.props.api.apiEmployeeData(payload).then((responce) => {
      this.setState({
        rowcount: responce.total_count,
        employeeData: responce.data,
      });
    });
  }

  componentDidMount() {
    console.log({ componentDidMount: "EmployeeList" });
    window.EmployeeList = this;
    this.onSearch({ status: "disabled" });
  }
  onSearch(data) {
    if (Object.keys(data).length === 0) {
      this.setState(
        {
          search: {
            page: this.state.search.page,
            limit: 10,
            status: "disabled",
          },
        },
        () => this.fetchList()
      );
    } else {
      this.setState(
        {
          search: {
            page: "",
            limit: "",
            status: "disabled",
          },
        },
        () => this.fetchList(data)
      );
    }
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {
    if (field.name == "lastdate") {
      this.setState({
        ...this.state,
        last_working_day: value,
      });
    }
    if (field.name == "remarks") {
      this.setState({
        ...this.state,
        disabled_remarks: document.querySelector(`input[name=${field.name}]`)
          .value,
      });
    }
  }

  onPageChange(pageData) {
    let payload = this.props.inactive === true ? { status: "disabled" } : {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList(payload)
    );
  }

  editEmployee(record) {
    let url = "/admin/profile/employee-profile?emp_id=" + record.id;
    this.props.navigate(url);
  }

  // deleteEmployee(record) {
  //   prompt(
  //     "Please Choose Delete Action?",
  //     {},
  //     <div>
  //       <Radio.Group
  //         onChange={(e) => {
  //           this.setState({
  //             deleteAction: e.target.value,
  //           });
  //           document.querySelector("#actionForm").classList.toggle("d-none");
  //         }}
  //         defaultValue="temp"
  //         buttonStyle="solid"
  //         className="mb-3"
  //       >
  //         <Radio.Button value="temp" danger>
  //           Temporary
  //         </Radio.Button>
  //         <Radio.Button value="permanent" danger>
  //           Permanent
  //         </Radio.Button>
  //       </Radio.Group>
  //       <AppForm id="actionForm">
  //         {this.fields.map((field, i) => (
  //           <div className="col-sm-12" key={i}>
  //             <AppInputFocus
  //               {...field}
  //               onChange={(...arg) => this.onFilterChange(field, ...arg)}
  //             ></AppInputFocus>
  //           </div>
  //         ))}
  //       </AppForm>
  //     </div>
  //   ).promise.then(() => {
  //     if (this.state.deleteAction === "permanent") {
  //       this.props.api
  //         .apiDeleteEmployee({
  //           emp_id: record.id,
  //         })
  //         .then(() => {
  //           this.fetchList();
  //         });
  //     } else {
  //       this.props.api
  //         .apiSoftDeleteEmployee(
  //           {
  //             disabled_remarks: this.state.disabled_remarks,
  //             last_working_day: this.state.last_working_day,
  //           },
  //           { emp_id: record.id }
  //         )
  //         .then(() => {
  //           this.fetchList();
  //         });
  //     }
  //     this.setState({
  //       deleteAction: "temp",
  //     });
  //   });
  // }

  render() {
    let { employeeData } = this.state;
    let { columns } = this;
    return (
      <>
        <EmployeeHeader />
        <EmployeeSearch
          hideStatus="true"
          inactive={this.props.inactive}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={employeeData}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              editAction={(data) => this.editEmployee(data)}
              deleteAction={(data) => this.deleteEmployee(data)}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </>
    );
  }
}

export default connect(InactiveEmployeeList);

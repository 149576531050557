import React, { Component } from "react";

export class schemeSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("");
    this.state = {
      search: {
        limit: 20,
        orderBy: "updatedAt",
        orderDirection: "DESC",
        page: 1,
      },
    };
    this.formFields = [
      {
        type: "select",
        name: "projectName",
        placeholder: "Select Sheme Name",
        required: true,
      },
    ];
  }

  handleSchemeName(data) {
    this.props.onSearch && this.props.onSearch(data[0]?.name);
  }

  componentDidMount() {
    this.fetchProjectName();
  }

  fetchProjectName() {
    this.props.api.apiGetAllSchemes().then(({ data }) => {
      let projects = this.formFields.find(
        (o) => o.name == "projectName"
      );
      projects.options = data?.items[0]?.records?.options("_id", "name");
    });
  }

  render() {
    return (
      <>
        <form
          className="row filter-row"
          onSubmit={(...args) => this.onSearch(...args)}
        >
          <div className="col-lg-3 col-sm-6 col-md-3">
            <div className="">
              {/* <button className="search-icon-section bg-white" type="button">
                <i className="search-icon-left fa fa-search"></i>
              </button> */}
              {/* <div className="row"> */}
              {this.formFields?.map((field, i) => (
                <div
                  className={
                  field.fieldclass
                    ? field.fieldclass
                    : "col-12 col-md-6 col-sm-8"
                }
                style={{ minWidth: '240px'}}
                key={"field" + i}
                >
                  <AppInput
                    {...field}
                    onChange={(...args) => this.handleSchemeName(...args)}
                  ></AppInput>
                </div>
              ))}
              </div>
              {/* <button
                className="cross-icon-section bg-white"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                <i className="search-icon-left fa fa-close"></i>
              </button> */}
            {/* </div> */}
          </div>
        </form>
      </>
    );
  }
}

export default connect(schemeSearch);

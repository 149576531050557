import { Component } from "react";
import { AccountDetailsFields } from "../../employee/Addemployee/AccountDetails";
import { ColFields } from "./employee-profile";

export class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: {},
    };
  }

  labelMaps = {
    ifscCode: "IFSC Code",
  };

  componentDidMount() {
    let { data } = this.props;
    console.log(data, "");
    this.setState({
      accountDetails: {
        accountNum: data?.bank_account_num,
        ifscCode: data?.bank_ifsc_code,
        bankName: data?.bank_name,
        branchName: data?.bank_branch_name,
      },
    });
  }

  render() {
    let { labelMaps } = this;
    let { accountDetails } = this.state;
    return (
      <>
        <div className="mb-4 flex">
          <div>
            <img className="h-10 w-10 m-auto" src={imagepaths.wallet} />
          </div>
          <div className="text-xl mx-2 font-semibold m-auto">
            Account Details
          </div>
        </div>
        <ColFields data={accountDetails} labelMaps={labelMaps} />
      </>
    );
  }
}

export default connect(AccountDetails);

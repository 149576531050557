/**
 * App Header
 */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import sidemenu from "./sidemenu";
import weSidemenu from "../schemes/sidemenu";

const Sidebar = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const [sidemenuData, setSidemenuData] = useState([]);
  const [menu, setMenu] = useState({});

  useEffect(() => {
    // if (!isAdmin()) {
    //   let filteredMenus = sidemenu.filter((v) => v.id !== "course_configure");
    //   setSidemenuData(filteredMenus);
    // } else if (isWomenEntrepreneur()) {
    //   let filteredMenus = weSidemenu.filter(
    //     (v) =>
    //       v.id !== "dashboard" &&
    //       v.id !== "womenentrepreneur" &&
    //       v.id !== "vendormanagement"
    //   );
    //   setSidemenuData(filteredMenus);
    // } else {
    //   let filteredMenus = sidemenu.filter((v) => v.id !== "courses");
    //   setSidemenuData(filteredMenus);
    // }

    if (isAdmin()) {
      let filteredMenus = sidemenu.filter((v) => v.id !== "schemes");
      setSidemenuData(filteredMenus);
    } else if (isWomenEntrepreneur()) {
      let filteredMenus = weSidemenu.filter(
        (v) =>
          v.id !== "dashboard" &&
          v.id !== "womenentrepreneur" &&
          v.id !== "vendormanagement" &&
          v.id !== "schemes"
      );
      setSidemenuData(filteredMenus);
    } else {
      let filteredMenus = sidemenu.filter(
        (v) => v.id !== "course_configure" && v.id !== "assessmentreview"
      );
      setSidemenuData(filteredMenus);
    }
  }, []);

  const isAdmin = () => {
    return props.store.user?.employee_role === "admin";
  };

  const isWomenEntrepreneur = () => {
    return props.store.user?.employee_role === "woman-entrepreneur";
  };

  const MenuLink = ({
    value,
    icon = "la la-dashboard",
    name,
    children,
    className,
  }) => (
    <>
      <a
        href="#"
        className={Object.className({
          [className]: true,
          subdrop: isSideMenu == value,
        })}
        onClick={() => toggleSidebar(isSideMenu == value ? "" : value)}
      >
        <i className={icon} />
        <span>{name}</span>
        <span className="menu-arrow" />
      </a>
      {isSideMenu == value && children}
    </>
  );

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };

  let pathname = props.location.pathname;
  return (
    <div className="sidebar" id="sidebar">
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax="95vh"
        thumbMinSize={30}
        universal={false}
        hideTracksWhenNotNeeded={true}
      >
        {!!menu && " "}
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              {/* <li className="menu-title">
                <span>Main</span>
              </li> */}
              {sidemenuData?.map((menu, i) => (
                <li
                  key={i}
                  className={Object.className({
                    submenu: menu.children && menu.children.length,
                  })}
                >
                  {menu.children && menu.children.length && (
                    <MenuLink
                      name={menu.label}
                      value={menu.label.toLowerCase()}
                      icon={menu.icon}
                      className={Object.className({
                        active: pathname.startsWith(menu.path),
                      })}
                    >
                      <ul>
                        {menu.children.map((submenu, j) => (
                          <li key={j}>
                            <Link
                              className={Object.className({
                                active: pathname == submenu.path,
                              })}
                              onClick={() => setMenu(submenu)}
                              to={submenu.path}
                            >
                              {submenu.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </MenuLink>
                  )}
                  {!(menu.children && menu.children.length) && (
                    <Link
                      to={menu.path}
                      onClick={() => {setMenu(menu.submenu); location.reload()}}
                      className={Object.className({
                        active:
                          pathname == menu.path ||
                          (menu.activepath &&
                            pathname.startsWith(menu.activepath)),
                      })}
                    >
                      {menu.icon && <i className={menu.icon} />}
                      {menu.image && <img src={menu.image} />}
                      <span>{menu.label}</span>
                      {pathname == menu.path && (
                        <span className={`!ml-auto ${menu.path}`}>
                          <em className="la la-hand-o-left"></em>
                        </span>
                      )}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <hr className="sidebar_hr" />
            {isAdmin() && (
              <Link to="/welcome" className="log_btn">
                Back to Home
              </Link>
            )}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default connect(Sidebar);

import { Component } from "react";
import { Helmet } from "react-helmet";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empDetails: [],
      rowcount: 0,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      columns: [
        {
          dataIndex: "job_id",
          title: "Job Id",
          display: false,
        },
        {
          dataIndex: "job_name",
          title: "Job Name",
          fixed: "left",
          render: (text, record) => <b>{record.job_name}</b>,
        },
        {
          dataIndex: "total",
          title: "Total Applicants",
          fixed: "left",
          render: (text, record) => <b>{record.total}</b>,
        },
        {
          dataIndex: "selected",
          title: "Selected Applicants",
          fixed: "left",
          render: (text, record) => <b>{record.selected}</b>,
        },
        {
          dataIndex: "rejected",
          title: "Rejected Applicants",
          fixed: "left",
          render: (text, record) => <b>{record.rejected}</b>,
        },
      ],
      dashboardTopData: {},
    };
    this.funnelStatus = _enum.APPLICANT_DASHBOARD_FUNNEL_STATUS;
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  componentDidMount() {
    // this.props.api.apiEmpDashboardDataMoke().then((data) => {
    //   console.log(data);
    //   let mergedData = {
    //     employee_details: [
    //       {
    //         label: "Total Employees",
    //         value: data?.employee_details?.total_emplyees,
    //         icon: imagepaths.userIcon,
    //       },
    //       {
    //         label: "Active Employees",
    //         value: data?.employee_details?.active_emplyees,
    //         icon: imagepaths.userCheckIcon,
    //       },
    //       {
    //         label: "Today’s Present",
    //         value: data?.employee_details?.todays_present,
    //         icon: imagepaths.multiUserIcon,
    //       },
    //       {
    //         label: "Today’s Absent",
    //         value: data?.employee_details?.todays_absent,
    //         icon: imagepaths.userCrossIcon,
    //       },
    //     ],
    //     task_manager: [
    //       {
    //         label: "Last 2 Months",
    //         value: data?.task_manager?.last_months,
    //         icon: imagepaths.userIcon,
    //       },
    //       {
    //         label: "Total Task",
    //         value: data?.task_manager?.total_task,
    //         icon: imagepaths.roundedCheckIcon,
    //       },
    //       {
    //         label: "Open Task",
    //         value: data?.task_manager?.open_task,
    //         icon: imagepaths.taskcopyIcon,
    //       },
    //       {
    //         label: "Closed Task",
    //         value: data?.task_manager?.closed_task,
    //         icon: imagepaths.taskCheckIcon,
    //       },
    //     ],
    //   };
    //   this.setState({
    //     empDetails: mergedData,
    //   });
    // });
    if (this.isAdmin) {
      this.props.api.apiGetAdminDashboard().then((data) => {
        console.log({ apiGetAdminDashboard: data });
        let mergedData = {
          employee_details: [
            {
              label: "Total Employees",
              value: data?.totalEmployees,
              icon: imagepaths.userIcon,
            },
            {
              label: "Active Employees",
              value: data?.activeEmployees,
              icon: imagepaths.userCheckIcon,
            },
            {
              label: "Today’s Present",
              value: data?.presentToday,
              icon: imagepaths.multiUserIcon,
            },
            {
              label: "Today’s Absent",
              value: data?.absentToday,
              icon: imagepaths.userCrossIcon,
            },
          ],
          task_manager: [
            // {
            //   label: "Last 2 Months",
            //   value: data?.last_months,
            //   icon: imagepaths.userIcon,
            // },
            {
              label: "Total Task",
              value: data?.totalTasks,
              icon: imagepaths.roundedCheckIcon,
            },
            {
              label: "Open Task",
              value: data?.openTasks,
              icon: imagepaths.taskcopyIcon,
            },
            {
              label: "Closed Task",
              value: data?.closedTasks,
              icon: imagepaths.taskCheckIcon,
            },
          ],
        };
        this.setState({
          empDetails: mergedData,
        });
      });
    } else {
      this.props.api.apiGetEmployeeDashboard().then((data) => {
        console.log({ apiGetEmployeeDashboard: data });
        let mergedData = {
          employee_details: [
            {
              label: "Total Employees",
              value: data?.totalEmployees,
              icon: imagepaths.userIcon,
            },
            {
              label: "Active Employees",
              value: data?.activeEmployees,
              icon: imagepaths.userCheckIcon,
            },
            {
              label: "Today’s Present",
              value: data?.presentToday,
              icon: imagepaths.multiUserIcon,
            },
            {
              label: "Today’s Absent",
              value: data?.absentToday,
              icon: imagepaths.userCrossIcon,
            },
          ],
          task_manager: [
            // {
            //   label: "Last 2 Months",
            //   value: data?.last_months,
            //   icon: imagepaths.userIcon,
            // },
            {
              label: "Total Task",
              value: data?.totalTasks,
              icon: imagepaths.roundedCheckIcon,
            },
            {
              label: "Open Task",
              value: data?.openTasks,
              icon: imagepaths.taskcopyIcon,
            },
            {
              label: "Closed Task",
              value: data?.closedTasks,
              icon: imagepaths.taskCheckIcon,
            },
          ],
        };
        this.setState({
          empDetails: mergedData,
        });
      });
    }
  }

  // cardAction(o, i) {
  //   let now = new Date().toDate();
  //   switch (o.label.snakeCase()) {
  //     case "total_employees":
  //     case "active_employees":
  //       this.props.navigate("/admin/employee");
  //       break;
  //     case "today's_present":
  //       this.props.navigate(`/admin/attendance/daywiseattendace`);
  //       break;
  //     case "today's_absent":
  //       this.props.navigate(`/admin/attendance/daywiseattendaceabsent`);
  //       break;
  //     case "on_leave":
  //       this.props.navigate("/admin/leavemanagment/approvedleaves");
  //       break;
  //     case "leave_approvals":
  //       this.props.navigate("/admin/leavemanagment");
  //       break;
  //     case "exception_approvals":
  //       this.props.navigate(
  //         "/admin/attendance/exceptionapprovals?status=PENDING"
  //       );
  //       break;
  //     case "exceptions":
  //       this.props.navigate("/admin/attendance/approvedexceptions");
  //       break;
  //   }
  // }

  render() {
    let { dashboardTopData, empDetails } = this.state;
    return (
      <>
        <Helmet>
          <title>Dashboard - Access Livelihoods</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Welcome Admin!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <p className="dash-widget-heading">Employee Details</p>
            {empDetails?.employee_details?.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div
                  className="card dash-widget cursor-pointer"
                  // onClick={() => this.cardAction(o, i)}
                >
                  <div className="card-body">
                    <span className="dash-widget-icon">
                      <img src={o.icon} alt="" />
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.value}</h3>
                      <span>{o.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <p className="dash-widget-heading">Task Manager</p>
            {empDetails?.task_manager?.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div className="card dash-widget cursor-pointer">
                  <div className="card-body">
                    <span className="dash-widget-icon">
                      <img src={o.icon} alt="" />
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.value}</h3>
                      <span>{o.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default connect(AdminDashboard);

import React, { Component } from "react";
export class ExpenseSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ExpenseSearch");
    this.form = React.createRef();
    this.state = {
      projectOptions: [],
      taskOptions: [],
      projectListData: [],
      financialYears: [],
      projectDetails: {},
      taskDetails: {},
      budgetOptions: [],
      search: {
        limit: 20,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "project_id",
        label: "Project ID",
        type: "select",
        options: [],
        className: "select-focus",
        onChange: (v) => this.handleProjectChange(v),
      },
      {
        name: "project_fy_id",
        label: "Financial Year",
        type: "select",
        options: [],
        className: "select-focus",
        onChange: (v) => this.handleProjectFYChange(v),
      },
      {
        name: "task_id",
        label: "Task Name",
        type: "select",
        options: [],
        className: "select-focus",
        onChange: (v) => this.handleTaskNameChange(v),
      },
      {
        name: "budget_id",
        label: "Expense Category",
        type: "select",
        options: [],
        className: "select-focus",
      },
    ];
  }

  onSearch(reset) {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);
    let payload;
    data = Object.filter(data, (val) => val);

    if (reset) {
      payload = {
        ...this.state.search,
      };
    } else {
      payload = {
        ...data,
      };
    }
    console.log("data on search", payload);
    this.props.onSearch && this.props.onSearch(reset, payload);

    // this.props.onSearch && this.props.onSearch(data);
  }

  componentDidMount() {
    this.setProjects();
  }

  setProjects() {
    this.props.api.apiGetProjectsList(this.state.search).then((projects) => {
      // console.log("projects", projects);

      this.setState({
        projectOptions: projects.data,
      });
    });
  }

  handleProjectChange(data) {
    console.log("handleProjectChange", data);

    if (data.length > 0) {
      this.setState({
        projectDetails: {
          project_id: data[0]?.project_id,
          projectName: data[0].project_name,
        },
      });
      this.getFinancialYearsList(data[0]?.project_id);
    }
  }

  handleProjectFYChange(data) {
    // console.log("handleProjectFYChange", data);
    if (data.length > 0) {
      let apiData = {
        project_id: this.state.projectDetails.project_id,
        project_fy_id: data[0]?.id,
      };
      this.setTasks(apiData);
    }
  }

  getFinancialYearsList(projectId) {
    this.props.api
      .apiFinancialYears({}, { project_id: projectId })
      .then(({ data, total }) => {
        this.setState({ financialYears: data });
        // console.log("financialYears: ", data);
      });
  }

  handleTaskNameChange(data) {
    if (data.length > 0) {
      this.setState({
        taskDetails: {
          taskName: data[0]?.task_name,
        },
      });
      this.getBudgets(
        data[0].task_id,
        data[0].project_id,
        data[0].project_fy_id
      );
    }
  }

  getBudgets(taskId, projectId, projectFyId) {
    this.props.api
      .apiGetBudgets({ project_fy_id: projectFyId }, { project_id: projectId })
      .then(({ data, total }) => {
        this.setState({
          budgetOptions: data.filter((v) => v.task_id === taskId),
        });
      });
  }

  setTasks(payload) {
    // console.log("setTasks: ", payload);

    this.props.api
      .apiGetTasks({ ...this.state.search }, payload)
      .then((tasks) => {
        // console.log("setTasks: ", tasks);

        this.setState({
          taskOptions: tasks.data.options(),
        });
      });
  }

  updateFields(fields) {
    fields.find((o) => o.name === "project_id").options =
      this.state.projectOptions.options("project_id", "project_name");
    fields.find((o) => o.name === "project_fy_id").options =
      this.state.financialYears.options("id", "financial_year");
    fields.find((field) => field.name === "task_id").options =
      this.state.taskOptions.options("task_id", "task_name");
    fields.find((o) => o.name === "budget_id").options =
      this.state.budgetOptions.options("budget_id", "budget_name");
    return fields;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.setState({
      projectOptions: [],
      financialYears: [],
      taskOptions: [],
      budgetOptions: [],
    });
    this.setProjects();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    let { fields } = this;

    fields = this.updateFields(this.fields);

    return (
      <>
        <AppForm className="row filter-row" ref="form">
          {this.props.hideStatus
            ? fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-3 col-lg-4 col-xl-2" key={i}>
                    <AppInputFocus {...field}></AppInputFocus>
                  </div>
                ))
            : fields.map((field, i) => (
                <div className="col-sm-6 col-md-3 col-lg-4 col-xl-2" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-3 col-lg-4 col-xl-2">
            <button
              className="btn btn-success btn-block w-100 mb-4"
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i class="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(ExpenseSearch);

import { Component, useRef } from "react";
import { LayoutWrapper } from "../../LayoutWrapper";
import EditDocList from "./EditDocList";
import EditProfile from "./EditProfile";
import ProfileDetails from "./ProfileDetails";

var triggerVal;
export const ProfileCard = ({
  children,
  ActionTag = (p) => <span {...p}></span>,
  onEdit = (v) => v,
  onDelete = (v) => v,
  onCheck = (v) => v,
  editable = true,
  profileaction = false,
}) => (
  <AppCard className="flex-fill">
    <div className="profile-view">
      <div className="profile_action_box">
        {profileaction && (
          <>
            <div className="pro-delete">
              <button
                type="button"
                className="cursor-pointer border-0 bg-transparent profile_btn red"
                onClick={(e) => onDelete(e)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="pro-check">
              <button
                type="button"
                className="cursor-pointer border-0 bg-transparent profile_btn yellow"
                onClick={(e) => onCheck(e)}
              >
                <i className="fa fa-check"></i>
              </button>
            </div>
          </>
        )}
        <div className={editable ? "pro-edit" : "pro-edit d-none"}>
          <a
            href="#"
            className="cursor-pointer text-black"
            onClick={(e) => onEdit(e)}
          >
            <img src={imagepaths.pencilplus} className={"h-5 w-5"} />
          </a>
        </div>
      </div>
      <div>{children}</div>
    </div>
  </AppCard>
);

export const ColFields = ({
  data,
  labelMaps,
  size = 1,
  valueReduce = (v) => v,
  colclass = "col-12",
}) => {
  console.log({
    "ColFields in emp": Object.entries(data).chunk(size).transposeSwap(),
    size,
  });
  return (
    <>
      {Object.entries(data)
        .chunk(size)
        .transposeSwap()
        .map((row, i) => (
          <Row className="mx-0" key={i}>
            {row?.map(([label, value], j) => (
              <Col key={j} className={"p-2 " + colclass}>
                <label className="text-gray-500 text-base font-medium">
                  {labelMaps[label] || label.toTitleCase()}
                </label>
                <span className="px-2">:</span>
                <span className="text-base font-medium">
                  {valueReduce(value, label)}
                </span>
              </Col>
            ))}
          </Row>
        ))}
    </>
  );
};

export const ActionTag = (p) => {
  const atag = useRef();
  let { onClick, children } = p;
  let attrs = Object.except(p, ["onClick"]);
  const handleAction = function () {
    onClick();
    setTimeout(() => {
      atag?.current.click();
    }, 100);
  };
  return (
    <span>
      <a
        ref={atag}
        href="#"
        data-bs-toggle="modal"
        data-bs-target={"#modal_edit_profile"}
      ></a>
      <span
        className="cursor-pointer"
        {...attrs}
        onClick={() => handleAction()}
      ></span>
    </span>
  );
};

export class EmployeeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      title: "",
      type: "EditProfile",
      fields: {},
      updateapi: "",
    };
  }

  componentDidMount() {
    window.EmpoyeeProfile = this;
  }

  updateDetails(event, data, form) {
    let { updateapi } = this.state;
    let updateFunc = this.props.api[updateapi] ?? ((v) => v);
    console.log({ updateFunc, updateapi, data, form });
    updateFunc(data).then(() => {
      this.modalcomponent.fetchData();
      document.querySelector("#bsmodalclose")?.click();
    });
  }

  handleEdit() {
    let url = `/admin/employee/onboarding?${Object.QueryString({
      emp_id: this.props.urlparams.emp_id,
      emp_name: this.props.urlparams.emp_name,
    })}`;
    this.props.navigate(url);
  }

  getBackLink() {
    let backLink = "/admin/employee";
    return backLink;
  }

  render() {
    let { state } = this;
    let { data = {}, fields = {}, title, type } = state;
    return (
      <LayoutWrapper
        title="Employee Profile"
        back={`Employees / ${this.props?.urlparams?.emp_name}`}
        backlink={this.getBackLink()}
        className={"employee-profile"}
      >
        <ProfileDetails onEdit={(...arg) => this.handleEdit(...arg)} />
        {type == "EditProfile" && data && (
          <EditProfile
            id="modal_edit_profile"
            data={data}
            title={title}
            fields={fields}
            type={type}
            onSubmit={(...arg) => this.updateDetails(...arg)}
            onClose={() => this.setState({ data: null })}
          />
        )}
        {type == "EditDocList" && (
          <EditDocList
            fetchDocsDetails={this.fetchDocsDetails}
            id="modal_edit_profile"
            data={data}
            title={title}
          />
        )}
      </LayoutWrapper>
    );
  }
}

export default connect(EmployeeProfile);

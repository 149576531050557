export const smMapcolumns = {
    attachments: {
        attachment_id: "attachmentId",
        file_name: "attachmentName",
    },
    courses: {
        course_id: "course_id",
        course_name: "course_name",
        roles: "roles",
        course_type: "course_type",
        course_difficulty_level: "course_difficulty_level",
        course_description: "course_description",
        created_by: "created_by",
        last_updated_by: "last_updated_by",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    modules: {
        course_id: "course_id",
        module_name: "module_name",
        module_description: "module_description",
        is_active: "is_active"
    },
    media: {
        course_id: "course_id",
        module_id: "module_id",
        media_name: "media_name",
        link: "link",
        thumbnail: "thumbnail",
        description: "description",
        is_active: "is_active"
    },
    ppt: {
        course_id: "course_id",
        module_id: "module_id",
        ppt_name: "ppt_name",
        ppt_description: "ppt_description",
        attachment_id: "attachment_id",
        is_active: "is_active"
    },
    assessments: {
        assessment_id: "assessment_id",
        assessment_name: "assessment_name",
        assessment_questions: [
            {
                q_label: "q_label",
                q_type: "q_type",
                q_options: [
                    "q_options"
                ],
                q_level: "q_level",
                a_value: "a_value",
            }
        ],
    },
    mapFields(name, data) {
        let fields = Object.keys(data);
        let mapper = this[name] || {};
        // console.log(fields);
        return fields.reduce((c, key) => {
            let mapobj = mapper[key];
            let newkey = mapobj || key;
            newkey = typeof newkey === "string" ? newkey : newkey.key;
            newkey = newkey || key;
            let mapvalue = data[key];
            if (mapobj && mapobj.value instanceof Function) {
                mapvalue = mapobj.value(mapvalue, c);
            } else if (mapobj && ![undefined, null].includes(mapobj.value)) {
                mapvalue = mapobj.value;
            }
            return {
                ...c,
                [newkey]: mapvalue,
            };
        }, {});
    },
    mapPayload(name, data, excepts = []) {
        let payload = { ...data };
        let mapper = this[name] || {};
        mapper = Object.entries(mapper).reduce((c, [key, value]) => {
            if (value.rkeys && value.rkeys instanceof Array) {
                let commonMap = {};
                value.rkeys.forEach(key => {
                    if (Object.keys(payload).includes(key)) {
                        c[key] = {
                            commonMap,
                            key: value.key,
                            value: value.value,
                            reversevalue: value.reversevalue,
                            mapkey: key,
                        };
                    }
                })
            }
            return c
        }, mapper);
        // console.log({ mapper });
        if (mapper) {
            let mapdata = Object.entries(mapper).reduce((c, [key, value]) => {
                let newkey = value || key;
                newkey = typeof newkey === "string" ? newkey : newkey.key;
                newkey = newkey || key;
                if (excepts.includes(newkey)) {
                    key = newkey;
                }
                let reversevalue = value.reversevalue || ((v) => v);
                return {
                    ...c,
                    [key]: {
                        mapkey: newkey,
                        value: reversevalue(data[newkey], { collector: c, data: payload, mapper: value })
                    },
                };
            }, {});
            let filterMapData = Object.filter(mapdata, (v) => ![undefined].includes(v.value));
            let retData = Object.map(filterMapData, (v) => v.value);
            let filterMapKeys = Object.values(filterMapData).map(v => v.mapkey);
            let restData = Object.filter(payload, (v, k) => !filterMapKeys.includes(k));
            return {
                ...restData,
                ...retData
            };
        } else {
            return data;
        }
    },
};
window.smMapcolumns = {
    ...(window.smMapcolumns || {}),
    ...smMapcolumns,
};
export default smMapcolumns;

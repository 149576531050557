import devapis from "./devapis";
import stagingapis from "./stagingapis";
import prodapis from "./prodapis";
const localtodev = "http://localhost:3000,http://localhost:3001,";
const localtostage = "";
const localtoprod = "";
export const config = {
    "dev": {
        "base": `${localtodev}http://alh.impetusaisolutions.com`,
        "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
        "static_token": "",
        ...devapis,
        "open/login": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
            ...devapis,
        },
        "datacollection": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...devapis,
        },
        "projectmanagement": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...devapis,
        },
        "public/dcfresponse": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...devapis,
        },
        "lms": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...devapis,
        },
        "womenentrepreneur": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gateway-api.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...devapis,
        },
        "schemes": {
            "base": `${localtodev}http://alh.impetusaisolutions.com`,
            "api_base": "http://alh-gschemes.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...devapis,
        },
    },
    "staging": {
        "base": `${localtostage}http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
        "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
        "static_token": "",
        ...stagingapis,

        "open/login": {
            "base": `${localtostage}http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
            "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
            ...stagingapis,
        },
        "datacollection": {
            "base": `${localtostage},http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
            "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...stagingapis,
        },
        "projectmanagement": {
            "base": `${localtostage}http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
            "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...stagingapis,
        },
        "public/dcfresponse": {
            "base": `${localtostage}http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
            "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...stagingapis,
        },
        "lms": {
            "base": `${localtostage}http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
            "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...stagingapis,
        },
        "womenentrepreneur": {
            "base": `${localtostage}http://alh-stage.impetusaisolutions.com,https://alh-stage.impetusaisolutions.com`,
            "api_base": "https://alh-stagegw.impetusaisolutions.com/api/v1",
            "static_token": "",
            ...stagingapis,
        },
    },
    "production": {
        "base": `${localtoprod}https://login.alcindia.org`,
        "api_base": "https://apigw.alcindia.org/api/v1",
        "static_token": "",
        ...prodapis,

        "open/login": {
            "base": "http://alh.impetusaisolutions.com",
            "api_base": "https://apigw.alcindia.org/api/v1",
            ...prodapis,
        },
        "datacollection": {
            "base": `${localtoprod}https://login.alcindia.org`,
            "api_base": "https://apigw.alcindia.org/api/v1",
            "static_token": "",
            ...prodapis,
        },
        "projectmanagement": {
            "base": `${localtoprod}https://login.alcindia.org`,
            "api_base": "https://apigw.alcindia.org/api/v1",
            "static_token": "",
            ...prodapis,
        },
        "public/dcfresponse": {
            "base": `${localtoprod}https://login.alcindia.org`,
            "api_base": "https://apigw.alcindia.org/api/v1",
            "static_token": "",
            ...prodapis,
        },
        "lms": {
            "base": `${localtoprod}https://login.alcindia.org`,
            "api_base": "https://apigw.alcindia.org/api/v1",
            "static_token": "",
            ...prodapis,
        },
        "womenentrepreneur": {
            "base": `${localtoprod}https://login.alcindia.org`,
            "api_base": "https://apigw.alcindia.org/api/v1",
            "static_token": "",
            ...prodapis,
        },
        "schemes": {
            "base": `${localtoprod}https://login.alcindia.org`,
            "api_base": "https://gschemes.alcindia.org/api/v1",
            "static_token": "",
            ...prodapis,
        },
    }
}

export default config;